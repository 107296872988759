import React from "react";
import "./DepBooks.css";
import { BreadcrumbAdmin } from "../../../../UI/BreadcrumbAdmin";
import { useGetCoursesByDepartmentQuery } from "../../../../../redux-toolkit/features/department/departmentApiSlice";
import ProgressBar from "../../../../UI/ProgressBar";
import { useParams } from "react-router-dom";
import { Error404 } from "../../../../UI/404/Error404";
import { NoRecord } from "../../../../UI/No record/NoRecord";

let image =
  "https://images.unsplash.com/photo-1589251204996-3367cc27f084?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1155&q=80";

const base = [
  {
    _id: 1,
    link: "/super-admin/manage-organization/departments",
    label: "Department",
  },
];

export const DepBooks = () => {
  const { departmentId } = useParams();
  const [more, setMore] = React.useState(0);
  const { data, isLoading, isError } =
    useGetCoursesByDepartmentQuery(departmentId);
  if (isError) {
    return <Error404 />;
  }
  if (isLoading) {
    return <ProgressBar />;
  }

  return (
    <div className="container">
      <BreadcrumbAdmin base={base} page={data.data.department.name} />
      <div className="depBooks">
        {data &&
          data?.data.courses.map((curElm, index) => (
            <div
              key={index}
              className={
                more === curElm._id ? "depBooksCardsActive" : "depBooksCards"
              }
            >
              <img src={image} alt="" />
              <div className="depBooksCards__content">
                <h5 className="ftwt__m">{curElm.name}</h5>
                <div className="seprator"></div>
                <p>{curElm.description}</p>
                {curElm.description.length > 70 && more != curElm._id && (
                  <p
                    onClick={() => setMore(curElm._id)}
                    className="depBooksCards__more"
                  >
                    Read more
                  </p>
                )}
                {more === curElm._id && (
                  <p onClick={() => setMore(0)} className="depBooksCards__more">
                    less
                  </p>
                )}
              </div>
            </div>
          ))}
        {data?.data.courses.length <= 0 && <NoRecord />}
      </div>
    </div>
  );
};
