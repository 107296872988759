import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import "./GraphCards.css";
import { Button, Checkbox, FormControlLabel, Radio } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
import StarIcon from '@mui/icons-material/Star';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { createPopper } from '@popperjs/core';
import { Popper } from 'react-popper';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import { setSelectBooks } from '../../../../redux-toolkit/features/theme/themeSlice';
import { useDispatch, useSelector } from 'react-redux';

export const GraphCards = ({
  item,
  isUser,
  isBooks,
  bookData,
  _id,
  defaultData,
  isStar,
}) => {
  const chartRef = useRef(null);
  const selectBooks = useSelector((state) => state.theme.selectBooks);
  const referenceElement = useRef(null);
  const popperElement = useRef(null);
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const [select, setSelect] = useState(false);
  const ref = React.useRef(null);
  const dispatch = useDispatch();

  const closePopup = () => {
    setSelect(false);
  };

  useOutsideClick(ref, closePopup);

  let popperInstance;
  useEffect(() => {
    const all = bookData?.data.map((item) => item._id);
    dispatch(setSelectBooks(all));
  }, []);

  useEffect(() => {
    const all = bookData?.data.map((item) => item._id);
    dispatch(setSelectBooks(all));
  }, [bookData]);

  const allBookSelect = (e) => {
    if (e.target.checked) {
      const all = bookData?.data.map((item) => item._id);
      dispatch(setSelectBooks(all));
    } else {
      dispatch(setSelectBooks([]));
    }
  };

  useEffect(() => {
    popperInstance = createPopper(
      referenceElement.current,
      popperElement.current,
      {
        placement: 'top',
        modifiers: {
          offset: {
            offset: '0, 10',
          },
        },
      }
    );

    return () => {
      popperInstance.destroy();
    };
  }, []);

  const handleMouseEnter = () => {
    setTooltipOpen(true);
  };

  const handleMouseLeave = () => {
    setTooltipOpen(false);
  };

  function checkIfAllZero(arr) {
    return arr?.every((value) => value === 0);
  }
  let checkAllZero = checkIfAllZero(item?.dataValue);

  useEffect(() => {
    const chartOptions = {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: checkAllZero ? defaultData : item?.dataValue,
            backgroundColor: checkAllZero ? ['#c3c3c3'] : item?.backgroundColor,
            // hoverBackgroundColor: ["#FF6384", "#36A2EB"],
            borderWidth: 0,
          },
        ],
      },
    };
    // Create the chart instance
    const chartInstance = new Chart(chartRef.current, chartOptions);

    // Cleanup function to destroy the chart instance
    return () => {
      chartInstance.destroy();
    };
  }, [bookData, selectBooks, '', item]);

  const handleChange = (e, id) => {
    console.log([...selectBooks, id]);
    if (e.target.checked) {
      dispatch(setSelectBooks([...selectBooks, id]));
    } else {
      const newSelectBooks = selectBooks.filter((item) => item != id);
      dispatch(setSelectBooks(newSelectBooks));
    }
  };

  return (
    <div className="graphCards">
      {isUser && (
        <InfoOutlinedIcon
          ref={referenceElement}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          sx={{
            color: 'var(--syellow)',
            fontSize: '20px',
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        />
      )}
      {isUser && isTooltipOpen && (
        <div className="popperOver">
          <h6>Total Users</h6>
          Active: Atleast completed 1 Book in 2 Months
          <br />
          <span> (Either Departmental or Organizational) .</span>
          <br /> InActive: No/Less activity in last 2 months <br />
        </div>
      )}
      <div className="graphCards__headLayout">
        <h5
          className="ftwt__b"
          style={{ color: '#444444' }}>
          {item?.title}
        </h5>
        {isBooks && bookData && bookData?.data?.length > 0 && (
          <div
            ref={ref}
            className="graphCards__selectLayout">
            <Button
              variant="outlined"
              endIcon={
                <ArrowForwardIosIcon
                  sx={{
                    transform: select ? 'rotate(-90deg)' : 'rotate(90deg)',
                    color: 'grey',
                    fontSize: '12px !important',
                  }}
                />
              }
              sx={{
                color: 'var(--grey400)',
                borderColor: 'var(--grey300)',
                width: '80px',
                padding: '2px 3px !important',
                fontWeight: '400 !important',
              }}
              onClick={() => setSelect(!select)}>
              Select
            </Button>
            <div
              className={
                select ? 'graphCards__selectActive' : 'graphCards__select'
              }>
              <div className="graphCards__selectcontent">
                {bookData &&
                  bookData?.data.map((item, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          defaultChecked={true}
                          checked={
                            Array.isArray(selectBooks) &&
                            selectBooks?.filter((cur) => cur === item._id)
                              .length === 1
                          }
                          name={item._id}
                          onChange={(e) => handleChange(e, item._id)}
                          sx={{
                            padding: '0px 3px',
                            '& .MuiSvgIcon-root': {
                              fontSize: '18px',
                            },
                          }}
                        />
                      }
                      onChange={(e) => handleChange(e, item._id)}
                      label={
                        <span
                          className="truncate-1"
                          style={{ fontSize: '13px' }}>
                          Book {index + 1}
                        </span>
                      }
                    />
                  ))}
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={true}
                      checked={bookData?.data?.length === selectBooks?.length}
                      name={'all'}
                      onChange={allBookSelect}
                      sx={{
                        padding: '0px 3px',
                        '& .MuiSvgIcon-root': {
                          fontSize: '18px',
                        },
                      }}
                    />
                  }
                  onChange={allBookSelect}
                  label={
                    <span
                      className="truncate-1"
                      style={{ fontSize: '13px' }}>
                      All
                    </span>
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="graphCards__details">
        <div className="graphCards__chart">
          <canvas ref={chartRef} />
          <div className="graphCards__inner-shadow"></div>
        </div>

        <div className="graphCards__detailscount">
          {item?.details?.map((item) => (
            <div className="graphCards__content">
              <RadioButtonCheckedIcon
                sx={{ color: item?.color, fontSize: '15px' }}
              />
              <h6>{item?.label}</h6>
              <h6 className="ftwt__r  graphCards__contentValues">
                {item?.values}
              </h6>
            </div>
          ))}
        </div>
      </div>

      <div className="graphCards__bottom">
        {!isStar && (
          <Person2RoundedIcon
            sx={{ color: 'var(--pblue)', fontSize: '20px' }}
          />
        )}
        {isStar && (
          <StarIcon sx={{ color: 'var(--sstar)', fontSize: '20px' }} />
        )}
        <h6 className="ftwt__b">{item?.footerLabel}</h6>
      </div>
    </div>
  );
};
