import React, { useRef, useEffect } from "react";
import { Books } from "../../Books/Books";
import { Navbar } from "../../Navbar/Navbar";
import { Outlet } from "react-router-dom";
import { useGetInitialAppliedThemeQuery } from "../../../redux-toolkit/features/theme/themeApiSlice";
import ProgressBar from "../../UI/ProgressBar";
import { useDispatch } from 'react-redux';
import { roleToggler } from '../../../redux-toolkit/features/access/accessSlice';

const EmployeeLayout = () => {
  const { data, isLoading, refetch, isError } = useGetInitialAppliedThemeQuery({
    refetchOnMountOrArgChange: true,
  });
  const dispatch = useDispatch();
  React.useEffect(() => {
    refetch();
    dispatch(roleToggler('Employee'));
  }, []);

  if (isLoading) {
    return (
      <div style={{ backgroundColor: 'white', height: '100vh' }}>
        <ProgressBar />;
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundImage: `url(${data?.data?.bgImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}>
      <Navbar />
      <Outlet />
    </div>
  );
};

export default EmployeeLayout;
