import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cart: [],
  totalPoints: 0, // Initialize totalPoints
};

export const rewardsSlice = createSlice({
  name: 'rewards',
  initialState,
  reducers: {
    updateCart: (state, action) => {
      const { cartTotalPoints, items } = action.payload;
      state.cart = items.map((curElm) => curElm);
      state.totalPoints = cartTotalPoints;
    },
    clearCart: (state, action) => {
      state.cart = [];
      state.totalPoints = 0;
    },
    addProduct: (state, action) => {
      const { cartTotalPoints, items } = action.payload;
      state.cart = items.map((curElm) => curElm);
      state.totalPoints = cartTotalPoints;
    },
    removeProduct: (state, action) => {
      debugger;
      const product_idToRemove = action.payload._id;

      const productToRemove = state.cart.find(
        (product) => product._id === product_idToRemove
      );
      debugger;

      if (productToRemove) {
        // Update totalPoints before removing the product
        state.totalPoints -= productToRemove.points * productToRemove.quantity;
        debugger;

        state.cart = state.cart.filter(
          (product) => product._id !== product_idToRemove
        );
      }
    },

    updateQuantity: (state, action) => {
      const { product_id, newQuantity } = action.payload;
      debugger;
      const productToUpdate = state.cart.find(
        (product) => product._id === product_id
      );
      if (newQuantity === 0) {
        // return (
        state.totalPoints -= productToUpdate.points * 1;
        state.cart = state.cart.filter((product) => product._id !== product_id);
        // );
      }

      if (productToUpdate && newQuantity != 0) {
        debugger;
        if (productToUpdate.quantity < newQuantity) {
          state.totalPoints +=
            productToUpdate.points * (newQuantity - productToUpdate.quantity);
        } else {
          state.totalPoints -=
            productToUpdate.points * (productToUpdate.quantity - newQuantity);
        }
        productToUpdate.quantity = newQuantity;
      }
    },
  },
});

export const {
  addProduct,
  clearCart,
  removeProduct,
  updateCart,
  updateQuantity,
} = rewardsSlice.actions;

export default rewardsSlice.reducer;
