import React from "react";
import "./CredentialPoints.css";
import { Avatar, AvatarGroup } from "@mui/material";

export const CredentialPoints = ({ point }) => {
  return (
    <div className="bs__point_creds_parent_container">
      <div className="bs__point_creds_tier_right">
        <div className="bs__point_creds_tier_step">
          <div className="bs__point_creds_tier_badge">
            <div className="bs__point_creds_tier_badge_border"></div>
            <div
              style={{
                width: "50px",
                zIndex: 2,
              }}
            >
              <img
                style={{
                  width: "50px",
                  zIndex: 2,
                }}
                src={point?.badgeImage}
                alt="badge"
              />
            </div>
            <small className="bs__point_creds_tier_badge_text">
              {point?.badgeName}
            </small>
          </div>
          <div
            className="bs__point_creds_tier_tasks_count"
            style={{
              borderTopLeftRadius: true ? "15px" : "",
              borderBottomLeftRadius: true ? "15px" : "",
              borderTopRightRadius: false ? "15px" : "",
              borderBottomRightRadius: false ? "15px" : "",
            }}
          >
            <div className="bs__point_creds_tier_task_count_zebra">
              {new Array(100).fill(0).map(() => (
                <div></div>
              ))}
            </div>
            <p style={{ color: "#fff" }}>{point?.valueThreshold} points</p>
          </div>
          <div className="bs__point_creds_avatars">
            <AvatarGroup max={4}>
              {point?.avatars?.length ? (
                point?.avatars?.map((avatar) => {
                  return (
                    <Avatar
                      style={{
                        width: "20px",
                        height: "20px",
                        border: "none",
                      }}
                      alt="avatar"
                      src={avatar}
                    />
                  );
                })
              ) : (
                <>
                  <Avatar
                    style={{
                      width: "20px",
                      height: "20px",
                      border: "none",
                    }}
                    alt="avatar"
                  />
                  <Avatar
                    style={{
                      width: "20px",
                      height: "20px",
                      border: "none",
                    }}
                    alt="avatar"
                  />
                  <Avatar
                    style={{
                      width: "20px",
                      height: "20px",
                      border: "none",
                    }}
                    alt="avatar"
                  />
                </>
              )}
            </AvatarGroup>
            <span className="bs__point_creds_task_badge_user_text">
              {point?.label}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
