import React from "react";
import { Button } from "@mui/material";
import "./NotAllowed.css";
import { Navbar } from "../Navbar/Navbar";
import { Link } from "react-router-dom";

export const NotAllowed = () => {
  return (
    <div>
      <Navbar isBook={true} />
      <div className="notAllowed">
        <h2>This page is restricted for you</h2>
        <Link style={{ textDecoration: "none" }} to="/quest">
          <Button variant="contained">Go back to home page</Button>
        </Link>
      </div>
    </div>
  );
};
