import * as React from "react";
import Box from "@mui/material/Box";
import "./AccessRoles.css";
import { AdminRolesCards } from "./AdminRolesCards";
import { useGetAllAccessQuery } from "../../../../../redux-toolkit/features/access/accessApiSlice";
import ProgressBar from "../../../../UI/ProgressBar";
import { Error404 } from "../../../../UI/404/Error404";

export const AccessRoles = ({
  setPermissions,
  empPermissions,
  allPermisions,
  permissions,
  employeeData,
}) => {
  const [selectedPermissions, setSelectedPermissions] = React.useState([]);
  const [selectedPermissionsSa, setSelectedPermissionsSa] = React.useState([]);

  const { data, isLoading, isError } = useGetAllAccessQuery();
  React.useEffect(() => {}, [data, permissions]);

  React.useEffect(() => {
    let allIds = [];
    if (allPermisions) {
      allPermisions.map((item) => allIds.push(item._id));
      setPermissions(allIds);
    }
  }, [allPermisions]);

  if (isLoading) {
    return <ProgressBar />;
  }
  if (isError) {
    return <Error404 />;
  }

  return (
    <div>
      <div className="accessRoles">
        <h5 className="ftwt__r">Customize the Permission set for Users</h5>
        <div className="accessRoles__content">
          <div className="accessRoles__left">
            <h4 className="ftwt__r accessRoles__head"> Admin</h4>
            <div className="accessRoles__cont">
              {data &&
                data?.data
                  ?.filter((elm) => elm.roleType === 'ADMIN_C')
                  .map((elm, index) => (
                    <AdminRolesCards
                      _id={elm._id}
                      label={elm.displayName}
                      empPermissions={empPermissions}
                      allPermissions={allPermisions}
                      descp={elm.description}
                      roles={elm.permissions}
                      setSelectedPermissions={setPermissions}
                      selectedPermissions={permissions}
                      employeeData={employeeData}
                    />
                  ))}
            </div>
          </div>
          <div className="accessRoles__right">
            <h4 className="ftwt__r accessRoles__head">Super Admin</h4>
            <div className="accessRoles__cont">
              {data &&
                data?.data
                  ?.filter((elm) => elm.roleType === 'SUPERADMIN_C')
                  .map((elm, index) => (
                    <AdminRolesCards
                      _id={elm._id}
                      label={elm.displayName}
                      empPermissions={empPermissions}
                      allPermissions={allPermisions}
                      descp={elm.description}
                      roles={elm.permissions}
                      setSelectedPermissions={setPermissions}
                      selectedPermissions={permissions}
                      employeeData={employeeData}
                    />
                  ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
