import React, { useState } from "react";
import Box from "@mui/material/Box";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import arrow from "../../../assets/Icons/arrow.svg";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import lock from "../../../assets/Icons//modules/Course locked.svg";
import done from "../../../assets/Icons//modules/Course done.svg";
import { useAlert } from "../../../hooks/useAlert";
import CheckIcon from '@mui/icons-material/Check';

const opacitySwitchTransitionStyles = {
  entering: { opacity: 0, width: 0, height: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 1 },
  exited: { opacity: 0, width: 0, height: 0 },
};
const opacitySwitchButtonTransitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 0, width: 0, height: 0 },
  exiting: { opacity: 0, width: 0, height: 0 },
  exited: { opacity: 1 },
};

function FacebookCircularProgress(props) {
  return (
    <Box sx={{ position: 'relative' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: 'var(--grey300)',
        }}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        sx={{
          color: 'var(--pmain) !important',
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
        }}
        {...props}
      />
    </Box>
  );
}

export const Moddetails = ({
  open,
  state,
  setSelectedTopic,
  module,
  isCourseModal,
  selectedTopic,
  selectedModule,
  moduleNo,
  moduleHandler,
}) => {
  const { displayAlert } = useAlert();

  const activeTopicHandler = (index) => {
    setSelectedTopic(index);
  };

  // const openModule = module;
  // const selectedModuleId = 2;
  // const selectedTopicId = 2;

  const activeHandler = (index) => {
    if (module.lockStatus != 'LOCKED') {
      activeTopicHandler(index);
      moduleHandler(moduleNo);
    } else {
      displayAlert({
        open: true,
        message: 'Module is Locked',
        type: 'error',
        timeout: '2000',
      });
    }
  };

  return (
    <>
      <Accordion
        disableGutters
        key={moduleNo}
        sx={{
          '&:before': {
            display: 'none',
          },
          paddingRight: '10px',
          backgroundColor: 'var(--pwhite)',
        }}
        elevation={0}>
        <AccordionSummary
          sx={{
            margin: '0px',
            width: open ? '370px' : '0px',
            paddingRight: '10px',
          }}
          expandIcon={
            open && (
              <img
                width={'13px'}
                src={arrow}
                alt=""
              />
            )
          }
          aria-controls="panel1a-content"
          id="panel1a-header">
          <div className={!open ? 'module__chap' : 'module__chapActive'}>
            <h5 className="ftwt__r module__track">
              <Box
                sx={{
                  border:
                    // moduleNo ===
                    // selectedModule ||
                    // module?.lockStatus === 'COMPLETE'
                    //   ? '2px solid var(--grey300)'
                    //   :
                    '2px solid  var(--grey300)',
                  width: '25px',
                  height: '25px',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <h5
                  style={
                    {
                      // color:
                      //   moduleNo === selectedModule ||
                      //   module?.lockStatus === 'COMPLETE'
                      //     ? 'var(--pmain)'
                      //     : '',
                    }
                  }>
                  {moduleNo + 1}
                </h5>
              </Box>
            </h5>
            {open && (
              <h5
                style={
                  {
                    // color:
                    //   moduleNo === selectedModule ||
                    //   module?.lockStatus === 'COMPLETE'
                    //     ? 'var(--pmain)'
                    //     : '',
                  }
                }
                className="ftwt__r  ">
                {module?.name}
              </h5>
            )}
            {module.lockStatus === 'LOCKED' && open && <img src={lock} />}
            {module.lockStatus === 'COMPLETE' && open && (
              <CheckIcon sx={{ color: 'var(--pmain)', fontSize: '18px' }} />
            )}
            {/* <img src={done}/> */}
          </div>
        </AccordionSummary>
        {open && (
          <AccordionDetails>
            <div className="module__topics">
              {module &&
                module?.submodules.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => activeHandler(index)}
                    className="module__topicsdiv    module__active">
                    <h5
                      style={{
                        fontWeight:
                          moduleNo === selectedModule &&
                          index === selectedTopic &&
                          '600',
                      }}
                      className={
                        (moduleNo === selectedModule &&
                          index === selectedTopic) ||
                        (item?.submoduleCompletion === 'COMPLETE' &&
                          !isCourseModal)
                          ? 'ftwt__r module__topicsActive'
                          : 'ftwt__r'
                      }>
                      {moduleNo + 1}.{index + 1}
                    </h5>
                    <h5
                      style={{
                        fontWeight:
                          moduleNo === selectedModule &&
                          index === selectedTopic &&
                          '600',
                      }}
                      className={
                        (moduleNo === selectedModule &&
                          index === selectedTopic) ||
                        (item?.submoduleCompletion === 'COMPLETE' &&
                          !isCourseModal)
                          ? 'ftwt__r module__topicsActive'
                          : 'ftwt__r'
                      }>
                      {item?.name}
                    </h5>
                  </div>
                ))}
            </div>
          </AccordionDetails>
        )}
      </Accordion>
    </>
  );
};
