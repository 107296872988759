import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import ProgressBar from "../../../../UI/ProgressBar";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { AccessTable } from "./AccessTable";
import { useGetAdministratorsRoleQuery } from "../../../../../redux-toolkit/features/access/accessApiSlice";
import { Error404 } from "../../../../UI//404/Error404";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const AccessBottom = () => {
  const [value, setValue] = React.useState(0);
  const [sortAll, setSortAll] = React.useState("asc");
  const [sortAdmin, setSortAdmin] = React.useState("asc");
  const [sortSupAdmin, setSortSupAdmin] = React.useState("asc");
  const [sortCustom, setSortCustom] = React.useState("asc");

  const { data, isLoading, isError } = useGetAdministratorsRoleQuery(
    {
      role: "all",
      sortType: sortAll,
      sortBy: "fname",
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const {
    data: admin,
    isLoading: adminLoader,
    isError: adminError,
  } = useGetAdministratorsRoleQuery(
    {
      role: "admin",
      sortType: sortAdmin,
      sortBy: "fname",
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const {
    data: supadmin,
    isLoading: supadminLoader,
    isError: supadminError,
  } = useGetAdministratorsRoleQuery(
    {
      role: "superadmin",
      sortType: sortSupAdmin,
      sortBy: "fname",
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const {
    data: custom,
    isLoading: customLoader,
    isError: customError,
  } = useGetAdministratorsRoleQuery(
    {
      role: 'custom',
      sortType: sortCustom,
      sortBy: 'fname',
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
   const handleChange = (event, newValue) => {
     setValue(newValue);
   };

  return (
    <div className="accessHead">
      <h4 className="ftwt__b">Administrator Roles</h4>
      <h5 className="ftwt__r">
        Get information of all the administrator roles and their access to use
        the features.
      </h5>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label={`All (${data?.data?.length})`}
              sx={{
                textTransform: "capitalize",
                // marginRight: "20px",
                fontWeight: "500",
                fontSize: "15px",
              }}
              {...a11yProps(0)}
            />
            <Tab
              sx={{
                textTransform: "capitalize",
                fontWeight: "500",
                fontSize: "15px",
              }}
              label={`Admin (${admin?.data?.length})`}
              {...a11yProps(1)}
            />

            <Tab
              label={`Super Admin (${supadmin?.data?.length})`}
              sx={{
                textTransform: "capitalize",
                // marginRight: "20px",
                fontWeight: "500",
                fontSize: "15px",
              }}
              {...a11yProps(2)}
            />
            <Tab
              label={`Multiple Role (${custom?.data?.length})`}
              sx={{
                textTransform: "capitalize",
                // marginRight: "20px",
                fontWeight: "500",
                fontSize: "15px",
              }}
              {...a11yProps(3)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <AccessTable
            users={data?.data}
            isLoading={isLoading}
            isError={isError}
            setSortType={setSortAll}
            sortType={sortAll}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AccessTable
            users={admin?.data}
            isLoading={adminLoader}
            isError={adminError}
            setSortType={setSortAdmin}
            sortType={sortAdmin}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <AccessTable
            users={supadmin?.data}
            isLoading={supadminLoader}
            isError={supadminError}
            setSortType={setSortSupAdmin}
            sortType={sortSupAdmin}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <AccessTable
            users={custom?.data}
            isLoading={customLoader}
            isError={customError}
            setSortType={setSortCustom}
            sortType={sortCustom}
          />
        </TabPanel>
      </Box>
    </div>
  );
};
