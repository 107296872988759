import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { Modulecontent } from "./Modulecontent";
import { Moduleside } from "./Moduleside";
import "./Coursedetails.css";
import { BreadcrumbsUi } from "../../UI/BreadcrumbsUi";
import { useGetCompleteCourseByIdQuery } from "../../../redux-toolkit/features/courses/coursesApiSlice";
import ProgressBar from "../../UI/ProgressBar";
import { Error404 } from "../../UI/404/Error404";
import { useAlert } from "../../../hooks/useAlert";
import { CompletedCourse } from "./CompletedCourse";

export const Coursedetail = () => {
  const { courseId, bookName, levelName } = useParams();
  const { state } = useLocation();
  const { data, isLoading, refetch, isFetching, isSuccess, isError } =
    useGetCompleteCourseByIdQuery(courseId);
  const [selectedModule, setSelectedModule] = React.useState(0);
  const [selectedTopic, setSelectedTopic] = React.useState(0);
  const { displayAlert } = useAlert();
  const [toggleCompleted, setToggleCompleted] = React.useState(false);
  const [isLast, setIsLast] = React.useState(false);

  React.useEffect(() => {
    if (data?.data?.percentageCompleted === 100) {
      setToggleCompleted(true);
    }
  }, [data]);

  React.useEffect(() => {
    if (
      selectedModule === data?.data.modules.length - 1 &&
      data?.data?.modules[selectedModule].submodules.length - 1 ===
        selectedTopic
    ) {
      setIsLast(true);
    }
  }, [selectedTopic, selectedModule]);

  React.useEffect(() => {
    if (
      selectedModule === data?.data.modules.length - 1 &&
      data?.data?.modules[selectedModule].submodules.length - 1 ===
        selectedTopic
    ) {
      setIsLast(true);
    }
  }, []);

  React.useEffect(() => {
    if (
      data?.data?.modules.length > 0 &&
      data?.data?.modules[0].submodules.length > 0 &&
      data?.data?.percentageCompleted != 100
    ) {
      if (data?.data?.isStarted) {
        let currentModule = data?.data.modules.findIndex(
          (obj) => obj._id === data?.data?.ongoingModuleId
        );
        let currentTopic = data?.data.modules[
          currentModule
        ].submodules.findIndex(
          (obj) => obj._id === data?.data?.ongoingSubModuleId
        );
        setSelectedModule(currentModule);
        setSelectedTopic(currentTopic);
      } else {
        setSelectedModule(0);
        setSelectedTopic(0);
      }
    }
  }, [data]);
  if (isLoading) {
    return (
      <div style={{ height: '100vh' }}>
        <ProgressBar />;
      </div>
    );
  }
  if (isError) {
    return (
      <div style={{ height: '100vh', paddingTop: '80px' }}>
        <Error404 />;
      </div>
    );
  }

  const currentModule = data?.data.modules[selectedModule];
  const currentSubmodule = currentModule.submodules[selectedTopic];

  const handleNext = () => {
    if (selectedTopic < currentModule.submodules.length - 1) {
      setSelectedTopic(selectedTopic + 1);
    } else if (selectedModule < data?.data.modules.length - 1) {
      if (
        data?.data.modules[selectedModule + 1].lockStatus === 'LOCKED' &&
        !isFetching
      ) {
        return displayAlert({
          open: true,
          message: 'Module is Locked',
          type: 'error',
          timeout: '2000',
        });
      }
      setSelectedModule(selectedModule + 1);
      setSelectedTopic(0);
    }
    if (
      selectedModule === data?.data.modules.length - 1 &&
      data?.data?.modules[selectedModule].submodules.length - 1 ===
        selectedTopic
    ) {
      setIsLast(true);
    }
  };

  const handlePrevious = () => {
    if (selectedTopic > 0) {
      setSelectedTopic(selectedTopic - 1);
    } else if (selectedModule > 0) {
      setSelectedModule(selectedModule - 1);
      setSelectedTopic(
        data?.data.modules[selectedModule - 1].submodules.length - 1
      );
    }
  };

  if (toggleCompleted) {
    return (
      <div className="Coursedetails__completed">
        <CompletedCourse
          content={data?.data}
          setToggleCompleted={setToggleCompleted}
        />
      </div>
    );
  }

  return (
    <>
      {data && (
        <div className="Coursedetails">
          <div>
            <Moduleside
              setSelectedTopic={setSelectedTopic}
              setSelectedModule={setSelectedModule}
              modules={data?.data.modules}
              selectedTopic={selectedTopic}
              selectedModule={selectedModule}
              moduleCompleted={data?.data?.completedSubModuleCount}
              moduleTotal={data?.data?.totalSubModuleCount}
              percentageCompleted={data?.data?.percentageCompleted}
            />
          </div>

          <div className="coursedetails__content">
            <Modulecontent
              toggleCompleted={toggleCompleted}
              refetch={refetch}
              isFetchingContent={isFetching}
              selectedTopic={currentSubmodule}
              selectedModule={currentModule}
              moduleIndex={selectedModule}
              topicIndex={selectedTopic}
              isFinished={data?.data?.isFinished}
              content={data?.data}
              handlePrevious={handlePrevious}
              handleNext={handleNext}
              isLast={isLast}
              topicId={
                data?.data?.modules[selectedModule]?.submodules[selectedTopic]
                  ?._id
              }
            />
          </div>
        </div>
      )}
    </>
  );
};
