import { apiSlice } from "../../api/apiSlice";
import {
  logoutUser,
  setRefresh,
  setCredentials,
  updateFeedback,
} from './authSlice';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // @desc: Login with credentials
    login: builder.mutation({
      query: (credentials) => ({
        url: 'api/auth/login',
        method: 'POST',
        body: { ...credentials },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled, q }) {
        try {
          const { data } = await queryFulfilled;
          const { accessToken } = data.data;
          const user = data.data;
          dispatch(setCredentials(user));
          // console.log("access token line 17", user);
          // Set redux login state.
        } catch (error) {
          console.error('error', error);
        }
      },
    }),

    // @desc: confirmFeedback with credentials
    confirmFeedback: builder.mutation({
      query: () => ({
        url: '/api/v1/users/feedback/given',
        method: 'POST',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled, q }) {
        try {
          dispatch(updateFeedback());
        } catch (error) {
          console.error('error', error);
        }
      },
    }),

    // @desc: Logout on server, clear local state and reset apiSlice state.
    sendLogout: builder.mutation({
      query: () => ({
        url: 'api/auth/logout',
        method: 'POST',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(logoutUser());
          setTimeout(() => {
            dispatch(apiSlice.util.resetApiState());
          }, 500);
        } catch (err) {
          console.log(err);
        }
      },
    }),
    forgotPassword: builder.mutation({
      query: (credentials) => ({
        url: 'api/auth/forgot-password',
        method: 'POST',
        body: { ...credentials },
      }),
    }),

    updateProfile: builder.mutation({
      query: (data) => ({
        url: `/api/auth/my-profile`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Profile'],
    }),
    resetPassword: builder.mutation({
      query: (credentials) => ({
        url: `api/auth/reset-password/${credentials.id}/${credentials.token}`,
        method: 'PATCH',
        body: credentials.password,
      }),
    }),

    // @desc: Refresh with refresh token
    refresh: builder.mutation({
      query: () => ({
        url: 'api/auth/refresh',
        method: 'GET',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const { accessToken } = data.data;

          dispatch(setRefresh(accessToken));
        } catch (err) {
          console.log(err);
        }
      },
    }),
    getCompletedAlphaScreens: builder.query({
      query: () => ({
        url: 'api/user/alpha-screens/complete',
        method: 'GET',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          localStorage.setItem(
            'alpha_screens',
            JSON.stringify(data?.data || [])
          );
        } catch (err) {
          console.log(err);
          localStorage.setItem('alpha_screens', JSON.stringify([]));
        }
      },
    }),
    markAlphaScreenComplete: builder.mutation({
      query: (data) => ({
        url: 'api/user/alpha-screens/complete',
        method: 'POST',
        body: data,
      }),
    }),
    changePassword: builder.mutation({
      query: (data) => ({
        url: '/api/auth/change-password',
        method: 'PATCH',
        body: data,
      }),
    }),
    changeProfileImage: builder.mutation({
      query: (data) => ({
        url: '/api/auth/avatar',
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['User'],
    }),
    getProfile: builder.query({
      query: () => ({
        url: `/api/auth/my-profile`,
        method: 'GET',
      }),
      providesTags: ['User'],
    }),
  }),
});

export const {
  useLoginMutation,
  useSendLogoutMutation,
  useForgotPasswordMutation,
  useUpdateProfileMutation,
  useResetPasswordMutation,
  useRefreshMutation,
  useGetProfileQuery,
  useConfirmFeedbackMutation,
  useChangePasswordMutation,
  useChangeProfileImageMutation,
  useGetCompletedAlphaScreensQuery,
  useMarkAlphaScreenCompleteMutation,
  useFe,
} = authApiSlice;
