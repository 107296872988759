import React from "react";
import "./BooksCover.css";
import coverImg from "../../assets/books/Egypt symbol.svg";

export const BooksCover = ({ coverImage }) => {
  return (
    <div className="books__left">
      <img src={coverImage} alt="" />
    </div>
  );
};
