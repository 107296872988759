import React, { useEffect, useState } from "react";

const PointsCounter = ({ points = 0 }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (count >= points) return;
    let timer = setTimeout(() => {
      setCount((count) => count + 1);
    }, 0.9);
    return () => {
      clearTimeout(timer);
    };
  }, [count, points]);

  return <div className="rewards_counter">{count}</div>;
};

export default PointsCounter;
