import React, { Component } from "react";

import EastIcon from "@mui/icons-material/East";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "./Wheel.css";
import WheelCard from "./WheelCard";
import { ReactComponent as InProgess } from '../../../assets/Icons/books/work_in_progress.svg';
import { ReactComponent as InProgessDark } from '../../../assets/Icons/books/work_in_progress_light theme.svg';

export class Wheel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 380, //height of the wheel
      width: 380, //width of the wheel
      radius: 270, // radius from center of wheel
      // rotateCircle: false,
      centerItem: 0, //id of center element; element which is active.
      prevItem: 6, //left of center element
      nextItem: 1, //right of center element
      lastItem: 6, //id of last element (5)
      carouselDeg: 270, //starting degree
      itemDeg: 90, //card degree

      // First 6 books hard-coded and spread into the cards.
      cards: [
        { id: 0, position: 1, rotate: 90, ...props?.books[0] },
        { id: 1, position: 2, rotate: 143, ...props?.books[1] },
        { id: 2, position: 3, rotate: 195, ...props?.books[2] },
        { id: 3, position: 4, rotate: 244, ...props?.books[3] },
        { id: 4, position: 5, rotate: 297, ...props?.books[4] },
        { id: 5, position: 6, rotate: 347, ...props?.books[5] },
        { id: 6, position: 7, rotate: 400, ...props?.books[6] },

        //values for 8 cards wheel.
        // { id: 6, position: 7, rotate: 360 },
        // {  id: 7, position: 8, rotate: 405 }
      ],
      expand: false,
    };

    this.tempTheta = 0.0;
  }
  // Function to change center card to next card or in reverse.
  getIdItems = (side) => {
    // true = next, false = prev
    const { nextItem, prevItem, lastItem } = this.state;

    if (side) {
      this.setState(
        {
          centerItem: nextItem,
        },
        () => prevNext(this.state.centerItem)
      );
      this.props.getCenterItem && this.props.getCenterItem(nextItem);
      this.setState({
        cards: [
          { id: 0, position: 1, rotate: 90, ...this.props?.books[0] },
          { id: 1, position: 2, rotate: 143, ...this.props?.books[1] },
          { id: 2, position: 3, rotate: 195, ...this.props?.books[2] },
          { id: 3, position: 4, rotate: 244, ...this.props?.books[3] },
          { id: 4, position: 5, rotate: 297, ...this.props?.books[4] },
          { id: 5, position: 6, rotate: 347, ...this.props?.books[5] },
          { id: 6, position: 7, rotate: 400, ...this.props?.books[6] },

          //values for 8 cards wheel.
          // { id: 6, position: 7, rotate: 360 },
          // {  id: 7, position: 8, rotate: 405 }
        ],
      });
    } else {
      this.setState(
        {
          centerItem: prevItem,
        },
        () => prevNext(this.state.centerItem)
      );
      this.props.getCenterItem && this.props.getCenterItem(prevItem);
      this.setState({
        cards: [
          { id: 0, position: 1, rotate: 90, ...this.props?.books[0] },
          { id: 1, position: 2, rotate: 143, ...this.props?.books[1] },
          { id: 2, position: 3, rotate: 195, ...this.props?.books[2] },
          { id: 3, position: 4, rotate: 244, ...this.props?.books[3] },
          { id: 4, position: 5, rotate: 297, ...this.props?.books[4] },
          { id: 5, position: 6, rotate: 347, ...this.props?.books[5] },
          { id: 6, position: 7, rotate: 400, ...this.props?.books[6] },
        ],
      });
    }

    const prevNext = (itemId) => {
      if (itemId === lastItem) {
        this.setState({
          nextItem: 0,
          prevItem: lastItem - 1,
        });
      } else if (itemId === 0) {
        this.setState({
          prevItem: lastItem,
          nextItem: 1,
        });
      } else {
        this.setState((state) => ({
          nextItem: state.centerItem + 1,
          prevItem: state.centerItem - 1,
        }));
      }
    };
    setTimeout(() => {
      const oldScrollConfig = JSON.parse(localStorage.getItem('wheelScroll'));

      localStorage.setItem(
        'wheelScroll',
        JSON.stringify({
          ...(oldScrollConfig || {}),
          state: this.state,
          activeBook: this.props.activeBook,
        })
      );
    }, 100);
  };

  /**
   *
   * @param {boolean} isUp
   */
  handleScroll = (isUp = true) => {
    const oldScrollConfig = JSON.parse(localStorage.getItem('wheelScroll'));

    let isCardOpen = isUp || false;
    if (oldScrollConfig) {
      isCardOpen = oldScrollConfig?.isCardOpen;
    }
    localStorage.setItem(
      'wheelScroll',
      JSON.stringify({
        ...(oldScrollConfig || {}),
        isUp,
        isCardOpen: isCardOpen,
      })
    );

    setTimeout(() => {
      document.getElementById('scroll-pivot')?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }, 100);
  };

  // Handler functions that triggers rotation
  next = () => {
    this.getIdItems(true);
    this.setState((state) => ({
      carouselDeg: state.carouselDeg - 51.42857, // each next or previous button click will rotate the wheel by 360/7deg (circle's total angle/number of cards) = 51.42857
    }));
  };

  prev = () => {
    this.getIdItems(false);
    this.setState((state) => ({
      carouselDeg: state.carouselDeg + 51.42857, // each next or previous button click will rotate the wheel by 360/7deg (circle's total angle/number of cards) = 51.42857
    }));
  };

  // To determine type of card based on postion and for css styling.
  type = (id) => {
    const { centerItem, nextItem, prevItem } = this.state;

    if (id === centerItem) {
      return 'active';
    } else if (id === nextItem) {
      return 'next';
    } else if (id === prevItem) {
      return 'prev';
    } else {
      return 'not-active';
    }
  };

  selectedBookHandler = (id, itemRotation) => {
    let rotation;

    if (this.state.centerItem === 0 && 6 === id) {
      this.setState({
        carouselDeg: this.state.carouselDeg + 1 * 51.42857,
      });
    } else if (this.state.centerItem === 6 && 0 === id) {
      this.setState({
        carouselDeg: this.state.carouselDeg - 1 * 51.42857,
      });
    } else if (this.state.centerItem < id) {
      rotation = id - this.state.centerItem;
      this.setState({
        carouselDeg: this.state.carouselDeg - rotation * 51.42857,
      });
    } else {
      rotation = this.state.centerItem - id;
      this.setState({
        carouselDeg: this.state.carouselDeg + rotation * 51.42857,
      });
    }
    this.props.getCenterItem && this.props.getCenterItem(id);

    this.setState({
      centerItem: id,
      prevItem: id - 1,
      nextItem: id + 1,
    });
  };
  handleExpand = () => {
    this.setState({
      expand: !this.state.expand,
    });
  };

  componentDidUpdate() {}

  componentDidMount() {
    if (this.props.isPreview) return;
    const hasSeenWheelSwipeDemo = JSON.parse(
      localStorage.getItem('know_swipe')
    );

    if (!hasSeenWheelSwipeDemo) {
      let wheel = document.getElementById('wheel');
      if (wheel) {
        wheel.classList.add('bounce');
      }
    }
    const oldScrollConfig = JSON.parse(localStorage.getItem('wheelScroll'));

    if (oldScrollConfig?.isUp) {
      this.handleScroll(true);
    }
    if (oldScrollConfig?.state) {
      this.setState({ ...oldScrollConfig.state });
      this.props.setActiveBook &&
        this.props.setActiveBook(oldScrollConfig.activeBook);
    }
    this.props.getCenterItem && this.props.getCenterItem(this.state.centerItem);
    document
      .getElementById('layout')
      .addEventListener('scroll', this.handleScrollStateToggle);
  }

  handleScrollStateToggle() {
    localStorage.setItem('know_swipe', JSON.stringify(true));
    let wheel = document.getElementById('wheel');
    if (wheel) {
      wheel?.classList.remove('bounce');
    }
    const scrollTop = document.getElementById('layout').scrollTop;
    const oldScrollConfig = JSON.parse(localStorage.getItem('wheelScroll'));
    if (scrollTop === 0) {
      localStorage.setItem(
        'wheelScroll',
        JSON.stringify({
          ...(oldScrollConfig || {}),
          isUp: false,
        })
      );
    } else {
      localStorage.setItem(
        'wheelScroll',
        JSON.stringify({
          ...(oldScrollConfig || {}),
          isUp: true,
        })
      );
    }
  }

  componentWillUnmount() {
    document
      .getElementById('layout')
      .removeEventListener('scroll', this.handleScrollStateToggle);
  }

  render() {
    return (
      <div
        className={
          this.props.isPreview ? 'quest__prevwheellayout' : 'quest__wheellayout'
        }
        id="wheel">
        <div
          ref={(ref_id) => (this.wheel = ref_id)}
          style={{
            border: this.props.isPreview
              ? `185px solid ${this.props.prevWheelBg}`
              : `185px solid ${this.props.wheelBgColor}`,
            transform: `rotate(${this.state.carouselDeg}deg)`,
            // top: this.props.isExpanded ? `81%` : `100%`,
          }}
          className={`wheel`}>
          {/* <Button onClick={this.handleExpand}>Hello</Button> */}
          {/* {this.state.cards}\ */}
          {this.state?.cards.map((item, i) => {
            let centerOfWheel = {
              x: parseFloat(this.state.width / 2.0),
              y: parseFloat(this.state.height / 2.0),
            };
            return (
              <WheelCard
                type={this.type(i)}
                itemRotation={item.rotate}
                theta={((Math.PI * 2) / 7.0) * i}
                radius={this.state.radius}
                center={centerOfWheel}
                key={item.id}
                id={item.id}
                selectedBookHandler={this.selectedBookHandler}
                name={item.name}
                handleClick={() => this.handleRotate(i)}
                bookData={this.props?.books}
                prevFunc={this.prev}
                nextFunc={this.next}
                startHandler={this.props.startHandler}
                isPreview={this.props.isPreview}
                activeTab={this.props.activeTab}
                isExpanded={this.props.isExpanded}
                coverImage={item.coverImage}
                wheelImage={item.wheelImage}
                bookType={item.type}
                isLocked={item.isLocked}
              />
            );
          })}
        </div>
        <div
          className={
            this.props.isPreview
              ? 'quest__prevcurrentPreview'
              : 'quest__current'
          }
          style={{
            transition: '0.4s ease-in-out',
            width: 'max-content',
          }}>
          <div className="step22">
            <div className="quest__wheelcenter">
              {/* Points card */}
              {/* <div className="quest__topcard">
              <img
                src={CoinsSvg}
                style={{
                  height: "35px",
                }}
                alt=""
              />

              <h3>{this.props.activeBook?.points || 0} Points</h3>
            </div> */}
              {/* Center card */}
              <div
                className="quest__card"
                // ${this.props.themeCardImage}
                style={{
                  zIndex: 22,
                  background:
                    this.props.wheelColors?.length === 2
                      ? `linear-gradient(to right, ${this.props.wheelColors[0]} , ${this.props.wheelColors[1]})`
                      : this.props.wheelColors
                      ? this.props.wheelColors[0]
                      : '',
                }}>
                <div className="ftwt__b ">
                  {this.props?.activeBook?.name ? (
                    <div
                      className="ftwt__b "
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '2px',
                        // width: '200px',
                        maxWidth: '250px',
                      }}>
                      <h4 className="ftwt__b truncate1">
                        {this.props.activeBook?.order &&
                          this.props.activeBook?.order + '. '}
                        {this.props?.activeBook?.name}
                      </h4>
                    </div>
                  ) : (
                    <>
                      <InProgess
                        className="quest__centerProgess"
                        style={{ height: '30px' }}
                      />
                      <InProgessDark
                        className="quest__centerProgessDark"
                        style={{ height: '30px' }}
                      />
                      <h4
                        style={{ marginTop: '10px' }}
                        className="ftwt__b truncate1">
                        Work In Progess
                      </h4>
                    </>
                  )}
                </div>
                <h6
                  className="ftwt__r truncate-4"
                  // style={{ lineBreak: "anywhere" }}
                >
                  {this.props.activeBook?.description || ''}
                </h6>
                {this.props.activeBook?.name ? (
                  <>
                    <button
                      onClick={() =>
                        this.props.startHandler(
                          this.props.activeBook?._id,
                          this.props.activeBook?.isLocked
                        )
                      }
                      className="contained__lgbtn2 home__zoom"
                      style={{ fontWeight: '600' }}>
                      Let's Start <EastIcon sx={{ fontSize: '18px' }} />
                    </button>
                    <div
                      style={{
                        marginTop: '25px',
                      }}
                      id="scroll-pivot"></div>
                  </>
                ) : null}
              </div>
              <div className="card__arrow">
                <style>
                  {`
                .card__arrow::before {
                  content: "";
                  position: absolute;
                  top: -13px;
                  left: 188px;
                  transform: translateX(-50%);
                   border-left: 18px solid transparent;
                  border-right: 18px solid transparent;
                  border-bottom: 18px solid ${
                    this.props.wheelColors ? this.props.wheelColors[0] : ''
                  }; 
                }
                `}
                </style>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Wheel;
