import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedRole: "Employee",
};

const access = createSlice({
  name: "access",
  initialState,
  reducers: {
    roleToggler: (state, action) => {
      state.selectedRole = action.payload;
    },
  },
});

export const { roleToggler } = access.actions;

export default access.reducer;

export const selectedRole = (state) => state.access.selectedRole;
