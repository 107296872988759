import React, { useState } from "react";
import DefaultMaleProfilePic from "../../../../assets/Icons/profile/Profile icon _ male.png";
import Avatar from "@mui/material/Avatar";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import { useNavigate } from "react-router-dom";
const data = [
  {
    _id: "B1",
  },
  {
    _id: "B2",
  },
  {
    _id: "B3",
  },
  {
    _id: "B4",
  },
  {
    _id: "B5",
  },
  {
    _id: "B6",
  },
  {
    _id: "B7",
  },
];

export const TasksHod = ({ allTask }) => {
  const [status, setStatus] = useState(false);
  const navigate = useNavigate();

  const viewAllTaskHandler = (_id) => {
    navigate(`/super-admin/manage-organization/task-verification/${_id}`);
  };

  return (
    <div className={"task__card"}>
      <div className="task__head">
        <div className="task__headprofile">
          <Avatar
            alt="Remy Sharp"
            sx={{ width: "65px", height: "65px" }}
            src={allTask.user.avatar}
          />
          <div className="task__headnotf">{allTask?.pendingTasks}</div>
        </div>
        <div>
          <div>
            <h4 className="ftwt__b">
              {allTask?.user.fname} {allTask?.user.lname}
            </h4>
          </div>
          <div>
            <h6 className="ftwt__r">Product Manager</h6>
          </div>
        </div>
      </div>
      <div className="task__books">
        <div className="task__bookshead">
          <h6 className="ftwt__r">Progress</h6>
          <h6 className="ftwt__r task__no">20/250 Tasks</h6>
        </div>
        <div className="task__bookscontent">
          {data.map((elm, index) => (
            <div
              key={index}
              className={
                index < allTask?.lastBook
                  ? "task__bookscard"
                  : "task__bookscardIA"
              }
            >
              <caption>Book {index + 1}</caption>
            </div>
          ))}
        </div>
      </div>
      <div className="task__viewAll">
        <button
          onClick={() => viewAllTaskHandler(allTask?.user._id)}
          className="contained__lgbtn"
        >
          View All
        </button>
      </div>
    </div>
  );
};
