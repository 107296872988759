import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete, Box, Button, Modal, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import { useAlert } from "../../../../hooks/useAlert";
import {
  useCreateProductMutation,
  useUpdateProductMutation,
} from '../../../../redux-toolkit/features/rewards/rewardsApiSlice';
import { ChevronRight, CloseOutlined } from '@mui/icons-material';

import DeleteInActiveSvg from '../../../../assets/delete_icon.svg';
import DeleteActiveSvg from '../../../../assets/Icons/delete_red.svg';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  right: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  borderRadius: 3,
  boxShadow: 24,
  p: ' 40px',
};

const AddProduct = ({ open, handleClose, categories, editData }) => {
  const { displayAlert } = useAlert();
  const [isLarge, setIsLarge] = React.useState(false);

  const [data, setData] = useState({
    _id: null,
    name: '',
    category: '',
    points: 0,
    image: '',
  });
  const [thumbnail, setThumbnail] = useState(null);
  const [createProduct] = useCreateProductMutation();
  const [updateProduct] = useUpdateProductMutation();

  const handleDataChange = (name) => (e) => {
    setData({
      ...data,
      [name]: e.target.value,
    });
  };

  const thumbnailHandler = (event) => {
    const file = event.target.files[0];

    if (file.size >= 1099276) {
      setIsLarge(true);

      displayAlert({
        open: true,
        message: `Image Size cannot be Larger than 1 MB`,
        type: 'error',
        timeout: '2000',
      });
    } else {
      setIsLarge(false);

      setThumbnail(file);
    }
  };

  const handleModelClose = () => {
    setData({
      name: '',
      category: '',
      points: 0,
      _id: null,
      image: '',
    });
    setThumbnail(null);
    handleClose();
  };

  const handleSubmit = async () => {
    if (
      !data.name ||
      !data.category ||
      !data.points ||
      (!thumbnail && !data.image)
    ) {
      displayAlert({
        open: true,
        message: `All fields required!`,
        type: 'error',
        timeout: '2500',
      });
      return;
    }
    try {
      const formData = new FormData();
      formData.append('name', data.name);
      formData.append('category', data.category);
      formData.append('points', data.points);
      !data?.image && formData.append('image', thumbnail);
      formData.append('_id', data._id);

      data?._id
        ? await updateProduct({ data: formData, _id: data._id })
        : await createProduct(formData);
      displayAlert({
        open: true,
        message: `Product ${data._id ? 'updated' : 'created'} successfully`,
        type: 'success',
        timeout: '2500',
      });
      handleModelClose();
    } catch (error) {
      displayAlert({
        open: true,
        message: `Something went wrong`,
        type: 'error',
        timeout: '2500',
      });
    }
  };

  useEffect(() => {
    if (!editData?._id) return;
    setData(editData);
  }, [editData, editData?._id]);

  return (
    <Modal
      open={open}
      onClose={handleModelClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <div className="create__formhead">
          <h1>{data?._id ? 'Update' : 'Add'} Product</h1>
          <CloseIcon
            onClick={handleModelClose}
            sx={{
              fontSize: '18px',
              cursor: 'pointer',
              color: 'var(--grey400)',
              '&:hover': {
                color: 'var(--error)',
              },
            }}
          />
        </div>
        <hr className="rewards_divider" />
        <div className="rewards_prod_form">
          <div>
            <h5 className="ftwt__r">
              Product Name<span className="required_star">*</span>
            </h5>

            <TextField
              sx={{
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
                marginTop: '5px',
              }}
              required
              variant="outlined"
              fullWidth
              name="name"
              value={data.name}
              onChange={handleDataChange('name')}
              id="name_inp"
            />
          </div>
          <div>
            <h5 className="ftwt__r">
              Product Image<span className="required_star">*</span>
            </h5>
            <div
              style={{ borderColor: isLarge ? 'var(--error)' : '' }}
              className="dropzone-wrapper">
              {thumbnail ? (
                <div className="dropzone-image">
                  <img
                    height={'100%'}
                    src={URL.createObjectURL(thumbnail)}
                    alt="Thumbnail Preview"
                  />
                  <CloseIcon
                    onClick={() => setThumbnail()}
                    sx={{
                      fontSize: '18px',
                      cursor: 'pointer',

                      color: 'var(--grey400)',
                      '&:hover': {
                        color: 'var(--error)',
                      },
                    }}
                  />
                </div>
              ) : (
                <div className="dropzone-desc">
                  <UploadFileOutlinedIcon
                    sx={{
                      fontSize: '70px',
                    }}
                  />
                  <p
                    style={{
                      fontSize: '14px',
                      // color: isLarge ? 'var(--error)' : '',
                    }}>
                    Choose an image file{' '}
                    <span style={{ fontSize: '11px' }}>or drag it here.</span>
                    <br />
                    <span style={{ fontSize: '11px' }}>
                      Maximum file size 1MB
                    </span>
                  </p>
                </div>
              )}
              {!thumbnail && (
                <input
                  name="thumbnail"
                  onChange={thumbnailHandler}
                  type="file"
                  accept="image/*"
                  // name="img_logo"
                  className="dropzone"
                />
              )}
            </div>
            {isLarge && (
              <p
                style={{
                  color: 'var(--error)',
                  marginTop: '5px',
                  fontSize: '12px',
                }}>
                Choose Image smaller than 1MB
              </p>
            )}
          </div>
          <div>
            <h5 className="ftwt__r">
              Category<span className="required_star">*</span>
            </h5>
            <Autocomplete
              options={categories}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              fullWidth
              onChange={(event, newValue) => {
                handleDataChange('category')({
                  target: { value: newValue?.id || '' },
                });
              }}
              name="category"
              value={categories?.find((cat) => {
                return (
                  cat.id === data.category || cat.id === editData?.category
                );
              })}
              clearIcon={
                <CloseOutlined
                  fontSize="small"
                  id="select_popup"
                  style={{
                    rotate: '90deg',
                  }}
                />
              }
              renderInput={(params) => (
                <TextField
                  sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    marginTop: '5px',
                    borderRadius: '2px',
                  }}
                  {...params}
                />
              )}
            />
          </div>

          <div>
            <h5 className="ftwt__r">
              Points<span className="required_star">*</span>
            </h5>

            <TextField
              sx={{
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
                marginTop: '5px',
              }}
              type="tel"
              required
              id="outlined-basic"
              variant="outlined"
              fullWidth
              name="points"
              value={data.points}
              onChange={handleDataChange('points')}
            />
          </div>

          <div className="department__actions">
            <div
              onClick={() => {
                setData((prev) => ({
                  ...prev,
                  name: '',
                  category: '',
                  points: 0,
                  image: '',
                }));
                setThumbnail('');
                setTimeout(() => {
                  const cross = document.getElementById('select_popup');
                  if (cross) {
                    cross.parentElement?.click();
                    let nameInp = document.getElementById('name_inp');
                    if (nameInp) {
                      nameInp.focus();
                    }
                  }
                }, 100);
              }}
              style={{ marginRight: '14px' }}
              className="figure">
              <img
                src={DeleteInActiveSvg}
                className="Sirv image-main"
                height="22px"
                alt=""
              />
              <img
                src={DeleteActiveSvg}
                className="Sirv image-hover"
                height="22px"
                alt=""
              />
            </div>
            <Button
              variant="contained"
              onClick={handleSubmit}>
              {data._id ? 'Save changes' : 'Create'}
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default AddProduct;
