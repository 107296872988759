import "./MyActivity.css";

import React, { useState } from "react";
import { MyActivityTabs } from "./MyActivityTabs";
import taskCompletionIcon from "../../assets/task_completed.svg";
import pointsAchievedIcon from "../../assets/points_acheived.svg";
import badgesEarnedIcon from "../../assets/badges_earned.svg";
import { useGetUserActivityStatsQuery } from "../../redux-toolkit/features/my-activity/myActivityApiSlice";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const MyActivity = () => {
  const user = useSelector((state) => state.auth.user);

  const { data } = useGetUserActivityStatsQuery(user?._id);

  const [appear, setAppear] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setAppear(false);
    }, 30000);
    return clearTimeout();
  }, []);

  return (
    <div className="container__mact">
      <div className="mact">
        <div
          className={'mact__top mact__mb20'}
          style={{
            margin: appear ? '' : '0px 0px',
          }}>
          <h1
            className={
              appear ? 'mact__fadeIn ' : 'mact__fadeOut'
            }>{`Welcome back, ${user?.fname} ${user?.lname}!`}</h1>
        </div>

        <div className="mact__statcont">
          <div className="mact__statcard">
            <div className="mact__statdata">
              <h1>{data?.data?.completedTasksCount ?? 'N/A'}</h1>
              <span>Tasks completed</span>
            </div>
            <div className="mact__staticon">
              <img
                src={taskCompletionIcon}
                alt="icon"
              />
            </div>
          </div>
          <div className="mact__statcard">
            <div className="mact__statdata">
              <h1>{data?.data?.pointsEarned ?? 'N/A'}</h1>
              <span>Points achieved</span>
            </div>
            <div className="mact__staticon">
              <img
                src={pointsAchievedIcon}
                alt="icon"
              />
            </div>
          </div>
          <div className="mact__statcard">
            <div className="mact__statdata">
              <h1>{data?.data?.badgesEarned ?? 'N/A'}</h1>
              <span>Badges earned</span>
            </div>
            <div className="mact__staticon">
              <img
                src={badgesEarnedIcon}
                alt="icon"
              />
            </div>
          </div>
        </div>

        <div className="mact__tabscont">
          <MyActivityTabs />
        </div>
      </div>
    </div>
  );
};

export default MyActivity;
