import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Button, FormHelperText, TextField } from "@mui/material";
import React, { useState } from "react";
import "./EditBooks.css";

import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import {
  useCreateLevelMutation,
  useDeleteLevelMutation,
  useGetLevelTasksQuery,
  useUpdateLevelMutation,
} from "../../../../redux-toolkit/features/books/bookApiSlice";
import { AddTask } from "./AddTask";

import { useFormik } from "formik";
import * as yup from "yup";
import { useAlert } from "../../../../hooks/useAlert";
import { DeleteModal } from "../../../UI/Delete Modal/DeleteModal";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteInActiveSvg from '../../../../assets/delete_icon.svg';
import { ReactComponent as EditSvg } from '../../../../assets/Icons/edit (1).svg';

const ITEM_HEIGHT = 48;

export default function LongMenu({ onEditClick, onDeleteClick }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <div
      style={{
        marginLeft: 'auto',
      }}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onMouseEnter={(event) => setAnchorEl(event.currentTarget)}
        // onMouseLeave={() => handleClose()}
      >
        <MoreVertIcon
          fontSize="small"
          sx={{
            color: 'var(--pblue)',
            fontSize: '18px',
            cursor: 'pointer',
          }}
        />
      </IconButton>
      <Menu
        anchorPosition={"left"}
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            // width: '10ch',
          },
        }}>
        <MenuItem
          sx={{
            '&:hover': {
              backgroundColor: 'var(--slightpurple)',
            },
            paddingTop: '5px', // Remove all padding
          }}
          onClick={(e) => {
            onEditClick && onEditClick();
            handleClose(e);
          }}>
          <EditSvg
            className="edit_svg_level"
            style={{
              fontSize: '15px',
              cursor: 'pointer',
              fontWeight: 100,
              marginRight: '10px',
              strokeWidth: '0.5px',
            }}
          />
          Edit
        </MenuItem>
        <MenuItem
          sx={{
            '&:hover': {
              backgroundColor: 'var(--slightpurple)',
            },
            paddingBottom: '5px', // Remove all padding
          }}
          onClick={(e) => {
            onDeleteClick && onDeleteClick();
            handleClose(e);
          }}>
          <img
            src={DeleteInActiveSvg}
            className="Sirv image-main"
            height="25px"
            style={{
              marginRight: '10px',
            }}
            alt=""
          />{' '}
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
}

export const AddLevels = ({
  isAdd,
  defaultOpen,
  levelData,
  index: levelIndex,
  levelNo,
  bookData,
  currentBookId,
  onAddSuccess,
  refetch,
}) => {
  // States
  const [open, setOpen] = useState(defaultOpen ?? false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [canAddTask, setCanAddTask] = useState(false);
  const [addTask, setAddTask] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  // Custom Hooks
  const { displayAlert } = useAlert();

  // RTKQuery Hooks
  const { data: tasks } = useGetLevelTasksQuery(levelData?._id, {
    skip: isAdd,
  });
  const [createLevel] = useCreateLevelMutation();
  const [updateLevel] = useUpdateLevelMutation();
  const [deleteLevel] = useDeleteLevelMutation();
  const [thumbnail, setThumbnail] = useState();
  const [thumbnailError, setThumbnailError] = useState('');

  const thumbnailHandler = (event) => {
    const file = event.target.files[0];
    setThumbnail(file);
    setThumbnailError('');
  };

  // Handlers
  const handleDeleteOpen = () => setDeleteOpen(true);
  const deleteModuleHandler = () => {
    const levelId = levelData?._id;
    deleteLevel(levelId)
      .unwrap()
      .then(() => {
        displayAlert({
          open: true,
          message: 'Successfully Deleted Level',
          type: 'success',
          timeout: '2000',
        });
      })
      .catch((err) => {
        displayAlert({
          open: true,
          message: 'Error in Deleting Level',
          type: 'error',
          timeout: '2000',
        });
      });
  };

  // Formik validation and formik hook
  const validationSchema = yup.object({
    name: yup.string('Enter Level name').required('Level name is required'),
    description: yup
      .string('Enter Level description')
      .required('Description is required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: levelData?.name,
      description: levelData?.description,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (isAdd) {
        const level = new FormData();
        level.append('name', values.name);
        level.append('description', values.description);
        level.append('coverImage', thumbnail);

        createLevel({
          bookId: bookData._id,
          level,
        })
          .unwrap()
          .then(() => {
            onAddSuccess && onAddSuccess();
            setCanAddTask(true);
            // formik.handleReset();
            // setOpen(!open);
          })
          .catch((err) => {
            console.log(err);
          })
          .catch((err) => {});
      } else {
        const updateLevelBody = new FormData();
        updateLevelBody.append('name', values.name);
        updateLevelBody.append('description', values.description);
        updateLevelBody.append('coverImage', thumbnail);

        updateLevel({
          levelId: levelData._id,
          updateLevelBody,
        })
          .unwrap()
          .then(() => {
            displayAlert({
              open: true,
              message: 'Successfully Updated Level',
              type: 'success',
              timeout: '2000',
            });
          })
          .catch((err) => {
            displayAlert({
              open: true,
              message: 'Error in Updating Level',
              type: 'error',
              timeout: '2000',
            });
          });
      }
    },
  });
  React.useEffect(() => {
    if (!isAdd) {
      setThumbnail(levelData?.coverImage);
    }
  }, []);

  return (
    <>
      {isAdd ? (
        <div
          className={
            open ? 'editbooks__accordiansActive' : 'editbooks__accordians'
          }
          key={levelNo}>
          <div
            onClick={() => setOpen(!open)}
            className={open ? 'editbooks__accHeadOpen' : 'editbooks__accHead'}>
            {!open ? (
              <ArrowForwardIosIcon
                fontSize="small"
                sx={{
                  transform: 'rotate(90deg)',
                  color: 'var(--pblue)',
                  fontSize: '18px',
                  cursor: 'pointer',
                  transition: 'transform 0.6s',
                }}
              />
            ) : (
              <ArrowForwardIosIcon
                fontSize="small"
                sx={{
                  transform: 'rotate(-90deg)',
                  color: 'var(--pblue)',
                  fontSize: '18px',
                  cursor: 'pointer',
                  transition: 'transform 0.6s',
                }}
              />
            )}
            <h5>Add level {levelData?.levels.length + 1}</h5>
          </div>

          {open && (
            <div className="editbooks__accordiansForm">
              <div>
                <h4 className="ftwt__b">Level Name</h4>
                <TextField
                  sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    marginTop: '5px',
                  }}
                  id="level-name-field"
                  name="name"
                  fullWidth
                  variant="outlined"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </div>
              <div
                className="dropzone-wrapper"
                style={{
                  border: thumbnailError ? '1px dashed #d32f2f' : '',
                }}>
                {thumbnail ? (
                  <div className="dropzone-image">
                    <img
                      height={'100%'}
                      src={
                        !thumbnail.type
                          ? bookData?.data?.coverImage
                          : URL.createObjectURL(thumbnail)
                      }
                      alt="Thumbnail Preview"
                    />
                    <CloseIcon
                      onClick={() => setThumbnail()}
                      sx={{
                        fontSize: '18px',
                        cursor: 'pointer',
                        color: 'var(--grey400)',
                        '&:hover': {
                          color: 'var(--error)',
                        },
                      }}
                    />
                  </div>
                ) : (
                  <div className="dropzone-desc">
                    <UploadFileOutlinedIcon sx={{ fontSize: '70px' }} />
                    <p
                      style={{
                        fontSize: '14px',
                        // color: isLarge ? 'var(--error)' : '',
                      }}>
                      Choose an image file{' '}
                      <span style={{ fontSize: '11px' }}>or drag it here.</span>
                      <br />
                      <span style={{ fontSize: '11px' }}>
                        Maximum file size 1MB
                      </span>
                    </p>
                  </div>
                )}

                {!thumbnail && (
                  <input
                    name="thumbnail"
                    onChange={thumbnailHandler}
                    type="file"
                    accept="image/*"
                    className="dropzone"
                  />
                )}
              </div>
              {thumbnailError ? (
                <FormHelperText
                  style={{
                    marginTop: '-5px',
                    color: '#d32f2f',
                  }}
                  error>
                  {thumbnailError}
                </FormHelperText>
              ) : null}
              <div>
                <h4 className="ftwt__b">Description</h4>
                <TextField
                  sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    marginTop: '5px',
                  }}
                  required
                  id="level-description-field"
                  variant="outlined"
                  name="description"
                  fullWidth
                  rows={2}
                  multiline
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
              </div>
              <div>
                <div className="editbooks__levelAction">
                  {/* <img src={DeleteInActiveSvg} height="30px" alt="" /> */}

                  <Button
                    variant="contained"
                    onClick={() => {
                      if (!thumbnail) {
                        setThumbnailError('Thumbnail is required');
                        return;
                      }
                      formik.handleSubmit();
                    }}>
                    Save
                  </Button>
                  <Button
                    disabled={!canAddTask}
                    variant="outlined">
                    Add task
                  </Button>
                </div>
              </div>

              {/* <div className="editbooks__modules">
              <AddTopic
                moduleNo={course.modules.length + 1}
                course={course}
                isAdd
              />
             </div> */}
            </div>
          )}
        </div>
      ) : (
        <div
          className={
            open ? 'editbooks__accordiansActive' : 'editbooks__accordians'
          }>
          <DeleteModal
            label={levelData?.name}
            deleteHandler={deleteModuleHandler}
            setDeleteOpen={setDeleteOpen}
            deleteOpen={deleteOpen}
          />
          <div
            onClick={() => setOpen(!open)}
            style={{
              borderBottomRightRadius: open ? '0px' : '10px',
              borderBottomLeftRadius: open ? '0px' : '10px',
            }}
            className="editbooks__accHead">
            {!open ? (
              <ArrowForwardIosIcon
                fontSize="small"
                onClick={() => setOpen(true)}
                sx={{
                  transform: 'rotate(90deg)',
                  color: 'var(--pblue)',
                  fontSize: '18px',
                  cursor: 'pointer',
                  transition: 'transform 0.6s',
                }}
              />
            ) : (
              <ArrowForwardIosIcon
                fontSize="small"
                onClick={() => setOpen(false)}
                sx={{
                  transform: 'rotate(-90deg)',
                  color: 'var(--pblue)',
                  fontSize: '18px',
                  cursor: 'pointer',
                  transition: 'transform 0.6s',
                }}
              />
            )}
            <h5>
              Level {levelIndex + 1} {'>'} {levelData.name}
            </h5>
            <LongMenu
              onEditClick={() => {
                setOpen(true);
                setIsEditable(true);
              }}
              onDeleteClick={() => {
                handleDeleteOpen();
              }}
            />
          </div>

          {open && (
            <div className="editbooks__accordiansForm">
              <div>
                <h4 className="ftwt__b">Level Name</h4>
                <TextField
                  sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    marginTop: '5px',
                  }}
                  id="level-name-field"
                  name="name"
                  fullWidth
                  variant="outlined"
                  disabled={!isEditable}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </div>
              <div
                className="dropzone-wrapper"
                style={{
                  border: thumbnailError ? '1px dashed #d32f2f' : '',
                }}>
                {thumbnail ? (
                  <div className="dropzone-image">
                    <img
                      height={'100%'}
                      src={
                        !thumbnail.type
                          ? levelData?.coverImage
                          : URL.createObjectURL(thumbnail)
                      }
                      alt="Thumbnail Preview"
                    />
                    {isEditable && (
                      <CloseIcon
                        onClick={() => setThumbnail()}
                        sx={{
                          fontSize: '18px',
                          cursor: 'pointer',
                          color: 'var(--grey400)',
                          '&:hover': {
                            color: 'var(--error)',
                          },
                        }}
                      />
                    )}
                  </div>
                ) : (
                  <div className="dropzone-desc">
                    <UploadFileOutlinedIcon sx={{ fontSize: '70px' }} />
                    <p
                      style={{
                        fontSize: '14px',
                        // color: isLarge ? 'var(--error)' : '',
                      }}>
                      Choose an image file{' '}
                      <span style={{ fontSize: '11px' }}>or drag it here.</span>
                      <br />
                      <span style={{ fontSize: '11px' }}>
                        Maximum file size 1MB
                      </span>
                    </p>
                  </div>
                )}
                {!thumbnail && (
                  <input
                    name="thumbnail"
                    onChange={thumbnailHandler}
                    type="file"
                    accept="image/*"
                    className="dropzone"
                  />
                )}
              </div>
              {thumbnailError ? (
                <FormHelperText
                  style={{
                    marginTop: '-5px',
                    color: '#d32f2f',
                  }}
                  error>
                  {thumbnailError}
                </FormHelperText>
              ) : null}
              <div>
                <h4 className="ftwt__b">Description</h4>
                <TextField
                  sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    marginTop: '5px',
                  }}
                  required
                  id="level-description-field"
                  variant="outlined"
                  name="description"
                  fullWidth
                  disabled={!isEditable}
                  rows={2}
                  multiline
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
                <div className="editbooks__levelAction">
                  {/* <img src={DeleteInActiveSvg} height="30px" alt="" /> */}
                  {/* <div className="figure">
                    <img
                      src={DeleteInActiveSvg}
                      onClick={handleDeleteOpen}
                      className="Sirv image-main"
                      height="30px"
                      alt=""
                    />
                    <img
                      src={DeleteActiveSvg}
                      onClick={handleDeleteOpen}
                      className="Sirv image-hover"
                      height="30px"
                      alt=""
                    />
                  </div> */}
                  {/* <Button
                    variant="outlined"
                    onClick={() => formik.handleSubmit()}
                  >
                    Edit
                  </Button> */}
                  {/* <div className="figure">
                    <img
                      src={SaveActiveSvg}
                      className="Sirv image-main"
                      onClick={() => formik.handleSubmit()}
                      height="21px"
                      alt=""
                    />
                    <img
                      src={SaveInActiveSvg}
                      className="Sirv image-hover"
                      onClick={() => formik.handleSubmit()}
                      height="21px"
                      alt=""
                    />
                  </div> */}

                  <Button
                    variant="contained"
                    onClick={() => {
                      if (!thumbnail) {
                        setThumbnailError('Thumbnail is required');
                        return;
                      }
                      formik.handleSubmit();
                    }}>
                    Save changes
                  </Button>
                </div>
              </div>
              <div className="seprator"></div>
              <div
                className="editbooks__levels"
                style={{
                  paddingBottom: !addTask ? '0' : '',
                  marginTop: !addTask ? '10px' : '',
                }}>
                {tasks &&
                  tasks.data.tasks.map((task, index) => {
                    return (
                      <AddTask
                        key={index}
                        taskData={task}
                        levelIndex={levelIndex}
                        index={index}
                        bookId={currentBookId}
                        levelId={levelData?._id}
                        refetch={refetch}
                      />
                    );
                  })}
                {addTask ? (
                  <AddTask
                    isAdd
                    taskData={tasks?.data}
                    levelIndex={levelIndex}
                    bookId={currentBookId}
                    levelId={levelData?._id}
                    refetch={refetch}
                    removeAddTask={() => setAddTask(false)}
                  />
                ) : (
                  <Button
                    style={{
                      marginLeft: 'auto',
                    }}
                    variant="outlined"
                    onClick={() => setAddTask(true)}>
                    Add task
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
