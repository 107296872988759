import React from "react";

import "./CourseCompletions.css";
import downloadIcon from "../../../assets/Icons/download.svg";
import { CertificatePDF } from "../CertificatePDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useGetCompletedCoursesQuery } from "../../../redux-toolkit/features/my-activity/myActivityApiSlice";

const CourseCompletionCard = ({ course }) => {
  return (
    <div className="cc__card">
      <PDFDownloadLink
        document={
          <CertificatePDF
            name={`${course?.user?.fname} ${course.user.lname}`}
            courseName={`${course?.course?.name}`}
          />
        }
        fileName={`${course?.course?.name}_certificate.pdf`}
      >
        {({ loading }) => (
          <button disabled={loading} className="cc__downloadbtn">
            <img
              src={downloadIcon}
              alt="download"
              style={{
                width: "35px",
              }}
            />
          </button>
        )}
      </PDFDownloadLink>
      <img
        src={course?.course?.coverImage}
        alt="course-cover"
        style={{ objectFit: "cover" }}
      />
      <div className="cc__card_body">
        <div>
          <div>
            <h4 className="frwt__b">{course?.course?.name}</h4>
          </div>
          <div className="admincard__contentseprator cc__separator"></div>
        </div>
        <span className="truncate-5">{course?.course?.description}</span>
      </div>
    </div>
  );
};

const CourseCompletions = () => {
  const { data } = useGetCompletedCoursesQuery();

  return (
    <div className="cc__container">
      {data?.data?.map((course) => {
        return <CourseCompletionCard course={course} />;
      })}
    </div>
  );
};

export default CourseCompletions;
