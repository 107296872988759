import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
  type: "info",
  message: "",
  timeout: 5000,
};

export const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    showAlert: (state, action) => ({
      ...initialState,
      ...action.payload,
      open: true,
    }),
    closeAlert: (state) => ({ ...state, open: false }),
  },
});

export const { showAlert, closeAlert } = alertSlice.actions;

export default alertSlice.reducer;
