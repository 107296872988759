import { Box, Button, Divider } from '@mui/material';
import React, { useEffect } from 'react';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import './Shop.css';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { useState } from 'react';
import { updateQuantity } from '../../../redux-toolkit/features/rewards/rewardsSlice';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { useAlert } from '../../../hooks/useAlert';

const ShopCard = ({
  handleAddProduct,
  isLoading,
  walletPoints,
  item,
  rewards,
  removeCartProduct,
}) => {
  let isAdded = rewards?.some((reward) => reward?.product?._id === item._id);
  let initialQuantity = rewards?.filter(
    (reward) => reward?.product?._id === item?._id
  );
  const totalPoints = useSelector((state) => state.rewards.totalPoints);
  const { displayAlert } = useAlert();

  const [qty, setQty] = useState(
    (initialQuantity && initialQuantity[0]?.quantity) || 1
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (initialQuantity) {
      setQty(initialQuantity[0]?.quantity);
    }
  }, [initialQuantity]);

  useEffect(() => {}, [isAdded, qty]);

  const handleupdateQty = (newQuantity) => {
    const product_id = item._id;
    dispatch(updateQuantity({ product_id, newQuantity }));
  };

  const handleRedeem = (productId, points, type) => {
    const finalPoints = +totalPoints + +points;
    if (walletPoints >= finalPoints) {
      handleAddProduct(productId, qty, type);
    } else {
      displayAlert({
        open: true,
        message: 'Not enough balance',
        type: 'error',
        timeout: '2000',
      });
    }
  };

  const handleIncrement = (productId, points) => {
    const finalPoints = +totalPoints + +points;
    if (walletPoints >= finalPoints) {
      setQty((prev) => prev + 1);
      handleAddProduct(productId, qty + 1, 'add');
    } else {
      displayAlert({
        open: true,
        message: 'Not enough balance',
        type: 'error',
        timeout: '2000',
      });
    }
  };
  const handleDecrement = (productId) => {
    if (qty > 1) {
      setQty((prev) => prev - 1);
      handleAddProduct(productId, qty - 1, 'remove');
    } else {
      removeCartProduct(productId);
    }
  };

  return (
    <div className="shopCard">
      <img
        src={item.image}
        alt=""
      />
      <div className="shopCard__heading">
        <h4 className="truncate-1">{item.name}</h4>
        <Divider
          style={{
            margin: '8px 0',
            width: '100%',
          }}
          variant="middle"
          color="#ddd"
        />
        <div>
          <StarBorderOutlinedIcon
            sx={{ fontSize: '20px', color: 'var(--pmain)' }}
          />

          <p> {item.points} Points </p>
        </div>
      </div>
      <div className="shopCard__action">
        {isAdded ? (
          <>
            {!isLoading ? (
              <div
                style={{
                  display: 'flex',
                  alignItem: 'center',
                  // border: '1px solid var(--pblue)',
                  borderRadius: '4px',
                  width: '30px',
                  // justifyContent: 'center',
                }}>
                <RemoveOutlinedIcon
                  onClick={() => handleDecrement(item._id)}
                  sx={{
                    fontSize: '20px',
                    color: 'white',
                    cursor: 'pointer',
                    margin: 'auto',
                    borderTopLeftRadius: '2px',
                    borderBottomLeftRadius: '2px',
                    backgroundColor: 'var(--pblue)',
                    border: '1px solid var(--pblue)',
                    padding: '8px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                />

                <input
                  type="text"
                  value={qty}
                  readOnly
                  onChange={(e) => setQty(e.target.value)}
                  style={{
                    width: '100px',

                    borderLeft: '0px solid var(--grey300)',
                    borderRight: '0px solid var(--grey300)',
                    borderBottom: '1px solid var(--grey300)',
                    borderTop: '1px solid var(--grey300)',
                    textAlign: 'center',
                    padding: '0px !important',
                    backgroundColor: 'white', // Set background color to white
                    borderRadius: '0', // Set border radius to 0
                  }}
                  // defaultValue={}
                />
                <AddOutlinedIcon
                  onClick={() => handleIncrement(item._id, item.points)}
                  sx={{
                    fontSize: '20px',
                    color: 'white',
                    cursor: 'pointer',
                    margin: 'auto',
                    borderTopRightRadius: '2px',
                    borderBottomRightRadius: '2px',
                    backgroundColor: 'var(--pblue)',
                    border: '1px solid var(--pblue)',
                    padding: '8px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                />
              </div>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  height: '5px !important',
                }}>
                <CircularProgress />
              </Box>
            )}
          </>
        ) : (
          <Button
            onClick={() => handleRedeem(item._id, item.points, 'add')}
            variant="contained"
            fullWidth>
            Redeem
          </Button>
        )}
      </div>
    </div>
  );
};

export default ShopCard;
