import React from "react";
import "./CredentialTier.css";

export const CredentialTier = ({ tier }) => {
  return (
    <div className="credential__parent_container">
      <div className="credential__tier_left">
        <small className="credential__tier_title">{tier?.title}</small>
        <div className="credential__tier_left_border"></div>
      </div>
      <div className="credential__tier_right">
        {tier?.steps?.map((step, i) => {
          let isEndBadge = i === 3;
          let isStartBadge = i === 0;
          return (
            <div key={i} className="credential__tier_step">
              <div
                className="credential__tier_badge"
                style={{
                  justifyContent: isEndBadge ? "center" : "",
                  paddingLeft: isEndBadge ? "0" : "",
                }}
              >
                {!isEndBadge ? (
                  <div className="credential__tier_badge_border"></div>
                ) : null}
                <div>
                  <img
                    style={{
                      width: isEndBadge ? "70px" : "50px",
                      zIndex: 1,
                    }}
                    src={step?.badge?.image}
                  />
                </div>
                {!isEndBadge ? (
                  <small className="credential__tier_badge_text">
                    {step.badge.title}
                  </small>
                ) : null}
              </div>
              <div
                className="credential__tier_tasks_count"
                style={{
                  borderTopLeftRadius: isStartBadge ? "15px" : "",
                  borderBottomLeftRadius: isStartBadge ? "15px" : "",
                  borderTopRightRadius: isEndBadge ? "15px" : "",
                  borderBottomRightRadius: isEndBadge ? "15px" : "",
                }}
              >
                <p style={{ color: "#5C5C5C" }}>{step.tasks}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
