import React from "react";
import arrow from "../../../assets/Icons/arrow.svg";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import "../Books.css";
import { BooksLevels } from "./BooksLevels";
import { useParams, useNavigate, Link } from "react-router-dom";
import { LevelCover } from "./LevelCover";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import {
  useGetBookByIdQuery,
  useGetBooksLevelsQuery,
  useGetLevelsByIdQuery,
} from "../../../redux-toolkit/features/books/bookApiSlice";
import ProgressBar from "../../UI/ProgressBar";
import { useDispatch, useSelector } from "react-redux";
import { CompletedBook } from "../Completed Book/CompletedBook";
import { bookModeToggler } from "../../../redux-toolkit/features/theme/themeSlice";

export const Levels = () => {
  const { bookId, levelId } = useParams();
  const [page, setPage] = React.useState(1);
  const bookMode = useSelector((state) => state.theme.bookMode);
  const [blevelId, setBLevelId] = React.useState();
  const [toggleCompleted, setToggleCompleted] = React.useState(false);
  const dispatch = useDispatch();
  const { data: bookData, isLoading, refetch } = useGetBookByIdQuery(bookId);
  const navigate = useNavigate();
  const {
    data: level,
    isSuccess,
    isLoading: loadingData,
    isError,
  } = useGetLevelsByIdQuery(levelId, {
    refetchOnMountOrArgChange: true,
  });

  React.useEffect(() => {
    dispatch(bookModeToggler(bookData?.data?.theme?.mode));
  }, [bookData]);

  React.useEffect(() => {
    if (bookData?.data?.completionPercentage === 100) {
      setToggleCompleted(true);
    }
  }, [bookData]);

  React.useEffect(() => {
    let currentIndex = bookData?.data.levels.findIndex(
      (obj) => obj._id === levelId
    );
    setPage(currentIndex);
  }, [bookData]);

  React.useEffect(() => {}, [bookData]);

  if (isLoading || loadingData) {
    return (
      <div style={{ height: "100vh" }}>
        <ProgressBar />
      </div>
    );
  }

  const next = () => {
    let currentIndex = bookData?.data.levels.findIndex(
      (obj) => obj._id === levelId
    );
    let level = bookData?.data?.levels[currentIndex + 1]?._id;
    if (currentIndex === bookData?.data.levels.length - 1) {
      return null;
    } else {
      return (
        navigate(`/quest/books/${bookId}/levels/${level}`),
        setPage(currentIndex + 1)
      );
    }
  };
  const prev = () => {
    let currentIndex = bookData?.data.levels.findIndex(
      (obj) => obj._id === levelId
    );
    let level = bookData?.data?.levels[currentIndex - 1]?._id;

    if (currentIndex === 0) {
      return null;
    } else {
      return (
        navigate(`/quest/books/${bookId}/levels/${level}`),
        setPage(currentIndex - 1)
      );
    }
  };

  if (toggleCompleted) {
    return (
      <CompletedBook
        completedLevelsCount={bookData?.data?.completedLevelsCount}
        completedTasksCount={bookData?.data?.completedLevelsCount}
        setToggleCompleted={setToggleCompleted}
        bookMode={bookData?.data?.theme?.mode}
        name={bookData?.data?.name}
        bookId={bookData?.data?._id}
      />
    );
  }


  return (
    <div className={bookMode === "dark" ? "darkBook" : ""}>
      <div
        // style={{
        //   backgroundImage: `url(${bookData?.data?.theme?.bgImage})`,
        // }}
        className="bg__books"
      >
        {bookData && bookData?.data.levels.length === 0 ? (
          <h1>No levels Found</h1>
        ) : (
          <div
            className={
              bookMode === "dark" ? "books__layout darkBook" : "books__layout"
            }
          >
            <div className="container__books">
              <div className="books__breadcrumbs">
                <Link to="/quest">
                  <div className="books__breadcrumbshome">
                    <HomeRoundedIcon
                      sx={{
                        padding: "5px",
                        color: bookMode === "dark" ? "var(--pblue)" : "white",
                        "&:hover": {
                          color: bookMode === "dark" ? "white" : "#444444",
                        },
                      }}
                    />
                  </div>
                </Link>
                <h4
                  className="ftwt__r"
                  style={{
                    color: bookMode === "dark" ? "var(--pblue)" : "white",
                  }}
                >
                  Home
                </h4>
                <PlayArrowIcon
                  sx={{
                    fontSize: "20px",
                    color: bookMode === "dark" ? "var(--pblue)" : "white",
                  }}
                />
                <Link
                  style={{ textDecoration: "none" }}
                  to={`/quest/books/${bookId}`}
                >
                  <h4
                    className="ftwt__r"
                    style={{
                      color: bookMode === "dark" ? "var(--pblue)" : "white",
                    }}
                  >
                    {bookData?.data?.name}
                  </h4>
                </Link>
                <PlayArrowIcon
                  sx={{
                    fontSize: "20px",
                    color: bookMode === "dark" ? "var(--pblue)" : "white",
                  }}
                />
                <h4
                  className="ftwt__r"
                  style={{
                    color: bookMode === "dark" ? "var(--pblue)" : "white",
                  }}
                >
                  {level?.data?.name}
                </h4>
              </div>
              <div className="books">
                <div className="books__lyt">
                  <LevelCover
                    levelCoverId={level?.data?._id}
                    name={level?.data?.name}
                    file={level?.data?.file}
                    coverImage={level?.data?.coverImage}
                    description={level?.data?.description}
                    _id={bookId}
                    bookName={bookData?.data?.name}
                  />
                </div>
                <div className="books__ryt">
                  <BooksLevels
                    curElm={level?.data}
                    bookTheme={bookData?.data.theme}
                    length={bookData?.data.levels.length}
                    setPage={setPage}
                    bookMode={bookMode}
                    next={next}
                    prev={prev}
                    page={page}
                    bookId={bookId}
                    levelId={levelId}
                    bookName={bookData?.data.name}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
