import React, { useState } from "react";
import CourseProgress from "../../Library/CourseProgress";
import { Moddetails } from "./Moddetails";
import "./Moduleside.css";
import { Transition } from "react-transition-group";
import { IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import star from "../../../assets/Icons/modules/Star.svg";

const duration = 150;
const sidebarStyle = {
  transition: `width ${duration}ms ease`,
};
const sidebarTransitionStyles = {
  entering: { width: "91px" },
  entered: { width: "491px" },
  exiting: { width: "491px" },
  exited: { width: "91px" },
};

const modeSwitcherStyle = {
  transition: `opacity ${duration}ms ease`,
  flexWrap: "nowrap",
};

const opacitySwitchTransitionStyles = {
  entering: { opacity: 0, width: 0, height: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 1 },
  exited: { opacity: 0, width: 0, height: 0 },
};
const opacitySwitchButtonTransitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 0, width: 0, height: 0 },
  exiting: { opacity: 0, width: 0, height: 0 },
  exited: { opacity: 1 },
};

const opacityArrowTransitionStyles = {
  entering: { transform: "rotate(0)" },
  entered: { transform: "rotate(180deg)" },
  exiting: { transform: "rotate(180deg)" },
  exited: { transform: "rotate(0)" },
};

export const Moduleside = ({
  modules,
  moduleTotal,
  moduleCompleted,
  selectedTopic,
  selectedModule,
  percentageCompleted,
  setSelectedTopic,
  setSelectedModule,
}) => {
  const [open, setOpen] = useState(true);
  const handleModSide = () => {
    setOpen(!open);
  };

   const moduleHandler = (module) => {
     setSelectedModule(module);
   };
 
  return (
    <Transition
      in={open}
      timeout={50}>
      {(state) => (
        <>
          <div
            style={{
              ...sidebarStyle,
              ...sidebarTransitionStyles[state],
              transition: 'width 0.4s ease',
            }}
            className="module">
            <div className={open ? 'module__content' : 'module__topnotopen'}>
              <div className="module__top">
                {!open && (
                  <>
                    <h4
                      className="ftwt__r "
                      style={{ ...opacitySwitchButtonTransitionStyles[state] }}>
                      {moduleCompleted}/{moduleTotal}
                    </h4>
                  </>
                )}
                {open && (
                  <>
                    <h4
                      className="ftwt__r"
                      style={{ ...opacitySwitchTransitionStyles[state] }}>
                      {moduleCompleted}/{moduleTotal} &nbsp; Completed
                    </h4>
                    {/* <img src={star} alt="" /> */}
                  </>
                )}
              </div>
              {open && (
                <div
                  className="module__progress"
                  style={{ ...opacitySwitchTransitionStyles[state] }}>
                  <CourseProgress percentageCompleted={percentageCompleted} />
                </div>
              )}
              <div>
                {modules &&
                  modules.map((item, index) => (
                    <Moddetails
                      selectedTopicId={selectedTopic?._id}
                      setSelectedTopic={setSelectedTopic}
                      selectedTopic={selectedTopic}
                      selectedModule={selectedModule}
                      moduleHandler={moduleHandler}
                      open={open}
                      moduleNo={index}
                      module={item}
                    />
                  ))}
              </div>
            </div>
            <div className="module__switch">
              <IconButton
                onClick={handleModSide}
                aria-label="sidebar-switch"
                size="small">
                <ArrowForwardIosIcon
                  sx={{
                    color: 'white',
                    strokeWidth: 1,
                    fontSize: '15px',
                    stroke: 'white',
                    transition: `transform ${duration}ms`,
                  }}
                  style={{ ...opacityArrowTransitionStyles[state] }}
                  fontSize="inherit"
                />
              </IconButton>
            </div>
          </div>
        </>
      )}
    </Transition>
  );
};
