import React from "react";
import "./ReportCard.css";
import svg from "../../../assets/Icons/Course duration.svg";
import { Link, useNavigate } from "react-router-dom";

export const ReportCard = ({ data, base }) => {
  const navigate = useNavigate();

  return (
    <div
      key={data._id}
      className="reportCard">
      <img
        src={data.icon}
        alt=""
      />
      <h5 style={{ fontSize: '14px', fontWeight: 600 }}>{data.label} </h5>
      <h6
        className="ftwt__r"
        style={{ fontSize: '14px' }}>
        {data.description}
      </h6>
      <div>
        <Link to={`${data.link}`}>
          <button className="contained__lgbtn">Show Report</button>
        </Link>
      </div>
    </div>
  );
};
