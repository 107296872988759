import { apiSlice } from "../../api/apiSlice";
import { bgSwitcher } from "./themeSlice";

export const themeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    applyTheme: builder.mutation({
      query: (_id) => ({
        url: `/api/themes/activate/${_id}`,
        method: "PATCH",
        providesTags: ["Themes"],
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(bgSwitcher(data.data));
        } catch (err) {
          console.log(err);
        }
      },
    }),
    getThemes: builder.query({
      query: () => "/api/themes",
      method: "GET",
    }),
    getInitialAppliedTheme: builder.query({
      query: () => "/api/themes/active/get",
      method: "GET",
    }),
    async onQueryStarted(arg, { dispatch, queryFulfilled }) {
      try {
        const { data } = await queryFulfilled;
        dispatch(bgSwitcher(data.data));
      } catch (err) {
        console.log(err);
      }
    },
    getAppliedTheme: builder.query({
      query: () => "/api/themes/active/get",
      method: "GET",
    }),
    invalidatesTags: ["Themes"],
  }),
});

export const {
  useGetThemesQuery,
  useApplyThemeMutation,
  useGetAppliedThemeQuery,
  useGetInitialAppliedThemeQuery,
} = themeApiSlice;
