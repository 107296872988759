import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import * as React from "react";
import { CredentialPoints } from "./CredentialPoints/CredentialPoints";
import "./BadgesSection.css";
import { CredentialTier } from "./CredentialTier/CredentialTier";
import {
  useAdminPointBasedBadgeStatsQuery,
  useAdminTaskBasedBadgeStatsQuery,
} from "../../../../redux-toolkit/features/dashboard/dashboardApliSlice";

const points = [
  {
    title: "Bronze badge",
    image:
      "https://quest-static-bucket.s3.ap-south-1.amazonaws.com/point_badges/bronze_badge.png",
  },
  {
    title: "Silver badge",
    image:
      "https://quest-static-bucket.s3.ap-south-1.amazonaws.com/point_badges/silver_badge.png",
  },
  {
    title: "Gold badge",
    image:
      "https://quest-static-bucket.s3.ap-south-1.amazonaws.com/point_badges/golden_badge.png",
  },
  {
    title: "Platinum badge",
    image:
      "https://quest-static-bucket.s3.ap-south-1.amazonaws.com/point_badges/platinum_badge.png",
  },
  {
    title: "Diamond badge",
    image:
      "https://quest-static-bucket.s3.ap-south-1.amazonaws.com/point_badges/diamond_badge.png",
  },
];
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const BadgesSection = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { data } = useAdminTaskBasedBadgeStatsQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const { data: pointsData } = useAdminPointBasedBadgeStatsQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <div className="bs__container">
      <Box sx={{ width: "100%" }}>
        <Box sx={{ padding: "0 30px" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className="bs__tabs_cont"
          >
            <Tab
              sx={{ textTransform: "capitalize", fontWeight: "500" }}
              label="Tasks"
              className="bs__tab"
              {...a11yProps(0)}
            />
            <Tab
              label="Points"
              sx={{
                textTransform: "capitalize",
                marginRight: "20px",
                fontWeight: "500",
              }}
              className="bs__tab"
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <div className="bs__tab_panel_container">
          <TabPanel value={value} index={0}>
            <div className="bs__task_based_container">
              {data && data.data
                ? data?.data.map((tier, i) => {
                    return <CredentialTier key={i} tier={tier} />;
                  })
                : null}
            </div>
          </TabPanel>
          <TabPanel value={value} index={1} style={{ overflow: "auto" }}>
            <div className="bs__point_based_container">
              {pointsData?.data?.map((point, i) => {
                return <CredentialPoints key={i} point={point} />;
              })}
            </div>
          </TabPanel>
        </div>
      </Box>
    </div>
  );
};
