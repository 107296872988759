import React from "react";
import "./Error404.css";
import errorMan from "../../../assets/error page illustration.svg";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
export const Error404 = () => {
  return (
    <div className="container1">
      <div className="error">
        <div className="error__left">
          <p>Page Not Found</p>
          <h1>404</h1>
          <h2>
            Ooops <br />
            Page Not Found
          </h2>
          <h5 className="ftwt__r">Technically something went wrong</h5>
          <Link
            style={{ textDecoration: 'none' }}
            to="/quest">
            <Button variant="outlined">Back to home Page</Button>
          </Link>
        </div>
        <div className="error__right">
          <img
            src={errorMan}
            alt="errorMan"
          />
        </div>
      </div>
    </div>
  );
};
