import React, { useEffect, useState } from "react";
import "./Themes.css";
import CheckIcon from "@mui/icons-material/Check";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import { useDispatch, useSelector } from "react-redux";
import { bgSwitcher } from "../../../../redux-toolkit/features/theme/themeSlice";
import {
  useGetThemesQuery,
  useApplyThemeMutation,
  useGetInitialAppliedThemeQuery,
} from "../../../../redux-toolkit/features/theme/themeApiSlice";
import ProgressBar from "../../../UI/ProgressBar";
import { useAlert } from "../../../../hooks/useAlert";
import { Error404 } from "../../../UI/404/Error404";
import { NoRecord } from "../../../UI/No record/NoRecord";
import { ThemesPreview } from "./ThemesPreview";

export const Themes = ({ refetchCurrentTheme }) => {
  const dispatch = useDispatch();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [selectedPreview, setSelectedPreview] = useState(false);

  const {
    data,
    isLoading,
    refetch: refetchAllThemes,
    isError,
  } = useGetThemesQuery({
    refetchOnMountOrArgChange: true,
  });
  const theme = useSelector((state) => state.theme.bg);
  const { data: appliedTheme, refetch } = useGetInitialAppliedThemeQuery({
    refetchOnMountOrArgChange: true,
  });
  const [applyTheme, { isError: applyError }] = useApplyThemeMutation({
    refetchOnMountOrArgChange: true,
  });

  const { displayAlert } = useAlert();


  useEffect(() => {
    // This effect runs when the 'theme' or 'data' variables change
    // You can add any necessary logic here
  }, [theme, data]);

  // Handles the preview button click and opens the preview for the selected theme
  const previewHandler = (bgImage) => {
    setSelectedPreview(bgImage);
    setPreviewOpen(true);
  };

  const applyHandler = async (data) => {
    // Handles the apply button click and applies the selected theme
    await applyTheme(data._id)
      .unwrap()
      .then(async (res) => {
        // If the theme application is successful, display a success alert and perform necessary actions
        refetchAllThemes();
        refetchCurrentTheme();
        await refetch();
        displayAlert({
          open: true,
          message: "Successfully Applied New Theme",
          type: "success",
          timeout: "1500",
        });
      })
      .catch((error) =>
        // If there is an error during theme application, display an error alert

        displayAlert({
          open: true,
          message:
            error?.data?.message || `Error while creating the Department`,
          type: "success",
          type: "error",
          timeout: "1500",
        })
      );
  };

  // Renders a progress bar while the data is being fetched
  if (isLoading) {
    return <ProgressBar />;
  }

  // Renders a 404 error component if there is an error during the data fetch
  if (isError) {
    return <Error404 />;
  }

  return (
    <div className="container ">
      <ThemesPreview
        setPreviewOpen={setPreviewOpen}
        previewOpen={previewOpen}
        setSelectedPreview={setSelectedPreview}
        selectedPreview={selectedPreview}
      />

      {data.data.length > 0 ? (
        <div className="themes">
          {data &&
            data?.data.map((data, index) => (
              <div className="themes__card" key={index}>
                <div className="themes__img">
                  <img
                    src={data.themeCardImage}
                    alt="bgImg"
                    style={{
                      objectFit: "cover",
                    }}
                  />
                </div>
                <h6 className="theme__name">{data.name} </h6>
                <div className="themes__actionscontainer">
                  <div>
                    {data.isActive ? (
                      <div className="themes__applied">
                        <CheckIcon className="themes__icons" />
                        <h6 className="ftwt__r">Applied</h6>
                      </div>
                    ) : (
                      <div
                        onClick={() => applyHandler(data)}
                        className="themes__actions"
                      >
                        <CheckIcon className="themes__icons" />
                        <h6 className="ftwt__r">Apply</h6>
                      </div>
                    )}
                  </div>
                  <div
                    onClick={() => previewHandler(data)}
                    className="themes__actions"
                  >
                    <PreviewOutlinedIcon className="themes__icons" />
                    <h6 className="ftwt__r">Preview</h6>
                  </div>
                </div>
              </div>
            ))}
        </div>
      ) : (
        <NoRecord />
      )}
    </div>
  );
};
