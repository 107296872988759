import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import ProgressBar from "../../../../UI/ProgressBar";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./AccessHead.css";
import { Button } from "@mui/material";

import { Error404 } from "../../../../UI//404/Error404";
import { AccessCards } from "./Access Cards/AccessCards";
import ReportsSvg from "../../../../../assets/Icons/Manage Org/Reports.svg";
import OrgBooksSvg from "../../../../../assets/Icons/Manage Org/Organisational Book.svg";
import DepBooksSvg from "../../../../../assets/Icons/Manage Org/Departmental Book.svg";
import CoursesSvg from "../../../../../assets/Icons/Manage Org/Courses.svg";
import TaskSvg from "../../../../../assets/Icons/Manage Org/Task Verification.svg";
import BadgesSvg from "../../../../../assets/Icons/Manage Org/Badges.svg";
import UsersSvg from "../../../../../assets/Icons/Manage Org/users.svg";
import ThemesSvg from "../../../../../assets/Icons/Manage Org/Themes.svg";
import {
  ArrowLeftOutlined,
  KeyboardBackspaceOutlined,
} from "@mui/icons-material";

const adminData = [
  {
    _id: 1,
    image: DepBooksSvg,
    label: "Departmental Book",
    descp:
      "User with this access can create, read, update, delete  books in the assigned department.",
  },
  {
    _id: 2,
    image: CoursesSvg,
    label: "Courses",
    descp:
      "Courses are a part of tasks under the Department Books. With this access, a user can create, read, update, delete courses.",
  },
  {
    _id: 3,
    image: TaskSvg,
    label: "Task Verification",
    descp:
      "Access to this permission allow the admins to validate the tasks executed by Employees. ",
  },
  {
    _id: 4,
    image: BadgesSvg,
    label: "Badges",
    descp:
      "This feature is to assign recognition to the courses and books created under one department.",
  },
  {
    _id: 5,
    image: ReportsSvg,
    label: "Reports",
    descp:
      "This feature will help admins to keep track of users under one department, their progress, and their activity.   ",
  },
];

const supAdminData = [
  {
    _id: 1,
    image: OrgBooksSvg,
    label: "Organizational Book",
    descp:
      "Users with this access can create, read, update, and delete books for the overall organization and all employees",
  },
  {
    _id: 2,
    image: UsersSvg,
    label: "Users",
    descp:
      "This feature will allow the super admin to create, read, update, and delete users from the platform ",
  },
  {
    _id: 3,
    image: DepBooksSvg,
    label: "Departments",
    descp:
      "Access to this permission allows one to create, read, update, and delete a department under the organization",
  },
  {
    _id: 4,
    image: ThemesSvg,
    label: "Themes",
    descp:
      "This feature will allow to change the theme for all users as per the ocassion",
  },
  {
    _id: 5,
    image: ReportsSvg,
    label: "Reports",
    descp:
      "This permission enables the user to keep track of all users, and their activity in their respective departments. With overall complete access to user and activity statistics.",
  },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const AccessHead = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // if (isLoading) {
  //   return <ProgressBar />;
  // }
  // if (isError) {
  //   return <Error404 />;
  // }

  const scrollCarousal = (direction, role) => {
    const div = document.getElementById(`${role}_carousal`);
    if (div) {
      if (direction === "left") {
        // scroll left
        div.scrollTo({
          left: 0,
          behavior: "smooth",
        });
      } else {
        // scroll right
        div.scrollTo({
          left: 1000,
          behavior: "smooth",
        });
      }
    }
  };

  return (
    <div className="accessHead">
      <h4 style={{ fontSize: "18px" }}>Permission (Read Only)</h4>
      <h5 className="ftwt__r" style={{ fontSize: "14px" }}>
        Find out the category under permissions and how extensively the
        permissions can be used to create the platform interactive.
      </h5>
      <Box sx={{ width: "100%", marginTop: "40px" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label="Admin"
              sx={{
                textTransform: "capitalize",
                // marginRight: "20px",
                fontWeight: "500",
                fontSize: "15px",
              }}
              {...a11yProps(0)}
            />
            <Tab
              sx={{
                textTransform: "capitalize",
                fontWeight: "500",
                fontSize: "15px",
              }}
              label="Super Admin"
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <TabPanel
          className="accessHead__panel"
          style={{ position: "relative" }}
          value={value}
          index={0}
        >
          <button
            onClick={() => scrollCarousal("left", "admin")}
            className="accessHead__left_btn"
          >
            <KeyboardBackspaceOutlined style={{ color: "white" }} />
          </button>
          <div id="admin_carousal" className="accessHead__corousel">
            {adminData.map((row) => (
              <AccessCards elm={row} />
            ))}
          </div>
          <button
            onClick={() => scrollCarousal("right", "admin")}
            className="accessHead__right_btn"
          >
            {" "}
            <KeyboardBackspaceOutlined
              style={{ color: "white", rotate: "180deg" }}
            />
          </button>
        </TabPanel>
        <TabPanel
          className="accessHead__panel"
          style={{ position: "relative" }}
          value={value}
          index={1}
        >
          <button
            onClick={() => scrollCarousal("left", "superadmin")}
            className="accessHead__left_btn"
          >
            <KeyboardBackspaceOutlined style={{ color: "white" }} />
          </button>
          <div id="superadmin_carousal" className="accessHead__corousel">
            {supAdminData.map((row) => (
              <AccessCards elm={row} />
            ))}
          </div>
          <button
            onClick={() => scrollCarousal("right", "superadmin")}
            className="accessHead__right_btn"
          >
            {" "}
            <KeyboardBackspaceOutlined
              style={{ color: "white", rotate: "180deg" }}
            />
          </button>
        </TabPanel>
      </Box>
    </div>
  );
};
