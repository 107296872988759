import { apiSlice } from "../../api/apiSlice";

export const reportsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getdepartmentBookSummary: builder.query({
      query: ({ sortBy, type, query, startDate, endDate }) => ({
        url: `/api/reports/super-admin/department-book-summary?sort_by=${sortBy}&type=${type}&query=${query}${
          startDate ? `&startDate=${startDate}` : ""
        }${endDate ? `&endDate=${endDate}` : ""}`,
        method: "GET",
      }),
    }),
    getDepartmentUserSummary: builder.query({
      query: ({ sortBy, type, query, startDate, endDate }) => ({
        url: `/api/reports/super-admin/department-user-summary?sort_by=${sortBy}&type=${type}&query=${query}${
          startDate ? `&startDate=${startDate}` : ""
        }${endDate ? `&endDate=${endDate}` : ""}`,
        method: "GET",
      }),
    }),
    getDepartmentUserStatistics: builder.query({
      query: ({ sortBy, type, query, startDate, endDate }) => ({
        url: `/api/reports/super-admin/organization-user-statistics?sort_by=${sortBy}&type=${type}&query=${query}${
          startDate ? `&startDate=${startDate}` : ""
        }${endDate ? `&endDate=${endDate}` : ""}`,
        method: "GET",
      }),
    }),
    getAdminUserActivity: builder.query({
      query: ({
        userDepartmentId,
        sortBy,
        type,
        query,
        startDate,
        endDate,
      }) => ({
        url: `/api/reports/admin/user/activity/${userDepartmentId}?sort_by=${sortBy}&type=${type}&query=${query}${
          startDate ? `&startDate=${startDate}` : ""
        }${endDate ? `&endDate=${endDate}` : ""}`,
        method: "GET",
      }),
    }),
    getAdminDeptUserStatistic: builder.query({
      query: ({ depId, startDate, endDate }) => ({
        url: `/api/reports/admin/user-statistics/${depId}?keep=true${
          startDate ? `&startDate=${startDate}` : ""
        }${endDate ? `&endDate=${endDate}` : ""}`,
        method: "GET",
      }),
    }),
    getAdminUserStatistic: builder.query({
      query: () => ({
        url: `/api/reports/super-admin/department-user-summary`,
        method: "GET",
      }),
    }),
    getAdminBookStatistic: builder.query({
      query: ({
        userDepartmentId,
        sortBy,
        type,
        query,
        startDate,
        endDate,
      }) => ({
        url: `/api/reports/admin/department-book-statistics/${userDepartmentId}?sort_by=${sortBy}&type=${type}&query=${query}${
          startDate ? `&startDate=${startDate}` : ""
        }${endDate ? `&endDate=${endDate}` : ""}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetDepartmentUserStatisticsQuery,
  useGetDepartmentUserSummaryQuery,
  useGetdepartmentBookSummaryQuery,
  useGetAdminBookStatisticQuery,
  useGetAdminUserStatisticQuery,
  useGetAdminUserActivityQuery,
  useGetAdminDeptUserStatisticQuery,
} = reportsApiSlice;
