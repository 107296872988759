import { apiSlice } from "../../api/apiSlice";
// const baseString = "/api/books";

export const booksApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // @desc: Get all books according to query

    // BOOKS ROUTES
    getBooks: builder.query({
      query: (type) => `/api/books?type=${type}`,
      providesTags: ["Books"],
    }),
    getBookById: builder.query({
      query: (_id) => ({
        url: `/api/books/${_id}`,
        method: "GET",
      }),
      providesTags: ["BookById"],
    }),
    updateBook: builder.mutation({
      query: ({ _id, formData }) => ({
        url: `/api/books/${_id}`,
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["Books", "BookById"],
    }),
    publishBook: builder.mutation({
      query: ({ _id, publishBookBody }) => {
        const formData = new FormData();
        formData.append("badgeName", publishBookBody?.badgeName);
        formData.append("image", publishBookBody?.image);
        return {
          url: `/api/books/publish/${_id}`,
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: ["Books", "BookById"],
    }),
    unPublishBook: builder.mutation({
      query: ({ _id }) => {
        return {
          url: `/api/books/unpublish/${_id}`,
          method: "PATCH",
        };
      },
      invalidatesTags: ["Books", "BookById"],
    }),
    createBook: builder.mutation({
      query: (createBookBody) => ({
        url: "/api/books",
        method: "POST",
        body: createBookBody,
      }),
      invalidatesTags: ["Books"],
    }),
    deleteBook: builder.mutation({
      query: (bookId) => ({
        url: `/api/books/${bookId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Books"],
    }),

    // LEVELS ROUTES
    getLevelsById: builder.query({
      query: (_id) => ({
        url: `/api/books/level/${_id}`,
        method: "GET",
      }),
      providesTags: ["EmployeeTask"],
    }),
    getBooksLevels: builder.query({
      query: (bookId) => ({
        url: `/api/books/${bookId}/level`,
        method: "GET",
      }),
      providesTags: ["BooksLevels"],
    }),
    createLevel: builder.mutation({
      query: ({ bookId, level }) => ({
        url: `/api/books/${bookId}/level`,
        method: "POST",
        body: level,
      }),
      invalidatesTags: ["BooksLevels"],
    }),
    updateLevel: builder.mutation({
      query: ({ levelId, updateLevelBody }) => ({
        url: `/api/books/level/${levelId}`,
        method: "PATCH",
        body: updateLevelBody,
      }),
      invalidatesTags: ["BooksLevels"],
    }),
    deleteLevel: builder.mutation({
      query: (_id) => ({
        url: `/api/books/level/${_id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["BooksLevels", "BookById"],
    }),

    // TASKS ROUTES
    getLevelTasks: builder.query({
      query: (levelId) => ({
        url: `/api/books/level/${levelId}/task`,
        method: "GET",
      }),
      providesTags: ["BooksLevelsTask"],
    }),
    getTaskById: builder.query({
      query: (taskId) => ({
        url: `/api/books/task/${taskId}`,
        method: "GET",
      }),
    }),
    createTask: builder.mutation({
      query: ({ bookId, levelId, task }) => ({
        url: `/api/books/${bookId}/level/${levelId}/task`,
        method: "POST",
        body: {
          name: task.name,
          description: task.description,
          course: task.course,
          points: task.points,
        },
      }),
      invalidatesTags: ["BooksLevelsTask", "BookById"],
    }),
    updateTask: builder.mutation({
      query: ({ taskId, updateTaskBody }) => ({
        url: `/api/books/task/${taskId}`,
        method: "PATCH",
        body: { ...updateTaskBody },
      }),
      invalidatesTags: ["BooksLevelsTask"],
    }),
    deleteTask: builder.mutation({
      query: (_id) => ({
        url: `/api/books/task/${_id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["BooksLevelsTask", "BookById"],
    }),
    getTaskCourse: builder.query({
      query: () => ({
        url: `api/books/get/task-courses`,
        method: "GET",
      }),
    }),

    //HOD TASK
    getPendingHodTask: builder.query({
      query: (type) => ({
        url: `/api/books/task/pending/hod?type=${type}`,
        method: "GET",
      }),
    }),

    // Task Employee view

    sendTask: builder.mutation({
      query: ({ bookId, levelId, taskId, taskData }) => ({
        url: `/api/books/${bookId}/level/${levelId}/task/${taskId}/complete`,
        method: "POST",
        body: taskData,
      }),
    }),

    getPendingTask: builder.query({
      query: (type) => ({
        url: `/api/books/task/pending?type=${type}`,
        method: "GET",
      }),
    }),

    getPendingTaskById: builder.query({
      query: (_id) => ({
        url: `/api/books/task/pending/${_id}`,
        method: "GET",
      }),
      providesTags: ["Tasks"],
    }),
    updateTaskStatus: builder.mutation({
      query: ({ status, taskCompletionId }) => ({
        url: `/api/books/task/complete/${taskCompletionId}/${status}`,
        method: "PATCH",
      }),
      invalidatesTags: ["Tasks", "EmployeeTask"],
    }),

    // ADMIN ROUTES
    getPendingTaskCompletion: builder.query({
      query: () => ({
        url: `/api/books/task/pending`,
        method: "GET",
      }),
      providesTags: ["TaskCompletion"],
    }),
    getApproveTaskCompletion: builder.mutation({
      query: (taskCompletionId) => ({
        url: `/api/books/task/complete/${taskCompletionId}/approve`,
        method: "GET",
      }),
      invalidatesTags: ["TaskCompletion"],
    }),
    getRejectTaskCompletion: builder.mutation({
      query: (taskCompletionId) => ({
        url: `/api/books/task/complete/${taskCompletionId}/reject`,
        method: "GET",
      }),
      invalidatesTags: ["TaskCompletion"],
    }),

    createTaskCompletion: builder.mutation({
      query: ({ bookId, levelId, taskId, completionData }) => ({
        url: `/api/books/${bookId}/level/${levelId}/task/${taskId}/complete`,
        method: "POST",
        body: completionData,
      }),
      invalidatesTags: ["BooksLevelsTask"],
    }),
    resumeTask: builder.query({
      query: (userId) => ({
        url: `/api/user/ongoing-book/${userId}`,
        method: "GET",
      }),
    }),
    rateBook: builder.mutation({
      query: ({ bookId, rating }) => ({
        url: `/api/books/rate/b/${bookId}`,
        method: "POST",
        body: { rating },
      }),
    }),
    getBookRating: builder.query({
      query: ({ bookId }) => ({
        url: `/api/books/rate/b/${bookId}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  // Book hooks
  useGetBooksQuery,
  useGetBookByIdQuery,
  useUpdateBookMutation,
  usePublishBookMutation,
  useUnPublishBookMutation,
  useCreateBookMutation,
  useDeleteBookMutation,

  // Level hooks
  useGetLevelsByIdQuery,
  useGetBooksLevelsQuery,
  useGetLevelTasksQuery,
  useCreateLevelMutation,
  useUpdateLevelMutation,
  useDeleteLevelMutation,

  // Task hooks
  useGetTaskByIdQuery,
  useCreateTaskMutation,
  useUpdateTaskMutation,
  useDeleteTaskMutation,
  useGetTaskCourseQuery,

  //HOD TASK
  useGetPendingHodTaskQuery,

  // Employee view tasks
  useSendTaskMutation,
  useGetPendingTaskQuery,
  useGetPendingTaskByIdQuery,
  useUpdateTaskStatusMutation,

  // Resume Task
  useResumeTaskQuery,

  // Admin hooks
  useGetPendingTaskCompletionQuery,
  useGetBookRatingQuery,
  useGetApproveTaskCompletionMutation,
  useGetRejectTaskCompletionMutation,
  useCreateTaskCompletionMutation,
  useRateBookMutation,
} = booksApiSlice;
