import React from "react";

import { AdminLayout } from "../AdminLayout";

const MainAdminLayout = ({ isAdmin, isSuperAdmin }) => {
  return (
    <>
      <AdminLayout isAdmin={isAdmin} isSuperAdmin={isSuperAdmin} />
    </>
  );
};

export default MainAdminLayout;
