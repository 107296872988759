import { Alert, Snackbar } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useAlert } from "../../hooks/useAlert";

const SnackbarAlert = () => {
  const alert = useSelector((state) => state.alert);

  const { clearAlert } = useAlert();

  const handleClose = (_, reason) => {
    reason !== "clickaway" && clearAlert();
  };
  let vertical = "top";
  let horizontal = "center";
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={alert.open}
        sx={{ marginTop: "45px" }}
        autoHideDuration={alert?.timeout || 1000}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <Alert variant="filled" onClose={handleClose} severity={alert.type}>
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SnackbarAlert;
