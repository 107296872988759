import React, { useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./EditCourses.css";
import { Button, TextField } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import { AddTopic } from "./AddTopic";
// import DeleteActiveSvg from '../../../../assets/Icons/delete_red.svg';
// import DeleteInActiveSvg from '../../../../assets/delete_icon.svg';
import SaveActiveSvg from "../../../../assets/My Workspace/Save_Default.svg";
import SaveInActiveSvg from "../../../../assets/My Workspace/save_hover.svg";

import DeleteInActiveSvg from '../../../../assets/delete_icon.svg';
import DeleteActiveSvg from '../../../../assets/Icons/delete_red.svg';

import {
  useCreateModuleMutation,
  useDeleteModuleMutation,
  useUpdateModuleMutation,
} from "../../../../redux-toolkit/features/courses/coursesApiSlice";
import { useAlert } from "../../../../hooks/useAlert";
import { DeleteModal } from "../../../UI/Delete Modal/DeleteModal";

export const AddModule = ({
  config,
  course,
  refetch,
  modules,
  setShowModule,
  isAdd,
  moduleNo,
  defaultOpen,
  onAddSuccess,
}) => {
  const [open, setOpen] = useState(defaultOpen ?? false);
  const [module, setModule] = useState(modules?.name);
  const [createModule] = useCreateModuleMutation();
  const [deleteModule] = useDeleteModuleMutation();
  const { displayAlert } = useAlert();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [updateModule] = useUpdateModuleMutation();
  const handleOpen = () => setDeleteOpen(true);
  const [showTopic, setShowTopic] = useState(false);

  const saveModuleHandler = async () => {
    const data = { name: module, submodules: [] };
    const finalModule = { data, courseId: course?._id };

    await createModule(finalModule);
    onAddSuccess && onAddSuccess();
    setModule("");
    await refetch();
    setShowModule(false);
    displayAlert({
      open: true,
      message: "Added New Module Successfully",
      type: "success",
      timeout: "1500",
    });
  };
  const deleteModuleHandler = async () => {
    const moduleId = modules?._id;
    await deleteModule(moduleId);
    await refetch();
    displayAlert({
      open: true,
      message: "Successfully Deleted Module",
      type: "error",
      timeout: "1500",
    });
  };

  const updateCourseHandler = async () => {
    const moduleId = modules?._id;
    const data = { name: module };
    const finalData = { data, moduleId };
    await updateModule(finalData);
    await refetch();
    displayAlert({
      open: true,
      message: "Successfully Updated Module",
      type: "success",
      timeout: "1500",
    });
  };
  return (
    <>
      {isAdd ? (
        <div
          className={
            open ? 'editcourses__accordiansActive' : 'editcourses__accordians'
          }
          key={moduleNo}>
          <div
            onClick={() => setOpen(!open)}
            className="editcourses__accHead">
            <h5>Add Module {course.modules.length + 1}</h5>
            {!open ? (
              <ArrowForwardIosIcon
                fontSize="small"
                sx={{
                  transform: 'rotate(90deg)',
                  color: 'var(--pblue)',
                  fontSize: '18px',
                  cursor: 'pointer',
                  transition: 'transform 0.6s',
                }}
              />
            ) : (
              <ArrowForwardIosIcon
                fontSize="small"
                sx={{
                  transform: 'rotate(-90deg)',
                  color: 'var(--pblue)',
                  fontSize: '18px',
                  cursor: 'pointer',
                  transition: 'transform 0.6s',
                }}
              />
            )}
          </div>

          {open && (
            <div className="editcourses__accordiansForm">
              <div>
                <h4 className="ftwt__b">Module Name</h4>
                <TextField
                  sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    marginTop: '5px',
                  }}
                  id="outlined-basic"
                  value={module}
                  onChange={(e) => setModule(e.target.value)}
                  fullWidth
                  variant="outlined"
                />
                <div className="editcourses__moduleAction">
                  {/* <img src={DeleteInActiveSvg} height="30px" alt="" /> */}
                  <Button
                    onClick={saveModuleHandler}
                    variant="contained">
                    Save
                  </Button>
                  {/* <div className="figure">
                    <img
                      src={SaveActiveSvg}
                      className="Sirv image-main"
                      onClick={saveModuleHandler}
                      height="21px"
                      alt=""
                    />
                    <img
                      src={SaveInActiveSvg}
                      className="Sirv image-hover"
                      onClick={saveModuleHandler}
                      height="21px"
                      alt=""
                    />
                  </div> */}
                </div>
              </div>

              {/* <div className="editcourses__modules">
                <AddTopic
                  moduleNo={course.modules.length + 1}
                  course={course}
                  isAdd
                />
               </div> */}
            </div>
          )}
        </div>
      ) : (
        <div
          className={
            open ? 'editcourses__accordiansActive' : 'editcourses__accordians'
          }
          key={moduleNo}>
          <DeleteModal
            label={modules?.name}
            deleteHandler={deleteModuleHandler}
            setDeleteOpen={setDeleteOpen}
            deleteOpen={deleteOpen}
          />
          <div
            onClick={() => setOpen(!open)}
            className="editcourses__accHead">
            <h5>Module {moduleNo + 1}</h5>
            {!open ? (
              <ArrowForwardIosIcon
                fontSize="small"
                sx={{
                  transform: 'rotate(90deg)',
                  color: 'var(--pblue)',
                  fontSize: '18px',
                  cursor: 'pointer',
                  transition: 'transform 0.6s',
                }}
              />
            ) : (
              <ArrowForwardIosIcon
                fontSize="small"
                sx={{
                  transform: 'rotate(-90deg)',
                  color: 'var(--pblue)',
                  fontSize: '18px',
                  cursor: 'pointer',
                  transition: 'transform 0.6s',
                }}
              />
            )}{' '}
          </div>

          {open && (
            <div className="editcourses__accordiansForm">
              <div>
                <h4 className="ftwt__b">Module Name</h4>
                <TextField
                  sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    marginTop: '5px',
                  }}
                  id="outlined-basic"
                  value={module}
                  onChange={(e) => setModule(e.target.value)}
                  fullWidth
                  variant="outlined"
                />
                <div className="editcourses__moduleAction">
                  <div
                    className="figure"
                    onClick={handleOpen}>
                    <img
                      src={DeleteInActiveSvg}
                      onClick={handleOpen}
                      className="Sirv image-main"
                      height="24px"
                      alt=""
                    />
                    <img
                      src={DeleteActiveSvg}
                      onClick={handleOpen}
                      className="Sirv image-hover"
                      height="24px"
                      alt=""
                    />
                  </div>
                  <Button
                    onClick={updateCourseHandler}
                    variant="contained">
                    Save Changes
                  </Button>
                  {/* <div className="figure">
                    <img
                      src={SaveActiveSvg}
                      className="Sirv image-main"
                      onClick={updateCourseHandler}
                      height="21px"
                      alt=""
                    />
                    <img
                      src={SaveInActiveSvg}
                      className="Sirv image-hover"
                      onClick={updateCourseHandler}
                      height="21px"
                      alt=""
                    />
                  </div> */}
                </div>
              </div>
              <div className="seprator"></div>
              <div className="editcourses__modules">
                {!showTopic && (
                  <div className="editcourses__showAddTopic">
                    <Button
                      onClick={() => setShowTopic(true)}
                      variant="outlined">
                      Add Topic
                    </Button>
                  </div>
                )}
                {showTopic && (
                  <AddTopic
                    course={course}
                    setShowTopic={setShowTopic}
                    refetch={refetch}
                    moduleNo={moduleNo + 1}
                    modules={modules}
                    isAdd
                  />
                )}
                {modules?.submodules.length > 0 &&
                  modules?.submodules.map((elm, index) => (
                    <AddTopic
                      refetch={refetch}
                      moduleNo={moduleNo + 1}
                      topicNo={index}
                      topics={elm}
                    />
                  ))}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
