import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  token: null,
  user: null,
  error: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { accessToken } = action.payload;
      state.token = accessToken;
      state.user = action.payload;
      state.error = false;
    },
    setRefresh: (state, action) => {
      state.token = action.payload;
    },
    updateFeedback: (state) => {
      const data = { hasGivenFeedback: true };
      state.user = { ...state.user, data };
    },
    logoutUser: (state, action) => {
      localStorage.clear();
      state.token = null;
      state.user = null;
      state.error = false;
    },
    isErrorRedirect: (state, action) => {
      const { redirect } = action.payload;
      state.error = redirect;
    },
    setAvatar: (state, action) => {
      const { avatar } = action.payload;
      state.user = {
        ...state.user,
        avatar,
      };
    },
    setName: (state, action) => {
      const { fname, lname } = action.payload;
      state.user = {
        ...state.user,
        fname,
        lname,
      };
    },
    logoutAuth: (state, action) => {
      return initialState;
    },
  },
});

export const {
  setCredentials,
  logoutUser,
  setRefresh,
  updateFeedback,
  isErrorRedirect,
  setAvatar,
  logoutAuth,
  setName,
} = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;
export const selectCurrentError = (state) => state.auth.error;
