import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React, { useState } from "react";
import { Home } from "../../../Books/Home";
import { Navbar } from "../../../Navbar/Navbar";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  right: "50%",
  height: "100%",
  maxHeight: "95vh",
  overflow: "hidden",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  borderRadius: 3,
  boxShadow: 24,
};

export const ThemesPreview = ({
  previewOpen,
  selectedPreview,
  setSelectedPreview,
  setPreviewOpen,
}) => {
  const [timer, setTimer] = useState(10000);
  const [timeoutId, setTimeoutId] = useState(null);

  const handleClose = () => {
    setPreviewOpen(false);
    setSelectedPreview();
    setTimer(10000);
    clearTimeout(timeoutId);
  };

  React.useEffect(() => {
    clearTimeout(timeoutId);
    const newTimeoutId = setTimeout(() => {
      // handleClose();
    }, timer);
    setTimeoutId(newTimeoutId);
  }, [previewOpen, timer]);

  return (
    <div>
      <div>
        <Modal
          open={previewOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={style}>
            <div
              className={selectedPreview?.mode === 'dark' && 'dark'}
              style={{
                backgroundImage: `url(${selectedPreview?.bgImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}>
              {/* <CloseIcon
                onClick={handleClose}
                sx={{
                  fontSize: "25px",
                  cursor: "pointer",
                  color: "var(--grey600)",
                  backgroundColor: "white",
                  borderRadius: "50%",
                  padding: "5px",
                  "&:hover": {
                    color: "var(--error)",
                  },
                }}
              /> */}

              <div
                style={{
                  position: 'fixed',
                }}>
                <Navbar
                  isPreview
                  isBook
                  bookMode={selectedPreview?.mode}
                />
              </div>

              <Home
                wheelColors={selectedPreview?.wheelColors}
                isPreview
                prevdividerImage={selectedPreview?.dividerImage}
                prevWheelBg={selectedPreview?.wheelBgColor}
              />
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
};
