import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { Typography } from "@mui/material";

 
 

export default function CourseProgress({ value, col, percentageCompleted }) {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 4,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: col,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: 'var(--pmain)',
    },
  }));
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    if (progress < percentageCompleted) {
      const timer = setInterval(() => {
        setProgress((prevProgress) => prevProgress + 1);
      }, 10); // Adjust the interval as needed

      return () => {
        clearInterval(timer);
      };
    }
  }, [progress, percentageCompleted]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <BorderLinearProgress
          variant="determinate"
          value={progress}
        />
      </Box>

      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          color="text.secondary">{`${Math.round(progress)}%`}</Typography>
      </Box>
    </Box>
    // <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
    //   <Box sx={{ flexGrow: 1 }}>
    //     <BorderLinearProgress
    //       variant="determinate"
    //       value={percentageCompleted > 20 ? 100 : 0}
    //     />
    //   </Box>
    //   <Box sx={{ flexGrow: 1 }}>
    //     <BorderLinearProgress
    //       variant="determinate"
    //       value={percentageCompleted > 40 ? 100 : 0}
    //     />
    //   </Box>{" "}
    //   <Box sx={{ flexGrow: 1 }}>
    //     <BorderLinearProgress
    //       variant="determinate"
    //       value={percentageCompleted > 60 ? 100 : 0}
    //     />
    //   </Box>{" "}
    //   <Box sx={{ flexGrow: 1 }}>
    //     <BorderLinearProgress
    //       variant="determinate"
    //       value={percentageCompleted > 80 ? 100 : 0}
    //     />
    //   </Box>{" "}
    //   <Box sx={{ flexGrow: 1 }}>
    //     <BorderLinearProgress
    //       variant="determinate"
    //       value={percentageCompleted === 100 ? 100 : 0}
    //     />
    //   </Box>
    // </Box>
  );
}
