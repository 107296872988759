import { apiSlice } from "../../api/apiSlice";
import { setAllEmployees } from "./employeeSlice";

export const employeeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addEmployee: builder.mutation({
      query: (data) => ({
        url: "/api/user",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Employee"],
    }),
    updateEmployee: builder.mutation({
      query: (data) => ({
        url: `/api/user/${data.id}`,
        method: "PATCH",
        body: data.body,
      }),
      invalidatesTags: ["Employee"],
    }),
    bulkUpload: builder.mutation({
      query: (data) => ({
        url: `/api/user/bulk/${data.departmentId}`,
        method: "POST",
        body: data?.formData,
      }),
    }),
    toggleEmployeeAccess: builder.mutation({
      query: (userId) => ({
        url: `/api/user/activity/${userId}`,
        method: "PATCH",
      }),
      invalidatesTags: ["Employee"],
    }),
    getEmployeeById: builder.query({
      query: (employeeId) => ({
        url: `/api/user/${employeeId}`,
        method: "GET",
      }),
    }),
    getAllRoles: builder.query({
      query: () => ({
        url: `/api/rbac/roles`,
        method: "GET",
      }),
    }),
    assignMultipleRoles: builder.mutation({
      query: (data) => ({
        url: `/api/user/role/multiple`,
        method: "PATCH",
        body: data,
      }),
    }),

    getAllEmployees: builder.query({
      query: () => ({
        url: "/api/user",
        method: "GET",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled, q }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAllEmployees(data));
          // Set redux login state.
        } catch (error) {
          console.error("error", error);
        }
      },
    }),

    getLeaderboardEmployee: builder.query({
      query: (userId) => ({
        url: `/api/user/leaderboard/user/${userId}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useAddEmployeeMutation,
  useUpdateEmployeeMutation,
  useToggleEmployeeAccessMutation,
  useBulkUploadMutation,
  useGetAllEmployeesQuery,
  useGetEmployeeByIdQuery,
  useGetAllRolesQuery,
  useAssignMultipleRolesMutation,
  useGetLeaderboardEmployeeQuery,
} = employeeApiSlice;
