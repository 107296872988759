import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Security } from "./Security/Security";
import { Personal } from "./Personal/Personal";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const AccountTabs = ({ refetchProfile, setValue, value, formik }) => {
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example">
            <Tab
              label="Personal"
              sx={{
                textTransform: 'capitalize',
                marginRight: '20px',
                fontWeight: '500',
              }}
              {...a11yProps(0)}
            />

            <Tab
              sx={{ textTransform: 'capitalize', fontWeight: '500' }}
              label="Security"
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <TabPanel
          value={value}
          index={0}>
          <div id="step4">
            <Personal refetchProfile={refetchProfile} />
          </div>
        </TabPanel>

        <TabPanel
          value={value}
          index={1}>
          <Security formik={formik} />
        </TabPanel>
      </Box>
    </div>
  );
};
