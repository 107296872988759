import React, { useState } from "react";
import "./Security.css";
import {
  TextField,
  Box,
  IconButton,
  InputAdornment,
  Button,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export const Security = ({ formik }) => {
  const [showCPassword, setShowCPassword] = React.useState(false);
  const [showNPassword, setShowNPassword] = React.useState(false);
  const [showCNPassword, setShowCNPassword] = React.useState(false);

  const handleShowCPassword = () => setShowCPassword((show) => !show);
  const handleShowNPassword = () => setShowNPassword((show) => !show);
  const handleShowCNPassword = () => setShowCNPassword((show) => !show);

  return (
    <div className="security">
      <div>
        <h4 className="ftwt__b">Password</h4>
        <h6 className="ftwt__r">
          please enter your current password to change your password
        </h6>
      </div>
      <div>
        <h6 className="ftwt__r">Current Password </h6>
        <TextField
          id="outlined-basic"
          sx={{ width: "350px" }}
          type={showCPassword ? "text" : "password"}
          name="currentPassword"
          value={formik.values.currentPassword}
          onChange={formik.handleChange}
          error={
            formik.touched.currentPassword &&
            Boolean(formik.errors.currentPassword)
          }
          helperText={
            formik.touched.currentPassword && formik.errors.currentPassword
          }
          InputProps={{
            endAdornment: (
              <InputAdornment
                sx={{ cursor: "pointer" }}
                onClick={handleShowCPassword}
                position="end"
              >
                <IconButton>
                  {showCPassword ? (
                    <Visibility sx={{ fontSize: "22px" }} />
                  ) : (
                    <VisibilityOff sx={{ fontSize: "22px" }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
      </div>
      <div>
        <h6 className="ftwt__r">New Password </h6>
        <TextField
          id="outlined-basic"
          sx={{ width: "350px" }}
          type={showNPassword ? "text" : "password"}
          name="newPassword"
          value={formik.values.newPassword}
          onChange={formik.handleChange}
          error={
            formik.touched.newPassword && Boolean(formik.errors.newPassword)
          }
          helperText={formik.touched.newPassword && formik.errors.newPassword}
          InputProps={{
            endAdornment: (
              <InputAdornment
                sx={{ cursor: "pointer" }}
                onClick={handleShowNPassword}
                position="end"
              >
                <IconButton>
                  {showNPassword ? (
                    <Visibility sx={{ fontSize: "22px" }} />
                  ) : (
                    <VisibilityOff sx={{ fontSize: "22px" }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
      </div>
      <div>
        <h6 className="ftwt__r">Confirm New Password </h6>
        <TextField
          id="outlined-basic"
          sx={{ width: "350px" }}
          type={showCNPassword ? "text" : "password"}
          name="confirmPassword"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          error={
            formik.touched.confirmPassword &&
            Boolean(formik.errors.confirmPassword)
          }
          helperText={
            formik.touched.confirmPassword && formik.errors.confirmPassword
          }
          InputProps={{
            endAdornment: (
              <InputAdornment
                sx={{ cursor: "pointer" }}
                onClick={handleShowCNPassword}
                position="end"
              >
                <IconButton>
                  {showCNPassword ? (
                    <Visibility sx={{ fontSize: "22px" }} />
                  ) : (
                    <VisibilityOff sx={{ fontSize: "22px" }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
      </div>
      <div className="security__action_cont">
        <Button
          onClick={formik.handleSubmit}
          sx={{ padding: "8px 25px !important" }}
          variant="contained"
        >
          Update Password
        </Button>
      </div>
    </div>
  );
};
