import { Button, Divider, Rating } from "@mui/material";
import React, { useEffect, useState } from "react";
import starSvg from "../../../assets/star-medal.png";
import "./CompletedBook.css";
import StarIcon from "@mui/icons-material/Star";
import {
  useGetBookRatingQuery,
  useRateBookMutation,
} from "../../../redux-toolkit/features/books/bookApiSlice";
import { useAlert } from "../../../hooks/useAlert";

export const CompletedBook = ({
  setToggleCompleted,
  completedLevelsCount,
  completedTasksCount,
  bookMode,
  name,
  bookId,
}) => {
  const { data } = useGetBookRatingQuery(
    {
      bookId,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const [rating, setRating] = useState(0);
  const [rateBook] = useRateBookMutation();
  const { displayAlert } = useAlert();

  const rateCurrentBook = async (value) => {
    setRating(value);
    await rateBook({
      bookId,
      rating: value,
    })
      .unwrap()
      .then(() => {
        displayAlert({
          open: true,
          message:
            'Successfully rated a book with ' +
            value +
            ' star' +
            (value > 1 ? 's' : ''),
          type: 'success',
          timeout: '2000',
        });
      });
  };

  useEffect(() => {
    setRating(data?.data?.rating || 0);
  }, [data]);

  return (
    <div className={bookMode === "dark" ? "darkBook" : ""}>
      <div className="completedBook">
        <h1 style={{ fontFamily: "Alex Brush, cursive" }}>Congratulations!</h1>
        <h4 className="ftwt__r">
          You have completed <span className="ftwt__b"> {name}</span>
        </h4>
        <h6 className="ftwt__r">
          Keep up the great job, You are on your way to become an explorer!
        </h6>
        <img src={starSvg} alt="" />
        <div className="completedBook__actions">
          <Button
            variant="contained"
            sx={{
              backgroundColor: "var(--grey100)",
              width: "150px",
              color: "var(--pmain)",
              "&:hover": {
                color: "white",
                backgroundColor: "var(--pmain)",
              },
            }}
          >
            Share
          </Button>
          <Button
            variant="contained"
            onClick={() => setToggleCompleted(false)}
            sx={{
              backgroundColor: "var(--grey100)",
              width: "150px",
              color: "var(--pmain)",
              "&:hover": {
                color: "white",
                backgroundColor: "var(--pmain)",
              },
            }}
          >
            Go Back
          </Button>
        </div>
        <div className="completedBook__info">
          <p className="ftwt__r"></p>
          <p className="ftwt__r">on January 15, 2023 </p>
        </div>
        <Divider
          style={{
            margin: "20px 0",
            width: "90%",
          }}
          variant="middle"
          color="#999"
        ></Divider>
        <h3>How was your experience?</h3>
        <h5 className="ftwt__r">
          We are eagar to hear your feedback to improve this tool.
        </h5>
        <Rating
          value={rating}
          onChange={(event, newValue) => {
            rateCurrentBook(newValue);
          }}
          emptyIcon={
            <StarIcon
              style={{ opacity: 0.55, width: "30px", height: "30px" }}
              fontSize="inherit"
            />
          }
          icon={
            <StarIcon
              style={{
                opacity: 1,
                color: "var(--syellow)",
                width: "30px",
                height: "30px",
              }}
              fontSize="inherit"
            />
          }
        />
        {/* <div className="completedBook__rating">
          <StarIcon
            onClick={() => setRating(1)}
            sx={{
              color: rating < 1 ? "var(--grey200)" : "var(--syellow)",
              cursor: "pointer",
            }}
          />
          <StarIcon
            onClick={() => setRating(2)}
            sx={{
              color: rating < 2 ? "var(--grey200)" : "var(--syellow)",
              cursor: "pointer",
            }}
          />
          <StarIcon
            onClick={() => setRating(3)}
            sx={{
              color: rating < 3 ? "var(--grey200)" : "var(--syellow)",
              cursor: "pointer",
            }}
          />
          <StarIcon
            onClick={() => setRating(4)}
            sx={{
              color: rating < 4 ? "var(--grey200)" : "var(--syellow)",
              cursor: "pointer",
            }}
          />
          <StarIcon
            onClick={() => setRating(5)}
            sx={{
              color: rating < 5 ? "var(--grey200)" : "var(--syellow)",
              cursor: "pointer",
            }}
          />
        </div> */}
      </div>
    </div>
  );
};
