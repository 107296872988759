import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import "react-quill/dist/quill.snow.css";
import "./AddTask.css";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { MenuItem } from "@mui/material";
import DeleteInActiveSvg from '../../../../assets/delete_icon.svg';
import DeleteActiveSvg from '../../../../assets/Icons/delete_red.svg';
import { AddTaskCourse } from "./AddTaskCourse";
import { useFormik } from "formik";
import * as yup from "yup";
import { useAlert } from "../../../../hooks/useAlert";
import {
  useCreateTaskMutation,
  useDeleteTaskMutation,
  useUpdateTaskMutation,
  useGetTaskCourseQuery,
} from "../../../../redux-toolkit/features/books/bookApiSlice";
import { DeleteModal } from "../../../UI/Delete Modal/DeleteModal";

export const AddTask = ({
  removeAddTask,
  isAdd,
  taskData,
  index: taskIndex,
  levelIndex,
  bookId,
  levelId,
  addNewTask,
  refetch,
}) => {
  // States
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [enableSave, setEnableSave] = useState(false);
  const [addCourse, setAddCourse] = useState(false);

  const { data } = useGetTaskCourseQuery();
  const [selectedCourse, setSelectedCourse] = useState("");

  // Custom hooks
  const { displayAlert } = useAlert();

  // RTKQuery hooks
  const [createTask] = useCreateTaskMutation();
  const [updateTask] = useUpdateTaskMutation();
  const [deleteTask] = useDeleteTaskMutation();

  // Handler
  const handleDeleteOpen = () => setDeleteOpen(true);
  const deleteTaskHandler = async () => {
    const taskId = taskData?._id;
    deleteTask(taskId)
      .unwrap()
      .then(() => {
        displayAlert({
          open: true,
          message: 'Successfully Deleted Task',
          type: 'success',
          timeout: '2000',
        });
        refetch();
      })
      .catch((err) => {
        console.log(err);
        displayAlert({
          open: true,
          message: 'Error in Deleting Task',
          type: 'error',
          timeout: '2000',
        });
      });
  };

  // Formik validation
  const validationSchema = yup.object({
    description: yup
      .string("Enter Level description")
      .required("Description is required"),
    points: yup
      .number("Must be a number!")
      .required("Points are required")
      .positive("Must be a positive value!")
      .min(1, "Must be greater than or equal to 1!"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      description: isAdd ? "" : taskData?.description,
      points: isAdd ? "" : taskData?.points,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      if (isAdd) {
        if (addCourse && !selectedCourse) {
          return displayAlert({
            open: true,
            message: 'Select Course To create task',
            type: 'error',
            timeout: '2000',
          });
        }

        const task = {
          name: `Task ${taskData?.tasks.length + 1}`,
          description: values.description,
          course: selectedCourse?.value,
          points: values.points,
        };

        createTask({
          bookId: bookId,
          levelId: levelId,
          task,
        })
          .unwrap()
          .then(async () => {
            displayAlert({
              open: true,
              message: 'Successfully Created Task',
              type: 'success',
              timeout: '2000',
            });
            await refetch();

            formik.handleReset();

            setTimeout(() => {
              const textArea = document.getElementById(
                'task-description-add-field'
              );

              textArea?.focus();

              textArea?.scrollIntoView({
                behavior: 'smooth',
              });
            }, 1000);
          })
          .catch((err) => {
            formik.handleReset();
            displayAlert({
              open: true,
              message: 'Error in Creating Task',
              type: 'error',
              timeout: '2000',
            });
            formik.handleReset();
          });
      } else {
        if (addCourse && !selectedCourse) {
          return displayAlert({
            open: true,
            message: 'Select Course To create task',
            type: 'error',
            timeout: '2000',
          });
        }
        let updateData;
        if (addCourse) {
          updateData = { ...values, course: selectedCourse.value };
        }

        if (!addCourse) {
          updateData = { ...values, course: null };
        }
        updateTask({
          taskId: taskData._id,
          updateTaskBody: updateData,
        })
          .unwrap()
          .then(() => {
            displayAlert({
              open: true,
              message: 'Successfully Updated Task',
              type: 'success',
              timeout: '2000',
            });
            refetch();
            formik.handleReset();
          })
          .catch((err) => {
            displayAlert({
              open: true,
              message: 'Error in Updating Task',
              type: 'error',
              timeout: '2000',
            });
          });
      }
    },
  });

  React.useEffect(() => {
    if (!isAdd && taskData.course != null && data) {
      const preCourse = data?.data.filter(
        (item) => item.value === taskData.course
      )[0];
      setSelectedCourse(preCourse);
      setAddCourse(true);
    }
  }, [data]);

  React.useEffect(() => {
    if (taskData && taskData.course != null) {
      setAddCourse(true);
    }
  }, []);

  React.useEffect(() => {
    if (isAdd) {
      setAddCourse(false);
    }
  }, [taskData]);
  React.useEffect(() => {}, [addCourse]);

  const courseHandler = (e) => {
    setSelectedCourse(e.target.value);
  };


  return (
    <>
      {isAdd ? (
        <div
          className="addTasks"
          key={levelIndex + 1}>
          <h6>
            Level {levelIndex + 1} &gt; Create Task {taskData.tasks.length + 1}
          </h6>
          <div>
            <AddTaskCourse
              setAddCourse={setAddCourse}
              addCourse={addCourse}
            />

            <h4 className="ftwt__b ">Question</h4>
            <TextField
              sx={{
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
                marginTop: '5px',
              }}
              required
              id="task-description-add-field"
              variant="outlined"
              name="description"
              fullWidth
              rows={2}
              multiline
              value={formik.values.description}
              onChange={formik.handleChange}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
            />
            {addCourse && (
              <h4 className="ftwt__b task__points">Select Course</h4>
            )}

            {addCourse && (
              <FormControl sx={{ width: '30%', marginTop: '5px' }}>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  // defaultValue={null}
                  // value={selectedCourse}
                  onChange={courseHandler}>
                  {data?.data?.map((item, index) => (
                    <MenuItem
                      sx={{ fontSize: '14px' }}
                      key={index}
                      value={item}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            <h4 className="ftwt__b task__points">Points</h4>
            <TextField
              sx={{
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
                marginTop: '5px',
              }}
              type={'number'}
              id="task-points-add-field"
              name="points"
              value={formik.values.points}
              onChange={formik.handleChange}
              fullWidth
              variant="outlined"
              error={formik.touched.points && Boolean(formik.errors.points)}
              helperText={formik.touched.points && formik.errors.points}
            />

            <div className="task__actions">
              <Button
                style={{ marginTop: '10px' }}
                onClick={formik.handleSubmit}
                variant="outlined">
                Add Task
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="addTasks">
          <DeleteModal
            label={taskData?.name}
            deleteHandler={deleteTaskHandler}
            setDeleteOpen={setDeleteOpen}
            deleteOpen={deleteOpen}
          />
          <h6>
            Level {levelIndex + 1} &gt; Task {taskIndex + 1}
          </h6>
          <div>
            <AddTaskCourse
              setAddCourse={setAddCourse}
              addCourse={addCourse}
            />

            <h4 className="ftwt__b task__points">Question</h4>
            <TextField
              sx={{
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
                marginTop: '5px',
              }}
              required
              id="task-description-field"
              variant="outlined"
              name="description"
              fullWidth
              rows={2}
              // label=""

              value={formik.values.description}
              multiline
              onChange={formik.handleChange}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
            />
            {addCourse && (
              <h4 className="ftwt__b task__points">Select Course</h4>
            )}

            {addCourse && (
              <FormControl sx={{ width: '30%', marginTop: '5px' }}>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={selectedCourse}
                  onChange={courseHandler}>
                  {data?.data?.map((item, index) => (
                    <MenuItem
                      sx={{ fontSize: '14px' }}
                      key={index}
                      value={item}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <h4 className="ftwt__b task__points">Points</h4>
            <TextField
              sx={{
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
                marginTop: '5px',
              }}
              type={'number'}
              id="task-points-field"
              name="points"
              onChange={formik.handleChange}
              value={formik.values.points}
              fullWidth
              variant="outlined"
              error={formik.touched.points && Boolean(formik.errors.points)}
              helperText={formik.touched.points && formik.errors.points}
            />
            <div className="editcourses__moduleAction">
              {/* <img src={DeleteInActiveSvg} height="30px" alt="" /> */}
              <div
                className="figure"
                onClick={handleDeleteOpen}>
                <img
                  src={DeleteInActiveSvg}
                  onClick={handleDeleteOpen}
                  className="Sirv image-main"
                  height="24px"
                  alt=""
                />
                <img
                  src={DeleteActiveSvg}
                  onClick={handleDeleteOpen}
                  className="Sirv image-hover"
                  height="24px"
                  alt=""
                />
              </div>
              <div className="figure">
                <Button
                  variant="contained"
                  onClick={() => formik.handleSubmit()}>
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
