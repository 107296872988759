import React, { useEffect, useState } from "react";
import "./TaskBadgeTimeline.css";
import { useSelector } from "react-redux";
import { useGetUserTaskBasedBadgesQuery } from "../../../redux-toolkit/features/my-activity/myActivityApiSlice";
import LockSvg from "../../../assets/lock.png";

const TaskBadgeItem = ({ id, isCompleted, url, name }) => {
  return (
    <>
      <div
        className="task_badge__timelinebadge"
        style={{
          outline: isCompleted ? "5px solid #FFBE18" : "",
        }}
      >
        <span>{id}</span>
        <img
          src={url}
          alt="bdg"
          className="task_badge__badge"
          style={{
            // opacity: hover ? 1 : 0,
            filter: isCompleted ? "none" : "brightness(90%) grayscale(1)",
          }}
        />
      </div>
    </>
  );
};

const TaskSuperBadgeItem = ({
  url,
  subicon,
  tierNumber,
  name,
  progressPercentage,
}) => {
  return (
    <div className="task_badge__superbadgecont">
      <div className="task_badge__superbadgemetadata">
        <p className="task_badge__tier_label">Tier {tierNumber}</p>
        <p className="task_badge__name">
          {name}
          <img
            src={subicon}
            alt="bdg"
            className="task_badge__subicon"
            style={{
              filter:
                progressPercentage > 99
                  ? "none"
                  : "brightness(90%) grayscale(1)",
            }}
          />
        </p>
        <div className="task_badge__lockLayout">
          {/* //! @Shubham add functionality
           */}
          {progressPercentage < 100 ? (
            <div className="task_badge_lock">
              <img height={23} src={LockSvg} alt="" />
            </div>
          ) : null}
          <div className="task_badge__superbadgeprogress">
            <div
              className="task_badge__superbadgecompleted"
              style={{
                width: progressPercentage + "%",
              }}
            ></div>
          </div>
        </div>
      </div>
      <img
        src={url}
        alt="bdg"
        className="task_badge__superbadge"
        style={{
          filter:
            progressPercentage > 99 ? "none" : "brightness(90%) grayscale(1)",
        }}
      />
    </div>
  );
};

const TaskBadgeTimeline = () => {
  const user = useSelector((state) => state.auth.user);

  const [badgesArray, setBadgesArray] = useState([]);

  const { data, isLoading } = useGetUserTaskBasedBadgesQuery(user?._id);

  const getTimelineStructure = (data, multiplier, tasksCompleted) => {
    // get a tier badge
    let tierBadge = data?.find((b) => b.isTierBadge);

    // get badges other than tier badge
    let otherBadges = data?.filter((b) => {
      return !b.isTierBadge;
    });

    return {
      tierNumber: +tierBadge?.tier,
      tierBadgeClass: "task_badge__superbadgecont_" + tierBadge?.tier,
      tierBadgeSubIcon: tierBadge?.tierBadgeSubIcon, // crown icon
      tier: +tierBadge?.tier,
      name: tierBadge?.badgeName,
      progressPercentage: tierBadge?.percentage || 0,
      tierBadgeUrl: tierBadge?.badgeImage,
      badges: otherBadges.map((badge, i) => ({
        id: i + multiplier, // badge number
        url: badge?.badgeImage,
        name: badge?.badgeName,
        hasEarned: badge?.hasEarned,
      })),
    };
  };

  const getTierBadges = (tierNumber) => {
    return data?.data
      .map((b) => {
        if (+b.tier === tierNumber) return b;
        return null;
      })
      .filter((x) => Boolean(x));
  };

  const setTimelineState = (data) => {
    let tasksCompleted = 0;
    data?.data.forEach((b) => {
      if (!b.isTierBadge && b.hasEarned) {
        tasksCompleted++;
      }
    });

    let tierOneBadges = getTierBadges(1);

    let tierTwoBadges = getTierBadges(2);

    let tierThreeBadges = getTierBadges(3);

    setBadgesArray([
      getTimelineStructure(tierOneBadges, 1, tasksCompleted),
      getTimelineStructure(tierTwoBadges, 4, tasksCompleted),
      getTimelineStructure(tierThreeBadges, 7, tasksCompleted),
    ]);
  };

  useEffect(() => {
    if (data) {
      setTimelineState(data);
    }
  }, [data]);

  if (isLoading) return null;

  return (
    <div className="task_badge__timeline">
      <div className="task_badge__timelineprofilecircle">
        <img
          src={user?.avatar}
          alt="avatar"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "100%",
          }}
        />
      </div>
      <div className="task_badge__outer">
        {badgesArray?.map((badge, index) => {
          return (
            <div className="task_badge__card" key={index}>
              <div className="task_badge__bdcont">
                {(+badge.tierNumber % 2 === 1
                  ? badge.badges
                  : [...badge.badges]?.reverse()
                ) // even row number should be reverse to make a path pattern
                  ?.map((childBadge) => {
                    return (
                      <TaskBadgeItem
                        key={childBadge.id}
                        id={childBadge.id}
                        url={childBadge.url}
                        isCompleted={childBadge.hasEarned}
                        name={childBadge?.name}
                      />
                    );
                  })}
              </div>
              <div className={badge.tierBadgeClass}>
                <TaskSuperBadgeItem
                  tierNumber={badge.tier}
                  name={badge.name}
                  url={badge.tierBadgeUrl}
                  progressPercentage={badge.progressPercentage}
                  subicon={badge?.tierBadgeSubIcon}
                />
              </div>
            </div>
          );
        })}

        <div className="task_badge__card"></div>
      </div>
    </div>
  );
};

export default TaskBadgeTimeline;
