import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import React, { useRef, useState } from "react";
import "./QuestActivity.css";
// Import Swiper styles
// import "swiper/css";

import { ActivityModal } from "./Activity Modal/ActivityModal";
import { ReactComponent as NoFileSvg } from '../../../../assets/no_data_available.svg';

export const QuestActivity = ({ bookData, userType }) => {
  const [activityOpen, setActivityOpen] = React.useState();
  const [bookId, setBookId] = React.useState('');
  const [containerScrollPosition, setContainerScrollPosition] = useState(0);
  const isButtonClicked = useRef(false);
  const prevTimer = useRef(null);
  const nextTimer = useRef(null);

  const handleNextClick = () => {
    // we need to clear the previous timeout to avoid enabled button clicked state to true if there is another click
    if (nextTimer.current) clearTimeout(nextTimer.current);
    isButtonClicked.current = true;
    const container = document.getElementById('swipe-container');
    if (container) {
      container.scrollTo({
        left: (containerScrollPosition + 1) * 331,
        behavior: 'smooth',
      });
    }
    setContainerScrollPosition((prev) =>
      // because single view has 2 book cards in view means 2 book cards ar already in view when we start the scroll
      bookData?.data.length - 2 === prev ? prev : prev + 1
    );
    // reassign the new timer on fresh click
    nextTimer.current = setTimeout(() => {
      isButtonClicked.current = false;
    }, 500);
  };

  const handlePrevClick = () => {
    if (prevTimer.current) clearTimeout(prevTimer.current);
    isButtonClicked.current = true;
    const container = document.getElementById('swipe-container');
    if (container) {
      container.scrollTo({
        left: (containerScrollPosition - 1) * 331,
        behavior: 'smooth',
      });
    }
    setContainerScrollPosition((prev) => (prev === 0 ? 0 : prev - 1));
    prevTimer.current = setTimeout(() => {
      isButtonClicked.current = false;
    }, 500);
  };

  return (
    <div className="questActivity">
      <h3>User Activity Trend</h3>
      {bookId ? (
        <ActivityModal
          userType={userType}
          bookId={bookId}
          activityOpen={activityOpen}
          setActivityOpen={setActivityOpen}
        />
      ) : null}
      <div className="questActivity__books">
        <div
          onClick={handlePrevClick}
          className="questActivity__booksleft  ">
          <div className="questActivity__booksleftsquare"></div>
          <WestOutlinedIcon
            color="primary"
            fontSize="small"
            style={{
              transform: 'skew(20deg)',
            }}
          />
        </div>

        <div
          className="swipe_container"
          id="swipe-container"
          onScroll={(e) => {
            // if the button is clicked don't calculate the scroll position as the position (containerScrollPosition) is already updated in button click
            if (isButtonClicked.current) return;
            // if the button is not clicked that means use is scrolling through gesture
            // calculate the scroll position based on card width
            const scrollPosition = Math.floor(e.currentTarget.scrollLeft / 331);
            if (scrollPosition !== containerScrollPosition) {
              // scroll position is where our next click should start from
              // so if user scrolls 2 cards ahead the next click should be 2
              setContainerScrollPosition((prev) => scrollPosition);
            }
          }}>
          {bookData?.data?.length ? (
            bookData?.data.map((book) => (
              <div
                onClick={() => {
                  setActivityOpen(true);
                  setBookId(book?._id);
                }}
                className="questActivity__cardslayout  ">
                <div className="questActivity__bottomcard"></div>

                <div className="questActivity__cardsimg">
                  <img
                    src={book?.coverImage}
                    alt=""
                  />
                </div>
                <div className="questActivity__cards">
                  <h5>{book?.name}</h5>
                  <p>{book?.employeeCount} Employees</p>
                </div>
              </div>
            ))
          ) : (
            <div
              style={{
                width: '100vw',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
                padding: '35px 0',
              }}>
              <NoFileSvg style={{ height: '25px' }} />
              <h4
                style={{
                  color: 'var(--pblue)',
                }}
                className="ftwt__r">
                No data available
              </h4>
            </div>
          )}
        </div>

        <div
          onClick={handleNextClick}
          className="questActivity__booksright  ">
          <div className="questActivity__booksrightsquare"></div>
          <EastOutlinedIcon
            color="primary"
            fontSize="small"
            style={{
              transform: 'skew(20deg)',
            }}
          />
        </div>
      </div>
    </div>
  );
};
