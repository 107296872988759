import { ChevronRight } from "@mui/icons-material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import * as React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectedTheme } from "../../redux-toolkit/features/theme/themeSlice";
import "./BreadcrumbsUi.css";

export const BreadcrumbAdmin = ({ base, isQuest, page }) => {
  const theme = useSelector(selectedTheme);

  return (
    <div className="breadcrumbAdmin">
      <Breadcrumbs
        separator={
          <ChevronRight fontSize="small" />
          // <PlayArrowIcon
          //   sx={{
          //     fontSize: "15px",
          //     color: isQuest ? "var(--pblue)" : "var(--grey600)",
          //   }}
          // />
        }
        aria-label="breadcrumb">
        {base.map((curElm) => (
          <Link
            style={{
              textDecoration: 'none',
              color: 'var(--grey600)',
              fontSize: '14px',
            }}
            reloadDocument={curElm?.reload}
            key={curElm._id}
            to={curElm.link}>
            <p
              style={{
                color: theme ? 'var(--pblue)' : 'var(--pmain)',
                fontWeight: '600',
                fontSize: '13px',
                cursor: 'pointer',
              }}>
              {curElm.label}
            </p>
          </Link>
        ))}
        <p
          key="11"
          style={{
            color: theme ? 'var(--grey400)' : 'var(--grey200)',
            fontSize: '13px',
            fontWeight: '600',
          }}>
          {page}
        </p>
      </Breadcrumbs>
    </div>
  );
};
