import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TaskBadgeTimeline from "./TaskBadgeTimeline/TaskBadgeTimeline";
import PointBadgeTimeline from "./PointBadgeTimeline/PointBadgeTimeline";
import CourseCompletions from "./CourseCompletions/CourseCompletions";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    className: "mact__tab",
  };
}

export const MyActivityTabs = () => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label="Badges"
              sx={{
                textTransform: "capitalize",
                marginRight: "20px",
                fontSize: "18px",

                fontWeight: value === 0 ? "900" : "600",
                opacity: value === 0 ? "1" : "0.7",
              }}
              {...a11yProps(0)}
            />

            <Tab
              sx={{
                textTransform: "capitalize",
                fontSize: "18px",
                fontWeight: value === 1 ? "900" : "600",
                opacity: value === 1 ? "1" : "0.7",
              }}
              label="Points"
              {...a11yProps(1)}
            />
            <Tab
              sx={{
                textTransform: "capitalize",
                fontSize: "18px",
                fontWeight: value === 2 ? "900" : "600",
                opacity: value === 2 ? "1" : "0.7",
              }}
              label="Courses"
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <TaskBadgeTimeline />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <PointBadgeTimeline />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <CourseCompletions />
        </TabPanel>
      </Box>
    </div>
  );
};
