import { useGetCoursesByDeptQuery } from "../../../redux-toolkit/features/courses/coursesApiSlice";
import "./Workspace.css";
// import { selectCurrentUser } from "../../redux-toolkit/features/auth/authSlice";
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";

import ProgressBar from "../../UI/ProgressBar";
import { CreateCourse } from "./CreateCourse";
import { Button } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { CreateEvent } from "./CreateEvent";
import { AdminCard } from "./AdminCard";
import { CreateCard } from "./Create Card/CreateCard";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { CreateBooks } from "./CreateBooks";
import { useGetBooksQuery } from "../../../redux-toolkit/features/books/bookApiSlice";
import { workspaceToggler } from "../../../redux-toolkit/features/theme/themeSlice";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const Workspace = () => {
  const departmentId = useSelector((state) => state.auth.user.departmentId);

  const { data, isLoading, refetch, isFetching, isError, isSuccess } =
    useGetCoursesByDeptQuery(departmentId);
  const {
    data: allBooksData,
    isLoading: isAllBooksLoading,
    isSuccess: isAllBooksSuccess,
  } = useGetBooksQuery('dept');
  const { workspace } = useSelector((state) => state.theme);
  const [value, setValue] = React.useState(0);
  const [courseOpen, setCourseOpen] = React.useState(false);
  const [eventOpen, setEventOpen] = React.useState(false);
  const [bookOpen, setBookOpen] = React.useState(false);
  const dispatch = useDispatch();
  const handleOpen = () => setCourseOpen(true);
  const handleEventOpen = () => setEventOpen(true);
  const handleBookOpen = () => setBookOpen(true);
  const allowedAccess = useSelector((state) => state.auth.user.allowedAccess);
  const [drawer, setDrawer] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(workspaceToggler(newValue));
  };

  React.useEffect(() => {
    refetch();
    // dispatch(workspaceToggler(0));
  }, []);
  const courseRefetchHandler = () => {
    refetch();
  };

  let hasCourseAccess = true;
  hasCourseAccess =
    allowedAccess?.filter((value) => ['ADMIN_COURSES'].includes(value)).length >
    0;
  let hasBooksAccess = true;
  hasBooksAccess =
    allowedAccess?.filter((value) => ['ADMIN_BOOKS'].includes(value)).length >
    0;

  React.useEffect(() => {
    if (!hasBooksAccess && hasCourseAccess) {
      dispatch(workspaceToggler(1));
    }
  }, [hasBooksAccess, hasCourseAccess]);

  return (
    <div
      // onClick={() => setDrawer(0)}
      className="container">
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            borderBottom: 1,

            borderColor: 'divider',
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <Tabs
            value={workspace}
            onChange={handleChange}
            aria-label="basic tabs example">
            {hasBooksAccess && (
              <Tab
                label="Departmental Quest"
                sx={{
                  textTransform: 'capitalize',
                  marginRight: '20px',
                  fontWeight: '500',
                }}
                {...a11yProps(0)}
              />
            )}
            {hasCourseAccess && (
              <Tab
                sx={{ textTransform: 'capitalize', fontWeight: '500' }}
                label="Courses"
                {...a11yProps(1)}
              />
            )}
          </Tabs>
          <div>
            {workspace === 1 && hasCourseAccess && (
              <Button
                variant="contained"
                sx={{ fontSize: '10px' }}
                onClick={handleOpen}>
                Add Course
              </Button>
            )}
            {workspace === 0 && hasBooksAccess && (
              <Button
                variant="contained"
                sx={{ fontSize: '10px' }}
                onClick={handleBookOpen}>
                Add Book
              </Button>
            )}
            <CreateCourse
              courseRefetch={courseRefetchHandler}
              courseOpen={courseOpen}
              setCourseOpen={setCourseOpen}
            />
            <CreateBooks
              type={'DEPT'}
              bookOpen={bookOpen}
              setBookOpen={setBookOpen}
            />
            <CreateEvent
              eventOpen={eventOpen}
              setEventOpen={setEventOpen}
            />
          </div>
        </Box>
        {hasBooksAccess && (
          <TabPanel
            value={workspace}
            index={0}>
            {isLoading && <ProgressBar />}
            <div className="workspace__cards">
              {isAllBooksSuccess &&
                allBooksData.data.books &&
                allBooksData.data.books.map((elm, index) => (
                  <div key={index}>
                    <AdminCard
                      setDrawer={setDrawer}
                      drawer={drawer}
                      isAdmin
                      data={elm}
                      handleBookOpen={handleBookOpen}
                      isBook
                    />
                  </div>
                ))}

              <div onClick={handleBookOpen}>
                <CreateCard label={'Add Book'} />
              </div>
            </div>
          </TabPanel>
        )}
        {hasCourseAccess && (
          <TabPanel
            value={workspace}
            index={1}>
            {isLoading && <ProgressBar />}
            <div className="workspace__cards">
              {isSuccess &&
                data.data.courses &&
                data?.data.courses?.map((elm, index) => (
                  <div key={index}>
                    <AdminCard
                      setDrawer={setDrawer}
                      drawer={drawer}
                      isAdmin
                      data={elm}
                      refetch={courseRefetchHandler}
                      handleOpen={handleOpen}
                      isCourse
                    />
                  </div>
                ))}
              <div onClick={handleOpen}>
                <CreateCard label={'Add Course'} />
              </div>
            </div>
          </TabPanel>
        )}
      </Box>
    </div>
  );
};

// btn
