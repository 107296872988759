import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import rightArrow from "../../../assets/Icons/arrow.svg";
import { roleToggler } from "../../../redux-toolkit/features/access/accessSlice";
import DateRangePickerComp from "../../UI/DateRangePicker";
import "./AdminDashboard.css";
import { BadgesSection } from "./BadgesSection/BadgesSection";
import { Graph } from "./Charts/Graph";
import { QuestActivity } from "./Quest Activity/QuestActivity";
import {
  useBadgeStatsQuery,
  useBookDataQuery,
  useUserStatsQuery,
  useBookStatsQuery,
} from "../../../redux-toolkit/features/dashboard/dashboardApliSlice";
import ProgressBar from "../../UI/ProgressBar";
import { setActiveItem } from '../../../redux-toolkit/features/theme/themeSlice';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const AdminDashboard = () => {
  const dispatch = useDispatch();
  const selectBooks = useSelector((state) => state.theme.selectBooks);
  const [value, setValue] = React.useState(0);

  const [openDepartmentSelect, setOpenDepartmentSelect] = React.useState();
  React.useEffect(() => {
    dispatch(roleToggler('Admin'));
    dispatch(setActiveItem(1));
  }, []);

  const { departmentId } = useSelector((state) => state.auth.user);
  const [dateRange, setDateRange] = React.useState(null);

  // Organizationals
  const { data: bookOrg, isLoading } = useBookDataQuery(
    { type: 'org' },
    { refetchOnMountOrArgChange: true }
  );
  const { data: badgeStatsOrg, isLoading: badgeStatsOrgLoading } =
    useBadgeStatsQuery(
      {
        type: 'org',
        startDate: dateRange ? dateRange[0] : undefined,
        endDate: dateRange ? dateRange[1] : undefined,
      },
      { refetchOnMountOrArgChange: true }
    );
  const { data: userStatsOrg, isLoading: userStatsOrgLoading } =
    useUserStatsQuery(
      {
        type: 'org',
        startDate: dateRange ? dateRange[0] : undefined,
        endDate: dateRange ? dateRange[1] : undefined,
      },
      { refetchOnMountOrArgChange: true }
    );
  const { data: bookStatsOrg, isLoading: bookStatsOrgLoading } =
    useBookStatsQuery(
      {
        bookIds: selectBooks,
        type: 'org',
        startDate: dateRange ? dateRange[0] : undefined,
        endDate: dateRange ? dateRange[1] : undefined,
      },
      {
        refetchOnMountOrArgChange: true,
        skip: value != 0 || selectBooks.length === 0,
      }
    );
  //  Departmental
  const { data: bookDept, isLoading: deptLoading } = useBookDataQuery(
    {
      type: 'dept',
    },
    { refetchOnMountOrArgChange: true }
  );

  const { data: badgeStatsDept, isLoading: badgeStatsDeptLoading } =
    useBadgeStatsQuery(
      {
        type: 'dept',
        deptId: departmentId,
        startDate: dateRange ? dateRange[0] : undefined,
        endDate: dateRange ? dateRange[1] : undefined,
      },
      { refetchOnMountOrArgChange: true }
    );
  const { data: userStatsDept } = useUserStatsQuery(
    {
      type: 'dept',
      deptId: departmentId,
      startDate: dateRange ? dateRange[0] : undefined,
      endDate: dateRange ? dateRange[1] : undefined,
    },
    { refetchOnMountOrArgChange: true }
  );
  const { data: bookStatsDept } = useBookStatsQuery(
    {
      bookIds: selectBooks,
      type: 'dept',
      deptId: departmentId,
      startDate: dateRange ? dateRange[0] : undefined,
      endDate: dateRange ? dateRange[1] : undefined,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: value != 1 || selectBooks.length === 0,
    }
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (
    badgeStatsDeptLoading ||
    bookStatsOrgLoading ||
    userStatsOrgLoading ||
    badgeStatsOrgLoading ||
    deptLoading ||
    isLoading
  ) {
    return <ProgressBar />;
  }

  return (
    <div className="container ">
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example">
            <Tab
              label="Organization"
              sx={{
                textTransform: 'capitalize',
                marginRight: '20px',
                fontWeight: '500',
              }}
              {...a11yProps(0)}
            />

            <Tab
              sx={{ textTransform: 'capitalize', fontWeight: '500' }}
              label="Department"
              {...a11yProps(1)}
            />
          </Tabs>
          <div className="dashboard__actions">
            <div className="dashboard__actionsdate">
              <DateRangePickerComp
                onChange={(value) => {
                  if (!value) setDateRange(null);
                  else
                    setDateRange(
                      value.map((val) => new Date(val).toISOString())
                    );
                }}
              />
            </div>
            {/* ?only for superadmin */}
            {/* <FilterListOutlinedIcon
              sx={{ color: "var(--pblue)", fontSize: "30px" }}
            />

            <div className="employee__manage_employee_dd">
              <div
                className={
                  (openDepartmentSelect ? "open" : "") +
                  " employee__manage_employee_dd_item"
                }
              >
                <div
                  onClick={() => {
                    setOpenDepartmentSelect(!openDepartmentSelect);
                  }}
                  className="employee__manage_employee_dd_label"
                >
                  <p value="">Department</p>
                  <div>
                    <img
                      src={rightArrow}
                      style={{ marginBottom: "2px" }}
                      alt=""
                    />
                  </div>
                </div>
                {openDepartmentSelect ? (
                  <div className="employee__select_menu">
                    <h1 className="select_divider" />
                    <p>UI UX</p>
                    <p>Development</p>
                    <p>Product Management</p>
                  </div>
                ) : null}
              </div>
            </div> */}
          </div>
        </Box>
        <TabPanel
          value={value}
          index={0}>
          <div className="adminLayout">
            <div className="dashboard__content ">
              <Graph
                badgeStats={badgeStatsOrg}
                userStats={userStatsOrg}
                bookStats={bookStatsOrg}
                bookData={bookOrg}
              />
              <QuestActivity bookData={bookOrg} />
              <BadgesSection />
            </div>
          </div>
        </TabPanel>
        <TabPanel
          value={value}
          index={1}>
          <div className="adminLayout">
            <div className="dashboard__content ">
              <Graph
                badgeStats={badgeStatsDept}
                userStats={userStatsDept}
                bookStats={bookStatsDept}
                bookData={bookDept}
              />
              <QuestActivity bookData={bookDept} />
              <BadgesSection />
            </div>
          </div>
        </TabPanel>
      </Box>
    </div>
  );
};

// btn
