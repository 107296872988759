import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./HodTaskVerification.css";
import { useGetPendingHodTaskQuery } from "../../../../redux-toolkit/features/books/bookApiSlice";
import ProgressBar from "../../../UI/ProgressBar";
import { Error404 } from "../../../UI/404/Error404";
import { NoRecord } from "../../../UI/No record/NoRecord";
import { TasksHod } from "./TasksHod";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: "20px 0px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const HodTaskVerification = () => {
  const [value, setValue] = React.useState(0);
  const { data, isLoading, isFetching, isError } = useGetPendingHodTaskQuery(
    "ORG",
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (isLoading || isFetching) {
    return <ProgressBar />;
  }

  if (isError) {
    return <Error404 />;
  }

  return (
    <div className="container">
      <div className="taskHod__layout">
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,

              borderColor: "divider",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="Organizational"
                sx={{
                  textTransform: "capitalize",
                  marginRight: "20px",
                  fontWeight: "500",
                }}
                {...a11yProps(0)}
              />
              {/* Departmental */}

              {/* <Tab
                sx={{ textTransform: "capitalize", fontWeight: "500" }}
                label="Departmental"
                {...a11yProps(1)}
              /> */}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            {data.data.taskCompletionRequests.length != 0 ? (
              <div className="taskHod">
                {data &&
                  data?.data?.taskCompletionRequests?.map((curElm, index) => (
                    <TasksHod allTask={curElm} />
                  ))}
              </div>
            ) : (
              <NoRecord />
            )}
          </TabPanel>
          {/* Departmental */}
          {/* <TabPanel value={value} index={1}>
            {data.data.taskCompletionRequests.length != 0 ? (
              <div className="taskHod">
                {data &&
                  data?.data?.taskCompletionRequests?.map((curElm, index) => (
                    <TasksHod allTask={curElm} />
                  ))}
              </div>
            ) : (
              <NoRecord />
            )}
          </TabPanel> */}
        </Box>
      </div>
    </div>
  );
};
