import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  courses: [],
  courseBydept: [],
  sortedCourses: [],
  error: "",
};

const library = createSlice({
  name: "library",
  initialState,
  reducers: {
    allCourses: (state, action) => {
      state.courses = action.payload;
    },
    sortCourses: (state, action) => {
      state.sortedCourses = action.payload;
    },
    coursesByDept: (state, action) => {
      state.courseBydept = action.payload;
    },
    bookmarkById: (state, action) => {
      state.courses = action.payload;
    },
    clearCoursesByDept: (state, action) => {
      state.courseBydept = [];
    },
  },
});

export const {
  allCourses,
  bookmarkById,
  sortCourses,
  coursesByDept,
  clearCoursesByDept,
} = library.actions;

export default library.reducer;

export const selectedCourses = (state) => state.library.courses;
export const selectedCoursesByDept = (state) => state.library.courseBydept;
export const selectedSortedCourses = (state) => state.library.sortedCourses;
