import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Tab,
  Tabs,
} from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import readSvg from "../../assets/Icons/read.svg";
import settingsSvg from "../../assets/Icons/settings.svg";
import { useMarkAllNotificationReadMutation } from "../../redux-toolkit/features/notifications/notificationsApiSlice";
import NotificationMessageBlock from "./NotificationMessageBlock";
import "./Notifications.css";

const NotificationBody = ({
  notifications,
  handleClose,
  refetchData,
  setNotificationType,
}) => {
  const [value, setValue] = React.useState("0");

  const [markAllDone] = useMarkAllNotificationReadMutation();

  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setNotificationType &&
      setNotificationType(
        newValue === "0" ? "all" : newValue === "1" ? "rewards" : "tasks"
      );
    setValue(newValue);
  };
  return (
    <div>
      <Grid container direction="column" spacing={1}>
        <Grid item className="notify__header">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <h5>Notifications</h5>
            <IconButton onClick={handleClose} className="notify__closeIcon">
              <Close sx={{ fontSize: "15px" }} />
            </IconButton>
          </Stack>
        </Grid>
        <Grid item>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Tabs
              sx={{
                width: "100%",
                textTransform: "none",
              }}
              className="notify__tabSwitcher"
              value={value}
              onChange={handleChange}
            >
              <Tab
                sx={{ textTransform: "none", fontSize: "14px" }}
                label="All"
                value={"0"}
              />
              <Tab
                sx={{ textTransform: "none", fontSize: "14px" }}
                label="Rewards"
                value={"1"}
              />
              <Tab
                sx={{ textTransform: "none", fontSize: "14px" }}
                label="Requests"
                value={"2"}
              />
            </Tabs>
          </Box>
        </Grid>
        <Grid sx={{ overflow: "auto", height: "50vh" }}>
          {notifications?.length > 0 ? (
            notifications?.map((item, index) => {
              return (
                <>
                  <NotificationMessageBlock
                    refetchData={() => {
                      refetchData();
                      handleClose();
                    }}
                    key={index}
                    notification={item}
                  />
                  <Divider
                    style={{
                      margin: 0,
                    }}
                  ></Divider>
                </>
              );
            })
          ) : (
            <p
              style={{
                padding: "40px",
                margin: "auto",
                textAlign: "center",
              }}
            >
              No notifications yet
            </p>
          )}
        </Grid>
        <Grid item className="notify__header">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "0px",
              }}
            >
              <IconButton
                onClick={() => {
                  navigate("/quest/notifications/settings");
                  handleClose();
                }}
              >
                {" "}
                {/* <SettingsOutlined fontSize="small" color="action" /> */}
                <img src={settingsSvg} alt="settings" />
              </IconButton>
              <Button
                size="medium"
                onClick={() => {
                  markAllDone();
                  refetchData();
                }}
                sx={{ fontSize: "14px" }}
                startIcon={<img src={readSvg} alt="settings" />}
              >
                Mark all as read
              </Button>
            </div>
            <Button
              onClick={() => {
                navigate("/quest/notifications");
                handleClose();
              }}
              size="medium"
              variant="contained"
            >
              View all Notifications
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};

export default NotificationBody;
