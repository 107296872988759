import React from 'react';
import './CoursePdf.css';
import { ReactComponent as LinkSvg } from '../../../assets/Icons/open_tab.svg';
import PdfSvg from '../../../assets/Icons/PDF_icon.png';

const CoursePdf = ({ item }) => {
  return (
    <a
      style={{ textDecoration: 'none' }}
      href={item.url}
      target="_blank"
      // rel="noreferrer"
    >
      <div
        key={item._id}
        href={item.url}
        className="coursePdf">
        <div className="coursePdfImg">
          <img
            src={PdfSvg}
            alt=""
          />
        </div>

        <div className="coursePdf__card">
          <h5 className="ftwt__b">{item.filename}</h5>
          <LinkSvg />
        </div>
      </div>
    </a>
  );
};

export default CoursePdf;
