import {
  Avatar,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import { useGetLeaderboardQuery } from "../../../../redux-toolkit/features/dashboard/dashboardApliSlice";
import "./LeaderboardTable.css";
import { UserModal } from '../../../UI/Profile Modal/UserModal';

const LeaderboardTable = ({ startDate, endDate, deptId }) => {
  const [page, setPage] = useState(1);
  const [profileOpen, setProfileOpen] = useState(false);
  const [rank, setRank] = useState();

  const { data } = useGetLeaderboardQuery(
    { page, limit: 10, startDate, endDate, deptId },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const getCellStyle = (rank) => {
    return {
      backgroundColor:
        rank === 1
          ? '#99C55155'
          : rank === 2
          ? '#8A73DB55'
          : rank === 3
          ? '#EE723355'
          : '#ffffff',
    };
  };
  const handleOpen = (id, userRank) => {
    setProfileOpen(id);
    const rankBol = userRank <= 3;
    setRank(rankBol);
  };

  return (
    // <TableContainer sx={{ height: "800px", overflowX: "none !important" }}>
    <>
      <UserModal
        profileOpen={profileOpen}
        setProfileOpen={setProfileOpen}
        rank={rank}
      />
      <Table
        stickyHeader
        aria-label="sticky table">
        <TableHead>
          <TableRow className="leaderboard__table_header">
            <TableCell
              width={80}
              sx={{ borderRadius: '10px 0 0 0' }}
              align="center">
              Rank
            </TableCell>
            <TableCell
              align="left"
              width={350}>
              Name
            </TableCell>
            <TableCell align="center">Points</TableCell>
            <TableCell
              align="center"
              width={200}>
              Achievement
            </TableCell>

            <TableCell
              width={300}
              align="center"
              sx={{ borderRadius: '0 10px 0 0' }}>
              Department
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="leaderboard__table_body">
          {data?.data?.list?.map((user, i) => {
            const rank = (page - 1) * 10 + (i + 1);
            return (
              <>
                <div className="row__spacing"></div>
                <TableRow
                  key={i}
                  className={
                    'leaderboard__table_row ' + (rank <= 3 ? '' : 'unranked')
                  }>
                  <TableCell
                    align="center"
                    style={{
                      borderTopLeftRadius: '5px',
                      borderBottomLeftRadius: '5px',
                      borderLeft: rank > 3 ? '0.1px solid #c3c3c3' : '',
                      ...getCellStyle(rank),
                    }}>
                    {rank}
                  </TableCell>
                  <TableCell
                    onClick={() => handleOpen(user?._id, rank)}
                    style={getCellStyle(rank)}
                    align="left">
                    <div
                      style={{ cursor: 'pointer' }}
                      className="leaderboard__name_avatar">
                      <Avatar style={{ width: '22px', height: '22px' }} />
                      <span className="truncate-1">
                        {`${
                          user?.fname
                            ? `${user?.fname} ${user?.lname}`
                            : user.email
                        }`}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell
                    style={getCellStyle(rank)}
                    align="center">
                    <div className="leaderboard__points_pill">
                      {user?.gainedPoints}
                    </div>
                  </TableCell>
                  <TableCell
                    style={getCellStyle(rank)}
                    align="center">
                    {user?.badge?.badgeName || 'None'}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      borderTopRightRadius: '5px',
                      borderBottomRightRadius: '5px',
                      borderRight: rank > 3 ? '0.1px solid #c3c3c3' : '',
                      ...getCellStyle(rank),
                    }}>
                    <span className="truncate-1">
                      {user?.department || 'N/A'}
                    </span>
                  </TableCell>
                </TableRow>
              </>
            );
          })}
        </TableBody>
      </Table>
      <div className="leaderboard__pagination_cont">
        <Pagination
          count={data?.data?.totalPages}
          shape="rounded"
          page={page}
          onChange={(e, newPage) => {
            setPage(newPage);
          }}
        />
      </div>
    </>
    // </TableContainer>
  );
};

export default LeaderboardTable;
