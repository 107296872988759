import { Star } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import { Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useParams } from "react-router-dom";
import {
  useDeleteCourseMutation,
  useGetCourseByIdQuery,
  useUpdateCourseMutation,
} from "../../../../redux-toolkit/features/courses/coursesApiSlice";
import { BreadcrumbAdmin } from "../../../UI/BreadcrumbAdmin";
import { DeleteModal } from "../../../UI/Delete Modal/DeleteModal";
import ProgressBar from "../../../UI/ProgressBar";
import { AddModule } from "./AddModule";
import { CertificateModal } from "./Certificate Modal/CertificateModal";
import "./EditCourses.css";
import { useAlert } from '../../../../hooks/useAlert';

const config = {
  modules: {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
    ],
  },
  formats: [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'indent',
  ],
};

const base = [
  {
    _id: 1,
    link: '/admin/my-workspace',
    label: 'My Workspace',
  },
];
export const EditCourses = () => {
  const { _id } = useParams();
  const { displayAlert } = useAlert();

  const { data, isLoading, refetch, isError } = useGetCourseByIdQuery(_id, {
    refetchOnMountOrArgChange: true,
  });
  const [course, setCourse] = useState({
    name: '',
    // points: "",
    description: '',
  });
  const [keepDefaultOpen, setKeepDefaultOpen] = useState(false);

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteCourse] = useDeleteCourseMutation();
  const [updateCourse] = useUpdateCourseMutation();
  const [showModule, setShowModule] = useState(false);
  const [certificateOpen, setCertificateOpen] = useState(false);
  const [thumbnail, setThumbnail] = useState(data?.data?.coverImage);
  const [dataChanged, setDataChanged] = useState(false);
  const [isLarge, setIsLarge] = React.useState(false);
  const thumbnailHandler = (event) => {
    const file = event.target.files[0];

    if (file.size >= 1099276) {
      setIsLarge(true);

      displayAlert({
        open: true,
        message: `Image Size cannot be Larger than 1 MB`,
        type: 'error',
        timeout: '2000',
      });
    } else {
      setIsLarge(false);
      setThumbnail(file);
      setDataChanged(true);
    }
  };

  React.useEffect(() => {
    setThumbnail(data?.data?.coverImage);
  }, [data]);

  const navigate = useNavigate();

  useEffect(() => {
    setCourse({
      name: data?.data.name,
      // points: data?.data.points,
      description: data?.data.description,
    });
  }, [data]);

  if (isLoading) {
    return <ProgressBar />;
  }
  const handleChange = (event) => {
    setDataChanged(true);
    setCourse({ ...course, [event.target.name]: event.target.value });
  };

  const refetchHander = () => {
    refetch();
  };

  const handleOpen = () => setDeleteOpen(true);
  const deleteCourseHandler = async () => {
    await deleteCourse(_id);
    navigate('/admin/my-workspace');
  };
  const updateCourseHandler = () => {
    //  createCourse(data);
    const formData = new FormData();
    formData.append('name', course.name);
    formData.append('description', course.description);
    formData.append('coverImage', thumbnail);

    updateCourse({ formData, _id }).then(() =>
      displayAlert({
        open: true,
        message: `Successfully updated the course details`,
        type: 'success',
        timeout: '2000',
      })
    );
    setDataChanged(false);
  };

  return (
    <>
      {data && !isError ? (
        <div className="container">
          <BreadcrumbAdmin
            base={base}
            page={data?.data?.name}
          />
          <CertificateModal
            setCertificateOpen={setCertificateOpen}
            certificateOpen={certificateOpen}
            courseId={_id}
            hasCertificate={data?.data.hasCertificate}
          />

          <DeleteModal
            label={data?.data.name}
            deleteHandler={deleteCourseHandler}
            setDeleteOpen={setDeleteOpen}
            deleteOpen={deleteOpen}
          />

          <div className="editcourses__container">
            <div className="editcourses">
              <div className="editcourses__left">
                <div>
                  <h4 className="ftwt__b"> Course Name</h4>
                  <TextField
                    sx={{
                      '& legend': { display: 'none' },
                      '& fieldset': { top: 0 },
                      marginTop: '5px',
                    }}
                    id="outlined-basic"
                    onChange={handleChange}
                    value={course.name}
                    name="name"
                    fullWidth
                    variant="outlined"
                  />
                </div>

                <div>
                  <h4 className="ftwt__b">Description</h4>
                  <TextField
                    sx={{
                      '& legend': { display: 'none' },
                      '& fieldset': { top: 0 },
                      marginTop: '5px',
                    }}
                    required
                    id="outlined-basic"
                    variant="outlined"
                    name="description"
                    fullWidth
                    rows={2}
                    multiline
                    value={course.description}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div
                style={{ borderColor: isLarge ? 'var(--error)' : '' }}
                className="editcourses__right">
                {!thumbnail && (
                  <input
                    name="thumbnail"
                    onChange={thumbnailHandler}
                    type="file"
                    accept="image/*"
                    className="dropzone"
                  />
                )}
                <h6>
                  Display Image <span>*</span>
                </h6>
                {thumbnail && (
                  <img
                    src={
                      !thumbnail.type
                        ? data?.data?.coverImage
                        : URL.createObjectURL(thumbnail)
                    }
                    alt=""
                  />
                )}

                {thumbnail ? (
                  <div
                    onClick={() => setThumbnail()}
                    className="editbooks__cross">
                    <CloseIcon
                      sx={{ fontSize: '14px', color: 'var(--grey400)' }}
                    />
                  </div>
                ) : (
                  <>
                    <div className="dropzone-desc-edit">
                      <UploadFileOutlinedIcon
                        sx={{ fontSize: '50px', color: 'var(--grey400) ' }}
                      />
                      <p
                        style={{
                          fontSize: '14px',
                          // color: isLarge ? 'var(--error)' : '',
                        }}>
                        Choose an image file{' '}
                        <span style={{ fontSize: '11px' }}>
                          or drag it here.
                        </span>
                        <br />
                        <span style={{ fontSize: '11px' }}>
                          Maximum file size 1MB
                        </span>
                      </p>
                    </div>
                    {isLarge && (
                      <p
                        style={{
                          color: 'var(--error)',
                          fontSize: '11px',
                        }}>
                        Choose Image smaller than 1MB
                      </p>
                    )}
                  </>
                )}
              </div>

              {/* </div> */}
              {/* </div> */}
            </div>
            {/* <div className="editCourses__point_badge">
              <Star
                style={{
                  color: "#ee7233",
                  fontSize: "18px",
                }}
              />
              <h5> {data?.data.points} Points </h5>
            </div> */}
            <br />

            <div className="seprator"></div>
            <br />
            <div className="editcourses__save">
              <Button
                onClick={handleOpen}
                // sx={{ width: "80px" }}
                color="error"
                variant="outlined">
                Delete Course
              </Button>
              <Button
                onClick={updateCourseHandler}
                disabled={!dataChanged}
                // sx={{ width: "80px" }}
                variant="contained">
                Save Changes
              </Button>
            </div>
            <div className="editcourses__addLevels">
              {data?.data.modules.length > 0 &&
                data?.data.modules.map((elm, index) => (
                  <AddModule
                    refetch={refetchHander}
                    moduleNo={index}
                    course={data?.data}
                    modules={elm}
                    config={config}
                    defaultOpen={
                      keepDefaultOpen &&
                      index === data?.data?.modules?.length - 1
                    }
                  />
                ))}
              {showModule && (
                <AddModule
                  isAdd
                  setShowModule={setShowModule}
                  refetch={refetchHander}
                  moduleNo={data?.data.modules.length + 1}
                  course={data?.data}
                  config={config}
                  defaultOpen
                  onAddSuccess={() => {
                    setKeepDefaultOpen(true);
                  }}
                />
              )}
              {!showModule && (
                <div className="editcourses__showAddModule">
                  <Button
                    onClick={() => {
                      setKeepDefaultOpen(false);

                      setShowModule(true);
                    }}
                    variant="outlined">
                    Add Module
                  </Button>
                </div>
              )}
              <div className="editcourses__next">
                <Button
                  onClick={() => setCertificateOpen(true)}
                  sx={{ width: '80px' }}
                  variant="contained"
                  disabled={data?.data.modules.length === 0}>
                  Next
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <h3>Something went wrong...</h3>
        </div>
      )}
    </>
  );
};
