import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import AlexBrush from "../../assets/fonts/AlexBrush-Regular.ttf";
import MontserratMedium from "../../assets/fonts/Montserrat-Medium.ttf";
import MontserratReg from "../../assets/fonts/Montserrat-Regular.ttf";
import MontserratSemiBold from "../../assets/fonts/Montserrat-SemiBold.ttf";

Font.register({
  family: "Alex Brush",
  src: AlexBrush,
});
Font.register({
  family: "MontserratReg",
  src: MontserratReg,
});
Font.register({
  family: "MontserratMed",
  src: MontserratMedium,
});
Font.register({
  family: "MontserratSemiBold",
  src: MontserratSemiBold,
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#E4E4E4",
    padding: "0px 0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "100%",
    position: "relative",
    fontFamily: "MontserratReg",
  },

  header: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  cubeContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    gap: "7px",
    margin: "24px 0",
  },
  cube: {
    height: "6px",
    width: "6px",
    backgroundColor: "#EE7233",
    transform: "rotate(45deg)",
  },
  divider: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "0 20px",
  },
  line: {
    width: "70px",
    height: "1px",
    backgroundColor: "black",
  },
  holderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    gap: "20px",
  },
  holderDot: {
    height: "12px",
    width: "12px",
    borderRadius: "100%",
    backgroundColor: "#3361cf",
  },
  holderName: {
    fontSize: "45px",
    fontFamily: "Alex Brush",
  },
  descriptionContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    textAlign: "center",
    padding: "0 160px",
  },
  description: {
    fontSize: "12px",
    // fontWeight: "100",
  },
  authorsContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    flexDirection: "row",
    fontSize: "14px",
    width: "100%",
  },
  authorName: {
    fontFamily: "Alex Brush",
    fontSize: "20px",
  },
  authorLine: {
    width: "100%",
    height: "1px",
    backgroundColor: "black",
    margin: "5px 0",
  },
  abstractContainer: {
    position: "absolute",
    left: "10px",
    top: 0,
    transform: "translateY(-25px)",
    gap: "10px",
  },

  abstractContainer2: {
    position: "absolute",
    left: "70px",
    top: "50px",
    gap: "10px",
  },
  abstractContainer3: {
    position: "absolute",
    right: "10px",
    bottom: 0,
    gap: "10px",
    transform: "translateY(50px)",
  },
  abstract1: {
    width: "50px",
    height: "50px",
    backgroundColor: "#99C551",
    borderRadius: "100%",
  },
  abstract2: {
    width: "50px",
    height: "120px",
    backgroundColor: "#EE7233",
    borderRadius: "25px",
  },
  abstract3: {
    width: "50px",
    height: "120px",
    backgroundColor: "#8A73DB",
    borderRadius: "25px",
  },
  abstract11: {
    width: "50px",
    height: "50px",
    backgroundColor: "#FFCE35",
    borderRadius: "100%",
  },
  abstract21: {
    width: "50px",
    height: "120px",
    backgroundColor: "#99C551",
    borderRadius: "25px",
  },
  abstract13: {
    width: "50px",
    height: "50px",
    backgroundColor: "#FFCE35",
    borderRadius: "100%",
  },
  abstract23: {
    width: "50px",
    height: "120px",
    backgroundColor: "#3361CF",
    borderRadius: "25px",
  },
  abstract33: {
    width: "50px",
    height: "120px",
    backgroundColor: "#EE7233",
    borderRadius: "25px",
  },
  strong: {
    fontFamily: "MontserratSemiBold",
    fontWeight: "semibold",
  },
});

export const CertificatePDF = ({ name, courseName }) => (
  <Document>
    <Page orientation="landscape" size={"A4"} style={styles.page}>
      <View style={styles.abstractContainer}>
        <View style={styles.abstract1}></View>
        <View style={styles.abstract2}></View>
        <View style={styles.abstract3}></View>
      </View>
      <View style={styles.abstractContainer2}>
        <View style={styles.abstract11}></View>
        <View style={styles.abstract21}></View>
      </View>
      <View style={styles.abstractContainer3}>
        <View style={styles.abstract13}></View>
        <View style={styles.abstract23}></View>
        <View style={styles.abstract33}></View>
      </View>
      <View style={styles.header}>
        <Text
          style={{
            fontSize: "30px",
            // fontWeight: "light",
          }}
        >
          CERTIFICATE
        </Text>
        <Text
          style={{
            fontSize: "18px",
            // fontWeight: "thin",
            marginTop: "10px",
          }}
        >
          OF ACHIEVEMENT
        </Text>
        <View style={styles.cubeContainer}>
          <View style={styles.cube}></View>
          <View style={styles.cube}></View>
          <View style={styles.cube}></View>
          <View style={styles.cube}></View>
          <View style={styles.cube}></View>
        </View>
        <View style={styles.divider}>
          <View style={styles.line}></View>
          <View style={styles.text}>
            <Text
              style={{
                fontSize: "12px",
                // fontWeight: "thin",
              }}
            >
              This certificate is proudly presented to
            </Text>
          </View>
          <View style={styles.line}></View>
        </View>
      </View>

      <View style={styles.holderContainer}>
        <View style={styles.holderDot}></View>

        <Text style={styles.holderName}>{name}</Text>
        <View style={styles.holderDot}></View>
      </View>
      <View style={styles.descriptionContainer}>
        <Text style={styles.description}>
          <Text style={styles.strong}>{name}</Text> has completed the course{" "}
          <Text style={styles.strong}>{courseName}</Text> and got this
          certificate on completion. Lorem ipsum dolor sit amet consectetur
          adipisicing elit. Nemo ipsam, officiis sint quisquam voluptate ut
          repellat quas nesciunt, magni ea aut nostrum repellendus est expedita
          quo esse quos earum voluptas!
        </Text>
      </View>
      <View style={styles.authorsContainer}>
        <View style={styles.author}>
          <Text style={styles.authorName}>Mr. Martin Doe</Text>
          <View style={styles.authorLine}></View>
          <Text>President Director</Text>
        </View>
        <View style={styles.author}>
          <Text style={styles.authorName}>Mr. Alex Doe</Text>
          <View style={styles.authorLine}></View>
          <Text>General Manager</Text>
        </View>
      </View>
    </Page>
  </Document>
);
