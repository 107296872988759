import { Divider, Grid, IconButton, Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useGetUserNotificationsQuery } from "../../redux-toolkit/features/notifications/notificationsApiSlice";
import NotificationMessageBlock from "./NotificationMessageBlock";
import settingsSvg from "../../assets/Icons/settings.svg";
import { useNavigate } from "react-router-dom";

const AllNotifications = () => {
  const [notificationType, setNotificationType] = useState("all");
  const navigate = useNavigate();
  const { data: notifications, refetch: refetchNotifications } =
    useGetUserNotificationsQuery(notificationType, {
      refetchOnMountOrArgChange: true,
    });
  return (
    <div className="container__accounts">
      <div className="notifs_container">
        <h2 style={{ width: "100%" }}>
          Notifications
          <IconButton
            onClick={() => {
              navigate("/quest/notifications/settings");
            }}
            style={{
              float: "right",
            }}
          >
            {" "}
            {/* <SettingsOutlined fontSize="small" color="action" /> */}
            <img src={settingsSvg} alt="settings" />
          </IconButton>
        </h2>
        <Grid
          item
          style={{
            marginTop: "1rem",
          }}
        >
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Tabs
              sx={{
                width: "100%",
                textTransform: "none",
              }}
              value={notificationType}
              onChange={(e, value) => setNotificationType(value)}
            >
              <Tab
                sx={{ textTransform: "none", fontSize: "14px" }}
                label="All"
                value={"all"}
              />
              <Tab
                sx={{ textTransform: "none", fontSize: "14px" }}
                label="Rewards"
                value={"rewards"}
              />
              <Tab
                sx={{ textTransform: "none", fontSize: "14px" }}
                label="Requests"
                value={"requests"}
              />
            </Tabs>
          </Box>
        </Grid>
        <div
          style={{ width: "100%", overflow: "auto", paddingBottom: "15rem" }}
        >
          {notifications?.data?.length > 0 ? (
            notifications?.data?.map((item, index) => {
              return (
                <>
                  <NotificationMessageBlock
                    refetchData={() => {
                      refetchNotifications();
                    }}
                    key={index}
                    notification={item}
                  />
                  <Divider
                    style={{
                      margin: 0,
                    }}
                  ></Divider>
                </>
              );
            })
          ) : (
            <p
              style={{
                padding: "40px",
                margin: "auto",
                textAlign: "center",
              }}
            >
              No notifications yet
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AllNotifications;
