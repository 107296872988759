import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Avatar } from "@mui/material";
import { Paper } from "@mui/material";
import Switch from "@mui/material/Switch";
import ProgressBar from "../../../../UI/ProgressBar";
import { useGetAdminUserActivityQuery } from "../../../../../redux-toolkit/features/reports/ReportsApiSlice";
import { Error404 } from "../../../../UI/404/Error404";
import { useSelector } from "react-redux";
import { SortIcon } from "../../../../Super Admin/Manage Organization/Employee/EmployeeTable";

export const UserActivityTable = ({
  check,
  searchQuery = "",
  getTableData,
  endDate,
  startDate,
}) => {
  const [sortBy, setSortBy] = useState("");
  const [sortType, setSortType] = useState("asc");

  const userDepartmentId = useSelector((state) => state.auth.user.departmentId);

  const { data, isError, isLoading } = useGetAdminUserActivityQuery(
    {
      userDepartmentId,
      sortBy: sortBy,
      type: sortType,
      query: searchQuery,
      endDate,
      startDate,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const handleSorting = (sortKey) => {
    setSortBy(sortKey);
    setSortType(!sortType || sortType === "desc" ? "asc" : "desc");
  };

  React.useEffect(() => {}, [check]);

  React.useEffect(() => {
    if (!data) return;
    getTableData(data);
  }, [data]);

  if (isLoading) {
    return <ProgressBar />;
  }

  if (isError) {
    return <Error404 />;
  }

  return (
    <div>
      <TableContainer
        sx={{
          height: '100%',
          maxHeight: 'calc( 100vh - 220px)',
          padding: '0 1px',
          minWidth: '2450px',
        }}>
        <Table
          stickyHeader
          aria-label="sticky table">
          <TableHead>
            <TableRow
              sx={{ borderRadius: '20px 20px 0px 0px' }}
              className="tableUi__head">
              {check.empName && (
                <TableCell
                  sx={{ borderRadius: '20px 0px 0px 0px' }}
                  align="center"
                  onClick={() => handleSorting('fname')}>
                  <div className="tableUi__head_sortable">
                    Employee Name <SortIcon sortOrder={sortType} />
                  </div>
                </TableCell>
              )}
              {check.email && (
                <TableCell align="center">Employee Email</TableCell>
              )}
              {check.empId && <TableCell align="center">Employee Id</TableCell>}
              {check.empDep && (
                <TableCell align="center">Employee Department</TableCell>
              )}
              {check.lLogin && <TableCell align="center">Last login</TableCell>}
              {check.bookStatus && (
                <TableCell align="center">Current Book Status</TableCell>
              )}
              {check.tasksCompleted && (
                <TableCell align="center">Total Tasks Completed</TableCell>
              )}
              {check.levelsCompleted && (
                <TableCell align="center">Total Levels Completed</TableCell>
              )}
              {check.badgeCount && (
                <TableCell align="center">Task Badge Count</TableCell>
              )}
              {check.pointsEarned && (
                <TableCell align="center">Total Points Earned</TableCell>
              )}

              {check.status && (
                <TableCell
                  sx={{ borderRadius: '0px 20px 0px 0px' }}
                  align="center">
                  Status
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.data.map((row) => (
                <>
                  <div className="row__spacing"></div>
                  <TableRow
                    className="tableUi___body"
                    key={row?._id}
                    // sx={{
                    //   // "&:last-child td, &:last-child th": {
                    //   // //     border: "1px solid grey",
                    //   // },
                    //   borderRadius: "20px",
                    // }}
                    sx={{ borderRadius: '15px' }}>
                    {check.empName && (
                      <TableCell
                        sx={{
                          borderRadius: '15px 0px 0px 15px',
                          //     border: "1px solid grey",
                        }}
                        align="center">
                        <div className="tableUi__rankscir">
                          <div className="tableUi__rank">
                            {row?.fname + ' ' + row.lname}
                          </div>
                        </div>
                      </TableCell>
                    )}
                    {check.email && (
                      <TableCell
                        sx={
                          {
                            // borderRadius: "15px 0px 0px 15px",
                            //     border: "1px solid grey",
                          }
                        }
                        align="center">
                        {row?.email}
                      </TableCell>
                    )}
                    {check.empId && (
                      <TableCell align="center">{row?.employeeId}</TableCell>
                    )}
                    {check.empDep && (
                      <TableCell align="center">
                        {row?.department.name}
                      </TableCell>
                    )}
                    {check.lLogin && (
                      <TableCell align="center">
                        {row?.lastLogin && row.lastLogin.slice(0, 10)}
                      </TableCell>
                    )}
                    {check.bookStatus && (
                      <TableCell align="center">
                        {row?.currentBokStatus}
                      </TableCell>
                    )}
                    {check.tasksCompleted && (
                      <TableCell align="center">
                        {row?.totalTasksCompleted}
                      </TableCell>
                    )}
                    {check.levelsCompleted && (
                      <TableCell align="center">
                        {row?.totalLevelsCompleted}
                      </TableCell>
                    )}
                    {check.badgeCount && (
                      <TableCell align="center">
                        {row?.totalBadgeCount}
                      </TableCell>
                    )}
                    {check.pointsEarned && (
                      <TableCell align="center">
                        {row?.totalPointsEarned}
                      </TableCell>
                    )}

                    {check.status && (
                      <TableCell
                        sx={{
                          borderRadius: '0px 15px 15px 0px',
                          //     border: "1px solid grey",
                        }}
                        align="center">
                        {row?.status}
                      </TableCell>
                    )}
                  </TableRow>
                  <div className="row__spacing"></div>
                </>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
