// @desc: Simple react hook to which dispatches two actions

import { useDispatch } from "react-redux";
import {
  showAlert,
  closeAlert,
} from "../redux-toolkit/features/alert/alertSlice";

export const useAlert = () => {
  const dispatch = useDispatch();

  const displayAlert = (alert) => {
    dispatch(showAlert(alert));
  };

  const clearAlert = (alert) => {
    dispatch(closeAlert(alert));
  };

  return { displayAlert, clearAlert };
};
