import React from "react";
import "./BooksContent.css";
import mainArrow from "../../assets/Icons/books/arrow with middle design.svg";
import { Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import CoinSvg from "../../assets/books/coins.svg";
import { useAlert } from "../../hooks/useAlert";

export const BooksContent = ({ bookData }) => {
  const { bookId } = useParams();
  const navigate = useNavigate();
  const { displayAlert } = useAlert();

  const openBookHandler = () => {
    if (bookData.levels.length < 1) {
      return displayAlert({
        open: true,
        message: "Can't open Empty Book",
        type: "error",
        timeout: "2000",
      });
    }
    const levelId = bookData.levels[0]?._id;
    navigate(`/quest/books/${bookId}/levels/${levelId}`);
  };

  return (
    <div className="books__right">
      <div className="books__righthead">
        <h1
          className="ftwt__r"
          style={{
            color:
              bookData?.name === "Mindful Movement" ? "var(--spurple)" : "",
          }}
        >
          {bookData?.name}
        </h1>
        <img src={bookData?.theme?.divider} alt="" />
      </div>
      <div className="books__rightmiddle">
        <h4>You have launched the Quest</h4>
        <h5 className="ftwt__r books__descp">{bookData?.description}</h5>

        <div className="books__points">
          <img src={CoinSvg} alt="" />
          <h5 className="ftwt__r">{bookData?.points} Points</h5>
        </div>
      </div>
      <div className="books__rightaction">
        <button className="outlined__book_btn" onClick={openBookHandler}>
          Open Book
        </button>
      </div>
    </div>
  );
};
