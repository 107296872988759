import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

export const AddTaskCourse = ({ setAddCourse, addCourse }) => {
  return (
    <div>
      <FormControl sx={{ display: "flex", gap: "5px" }}>
        <RadioGroup
          row
          value={addCourse}
          sx={{
            "& .MuiSvgIcon-root": {
              fontSize: 20,
            },
          }}
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <FormControlLabel
            sx={{ "& .MuiFormControlLabel-label": { fontSize: "17px" } }}
            value={true}
            control={<Radio />}
            onChange={() => setAddCourse(true)}
            label="Course"
          />
          <FormControlLabel
            sx={{ "& .MuiFormControlLabel-label": { fontSize: "17px" } }}
            value={false}
            control={<Radio />}
            onChange={() => setAddCourse(false)}
            label="Task"
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
};
