import React from "react";
import "./Search.css";
import searchSvg from "../../../assets/Search.svg";

export const Search = ({
  searchVal,
  setSearchVal,
  placehold,
  mini = false,
}) => {
  return (
    <>
      <div
        className={mini ? "quest__search_mini quest__search" : "quest__search"}
      >
        <img
          src={searchSvg}
          alt="searchSvg"
          style={{ width: "20px", marginRight: "5px" }}
        />
        <input
          type="text"
          value={searchVal}
          onChange={(e) => setSearchVal(e.target.value)}
          placeholder={placehold}
        />
      </div>
    </>
  );
};
