import React, { useState } from "react";
import { BreadcrumbAdmin } from "../../../../UI/BreadcrumbAdmin";
import "../../../../Admin/Reports/Reports Detailed/ReportsDetailed.css";
import { Search } from "../../../../UI/Search/Search";
import { Button } from "@mui/material";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { UserStatisticAdminTable } from "./UserStatisticAdminTable";
import { Checkbox } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import rightArrow from "../../../../../assets/Icons/arrow.svg";
import { CSVLink } from "react-csv";
import DateRangePickerComp from "../../../../UI/DateRangePicker";
import { useOutsideClick } from "../../../../../hooks/useOutsideClick";

const list = [
  {
    name: "totalUsers",
    label: "Total Users",
  },
  { name: "activeUsers", label: "Total Active Users" },
  { name: "inactiveUsers", label: "Total Inactive Users" },
  { name: "pointsEarned", label: "Total Team Points Earned " },
  { name: "pointsRedeemed", label: "Total Team Points Redeemed" },
];

export const UserStatisticAdmin = () => {
  const [searchVal, setSearchVal] = useState("");
  const [select, setSelect] = useState(false);
  const [sortedStatus, setSortedStatus] = React.useState(true);
  const [sortedItem, setSortedItem] = React.useState();
  const [csvData, setCsvData] = useState([]);
  const [dateRange, setDateRange] = React.useState(null);

  const ref = React.useRef(null);

  const closePopup = () => {
  setSelect(false);
  };

  useOutsideClick(ref, closePopup);

  const [check, setCheck] = useState({
    totalUsers: true,
    activeUsers: true,
    inactiveUsers: true,
    pointsEarned: true,
    pointsRedeemed: true,
  });

  const sortHandler = async (value) => {
    setSortedItem(value);
    setTimeout(() => {
      setSortedStatus(!sortedStatus);
    }, 500);
  };

  const base = [
    {
      _id: 1,
      link: "/admin/reports",
      label: "Reports",
    },
  ];

  const handleChange = (e) => {
    setCheck({ ...check, [e.target.name]: e.target.checked });
  };

  return (
    <div className="container">
      <BreadcrumbAdmin
        base={base}
        page={'User Statistic'}
      />
      <div className="reportsDetailed__head">
        <Search
          mini
          setSearchVal={setSearchVal}
          searchVal={searchVal}
          placehold={'Search...'}
        />
        <div className="reportsDetailed__headright">
          <DateRangePickerComp
            onChange={(value) => {
              if (!value) {
                setDateRange(null);
              } else {
                setDateRange(value.map((val) => new Date(val).toISOString()));
              }
            }}
          />
          <div ref={ref} className="reports__selectLayout">
            <Button
              variant="outlined"
              endIcon={
                <ArrowForwardIosIcon
                  sx={{
                    transform: select ? 'rotate(-90deg)' : 'rotate(90deg)',
                    color: 'grey',
                    fontSize: '16px !important',
                  }}
                />
              }
              sx={{
                color: 'var(--grey400)',
                borderColor: 'var(--grey300)',
                width: '150px',
                fontWeight: '400 !important',
              }}
              onClick={() => setSelect(!select)}>
              Manage Field
            </Button>
            <div
              className={select ? 'reports__selectActive' : 'reports__select'}>
              <div className="reports__selectcontent">
                {list.map((item, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        defaultChecked={item.name}
                        name={item.name}
                        onChange={handleChange}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: '20px' } }}
                      />
                    }
                    onChange={handleChange}
                    label={
                      <span style={{ fontSize: '15px' }}>{item.label}</span>
                    }
                  />
                ))}
              </div>
            </div>
          </div>
          <CSVLink
            filename="User activity"
            data={csvData}>
            <Button
              variant="contained"
              endIcon={<FileUploadOutlinedIcon sx={{ color: 'white' }} />}>
              Export
            </Button>
          </CSVLink>
        </div>
      </div>

      <div className="reports__table">
        <UserStatisticAdminTable
          check={check}
          startDate={dateRange && dateRange[0]}
          endDate={dateRange && dateRange[1]}
          getTableData={(data) => {
            let cleanedCSVData = [
              {
                'Total users': data?.data.totalUsers,
                'Total active users': data?.data.totalActiveUsers,
                'Total inactive users': data?.data.totalInactiveUsers,
                'Total team points earned': data?.data.totalTeamPointsEarned,
                'Total points redeemed': data?.data.totalTeamPointsRedeemed,
              },
            ];
            setCsvData(cleanedCSVData);
          }}
        />
      </div>
    </div>
  );
};
