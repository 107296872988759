import React, { useState } from "react";
import { BreadcrumbAdmin } from "../../../../UI/BreadcrumbAdmin";
import "../../../../Admin/Reports/Reports Detailed/ReportsDetailed.css";
import { Search } from "../../../../UI/Search/Search";
import { Button } from "@mui/material";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Checkbox } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import rightArrow from "../../../../../assets/Icons/arrow.svg";
import { CustomStatisticTable } from "./CustomReportTable";
import { useOutsideClick } from "../../../../../hooks/useOutsideClick";

const list = [
  { name: "empName", label: "Emp Name" },
  { name: "email", label: "Emp Email" },
  { name: "empId", label: "Emp ID" },
  { name: "empDep", label: "Emp Department" },
  { name: "lLogin", label: "Last login" },
  { name: "bookComp", label: "Book Completed" },
  { name: "bookInProg", label: "Book Inprogress" },
  { name: "pointsEarned", label: "Points Earned" },
  { name: "status", label: "Status" },

  { name: "bookId", label: "Book ID" },
  { name: "bookDept", label: "Book Department" },
  { name: "bookCreator", label: "Book Creator" },
  {
    name: "depName",
    label: "Department Name",
  },
  { name: "Id", label: "Department Id" },
  { name: "hod", label: "Department HOD" },
  { name: "noOfCompletions", label: "No of Completions" },
  { name: "noofInprogress", label: "No of Inprogress" },
  { name: "pointsAssigned", label: "Points Assigned" },
];

export const CustomReport = () => {
  const [searchVal, setSearchVal] = useState("");
  const [select, setSelect] = useState(false);
  const [sortedStatus, setSortedStatus] = React.useState(true);
  const [sortedItem, setSortedItem] = React.useState();

  const ref = React.useRef(null);

  const closePopup = () => {
  setSelect(false);
  };

  useOutsideClick(ref, closePopup);

  const [check, setCheck] = useState({
    empName: true,
    email: true,
    empId: true,
    empDep: true,
    lLogin: true,
    bookComp: true,
    bookInProg: true,
    pointsEarned: true,
    status: true,
    bookId: true,
    bookDept: true,
    bookCreator: true,
    depName: true,
    Id: true,
    hod: true,
    noOfCompletions: true,
    noofInprogress: true,
    pointsAssigned: true,
  });
  const [sort, setSort] = React.useState("");

  const sortHandler = async (value) => {
    setSortedItem(value);
    setTimeout(() => {
      setSortedStatus(!sortedStatus);
    }, 500);
  };

  const base = [
    {
      _id: 1,
      link: "/super-admin/reports",
      label: "Reports",
    },
  ];
  const handleChange = (e) => {
    setCheck({ ...check, [e.target.name]: e.target.checked });
  };

  return (
    <div className="container">
      <BreadcrumbAdmin
        base={base}
        page={'Custom Report'}
      />
      <div className="reportsDetailed__head">
        <Search
          setSearchVal={setSearchVal}
          searchVal={searchVal}
          placehold={'Search...'}
        />
        <div className="reportsDetailed__headright">
          <FilterListOutlinedIcon color="primary" />
          <div className="main__sortitemsActivecont">
            <div
              value={sortedItem}
              onChange={sortHandler}
              className={
                sortedStatus ? 'main__sortitems' : 'main__sortitemsActive'
              }>
              <div
                onClick={() => setSortedStatus(!sortedStatus)}
                className="mainsortmain">
                {sortedItem ? <p>{sortedItem}</p> : <p value="">Sort By</p>}
                <div>
                  <img
                    src={rightArrow}
                    style={{ marginBottom: '2px' }}
                    alt=""
                  />
                </div>
              </div>
              <div className="main__selection">
                <div className="main__sortseprator"></div>
                {/* <p onClick={(e) => sortHandler("Select")}>Select</p> */}
                <p onClick={(e) => sortHandler('A-Z')}>A-Z </p>
                <p onClick={(e) => sortHandler('date')}>Date </p>
                <p onClick={(e) => sortHandler('Z-A')}>Z-A</p>
              </div>
            </div>
          </div>

          <div ref={ref} className="reports__selectLayout">
            <Button
              variant="outlined"
              endIcon={
                <ArrowForwardIosIcon
                  sx={{
                    transform: select ? 'rotate(-90deg)' : 'rotate(90deg)',
                    color: 'grey',
                    fontSize: '16px !important',
                  }}
                />
              }
              sx={{
                color: 'var(--grey400)',
                borderColor: 'var(--grey300)',
                width: '150px',
                fontWeight: '400 !important',
              }}
              onClick={() => setSelect(!select)}>
              Manage Field
            </Button>
            <div
              className={select ? 'reports__selectActive' : 'reports__select'}>
              <div className="reports__selectcontent">
                {list.map((item, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        defaultChecked={item.name}
                        name={item.name}
                        onChange={handleChange}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: '20px' } }}
                      />
                    }
                    onChange={handleChange}
                    label={
                      <span style={{ fontSize: '15px' }}>{item.label}</span>
                    }
                  />
                ))}
              </div>
            </div>
          </div>
          <Button
            variant="contained"
            endIcon={<FileUploadOutlinedIcon sx={{ color: 'white' }} />}>
            Export
          </Button>
        </div>
      </div>

      <div className="reports__table">
        <CustomStatisticTable check={check} />
      </div>
    </div>
  );
};
