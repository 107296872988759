import React, { useState, useEffect } from "react";
import "./LevelCover.css";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import LevelWaves from "./LevelWaves";
import { useLocation } from "react-router-dom";
import ShakespeareIcon from "../../../assets/books/Group 43841.png";
import { Button } from "@mui/material";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";

export const LevelCover = ({
  levelCoverId,
  name,
  bookName,
  description,
  coverImage,
  file,
}) => {
  const [playing, setPlaying] = React.useState(false);
  const [start, setStart] = React.useState(0);
  const [startButton, setStartButton] = React.useState(false);
  const [seconds, setSeconds] = useState(15);

  const audioRef = React.createRef();

  const playAudio = () => {
    setPlaying(true);
    audioRef?.current.play();
  };
  const stopAudio = () => {
    setPlaying(false);
    audioRef?.current.pause();
    audioRef?.current.load();
  };

  React.useEffect(() => {
    if (bookName === "The Famous 70's") {
      const audioElement = audioRef.current;

      const handleAudioEnd = () => {
        setPlaying(false);
      };

      audioElement.addEventListener('ended', handleAudioEnd);

      return () => {
        audioElement.removeEventListener('ended', handleAudioEnd);
      };
    }
  }, []);
  useEffect(() => {
    setStart(0);
  }, [levelCoverId]);

  useEffect(() => {
    // Decrease the timer every second
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    // Clear the interval when the component is unmounted or the timer reaches 0
    if (seconds === 0) {
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [seconds]);
  const startHandler = () => {
    setStart(levelCoverId);
    setSeconds(10);
  };

  return (
    <div
      key={levelCoverId}
      className="level__left">
      {bookName != 'Sporter' &&
        bookName != 'Works of Shakespeare' &&
        bookName != 'Mindful Movement' && <h1>{name}</h1>}

      {/* Default All Books */}
      {bookName != "The Famous 70's" &&
        bookName != 'Sporter' &&
        bookName != 'Works of Shakespeare' &&
        bookName != 'Mindful Movement' && (
          <img
            src={coverImage}
            alt=""
          />
        )}

      {/* The Famous 70's */}
      {bookName === "The Famous 70's" && (
        <>
          {file && (
            <audio
              ref={audioRef}
              src={file?.url}
            />
          )}
          {playing && (
            <div className="rotate__waves">
              <LevelWaves />
            </div>
          )}
          <div className="rotate__container">
            <img
              className={playing ? 'rotate' : ''}
              src={coverImage}
              alt=""
            />

            <div className="rotate__action">
              {!playing && (
                <PlayArrowIcon
                  className="play-pause-icon"
                  sx={{ cursor: 'pointer', fontSize: '30px' }}
                  onClick={playAudio}
                />
              )}
              {playing && (
                <PauseIcon
                  className="play-pause-icon"
                  sx={{ cursor: 'pointer', fontSize: '30px' }}
                  onClick={stopAudio}
                />
              )}
            </div>
          </div>
        </>
      )}

      {/* Sports */}
      {bookName === 'Sporter' && (
        <img
          className="Sports"
          src={coverImage}
          alt=""
        />
      )}

      {bookName === 'Works of Shakespeare' && (
        <div className="shakespeare__container">
          <img
            className="shakespeare__icon"
            src={ShakespeareIcon}
            alt=""
          />
          <div className="shakespeare">
            <img
              src={coverImage}
              alt=""
            />
            <h5 className="ftwt__r">{description}</h5>
          </div>
        </div>
      )}

      {bookName === 'Mindful Movement' && (
        <>
          {startButton ? (
            <>
              <div>
                <h1
                  style={{
                    color: 'var(--spurple)',
                  }}>
                  {name}
                </h1>
                {start === levelCoverId && seconds > 0 ? (
                  <img
                    className="mindful__gif"
                    src={file?.url}
                    alt=""
                  />
                ) : (
                  <img
                    src={coverImage}
                    alt=""
                  />
                )}
                {start === 0 && (
                  <Button
                    onClick={startHandler}
                    variant="outlined">
                    Start Timer
                  </Button>
                )}
                {start === levelCoverId && seconds > 0 && (
                  <Button
                    startIcon={
                      <TimerOutlinedIcon sx={{ color: 'var(--pblue)' }} />
                    }
                    sx={{ width: '90px' }}
                    variant="outlined">
                    0:{seconds < 10 && '0'}
                    {seconds}
                  </Button>
                )}
                {start === levelCoverId && seconds === 0 && (
                  <Button
                    variant="outlined"
                    sx={{ color: 'var(--pblue)' }}
                    onClick={startHandler}>
                    <RestartAltOutlinedIcon
                      sx={{
                        color: 'var(--pblue)',
                        fontSize: '18px',
                        marginRight: '5px',
                      }}
                    />
                    Restart
                  </Button>
                )}
              </div>
              <h5 className="ftwt__r">{description}</h5>
            </>
          ) : (
            <div className="mindful__preStart">
              <h6>
                "Yoga is a light, which once lit, will never dim. The better
                your practice, the brighter your flame."
              </h6>
              <span onClick={() => setStartButton(true)}>Let's Start</span>
            </div>
          )}
        </>
      )}

      {bookName != 'Sporter' &&
        bookName != 'Works of Shakespeare' &&
        bookName != 'Mindful Movement' && (
          <h5 className="ftwt__r">{description}</h5>
        )}
    </div>
  );
};
