import React from "react";
import "./CourseCover.css";
import { Moddetails } from "../../Mycourses/Course/Moddetails";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import module from "../../../assets/books/course cover/Number of modules.svg";
import quiz from "../../../assets/books/course cover/Quiz.svg";
import time from "../../../assets/books/course cover/Course hours.svg";
import star from "../../../assets/books/course cover/Star 1.svg";
import { getFormattedTimeInHoursAndMinuteByMinutes } from "../../../utils";

export const CourseCover = ({
  course,
  bookName,
  levelName,
  bookId,
  levelId,
  taskId,
}) => {
  const navigate = useNavigate();
  const startHandler = (courseId) => {
    navigate(
      `/quest/${encodeURIComponent(bookName)}/${bookId}/${encodeURIComponent(
        levelName
      )}/${levelId}/task/${taskId}/course/${courseId}`
    );
  };

  return (
    <div>
      <div className="ccover">
        <div className="ccover__head">
          <div>
            <h2>{course.name}</h2>
            {/* <img src={star} alt="" />
            <h3 className="ftwt__r">4.5</h3> */}
          </div>

          {course?.isFinished ? (
            <Button
              onClick={() => startHandler(course._id)}
              variant="contained"
              color="success">
              Completed
            </Button>
          ) : (
            <Button
              onClick={() => startHandler(course._id)}
              variant="contained">
              {course?.isStarted ? 'Continue' : 'Get Started'}
            </Button>
          )}
        </div>
        <div className="seprator"></div>
      </div>
      <div className="ccoverD">
        <div className="ccover__icons">
          <div>
            <img
              src={module}
              alt=""
            />
            <h5 className="ftwt__r">{course?.numberOfModules} Modules</h5>
          </div>
          <div>
            <img
              src={time}
              alt=""
            />
            <h5 className="ftwt__r">
              {getFormattedTimeInHoursAndMinuteByMinutes(
                course?.totalTimeToComplete
              )}
            </h5>
          </div>
        </div>
        <div className="ccover__img">
          {course?.coverImage != null && (
            <img
              src={course?.coverImage}
              alt=""
            />
          )}
        </div>
        <div className="ccover__middle">
          <h4 className="ftwt__b">Description:</h4>
          <h5 className="ftwt__r">{course.description}</h5>
        </div>
        <div className="ccover__topic">
          <h4 className="ftwt__b">Topics:</h4>
          {course &&
            course?.modules.map((item, index) => (
              <Moddetails
                isCourseModal
                open={true}
                moduleNo={index}
                module={item}
              />
            ))}
        </div>
      </div>
    </div>
  );
};
