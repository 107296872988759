import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";
import themeReducer from "./features/theme/themeSlice";
import coursesReducer from "./features/courses/coursesSlice";
import authReducer from "./features/auth/authSlice";
import libraryReducer from "./features/library/librarySlice";
import accessReducer from "./features/access/accessSlice";
import dashboardReducer from "./features/dashboard/dashboardSlice";
import rewardsReducer from './features/rewards/rewardsSlice';

import alertReducer from './features/alert/alertSlice';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

import storage from 'redux-persist/lib/storage';

// export const store = configureStore({
//   reducer: {
//     [apiSlice.reducerPath]: apiSlice.reducer,
//     auth: authReducer,
//     library: libraryReducer,
//     alert: alertReducer,
//     courses: coursesReducer,
//     theme: themeReducer,
//   },
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware().concat(apiSlice.middleware),
//   devTools: process.env.REACT_APP_CLIENT_ENV === "development",
// });

const reducers = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth: authReducer,
  library: libraryReducer,
  alert: alertReducer,
  courses: coursesReducer,
  theme: themeReducer,
  access: accessReducer,
  dashboard: dashboardReducer,
  rewards: rewardsReducer,
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(apiSlice.middleware),

  devTools: process.env.REACT_APP_CLIENT_ENV === "development",
});
