import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
export const RestrictedRoutes = ({
  component: Component,
  access,
  role,
  ...rest
}) => {
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);
  let hasAccess =
    user?.allowedAccess.filter((value) => access.includes(value)).length > 0;

  return hasAccess ? (
    <Outlet />
  ) : (
    <Navigate to="/restricted" state={{ from: location }} replace />
  );
};
