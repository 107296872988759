import React, { useState } from "react";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import addPlusIcon from "../../../../assets/add_plus.svg";
import { Divider, IconButton, Menu, MenuItem } from "@mui/material";
import { ReactComponent as EditSvg } from '../../../../assets/Icons/edit (1).svg';
import DeleteInActiveSvg from '../../../../assets/delete_icon.svg';
import { useDeleteProductMutation } from "../../../../redux-toolkit/features/rewards/rewardsApiSlice";
import { useAlert } from "../../../../hooks/useAlert";

function LongMenu({ onEditClick, onDeleteClick }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <div
      style={{
        position: 'absolute',
        right: 0,
      }}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-haspopup="true"
        onClick={(event) => setAnchorEl(event.currentTarget)}
        // onMouseLeave={() => handleClose()}
      >
        <MoreVertIcon
          sx={{
            color: 'white',
            fontSize: '20px',
            background: 'rgba(68, 68, 68, 0.4)',
            borderRadius: '5px',
          }}
        />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        <MenuItem
          sx={{
            '&:hover': {
              backgroundColor: 'var(--slightpurple)',
            },
          }}
          onClick={(e) => {
            onEditClick && onEditClick();
            handleClose(e);
          }}>
          <EditSvg
            className="edit_svg_level"
            style={{
              fontSize: '15px',
              cursor: 'pointer',
              fontWeight: 100,
              marginRight: '10px',
              strokeWidth: '0.5px',
            }}
          />
          Edit
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            onDeleteClick && onDeleteClick();
            handleClose(e);
          }}>
          <img
            src={DeleteInActiveSvg}
            className="Sirv image-main"
            height="25px"
            style={{
              marginRight: '10px',
            }}
            alt=""
          />{' '}
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
}

const RewardsCard = ({ product, isAdd, onClick, onDelete, onEdit }) => {
  const { _id, name, image, points } = product || {};
  const { displayAlert } = useAlert();

  const [deleteProduct] = useDeleteProductMutation();

  return (
    <div className="rewards_card" onClick={onClick}>
      {!isAdd && (
        <LongMenu
          onDeleteClick={async () => {
            const ok = window.confirm(
              "Are you sure you want to delete this product?"
            );
            if (!ok) return;
            try {
              await deleteProduct(_id);
              displayAlert({
                open: true,
                message: `Product deleted successfully`,
                type: "success",
                timeout: "2500",
              });
              onDelete();
            } catch (error) {
              displayAlert({
                open: true,
                message: `Something went wrong`,
                type: "error",
                timeout: "2500",
              });
            }
          }}
          onEditClick={() => onEdit(product)}
        />
      )}
      {!isAdd ? (
        <img
          className="rewards_product_img"
          src={image || "https://via.placeholder.com/185x150.png"}
          alt="product"
        />
      ) : (
        <div
          className="rewards_product_img"
          style={{
            backgroundColor: "#00000044",
            marginBottom: "7px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <img src={addPlusIcon} alt="add_more" />
        </div>
      )}
      <div className="card_body">
        <p className="reward_name truncate-1">{isAdd ? "Add more" : name}</p>
        <Divider
          style={{
            margin: "8px 0",
            width: "100%",
          }}
          variant="middle"
          color={isAdd ? "#fff" : "#ddd"}
        />
        <div className="reward_points">
          <StarBorderOutlinedIcon
            sx={{
              color: "var(--pmain)",
              fontSize: "14px",
              visibility: isAdd ? "hidden" : "",
            }}
          />

          <p
            style={{
              fontSize: "12px",
            }}
          >
            {isAdd ? null : `${points || 0} Points`}
          </p>
        </div>
      </div>
    </div>
  );
};

export default RewardsCard;
