import React, { useCallback, useState } from "react";
import { BreadcrumbAdmin } from "../../../../UI/BreadcrumbAdmin";
import "../../../../Admin/Reports/Reports Detailed/ReportsDetailed.css";
import { Search } from "../../../../UI/Search/Search";
import { Button } from "@mui/material";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Checkbox } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import rightArrow from "../../../../../assets/Icons/arrow.svg";
import { UserActivityTable } from "./UserActivityTable";
import { debounce } from "../../../../../utils";
import { CSVLink } from "react-csv";
import DateRangePickerComp from "../../../../UI/DateRangePicker";
import { useOutsideClick } from "../../../../../hooks/useOutsideClick";

const list = [
  { name: "empName", label: "Emp Name" },
  { name: "email", label: "Emp Email" },
  { name: "empId", label: "Emp ID" },
  { name: "empDep", label: "Emp Department" },
  { name: "lLogin", label: "Last login" },
  { name: "bookStatus", label: "Current Book Status" },
  { name: "tasksCompleted", label: "Total Tasks Completed" },
  { name: "levelsCompleted", label: "Total Levels Completed" },
  { name: "badgeCount", label: "Task Badge Count" },
  { name: "pointsEarned", label: "Total Points Earned" },
  { name: "status", label: "Status" },
];

export const UserActivity = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [select, setSelect] = useState(false);
  const [csvData, setCsvData] = useState([]);

  const ref = React.useRef(null);

  const closePopup = () => {
  setSelect(false);
  };

  useOutsideClick(ref, closePopup);

  const [dateRange, setDateRange] = React.useState(null);

  const [check, setCheck] = useState({
    empName: true,
    email: true,
    empId: true,
    empDep: true,
    lLogin: true,
    bookStatus: true,
    tasksCompleted: true,
    levelsCompleted: true,
    badgeCount: true,
    pointsEarned: true,
    status: true,
  });

  const base = [
    {
      _id: 1,
      link: "/admin/reports",
      label: "Reports",
    },
  ];
  const setDebouncedSearchQuery = useCallback(
    debounce((value) => {
      setSearchQuery(value);
    }, 500),
    []
  );
  const handleChange = (e) => {
    setCheck({ ...check, [e.target.name]: e.target.checked });
  };

  return (
    <div className="container">
      <BreadcrumbAdmin
        base={base}
        page={'User Activity'}
      />
      <div className="reportsDetailed__head">
        <Search
          mini
          setSearchVal={(val) => setDebouncedSearchQuery(val)}
          placehold={'Search...'}
        />
        <div className="reportsDetailed__headright">
          <DateRangePickerComp
            onChange={(value) => {
              if (!value) {
                setDateRange(null);
              } else {
                setDateRange(value.map((val) => new Date(val).toISOString()));
              }
            }}
          />
          <div ref={ref} className="reports__selectLayout">
            <Button
              variant="outlined"
              endIcon={
                <ArrowForwardIosIcon
                  sx={{
                    transform: select ? 'rotate(-90deg)' : 'rotate(90deg)',
                    color: 'grey',
                    fontSize: '16px !important',
                  }}
                />
              }
              sx={{
                color: 'var(--grey400)',
                borderColor: 'var(--grey300)',
                width: '150px',
                fontWeight: '400 !important',
              }}
              onClick={() => setSelect(!select)}>
              Manage Field
            </Button>
            <div
              className={select ? 'reports__selectActive' : 'reports__select'}>
              <div className="reports__selectcontent">
                {list.map((item, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        defaultChecked={item.name}
                        name={item.name}
                        onChange={handleChange}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: '20px' } }}
                      />
                    }
                    onChange={handleChange}
                    label={
                      <span style={{ fontSize: '15px' }}>{item.label}</span>
                    }
                  />
                ))}
              </div>
            </div>
          </div>
          <CSVLink
            filename="User activity"
            data={csvData}>
            <Button
              variant="contained"
              endIcon={<FileUploadOutlinedIcon sx={{ color: 'white' }} />}>
              Export
            </Button>
          </CSVLink>
        </div>
      </div>

      <div className="reports__table">
        <UserActivityTable
          searchQuery={searchQuery}
          check={check}
          startDate={dateRange && dateRange[0]}
          endDate={dateRange && dateRange[1]}
          getTableData={(data) => {
            let cleanedCSVData = data?.data?.map((obj) => {
              let d = {
                'Employee name': `${obj.fname} ${obj.lname}`,
                'Employee email': obj.email,
                'Employee id': obj.employeeId,
                'Employee department': obj.department?.name,
                'Last login': obj.lastLogin,
                'Current book status': obj.currentBokStatus,
                'Total tasks completed': obj.totalTasksCompleted,
                'Total levels completed': obj.totalLevelsCompleted,
                'Total points earned': obj.totalPointsEarned,
                'Total badge count': obj.totalBadgeCount,
              };
              return d;
            });
            setCsvData(cleanedCSVData);
          }}
        />
      </div>
    </div>
  );
};
