import { Button } from "@mui/material";
import React, { useState } from "react";
import { useLocation, useParams, Link } from "react-router-dom";
import { BreadcrumbAdmin } from "../../UI/BreadcrumbAdmin";
import "./Modulecontent.css";
import { Box } from "@mui/material";
import star from "../../../assets/Icons/modules/Star.svg";
import ProgressBar from "../../UI/ProgressBar";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import {
  useMarkCompleteMutation,
  useGetSubmoduleByIdQuery,
} from "../../../redux-toolkit/features/courses/coursesApiSlice";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import { Error404 } from "../../UI/404/Error404";
import { useAlert } from "../../../hooks/useAlert";
import ReactQuill from "react-quill";
import CoursePdf from './CoursePdf';

export const Modulecontent = ({
  content,
  refetch,
  selectedTopic,
  selectedModule,
  handlePrevious,
  handleNext,
  moduleIndex,
  topicIndex,
  topicId,
  isLast,
  toggleCompleted,
}) => {
  const { courseId, levelId, taskId, bookName, bookId, levelName } =
    useParams();
  const [markComplete] = useMarkCompleteMutation();
  const { displayAlert } = useAlert();

  const { data, isLoading, isFetching, isError } = useGetSubmoduleByIdQuery(
    topicId,
    {
      skip: toggleCompleted === true,
      refetchOnMountOrArgChange: true,
    }
  );

  if (isLoading || isFetching) {
    return (
      <div
        className=" modcon"
        style={{ height: '97vh' }}>
        <ProgressBar />
      </div>
    );
  }
  if (isError) {
    return (
      <div style={{ height: '97vh', paddingTop: '80px' }}>
        <Error404 />
      </div>
    );
  }

  const base = [
    {
      _id: 1,
      link: `/quest/books/${bookId}`,
      label: bookName,
    },
    {
      _id: 2,
      link: `/quest/books/${bookId}/levels/${levelId}`,
      label: levelName,
    },
  ];

  const markDoneHandler = () => {
    let bookDetails;
    // if (isLast) {
    bookDetails = {
      bookId: bookId,
      levelId: levelId,
      taskId: taskId,
    };
    // }
    markComplete({
      courseId,
      moduleId: selectedModule._id,
      topicId: selectedTopic._id,
      bookDetails,
    })
      .unwrap()
      .then(async () => {
        await displayAlert({
          open: true,
          message: 'Successfully Marked as completed ',
          type: 'success',
          timeout: '2000',
        });
        await refetch();
        await handleNext();
      });
  };

  return (
    <>
      {data ? (
        <div className="modcon">
          <div className="course__breadcrumbs">
            <Link to="/quest">
              <div className="course__breadcrumbshome">
                <HomeRoundedIcon
                  sx={{
                    fontSize: '22px',
                    color: true ? 'var(--pblue)' : 'white',
                    '&:hover': {
                      color: 'white',
                    },
                  }}
                />
              </div>
            </Link>
            <BreadcrumbAdmin
              isQuest
              base={base}
              page={content?.name}
            />
          </div>

          <div className="modcon__top">
            <Box
              sx={{
                border: '2px solid var(--grey300)',
                width: '25px',
                height: '25px',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <h5>
                {moduleIndex + 1}.{topicIndex + 1}
              </h5>
            </Box>
            <h3>{data?.data?.name}</h3>
          </div>
          <div className="seprator">&nbsp;</div>

          <div className="modcon__middle">
            <div className="modcon__desc">
              <h3 className="ftwt__b">Description:</h3>
              <div>
                <div
                  className="course_content__desc"
                  dangerouslySetInnerHTML={{
                    __html: data?.data?.content,
                  }}></div>
              </div>

              <div className="course_content__resources">
                {data?.data?.resources &&
                  data?.data?.resources.length > 0 &&
                  data?.data?.resources.map((item) => (
                    <CoursePdf item={item} />
                  ))}
              </div>
            </div>
            <div className="modcon__bottom">
              <div className="seprator">&nbsp;</div>

              <div className="modcon__action">
                {data?.data?.submoduleCompletion === 'INCOMPLETE' ? (
                  <h5 onClick={markDoneHandler}>Mark As Done</h5>
                ) : (
                  <h5>Completed</h5>
                )}
                <div className="course__actions">
                  <span className="course__prev">
                    <KeyboardArrowLeftOutlinedIcon
                      onClick={handlePrevious}
                      sx={{
                        fontSize: '25px',
                        color: 'var(--pblue)',
                        '&:hover': {
                          color: 'white',
                        },
                      }}
                    />
                  </span>
                  <span className="course__next">
                    <KeyboardArrowRightOutlinedIcon
                      onClick={handleNext}
                      sx={{
                        fontSize: '25px',
                        color: 'var(--pblue)',
                        '&:hover': {
                          color: 'white',
                        },
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            backgroundColor: 'white',
            height: '95vh',
            marginTop: '70px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <h1>No Topic Selected</h1>
        </div>
      )}
    </>
  );
};

{
  /* <div className="modcon__img">
            {content?.coverImage != null && (
              <img src={content.coverImage} alt="" />
            )}
          </div> */
}