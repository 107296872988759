import React from "react";
import "./AccessCards.css";

export const AccessCards = ({ elm }) => {
  return (
    <div key={elm._id} className="accessCards">
      <img src={elm.image} alt="" />
      <h4 style={{ fontSize: "16px", color: "#5c5c5c" }}>{elm.label}</h4>
      <h6 className="ftwt__r" style={{ fontSize: "14px" }}>
        {elm.descp}{" "}
      </h6>
    </div>
  );
};
