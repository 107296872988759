import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
import { ReactComponent as EditSvg } from '../../../../assets/Icons/edit (1).svg';
import { useAlert } from "../../../../hooks/useAlert";
import { useGetAllUsersQuery } from "../../../../redux-toolkit/features/department/departmentApiSlice";
import { useToggleEmployeeAccessMutation } from "../../../../redux-toolkit/features/employee/employeeApiSlice";
import ProgressBar from "../../../UI/ProgressBar";
import "../../../UI/Tables/TableUi.css";
import sortUp from "../../../../assets/employee/sort_up.svg";
import sortDown from "../../../../assets/employee/sort_down.svg";
import { useSelector } from "react-redux";
import { Checkbox } from "@mui/material";
import { Toggle } from "../../../UI/Toggle";
const rows = [
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: true,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: false,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: true,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: true,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: true,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: true,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: true,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: true,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: true,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: true,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: true,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: false,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: true,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: false,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: true,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: true,
  },
];

export const SortIcon = ({ sortOrder }) => {
  return (
    <span
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "2px",
        width: "fit-content",
      }}
    >
      <img src={sortUp} />
      <img src={sortDown} />
    </span>
  );
};

export const EmployeeTable = ({
  refetchData,
  onEditClick,
  searchQuery = "",
  setSelectedEmployee,
  selectedEmployee,
}) => {
  const { displayAlert } = useAlert();

  const [sortBy, setSortBy] = useState("");
  const [sortType, setSortType] = useState("asc");
  const role = useSelector((state) => state.auth.user.role);

  const { data: users, refetch } = useGetAllUsersQuery(
    {
      sortBy: sortBy,
      type: sortType,
      query: searchQuery,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const [toggleEmployeeAccess] = useToggleEmployeeAccessMutation();

  const handleChange = async (id) => {
    await toggleEmployeeAccess(id)
      .unwrap()
      .then((res) => {
        displayAlert({
          open: true,
          message: res?.message || `Employee status changed`,
          type: res?.data?.isActive ? "success" : "error",
          timeout: "1500",
        });
      })
      .catch((err) => {
        displayAlert({
          open: true,
          message:
            err?.data?.message ||
            `Error while updating the employee activity status`,
          type: "error",
          timeout: "1500",
        });
      });
    refetch();
  };

  const selectHandler = (e, _id) => {
    if (e.target.checked) {
      setSelectedEmployee([...selectedEmployee, _id]);
    } else {
      setSelectedEmployee(selectedEmployee.filter((item) => item != _id));
    }
  };

  const handleSorting = (sortKey) => {
    setSortBy(sortKey);
    setSortType(!sortType || sortType === "desc" ? "asc" : "desc");
  };

  useEffect(() => {
    refetch();
  }, [refetchData]);

  useEffect(() => {}, [users]);

  return (
    <div style={{ width: "100%", overflow: "scroll" }}>
      {users ? (
        <TableContainer
          sx={{
            height: "calc( 100vh - 230px)",
            padding: "0 1px",
            width: "1350px",
          }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow
                sx={{ borderRadius: "15px 15px 0px 0px" }}
                className="tableUi__head"
              >
                <TableCell
                  sx={{ borderRadius: "15px 0px 0px 0px" }}
                  align="center"
                >
                  Select
                </TableCell>

                <TableCell
                  align="center"
                  onClick={() => handleSorting("fname")}
                >
                  <div className="tableUi__head_sortable">
                    First Name <SortIcon sortOrder={sortType} />
                  </div>
                </TableCell>
                <TableCell align="center">Last Name</TableCell>
                <TableCell align="center">Email Id</TableCell>
                <TableCell
                  align="center"
                  onClick={() => handleSorting("department_name")}
                >
                  <div className="tableUi__head_sortable">
                    Department
                    <SortIcon sortOrder={sortType} />
                  </div>
                </TableCell>
                <TableCell align="center">Emp Id</TableCell>
                <TableCell
                  sx={{
                    borderRadius:
                      role != "SUPERADMIN" ? "0px 15px 0px 0px" : "",
                  }}
                  align="center"
                >
                  Status
                </TableCell>

                {role === "SUPERADMIN" && (
                  <TableCell
                    sx={{ borderRadius: "0px 15px 0px 0px" }}
                    align="center"
                  >
                    Action
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {users &&
                users?.data.users.map((row) => (
                  <React.Fragment key={row?._id}>
                    <div className="row__spacing"></div>
                    <TableRow
                      className="tableUi___body"
                      key={row?.name}
                      sx={{ borderRadius: "10px" }}
                      style={{
                        backgroundColor: selectedEmployee?.includes(row?._id)
                          ? "#3361cf22"
                          : "",
                      }}
                    >
                      <TableCell
                        sx={{
                          borderRadius: "10px 0px 0px 10px",
                          // border: "1px solid grey",
                        }}
                        align="center"
                      >
                        <Checkbox
                          checked={selectedEmployee?.includes(row?._id)}
                          value={row?._id}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
                          onChange={(e) => selectHandler(e, row?._id)}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <div className="tableUi__rankscir">
                          <div className="tableUi__rank"> {row?.fname}</div>
                        </div>
                      </TableCell>
                      <TableCell align="center">{row?.lname}</TableCell>
                      <TableCell align="center">{row?.email}</TableCell>
                      <TableCell align="center">
                        {row?.department?.name}
                      </TableCell>
                      <TableCell align="center">{row?.employeeId}</TableCell>
                      <TableCell
                        sx={{
                          borderRadius:
                            role != "SUPERADMIN" ? "0px 10px 10px 0px" : "",
                          // border: role != "SUPERADMIN" ? "1px solid grey" : "",
                        }}
                        align="center"
                      >
                        <Toggle
                          isOn={row?.isActive}
                          handleToggle={() => {
                            handleChange(row._id);
                          }}
                          id={row?._id}
                          inputProps={{
                            style: {
                              position: "absolute",
                            },
                          }}
                        />
                      </TableCell>
                      {role === "SUPERADMIN" && (
                        <TableCell
                          sx={{
                            borderRadius: "0px 10px 10px 0px",
                            // border: "1px solid grey",
                          }}
                          align="center"
                        >
                          <EditSvg
                            onClick={() => {
                              onEditClick && onEditClick(row?._id);
                            }}
                            style={{ fontSize: "15px", cursor: "pointer" }}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className="employee__loader">
          <ProgressBar />
        </div>
      )}
    </div>
  );
};
