import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  assigned: [],
  selfEnrolled: [],
  bookmarks: [],
  error: "",
};

const courses = createSlice({
  name: "courses",
  initialState,
  reducers: {
    assignedCourse: (state, action) => {
      // REMOVE DUPLICATE

      const filtassigned = action.payload.filter((elnm) => "assignor" in elnm);
      const aa = action.payload.filter((val) => !filtassigned.includes(val));
      state.assigned = filtassigned;
      state.selfEnrolled = aa;
    },
    selfEnrolledCourse: (state, action) => {
      state.selfEnrolled = action.payload;
    },
    bookmarksCourse: (state, action) => {
      state.bookmarks = action.payload;
    },
  },
});

export const { assignedCourse, selfEnrolledCourse, bookmarksCourse } =
  courses.actions;

export default courses.reducer;

export const selectedAssigned = (state) => state.courses.assigned;
export const selectedSelfEnrolled = (state) => state.courses.selfEnrolled;
export const selectedBookmarks = (state) => state.courses.bookmarks;
