import {
  ClearOutlined,
  DateRangeOutlined,
  KeyboardDoubleArrowLeftOutlined,
  KeyboardDoubleArrowRightOutlined,
  NavigateBeforeOutlined,
  NavigateNextOutlined,
} from "@mui/icons-material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import React, { useEffect, useRef, useState } from "react";
import "react-calendar/dist/Calendar.css";
import "./DateRangePicker.css";
import { ReactComponent as CalenderSvg } from "../../assets/Icons/calendar.svg";

const DateRangePickerComp = ({
  maxDate,
  minDate,
  onChange = (value) => {},
}) => {
  const calRef = useRef();
  const [value, setValue] = useState([]);
  const [isCalOpen, setIsCalOpen] = useState(false);

  const injectTextInEmptyDF = () => {
    // const calendarCard = document?.querySelector(
    //   ".react-daterange-picker__calendar"
    // );
    // if (calendarCard) {
    //   calendarCard.addEventListener("click", (e) => {
    //     e.stopPropagation();
    //   });
    // }

    const divider = document.querySelector(
      ".react-daterange-picker__range-divider"
    );
    if (!value?.length) {
      const inputDivs = document.querySelectorAll(
        ".react-daterange-picker__inputGroup"
      );

      if (divider) divider.style = "display:none;";

      inputDivs?.forEach((inputDiv, i) => {
        inputDiv.childNodes.forEach((child) => {
          child.style = "display: none";
        });
      });
      let div = document.createElement("div");
      div.id = "temp-placeholder";
      div.style = "color: gray; z-index: 10; cursor: pointer;";
      div.textContent = "Custom range";
      // div.addEventListener("click", (e) => {
      //   const arrow = document.getElementById("open_cal");
      //   if (arrow) {
      //     arrow.parentElement?.click();
      //   }
      // });
      inputDivs[0]?.appendChild(div);
    } else {
      const inputDivs = document.querySelectorAll(
        ".react-daterange-picker__inputGroup"
      );
      if (divider) divider.style = "display:unset;";

      inputDivs?.forEach((inputDiv, i) => {
        inputDiv.childNodes?.forEach((child) => {
          child.style += "display: unset";
          const div = document.getElementById("temp-placeholder");
          div?.remove();
        });
      });
    }
  };

  useEffect(() => {
    injectTextInEmptyDF();
  }, [value, isCalOpen]);

  return (
    <div
      style={{
        cursor: "pointer",
        position: "relative",
      }}
      className="date_range_cont"
    >
      <div
        style={{
          position: "absolute",
          inset: 0,
          zIndex: "1001",
          display: value && value[0] ? "none" : "",
        }}
        onClick={(e) => {
          e.stopPropagation();
          if (value && value[0]) return;
          setIsCalOpen(!isCalOpen);
        }}
      ></div>

      <CalenderSvg
        className="dp_calender_icon"
        fontSize="small"
        style={{ height: "20px" }}
        color="action"
      />
      <DateRangePicker
        maxDate={maxDate || new Date()}
        minDate={minDate}
        className="local_dp"
        onChange={(val) => {
          setValue(val);
          onChange(val);
          setIsCalOpen(false);
        }}
        isOpen={isCalOpen}
        rangeDivider={value && value[0] ? "-" : ""}
        value={value}
        format="dd MMM yy"
        clearIcon={
          value && value[0] ? (
            <ClearOutlined fontSize="small" color="action" />
          ) : null
        }
        openCalendarOnFocus={false}
        // onCalendarOpen={() => setIsCalOpen(true)}
        onCalendarClose={() => {
          setTimeout(() => setIsCalOpen(false), 500);
        }}
        // onClickYear={(e) => e.stopPropagation()}
        // onClickDay={(e) => e.stopPropagation()}
        // onClickDecade={(e) => e.stopPropagation()}
        // onClickMonth={(e) => e.stopPropagation()}
        // onClickWeekNumber={(e) => e.stopPropagation()}
        calendarIcon={
          <ArrowForwardIosIcon
            id="open_cal"
            sx={{
              transform: "rotate(90deg)",
              color: "grey",
              fontSize: "15px",
            }}
          />
        }
        nextLabel={
          <NavigateNextOutlined
            className="calendar_navigate_btn"
            sx={{ fontSize: "22px", color: "var(--pblue)" }}
          />
        }
        next2Label={
          <KeyboardDoubleArrowRightOutlined
            className="calendar_navigate_btn"
            sx={{ fontSize: "22px", color: "var(--pblue)" }}
          />
        }
        prevLabel={
          <NavigateBeforeOutlined
            className="calendar_navigate_btn"
            sx={{ fontSize: "22px", color: "var(--pblue)" }}
          />
        }
        prev2Label={
          <KeyboardDoubleArrowLeftOutlined
            className="calendar_navigate_btn"
            sx={{ fontSize: "22px", color: "var(--pblue)" }}
          />
        }
      />
    </div>
  );
};

export default DateRangePickerComp;
