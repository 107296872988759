import React from "react";
import "./LevelWaves.css";

const LevelWaves = () => {
  return (
    <div>
      <div className="boxWaves">
        <div class="boxContainer">
          <div class="box box4"></div>
          <div class="box box5"></div>
          <div class="box box1"></div>
          <div class="box box2"></div>
          <div class="box box3"></div>
          <div class="box box4"></div>
          <div class="box box5"></div>
          <div class="box box4"></div>
          <div class="box box5"></div>
          <div class="box box1"></div>
          <div class="box box2"></div>
          <div class="box box3"></div>
          <div class="box box4"></div>
          <div class="box box5"></div>
          <div class="box box3"></div>
          <div class="box box1"></div>
          <div class="box box2"></div>

          <div class="box box1"></div>
          <div class="box box2"></div>
          <div class="box box3"></div>
          <div class="box box4"></div>
          <div class="box box5"></div>
          <div class="box box3"></div>
          <div class="box box4"></div>
          <div class="box box5"></div>
          <div class="box box1"></div>
          <div class="box box2"></div>
          <div class="box box4"></div>
          <div class="box box5"></div>
        </div>
      </div>
    </div>
  );
};

export default LevelWaves;
