import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import SnackbarAlert from "./Components/Snackbar/SnackbarAlert";
import "./index.css";
import { store } from "./redux-toolkit/store";

const theme = createTheme({
  typography: {
    fontFamily: ["Montserrat, sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#3361cf",
    },
    success: {
      main: "#2CAB35",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 2550,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));

let persistor = persistStore(store);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <PersistGate loading={null} persistor={persistor}>
            <Routes>
              <Route
                path="/*"
                element={
                  <>
                    <App />
                    <SnackbarAlert />
                  </>
                }
              />
            </Routes>
          </PersistGate>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);
