import { apiSlice } from "../../api/apiSlice";
import { clearCart, updateCart, updateQuantity } from "./rewardsSlice";

export const rewardsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query({
      query: ({ catId, query }) => ({
        url: `/api/rewards/products?category=${catId}&query=${query}`,
        method: "GET",
      }),
    }),
    getAllProducts: builder.query({
      query: () => ({
        url: `/api/rewards/products`,
        method: "GET",
      }),
    }),
    getAllProductsEmployee: builder.query({
      query: ({ query, category }) => ({
        url: `/api/rewards/products/get/employee?query=${query}&category=${category}`,
        method: "GET",
      }),
    }),
    createProduct: builder.mutation({
      query: (data) => ({
        url: `/api/rewards/products`,
        method: "POST",
        body: data,
      }),
    }),
    updateProduct: builder.mutation({
      query: ({ data, _id }) => ({
        url: `/api/rewards/products/${_id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteProduct: builder.mutation({
      query: (productId) => ({
        url: `/api/rewards/products/${productId}`,
        method: "DELETE",
      }),
    }),
    getCategories: builder.query({
      query: () => ({
        url: `/api/rewards/categories`,
        method: "GET",
      }),
    }),
    getEmployee: builder.query({
      query: (searchQuery) => ({
        url: `/api/rewards/orders/users?query=${searchQuery}`,
        method: "GET",
      }),
    }),
    purchase: builder.mutation({
      query: (address) => ({
        url: `/api/rewards/orders`,
        method: "POST",
        body: { ...address },
      }),
    }),
    createCategory: builder.mutation({
      query: (data) => ({
        url: `/api/rewards/categories`,
        method: "POST",
        body: data,
      }),
    }),
    updateCategory: builder.mutation({
      query: ({ data, _id }) => ({
        url: `/api/rewards/categories/${_id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteCategory: builder.mutation({
      query: (categoryId) => ({
        url: `/api/rewards/categories/${categoryId}`,
        method: "DELETE",
      }),
    }),
    getRedeemStatuses: builder.query({
      query: ({ status }) => ({
        url: `/api/rewards/orders?status=${status}`,
        method: "GET",
      }),
    }),
    updateRedeemStatus: builder.mutation({
      query: (data) => ({
        url: `/api/rewards/orders/status`,
        method: "PATCH",
        body: data,
      }),
    }),
    getPointsStatus: builder.query({
      query: () => ({
        url: `/api/user/points/stats`,
        method: "GET",
      }),
    }),
    getRedeemStatus: builder.query({
      query: ({ sortBy, sortType }) => ({
        url: `/api/rewards/orders/my?sortBy=${sortBy || ""}&sortType=${
          sortType || ""
        }`,
        method: "GET",
      }),
    }),
    addCart: builder.mutation({
      query: ({ productId, newQuantity }) => ({
        url: `/api/rewards/cart/item/${productId}`,
        method: "POST",
        body: { ...newQuantity },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled, q }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateCart(data.data));
          // Set redux login state.
        } catch (error) {
          // console.error("error", error);
        }
      },
    }),
    clearCartProduct: builder.mutation({
      query: () => ({
        url: `/api/rewards/cart/clear`,
        method: "DELETE",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled, q }) {
        try {
          dispatch(clearCart());
        } catch (error) {
          console.error("error", error);
        }
      },
    }),
    removeCartProduct: builder.mutation({
      query: (productId) => ({
        url: `/api/rewards/cart/item/${productId}`,
        method: "DELETE",
      }),

      async onQueryStarted(arg, { dispatch, queryFulfilled, q }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(updateCart(data.data));
        } catch (error) {
          console.error("error", error);
        }
      },
    }),
    getUserCart: builder.query({
      query: () => ({
        url: `/api/rewards/cart`,
        method: "GET",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled, q }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(updateCart(data.data));
        } catch (error) {
          console.error("error", error);
        }
      },
    }),
  }),
});

export const {
  useRemoveCartProductMutation,
  useGetProductsQuery,
  useGetAllProductsQuery,
  useGetCategoriesQuery,
  useGetRedeemStatusesQuery,
  useGetAllProductsEmployeeQuery,
  useCreateProductMutation,
  useUpdateProductMutation,
  useUpdateCategoryMutation,
  useCreateCategoryMutation,
  useDeleteProductMutation,
  useDeleteCategoryMutation,
  useUpdateRedeemStatusMutation,
  useGetRedeemStatusQuery,
  useAddCartMutation,
  useGetUserCartQuery,
  usePurchaseMutation,
  useGetEmployeeQuery,
  useGetPointsStatusQuery,
  useClearCartProductMutation,
} = rewardsApiSlice;
