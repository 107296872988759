import { useNavigate, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  selectCurrentToken,
  selectCurrentError,
} from "../../redux-toolkit/features/auth/authSlice";
import { useLoginMutation } from "../../redux-toolkit/features/auth/authApiSlice";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import "./Login.css";
import React from 'react';

export const RequireAuth = () => {
  const token = useSelector(selectCurrentToken);
  const error = useSelector(selectCurrentError);
  const navigate = useNavigate();

  return (
    <>
      {token === null && !error ? (
        <div className="reqAuth">
          <Box sx={{ width: '30%' }}>
            <LinearProgress />
          </Box>
        </div>
      ) : (
        <>
          {!error && <Navigate to={'/quest'} />}
          {error && <Navigate to={'/login'} />}
        </>
      )}
    </>
  );
};
