import {
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import React, { useEffect, useState } from "react";
import { SortIcon } from "../../Manage Organization/Employee/EmployeeTable";
import {
  useGetRedeemStatusesQuery,
  useUpdateRedeemStatusMutation,
} from "../../../../redux-toolkit/features/rewards/rewardsApiSlice";
import dayjs from "dayjs";
import { useAlert } from "../../../../hooks/useAlert";

const RedeemStatusTable = ({ status, onDataChange }) => {
  const { displayAlert } = useAlert();

  const [sortType, setSortType] = useState("asc");
  const [selectedOrderProducts, setSelectedOrderProducts] = useState([]);
  const [updateRedeemStatus] = useUpdateRedeemStatusMutation();
  const { data, refetch } = useGetRedeemStatusesQuery(
    {
      status,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const handleStatusUpdate = async () => {
    try {
      const data = selectedOrderProducts?.map((item) => ({
        status: 'completed',
        itemId: item.items._id,
        orderId: item._id,
      }));

      await updateRedeemStatus(data);
      refetch();
      setSelectedOrderProducts([]);
      displayAlert({
        open: true,
        message: `Successfully approved the redeem request`,
        type: 'success',
        timeout: '2500',
      });
    } catch (error) {
      displayAlert({
        open: true,
        message: `Something went wrong while updating the status`,
        type: 'error',
        timeout: '2500',
      });
    }
  };

  useEffect(() => {
    if (!data || !data?.data) return;
    onDataChange &&
      onDataChange(
        data?.data.map((d) => ({
          'Employee Name': `${d?.employee?.fname} ${d?.employee?.lname}`,
          'Product points': d?.items?.product?.points || 0,
          'Product name': d?.items?.product?.name || 'N/A',
          'Product quantity': d?.items?.quantity || 0,
          'Requested date': dayjs(d?.createdAt).format('DD/MM/YY'),
        }))
      );
  }, [data]);

  return (
    <>
      <div style={{ width: '100%', overflow: 'scroll' }}>
        <TableContainer
          sx={{
            maxHeight: 'calc( 100vh - 230px)',
            padding: '0 1px',
            width: '1350px',
          }}>
          <Table
            stickyHeader
            aria-label="sticky table">
            <TableHead>
              <TableRow
                sx={{ borderRadius: '15px 15px 0px 0px' }}
                className="tableUi__head">
                {status != 'completed' && (
                  <TableCell
                    sx={{ borderRadius: '15px 0px 0px 0px' }}
                    align="center">
                    Select
                  </TableCell>
                )}
                <TableCell
                  sx={{
                    borderRadius: status === 'completed' && '15px 0px 0px 0px',
                  }}
                  align="center">
                  <div className="tableUi__head_sortable">
                    Employee Name <SortIcon sortOrder={sortType} />
                  </div>
                </TableCell>

                <TableCell align="center"> Product name</TableCell>
                <TableCell align="center">
                  <div className="tableUi__head_sortable">
                    Product points <SortIcon sortOrder={sortType} />
                  </div>
                </TableCell>
                <TableCell align="center">Product quantity</TableCell>

                <TableCell
                  align="center"
                  sx={{ borderRadius: '0px 15px 0px 0px' }}>
                  <div className="tableUi__head_sortable">
                    Requested date
                    <SortIcon sortOrder={sortType} />
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data?.data?.length > 0 ? (
                data.data?.map((order, i) => {
                  return (
                    <React.Fragment key={order?.items?._id}>
                      <div className="row__spacing"></div>
                      <TableRow
                        className="tableUi___body"
                        sx={{ borderRadius: '10px' }}
                        style={{
                          backgroundColor: selectedOrderProducts?.find(
                            (x) => order?.items._id === x.items._id
                          )
                            ? '#3361cf22'
                            : '',
                        }}>
                        {status != 'completed' && (
                          <TableCell align="center">
                            <div className="tableUi__rankscir">
                              <div
                                style={{
                                  display: 'inline-flex',
                                  alignItems: 'center',
                                }}>
                                {status === 'pending' ? (
                                  <Checkbox
                                    sx={{
                                      '& .MuiSvgIcon-root': { fontSize: 18 },
                                    }}
                                    checked={selectedOrderProducts?.some(
                                      (or) => or.items._id === order?.items._id
                                    )}
                                    onChange={(_, checked) => {
                                      if (checked) {
                                        setSelectedOrderProducts([
                                          ...selectedOrderProducts,
                                          order,
                                        ]);
                                      } else {
                                        setSelectedOrderProducts(
                                          selectedOrderProducts.filter((x) => {
                                            return (
                                              order?.items._id !== x.items._id
                                            );
                                          })
                                        );
                                      }
                                    }}
                                  />
                                ) : null}
                              </div>
                            </div>
                          </TableCell>
                        )}
                        <TableCell align="center">
                          {order?.employee?.fname} {order?.employee?.lname}
                        </TableCell>
                        <TableCell align="center">
                          {order?.items?.product?.name || 'N/A'}
                        </TableCell>
                        <TableCell align="center">
                          <div
                            style={{
                              display: 'inline-flex',
                              alignItems: 'center',
                              gap: '4px',
                            }}>
                            <StarBorderOutlinedIcon
                              sx={{ color: 'var(--pmain)', fontSize: '20px' }}
                            />{' '}
                            {order?.items?.product?.points || 0}
                          </div>
                        </TableCell>
                        <TableCell align="center">
                          {order?.items?.quantity || 0}
                        </TableCell>

                        <TableCell align="center">
                          {dayjs(order?.createdAt).format('DD/MM/YY')}
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })
              ) : (
                <>
                  <div className="row__spacing"></div>
                  <TableRow
                    className="tableUi___body"
                    sx={{ borderRadius: '10px' }}>
                    <TableCell align="center"></TableCell>

                    <TableCell align="center"></TableCell>
                    <TableCell
                      align="center"
                      style={{ color: 'gray' }}>
                      - No {status} orders -
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {status === 'pending' ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '24px 0',
          }}>
          <Button
            disabled={!selectedOrderProducts.length}
            onClick={handleStatusUpdate}
            variant="contained">
            Approve
          </Button>
        </div>
      ) : null}
    </>
  );
};

export default RedeemStatusTable;
