export const Toggle = ({
  isOn,
  handleToggle,
  activeColor = "var(--success)",
  inActiveColor = "var(--grey200)",
  id,
  inputProps,
}) => {
  if (!id) return <p style={{ color: "red" }}>Provide id prop</p>;
  return (
    <>
      <input
        checked={isOn}
        onChange={handleToggle}
        className="switch-checkbox"
        id={id}
        type="checkbox"
        {...inputProps}
      />
      <label
        style={{ background: isOn ? activeColor : inActiveColor }}
        className="switch-label"
        htmlFor={id}
      >
        <span className={`switch-button`} />
      </label>
    </>
  );
};
