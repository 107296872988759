import React from "react";
import { BreadcrumbAdmin } from "../../UI/BreadcrumbAdmin";
import "./Cart.css";
import CartTable from "./CartTable";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import Checkout from "./Checkout";
import {
  useGetPointsStatusQuery,
  useGetUserCartQuery,
} from '../../../redux-toolkit/features/rewards/rewardsApiSlice';
const base = [
  {
    _id: 1,
    link: '/quest/rewards',
    label: 'Rewards',
  },
];
const Cart = () => {
  const { data: cart, refetch: refetchCart } = useGetUserCartQuery({
    refetchOnMountOrArgChange: true,
  });
  return (
    <div className="container__mact">
      <div className="bg__rewards">
        <div className="cart">
          <div className="container">
            <BreadcrumbAdmin
              base={base}
              page={'Cart'}
            />
            <div className="cart__layout">
              <div className="cart__leftLayout">
                <div className="cart__left">
                  <CartTable />
                </div>

                <div className="cart__leftAction">
                  <Link
                    to={'/quest/rewards'}
                    style={{ textDecoration: 'none' }}>
                    <Button
                      sx={{
                        color: 'gray',
                        borderColor: 'gray',
                        marginRight: '16px',

                        '&:hover': { color: 'var(--pblue)' },
                      }}
                      variant="outlined">
                      Add more items
                    </Button>{' '}
                  </Link>
                </div>
              </div>
              <div className="cart__right">
                <Checkout />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
