import React from "react";
import NoFileSvg from "../../../assets/No files available.svg";
import "./NoRecord.css";
export const NoRecord = () => {
  return (
    <div className="noRecord">
      <img src={NoFileSvg} alt="" />
      <h3 className="ftwt__r">No Records Found</h3>
    </div>
  );
};
