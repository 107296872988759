import React, { useState } from "react";
import bg from "../../assets/Login Page/Desktop/Login Page _ Desktop Background.svg";
import bgmb from "../../assets/Login Page/Mobile/Login Page _ Mobile Background.svg";
import logo from "../../assets/Login Page/Desktop/Logo.png";
import artwork1 from "../../assets/Login Page/Desktop/Artwork 1.png";
import artwork2 from "../../assets/Login Page/Desktop/Artwork 2.png";
import "./Login.css";
import Grid from "@mui/material/Grid";
import { LoginForm } from "./LoginForm";
import { Fpassword } from "./Fpassword";
import { Respassword } from "./Respassword";
import { useLocation, useParams } from "react-router-dom";
import { Notify } from "../UI/Notify";
import {
  logoutAuth,
  selectCurrentError,
} from '../../redux-toolkit/features/auth/authSlice';
import { useDispatch, useSelector } from "react-redux";
import { errormsg } from "../../redux-toolkit/features/auth/authSlice";
import { useAlert } from "../../hooks/useAlert";
import Countdown from "react-countdown";
import { useEffect } from 'react';
import { logoutTheme } from '../../redux-toolkit/features/theme/themeSlice';

export const Login = ({ onLoginSuccess }) => {
  const { displayAlert } = useAlert();

  const location = useLocation();
  const dispatch = useDispatch();
  const { id, token } = useParams();
  const [status, setStatus] = useState(true);

  useEffect(() => {
    dispatch(logoutTheme());
    dispatch(logoutAuth());
  }, []);
  return (
    <>
      <div className="login">
        <img
          src={bg}
          className="login__img disableTablet"
          alt=""
        />
        <img
          src={bgmb}
          className="login__img disableDesktop"
          alt=""
        />
        <div className="login__overlay">
          <div className="containerlogin">
            <Grid
              container
              alignItems="center"
              justifyContent="center">
              <Grid
                className="disableTablet"
                item
                lg={6}>
                <div className="login__left">
                  <h1>
                    <i>
                      Welcome to the <br /> Growth Community
                    </i>
                  </h1>
                  <img
                    src={artwork1}
                    className="login__leftimg"
                    width="60%"
                    alt="artwork1"
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={6}>
                <div className="login__right">
                  <img
                    src={logo}
                    className="login__rightimg"
                    width="32%"
                    alt="quest logo"
                  />
                  {location.pathname === '/login' && (
                    <LoginForm
                      onLoginSuccess={onLoginSuccess}
                      setStatus={setStatus}
                    />
                  )}
                  {(location.pathname === '/forgot-password' ||
                    location.pathname === '/send-mail') && (
                    <Fpassword setStatus={setStatus} />
                  )}
                  {location.pathname === `/reset-password/${id}/${token}` && (
                    <Respassword token={token} />
                  )}
                </div>
                <img
                  src={artwork2}
                  className="login__leftartwork"
                  width="70px"
                  alt="quest logo"
                />
              </Grid>
              <p className="login__powered">
                Powered by &nbsp;
                <a
                  href="https://www.schbang-q.com"
                  rel="noreferrer"
                  target="_blank">
                  <b>SchbangQ</b>
                </a>
              </p>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};
