import { io } from "socket.io-client";

let socket = null;

const setupSocket = () => {
  if (socket) return socket;

  const token = JSON.parse(
    JSON.parse(localStorage.getItem("persist:root"))?.auth
  )?.token;
  if (!token) return null;
  const SOCKET_URL =
    process.env.REACT_APP_CLIENT_ENV === "development"
      ? process.env.REACT_APP_LOCAL_SOCKET_URL
      : process.env.REACT_APP_SERVER_SOCKET_URL;
  socket = io(SOCKET_URL, {
    transports: ["websocket"],
    auth: {
      token: token,
    },
  });

  socket.on("connect", () => {
    // console.log(`Socket connection established #${socket?.id}`);
  });

  socket.on("disconnect", (reason) => {
    if (reason === 'io server disconnect') {
      return socket?.connect();
    }
    // console.log(`Socket connection closed #${reason}`);
  });

  return socket;
};

export default setupSocket;
