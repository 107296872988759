import { apiSlice } from "../../api/apiSlice";
import { setAllUsers } from "./departmentSlice";

export const departmentApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createDepartment: builder.mutation({
      query: (data) => ({
        url: "/api/department",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Department"],
    }),
    getAllDepartments: builder.query({
      query: () => ({
        url: "/api/department",
        method: "GET",
      }),
      providesTags: ["Department"],
    }),
    deleteDepartment: builder.mutation({
      query: (departmentId) => ({
        url: `/api/department/${departmentId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Department"],
    }),
    updateDepartment: builder.mutation({
      query: ({ formData, departmentId }) => ({
        url: `/api/department/${departmentId}`,
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["Department"],
    }),
    getDepartmentById: builder.query({
      query: (departmentId) => ({
        url: `/api/department/${departmentId}`,
        method: "GET",
      }),
      providesTags: ["Department"],
    }),
    getCoursesByDepartment: builder.query({
      query: (departmentId) => ({
        url: `/api/lms/course/department/${departmentId}`,
        method: "GET",
      }),
    }),
    getUsersByDepartment: builder.query({
      query: (departmentId) => ({
        url: `/api/user/department/${departmentId}`,
        method: "GET",
      }),
    }),
    getDepartmentHOD: builder.query({
      query: (departmentId) => ({
        url: `/api/user/department/hod/${departmentId}`,
        method: "GET",
      }),
    }),
    getDepartmentHOD: builder.query({
      query: (departmentId) => ({
        url: `/api/user/department/hod/${departmentId}`,
        method: "GET",
      }),
    }),
    getAllUsersDept: builder.query({
      query: () => ({
        url: `/api/user`,
        method: "GET",
      }),
    }),
    getAllUsers: builder.query({
      query: (data) => {
        return {
          url: `/api/user?sort_by=${data?.sortBy || "createdAt"}&type=${
            data?.type || "desc"
          }&query=${encodeURIComponent(data.query)}`,
          method: "GET",
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled, q }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAllUsers(data));
          // Set redux login state.
        } catch (error) {
          console.error("error", error);
        }
      },
    }),
  }),
});

export const {
  useCreateDepartmentMutation,
  useGetAllUsersQuery,
  useGetAllUsersDeptQuery,
  useGetAllDepartmentsQuery,
  useGetCoursesByDepartmentQuery,
  useGetDepartmentHODQuery,
  useGetUsersByDepartmentQuery,
  useDeleteDepartmentMutation,
  useGetDepartmentByIdQuery,
  useUpdateDepartmentMutation,
} = departmentApiSlice;
