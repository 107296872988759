import { Autocomplete, Button, TextField } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";

import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import countries from "../../../assets/json/countries.json";
import { useAlert } from "../../../hooks/useAlert";

import useAuth from "../../../hooks/useAuth";
import {
  useGetProfileQuery,
  useUpdateProfileMutation,
} from "../../../redux-toolkit/features/auth/authApiSlice";
import "./Personal.css";
import { setName } from "../../../redux-toolkit/features/auth/authSlice";
import { useDispatch } from "react-redux";

const FormInput = ({ label, required, ...props }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
      }}
    >
      <h5 className="ftwt__r">
        {label}{" "}
        {required ? (
          <span
            style={{
              color: "red",
            }}
          >
            *
          </span>
        ) : null}
      </h5>
      <TextField {...props} />
    </div>
  );
};

const FormSelect = ({
  label,
  required,
  options,
  inputProps,
  containerStyle,
  optionAction,
  ...props
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        ...containerStyle,
      }}
    >
      <h5 className="ftwt__r">
        {label}{" "}
        {required ? (
          <span
            style={{
              color: "red",
            }}
          >
            *
          </span>
        ) : null}
      </h5>
      <Autocomplete
        id="navbar-search"
        isOptionEqualToValue={(op, val) => {
          return op?.value?.toLowerCase() === val?.value?.toLowerCase();
        }}
        options={options}
        renderOption={(props, option) => (
          <li style={{ fontSize: "14px" }} {...props}>
            {option.label}
            {optionAction ? optionAction(option) : null}
          </li>
        )}
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
              }}
              {...inputProps}
              sx={{ borderRadius: "2px" }}
              className="personal__select"
            />
          </>
        )}
        {...props}
      />
    </div>
  );
};

const validationSchema = yup.object({
  fname: yup
    .string("Enter first name")
    .min(1, "Enter first name")
    .required("First name is required"),
  email: yup
    .string("Enter Email")
    .email("Email is invalid")
    .min(1, "Enter Email")
    .required("Email is required"),
  lname: yup
    .string("Enter last name")
    .min(1, "Enter last name")
    .required("Last name is required"),
  department: yup.string("Enter department").required("Department is required"),
  designation: yup
    .string("Enter designation")
    .required("Designation is required"),
  doj: yup
    .date("Enter date of joining")
    .required("Date of joining is required"),
});

export const Personal = ({ refetchProfile }) => {
  const { displayAlert } = useAlert();
  const authObj = useAuth();
  const dispatch = useDispatch();
  const [refetchEmp, setRefetchEmp] = useState(false);

  const [employeeToBeEdited] = useState(authObj?.id || null);

  const { data: employeeData, refetch: refetchEmployeeById } =
    useGetProfileQuery({
      skip: employeeToBeEdited === null,
      refetchOnMountOrArgChange: true,
    });

  const [updateProfile] = useUpdateProfileMutation();

  const [phoneError, setPhoneError] = useState("");

  const calculateTenure = () => {
    const today = new Date();

    const doj = new Date(formik.values.doj);

    const tenure =
      today.getMonth() -
      doj.getMonth() +
      12 * (today.getFullYear() - doj.getFullYear());
    const years = Math.floor(tenure / 12);

    formik.setFieldValue(
      "tenure",
      years > 0
        ? `${years} Year${years > 1 ? "s" : ""} ${tenure - years * 12} months`
        : `${tenure > 0 ? tenure : 1} month`
    );
  };

  const formik = useFormik({
    initialValues: {
      fname: "",
      email: "",
      lname: "",
      dob: "",
      mobile: "",
      department: "",
      location: "",
      designation: "",
      doj: "",
      tenure: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        if (phoneError) {
          return;
        }
        if (!!employeeToBeEdited) {
          await updateProfile({
            fname: values.fname,
            lname: values.lname,
            email: values.email,
            doj: values.doj,
            dob: values.dob,
            designation: values.designation,
            location: values.location,
            mobile: values?.mobile?.toString() || "",
          })
            .unwrap()
            .then((res) => {
              dispatch(setName({ fname: values.fname, lname: values.lname }));
              displayAlert({
                open: true,
                message: res?.data?.message || `Profile Updated Successfully!`,
                type: "success",
                timeout: "1500",
              });
              refetchProfile && refetchProfile();
              setRefetchEmp(!refetchEmp);
            })
            .catch((err) => {
              displayAlert({
                open: true,
                message:
                  err?.data?.message || `Error while updating the profile`,
                type: "error",
                timeout: "1500",
              });
            });
        } else {
          displayAlert({
            open: true,
            message: `Error while updating the profile`,
            type: "error",
            timeout: "1500",
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const checkPhoneError = () => {
    try {
      if (formik.values.mobile && !isValidPhoneNumber(formik.values.mobile)) {
        setPhoneError("Enter a valid phone");
      } else {
        setPhoneError("");
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (!formik.values.mobile) return;
    checkPhoneError();
  }, [formik.values.mobile]);

  useEffect(() => {
    if (!formik.values.doj) return;
    calculateTenure();
  }, [formik.values.doj]);

  useEffect(() => {
    refetchEmployeeById();
  }, [employeeToBeEdited]);

  useEffect(() => {
    if (employeeData && employeeToBeEdited !== null) {
      formik.setValues({
        ...employeeData?.data,
        dob: employeeData?.data?.dob?.split("T")[0] ?? "",
        doj: employeeData?.data?.doj?.split("T")[0] ?? "",
      });
    } else {
      formik.resetForm();
    }
  }, [employeeData, employeeToBeEdited]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="personal__form_container">
        <FormInput
          label={"First name"}
          required
          id="outlined-basic"
          variant="outlined"
          fullWidth
          name="fname"
          value={formik.values.fname}
          onChange={formik.handleChange}
          error={formik.touched.fname && Boolean(formik.errors.fname)}
          helperText={formik.touched.fname && formik.errors.fname}
        />

        <FormInput
          label={"Last name"}
          required
          id="outlined-basic"
          variant="outlined"
          fullWidth
          name="lname"
          value={formik.values.lname}
          onChange={formik.handleChange}
          error={formik.touched.lname && Boolean(formik.errors.lname)}
          helperText={formik.touched.lname && formik.errors.lname}
        />

        <FormInput
          label={"Email ID"}
          type={"email"}
          required
          id="outlined-basic"
          variant="outlined"
          fullWidth
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />

        <FormInput
          label={"Department"}
          type={"text"}
          required
          disabled
          id="outlined-basic"
          variant="outlined"
          fullWidth
          name="department"
          value={formik.values.department}
          onChange={formik.handleChange}
          error={formik.touched.department && Boolean(formik.errors.department)}
          helperText={formik.touched.department && formik.errors.department}
        />
        <FormInput
          label={"Designation"}
          required
          id="outlined-basic"
          variant="outlined"
          fullWidth
          name="designation"
          value={formik.values.designation}
          onChange={formik.handleChange}
          error={
            formik.touched.designation && Boolean(formik.errors.designation)
          }
          helperText={formik.touched.designation && formik.errors.designation}
        />
        <FormInput
          label={"Date of Joining"}
          required
          id="outlined-basic"
          variant="outlined"
          fullWidth
          name="doj"
          value={formik.values.doj}
          onChange={formik.handleChange}
          error={formik.touched.doj && Boolean(formik.errors.doj)}
          helperText={formik.touched.doj && formik.errors.doj}
          type="date"
        />
        <FormInput
          label={"Date of Birth"}
          id="outlined-basic"
          variant="outlined"
          fullWidth
          name="dob"
          value={formik.values.dob}
          onChange={formik.handleChange}
          error={formik.touched.dob && Boolean(formik.errors.dob)}
          helperText={formik.touched.dob && formik.errors.dob}
          type="date"
          max={new Date()}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <h5 className="ftwt__r">Telephone</h5>

          <PhoneInput
            defaultCountry="IN"
            placeholder="Enter phone number"
            name="mobile"
            value={formik.values.mobile}
            onChange={(val) => {
              if (!val) {
                setPhoneError("");
              }
              formik.setFieldValue("mobile", val);
            }}
            className="personal__phone_input"
            style={{
              border: phoneError ? "1px solid #d32f2f" : "",
            }}
          />
          <small
            style={{
              fontSize: "0.75rem",
              color: "#d32f2f",
              margin: "3px 14px 0",
            }}
          >
            {phoneError}
          </small>
        </div>

        <FormSelect
          label={"Location"}
          id="outlined-basic"
          variant="outlined"
          fullWidth
          name="location"
          value={{
            label: formik.values.location,
            value: formik.values.location,
          }}
          onChange={(_, val) => {
            formik.setFieldValue("location", val?.value || "");
          }}
          inputProps={{
            error: formik.touched.location && Boolean(formik.errors.location),
            helperText: formik.touched.location && formik.errors.location,
          }}
          options={countries}
        />
        <FormInput
          label={"Tenure"}
          required
          id="outlined-basic"
          variant="outlined"
          fullWidth
          name="tenure"
          disabled
          value={formik.values.tenure}
          onChange={formik.handleChange}
          error={formik.touched.tenure && Boolean(formik.errors.tenure)}
          helperText={formik.touched.tenure && formik.errors.tenure}
        />
      </div>

      <div className="personal__action_cont">
        <Button
          type="submit"
          variant="contained"
          onClick={() => {
            checkPhoneError();
          }}
        >
          Save changes
        </Button>
      </div>
    </form>
  );
};
