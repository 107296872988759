import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Modal,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { Search } from "../../../UI/Search/Search";

import { Download } from "@mui/icons-material";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import dragDropSvg from "../../../../assets/Icons/SuperAdmin Icons/SuperAdmin _ Manage Organisation/DragDrop.svg";
import countries from "../../../../assets/json/countries.json";
import { useAlert } from "../../../../hooks/useAlert";
import {
  useGetAllDepartmentsQuery,
  useGetDepartmentHODQuery,
} from "../../../../redux-toolkit/features/department/departmentApiSlice";
import {
  useAddEmployeeMutation,
  useBulkUploadMutation,
  useGetAllRolesQuery,
  useGetEmployeeByIdQuery,
  useUpdateEmployeeMutation,
} from "../../../../redux-toolkit/features/employee/employeeApiSlice";
import { debounce } from "../../../../utils";
import "./Employee.css";

import rightArrow from "../../../../assets/Icons/arrow.svg";

import { EmployeeTable } from "./EmployeeTable";
import { AccessRoles } from "./AccessModal/AccessRoles";
import { useGetAllAccessQuery } from "../../../../redux-toolkit/features/access/accessApiSlice";
import { useSelector } from "react-redux";
import { AssignMultiple } from "./AccessModal/AssignMultiple";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const boxStyling = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  right: '50%',
  maxHeight: '85vh',
  overflowY: 'auto',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: 3,
  boxShadow: 24,
  p: '25px 40px',
};

const roleStyling = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  right: '50%',
  height: '100%',
  maxHeight: '530px',
  overflowY: 'auto',
  maxWidth: '40vw',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: 3,
  boxShadow: 24,
  p: '40px',
};

const FormInput = ({ label, required, ...props }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
      }}
    >
      <h5 className="ftwt__r">
        {label}{" "}
        {required ? (
          <span
            style={{
              color: "red",
            }}
          >
            *
          </span>
        ) : null}
      </h5>
      <TextField {...props} />
    </div>
  );
};

const FormSelect = ({
  label,
  required,
  options,
  inputProps,
  containerStyle,
  optionAction,
  ...props
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        ...containerStyle,
      }}
    >
      <h5 className="ftwt__r">
        {label}{" "}
        {required ? (
          <span
            style={{
              color: "red",
            }}
          >
            *
          </span>
        ) : null}
      </h5>
      <Autocomplete
        id="navbar-search"
        isOptionEqualToValue={(op, val) => {
          return op?.value?.toLowerCase() === val?.value?.toLowerCase();
        }}
        options={options}
        renderOption={(props, option) => (
          <li style={{ fontSize: "14px" }} {...props}>
            {option.label}
            {/* Add action in the option from the props */}
            {optionAction ? optionAction(option) : null}
          </li>
        )}
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
              }}
              {...inputProps}
              sx={{ borderRadius: "2px" }}
            />
          </>
        )}
        {...props}
      />
    </div>
  );
};

const validationSchema = yup.object({
  fname: yup
    .string("Enter first name")
    .min(1, "Enter first name")
    .required("First name is required"),
  email: yup
    .string("Enter Email")
    .email("Email is invalid")
    .min(1, "Enter Email")
    .required("Email is required"),
  lname: yup
    .string("Enter last name")
    .min(1, "Enter last name")
    .required("Last name is required"),
  department: yup.string("Enter department").required("Department is required"),
  designation: yup
    .string("Enter designation")
    .required("Designation is required"),
  doj: yup
    .date("Enter date of joining")
    .required("Date of joining is required"),
  // TODO: enable once RBAC is configured
  // assignRole: yup.string("Enter assignRole").required("assignRole is required"),
  // permissions: yup
  //   .string("Enter permissions")
  //   .required("permissions is required"),
});

export const Employee = () => {
  const { displayAlert } = useAlert();

  const [openCreateEmployee, setOpenCreateEmployee] = useState(false);
  const [openBulkUpload, setOpenBulkUpload] = useState(false);
  const [permissionsToBeViewed, setPermissionsToBeViewed] = useState(null);
  const [openAssignRole, setOpenAssignRole] = useState(false);

  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [refetchEmp, setRefetchEmp] = useState(false);
  const [accessOpen, setAccessOpen] = useState(false);
  const { data: roles } = useGetAllRolesQuery();
  const [selectedEmployee, setSelectedEmployee] = useState([]);

  const [employeeToBeEdited, setEmployeeToBeEdited] = useState(null);

  const [openManageEmployeeSelect, setOpenManageEmployeeSelect] =
    useState(false);

  const [bulkUploadInDepartment, setBulkUploadInDepartment] = useState({
    label: "",
    value: "",
  });
  const [selectedBulkUploadFile, setSelectedBulkUploadFile] = useState(null);

  const { data: departments, isLoading, isError } = useGetAllDepartmentsQuery();
  const [permissions, setPermissions] = useState([]);
  const [isCustom, setIsCustom] = useState(false);
  const [assignedRole, setAssignedRole] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const {
    data: employeeData,
    isLoading: empLoading,
    refetch: refetchEmployeeById,
  } = useGetEmployeeByIdQuery(employeeToBeEdited, {
    skip: employeeToBeEdited === null,
    refetchOnMountOrArgChange: true,
  });

  const { data: departmentHod, refetch: refetchDepartmentHOD } =
    useGetDepartmentHODQuery(selectedDepartment, {
      skip: selectedDepartment === null,
    });

  const [addEmployee] = useAddEmployeeMutation();
  const [updateEmployee] = useUpdateEmployeeMutation();
  const [bulkUpload] = useBulkUploadMutation();

  // some issue with the formik.setFieldError() so used local state for the error
  const [phoneError, setPhoneError] = useState("");

  const onPermissionSetClick = (permission) => {
    setPermissionsToBeViewed(permission);
  };

  const handlePermissionModalClose = () => {
    setPermissionsToBeViewed(null);
  };

  const handleAssignModalClose = () => {
    setOpenAssignRole(false);
  };

  const handleClose = async () => {
    formik.handleReset();
    await setPermissions([]);
    setOpenBulkUpload(false);
    setPhoneError("");

    setEmployeeToBeEdited(null);

    setBulkUploadInDepartment({
      label: "",
      value: "",
    });
    setSelectedBulkUploadFile(null);
    setOpenCreateEmployee(false);
  };

  // React.useEffect(() => {
  //   if (employeeData?.data?.permissionSets) {
  //     setPermissions(employeeData?.data?.selectedPermissions);
  //   }
  // }, [employeeData]);
  const calculateTenure = () => {
    const today = new Date();

    const doj = new Date(formik.values.doj);

    const tenure =
      today.getMonth() -
      doj.getMonth() +
      12 * (today.getFullYear() - doj.getFullYear());
    const years = Math.floor(tenure / 12);

    formik.setFieldValue(
      "tenure",
      years > 0
        ? `${years} Year${years > 1 ? "s" : ""} ${tenure - years * 12} months`
        : `${tenure > 0 ? tenure : 1} month`
    );
  };
  const formik = useFormik({
    initialValues: {
      fname: "",
      email: "",
      lname: "",
      dob: "",
      mobile: "",
      department: "",
      location: "",
      designation: "",
      reportsTo: "",
      doj: "",
      tenure: "",
      assignRole: "",
      permissions: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        if (!!employeeToBeEdited) {
          await updateEmployee({
            body: {
              ...values,
              mobile: values?.mobile?.toString() || "",
              reportsTo: undefined,
              permissions: permissions,
              isCustom: isCustom,
            },
            id: employeeToBeEdited,
          })
            .unwrap()
            .then((res) => {
              displayAlert({
                open: true,
                message: `Employee Updated Successfully!`,
                type: "success",
                timeout: "1500",
              });
              setRefetchEmp(!refetchEmp);
              setPermissions([]);

              handleClose();
            })
            .catch((err) => {
              displayAlert({
                open: true,
                message:
                  err?.data?.message || `Error while updating the employee`,
                type: "error",
                timeout: "1500",
              });
            });
        } else {
          await addEmployee({
            ...values,
            mobile: values?.mobile?.toString() || "",
          })
            .unwrap()
            .then((res) => {
              displayAlert({
                open: true,
                message: `New Employee Added Successfully!`,
                type: "success",
                timeout: "1500",
              });
              setRefetchEmp(!refetchEmp);
              setPermissions([]);
              handleClose();
            })
            .catch((err) => {
              displayAlert({
                open: true,
                message:
                  err?.data?.message || `Error while creating the employee`,
                type: "error",
                timeout: "1500",
              });
            });
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleBulkUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    await bulkUpload({
      formData,
      departmentId: bulkUploadInDepartment?.value,
    })
      .unwrap()
      .then((res) => {
        setRefetchEmp(!refetchEmp);
        setOpenBulkUpload(false);

        displayAlert({
          open: true,
          message: `Bulk upload successful!`,
          type: "success",
          timeout: "1500",
        });
      })
      .catch((err) => {
        displayAlert({
          open: true,
          message:
            err?.data?.message || `Something went wrong while bulk uploading`,
          type: "error",
          timeout: "6000",
        });
      });
  };

  const checkPhoneError = () => {
    try {
      if (formik.values.mobile && !isValidPhoneNumber(formik.values.mobile)) {
        setPhoneError("Enter a valid phone");
      } else {
        setPhoneError("");
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (!formik.values.mobile) return;
    checkPhoneError();
  }, [formik.values.mobile]);

  useEffect(() => {
    if (!formik.values.doj) return;
    calculateTenure();
  }, [formik.values.doj]);

  useEffect(() => {
    refetchDepartmentHOD();
  }, [selectedDepartment]);

  useEffect(() => {
    refetchEmployeeById();
  }, [employeeToBeEdited]);

  useEffect(() => {
    setIsCustom(false);
    setOpenCreateEmployee(!!employeeToBeEdited);
    if (employeeData && employeeToBeEdited != null) {
      formik.setValues({
        ...employeeData?.data,
        dob: employeeData?.data?.dob?.split("T")[0] ?? "",
        doj: employeeData?.data?.doj?.split("T")[0] ?? "",
      });
      setSelectedDepartment(employeeData?.data?.department);
    } else {
      formik.resetForm();
      setSelectedDepartment(null);
    }

    if (
      employeeData?.data?.permissionSets?.name === "ADMIN" ||
      employeeData?.data?.permissionSets?.name === "EMPLOYEE" ||
      employeeData?.data?.permissionSets?.name === "SUPERADMIN"
    ) {
      formik.setFieldValue(
        "assignRole",
        roles?.data.filter(
          (item) => item.label === employeeData?.data?.permissionSets?.name
        )[0]
      );
    } else {
      formik.setFieldValue(
        "assignRole",
        roles?.data.filter((item) => item.label === "CUSTOMROLE")[0]
      );
      setIsCustom(true);
    }

    // if (employeeData?.data?.permissionSets?.name === "EMPLOYEE") {

    //   formik.setFieldValue("assignRole", "Employee");
    // } else if (employeeData?.data?.permissionSets?.name === "ADMIN") {

    //   formik.setFieldValue("assignRole", "Admin");
    // } else if (employeeData?.data?.permissionSets?.name === "SUPERADMIN") {

    //   formik.setFieldValue("assignRole", "Superadmin");
    // } else {
    //   setIsCustom(true);
    //   formik.setFieldValue("assignRole", "Multiple Roles");
    // }
  }, [employeeData, employeeToBeEdited]);

  React.useEffect(() => {}, [formik.values.assignRole]);

  const setDebouncedSearchQuery = useCallback(
    debounce((value) => {
      setSearchQuery(value);
    }, 500),
    []
  );
  const roleHandler = (event) => {
    formik.setFieldValue("assignRole", event.target.value);
    if (event.target.value.label === "CUSTOMROLE") {
      setIsCustom(true);
      setPermissions([]);
    } else if (event.target.value.label === "EMPLOYEE") {
      setIsCustom(false);
      setPermissions([]);
    } else {
      setIsCustom(false);
      setPermissions([event.target.value.value]);
    }
  };

  return (
    <div className="container">
      <Modal
        open={!!permissionsToBeViewed}
        onClose={handlePermissionModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box
          sx={{
            ...boxStyling,
            width: 1150,
          }}>
          <div className="employee__modal_header">
            <h1
              style={{
                fontSize: '16px',
              }}>
              {permissionsToBeViewed?.label}
            </h1>
            <CloseIcon
              onClick={handlePermissionModalClose}
              sx={{
                fontSize: '18px',
                cursor: 'pointer',
                color: 'var(--grey400)',
                '&:hover': {
                  color: 'var(--error)',
                },
              }}
            />
          </div>
          <div
            style={{
              width: '100%',
              marginTop: '18px',
            }}>
            {permissionsToBeViewed?.roles?.map((curElm, index) => (
              <div
                key={index}
                className="employee__permission_item">
                <Checkbox
                  size="small"
                  disabled
                  checked
                />
                <h5 className="ftwt__r">{curElm.name}</h5>
              </div>
            ))}
          </div>
        </Box>
      </Modal>
      <Modal
        open={openAssignRole}
        onClose={handleAssignModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box
          sx={{
            ...roleStyling,
            width: 1150,
          }}>
          <div className="employee__modal_header">
            <h1
              style={{
                fontSize: '16px',
              }}>
              Assign Role Access
            </h1>
            <CloseIcon
              onClick={handleAssignModalClose}
              sx={{
                fontSize: '18px',
                cursor: 'pointer',
                color: 'var(--grey400)',
                '&:hover': {
                  color: 'var(--error)',
                },
              }}
            />
          </div>

          <AssignMultiple
            setOpenAssignRole={setOpenAssignRole}
            selectedEmployee={selectedEmployee}
            setSelectedEmployee={setSelectedEmployee}
          />
        </Box>
      </Modal>
      <Modal
        open={openBulkUpload}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box
          sx={{
            ...boxStyling,
            width: 820,
          }}>
          <div className="employee__modal_header">
            <h1
              style={{
                fontSize: '16px',
              }}>
              Bulk upload users
            </h1>
            <CloseIcon
              onClick={handleClose}
              sx={{
                fontSize: '18px',
                cursor: 'pointer',
                color: 'var(--grey400)',
                '&:hover': {
                  color: 'var(--error)',
                },
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'end',
              flexDirection: 'column',
              width: '100%',
              marginTop: '20px',
            }}>
            <div className="employee__bulk_upload_action_header">
              <FormSelect
                containerStyle={{
                  width: '40%',
                }}
                label={'Department'}
                required
                id="outlined-basic"
                variant="outlined"
                fullWidth
                name="department"
                value={
                  bulkUploadInDepartment?.value ? bulkUploadInDepartment : null
                }
                onChange={(_, val) => {
                  setBulkUploadInDepartment(val ?? { label: '', value: '' });
                }}
                options={
                  isLoading || !departments?.data?.departments
                    ? []
                    : departments?.data?.departments.map((dep) => ({
                        label: dep?.name,
                        value: dep?._id,
                      }))
                }
              />
              <a
                href="https://quest-static-bucket.s3.ap-south-1.amazonaws.com/sample+file.csv"
                download>
                <Button
                  variant="text"
                  endIcon={<Download color="primary" />}>
                  Download sample file
                </Button>
              </a>
            </div>
            <label
              htmlFor="bulk"
              className="employee__bulk_upload_label"
              onClick={() => {
                if (!bulkUploadInDepartment?.value) {
                  displayAlert({
                    open: true,
                    message: `Please select a department before uploading a file`,
                    type: 'error',
                    timeout: '2000',
                  });
                  return;
                }
              }}
              onDrop={(e) => {
                if (!bulkUploadInDepartment?.value) {
                  displayAlert({
                    open: true,
                    message: `Please select a department before uploading a file`,
                    type: 'error',
                    timeout: '2000',
                  });
                  return;
                }
                e.preventDefault();
                setSelectedBulkUploadFile(e.dataTransfer.files[0]);
              }}
              onDragOver={(e) => e.preventDefault()}>
              <img src={dragDropSvg} />
              {selectedBulkUploadFile ? (
                <>
                  <p className="employee__bulk_upload_label_text">
                    {selectedBulkUploadFile?.name}
                  </p>
                </>
              ) : (
                <>
                  <p className="employee__bulk_upload_label_text">
                    Click to upload (CSV format) &nbsp;
                    <span className="employee__bulk_upload_label_text_sm">
                      or drag and drop here
                    </span>
                  </p>
                  <small className="employee__bulk_upload_label_text_sm">
                    Maximum file size 50MB
                  </small>
                </>
              )}
            </label>

            <input
              id="bulk"
              type={'file'}
              disabled={!bulkUploadInDepartment?.value}
              hidden
              accept="text/csv"
              onChange={(e) => {
                setSelectedBulkUploadFile(e.target.files[0]);
                e.target.value = null;
              }}
            />
          </div>

          <div className="employee__modal_footer">
            <div className="icon-container">
              <Button
                type="submit"
                variant="outlined"
                onClick={handleClose}>
                Cancel
              </Button>
            </div>
            <Button
              disabled={!selectedBulkUploadFile}
              variant="contained"
              onClick={(e) => {
                e.preventDefault();
                handleBulkUpload(selectedBulkUploadFile);
              }}>
              Save
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openCreateEmployee}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <form onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              ...boxStyling,
              width: '85vw',
            }}>
            <div className="employee__modal_header">
              <h1
                style={{
                  fontSize: '16px',
                }}>
                Add employee detail
              </h1>
              <CloseIcon
                onClick={handleClose}
                sx={{
                  fontSize: '18px',
                  cursor: 'pointer',
                  color: 'var(--grey400)',
                  '&:hover': {
                    color: 'var(--error)',
                  },
                }}
              />
            </div>
            <div className="employee__form_container">
              <FormInput
                label={'First name'}
                required
                id="outlined-basic"
                variant="outlined"
                fullWidth
                name="fname"
                value={formik.values.fname}
                onChange={formik.handleChange}
                error={formik.touched.fname && Boolean(formik.errors.fname)}
                helperText={formik.touched.fname && formik.errors.fname}
              />

              <FormInput
                label={'Last name'}
                required
                id="outlined-basic"
                variant="outlined"
                fullWidth
                name="lname"
                value={formik.values.lname}
                onChange={formik.handleChange}
                error={formik.touched.lname && Boolean(formik.errors.lname)}
                helperText={formik.touched.lname && formik.errors.lname}
              />

              <FormInput
                label={'Email ID'}
                type={'email'}
                required
                id="outlined-basic"
                variant="outlined"
                fullWidth
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <FormSelect
                label={'Department'}
                required
                id="outlined-basic"
                variant="outlined"
                fullWidth
                name="department"
                value={
                  formik.values.department
                    ? {
                        label: departments?.data?.departments
                          ? departments?.data?.departments.find(
                              (dep) => dep?._id === formik.values.department
                            )?.name
                          : 'Unknown department',
                        value: formik.values.department,
                      }
                    : null
                }
                onChange={(_, val) => {
                  formik.setFieldValue('reportsTo', '');
                  formik.setFieldValue('department', val?.value || '');
                  setSelectedDepartment(val?.value);
                }}
                inputProps={{
                  error:
                    formik.touched.department &&
                    Boolean(formik.errors.department),
                  helperText:
                    formik.touched.department && formik.errors.department,
                }}
                options={
                  isLoading || !departments?.data?.departments
                    ? []
                    : departments?.data?.departments.map((dep) => ({
                        label: dep?.name,
                        value: dep?._id,
                      }))
                }
              />
              <FormInput
                label={'Designation'}
                required
                id="outlined-basic"
                variant="outlined"
                fullWidth
                name="designation"
                value={formik.values.designation}
                onChange={formik.handleChange}
                error={
                  formik.touched.designation &&
                  Boolean(formik.errors.designation)
                }
                helperText={
                  formik.touched.designation && formik.errors.designation
                }
              />
              <FormInput
                label={'Date of Joining'}
                required
                id="outlined-basic"
                variant="outlined"
                fullWidth
                name="doj"
                value={formik.values.doj}
                onChange={formik.handleChange}
                error={formik.touched.doj && Boolean(formik.errors.doj)}
                helperText={formik.touched.doj && formik.errors.doj}
                type="date"
              />
              <FormInput
                label={'Date of Birth'}
                id="outlined-basic"
                variant="outlined"
                fullWidth
                name="dob"
                value={formik.values.dob}
                onChange={formik.handleChange}
                error={formik.touched.dob && Boolean(formik.errors.dob)}
                helperText={formik.touched.dob && formik.errors.dob}
                type="date"
                max={new Date()}
              />

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                }}>
                <h5 className="ftwt__r">Telephone</h5>

                <PhoneInput
                  defaultCountry="IN"
                  placeholder="Enter phone number"
                  name="mobile"
                  value={formik.values.mobile}
                  onChange={(val) => {
                    if (!val) {
                      setPhoneError('');
                    }
                    formik.setFieldValue('mobile', val);
                  }}
                  className="employee__phone_input"
                  style={{
                    border: phoneError ? '1px solid #d32f2f' : '',
                  }}
                />
                <small
                  style={{
                    fontSize: '0.75rem',
                    color: '#d32f2f',
                    margin: '3px 14px 0',
                  }}>
                  {phoneError}
                </small>
              </div>

              <FormSelect
                label={'Location'}
                id="outlined-basic"
                variant="outlined"
                fullWidth
                disableClearable
                name="location"
                value={{
                  label: formik.values.location,
                  value: formik.values.location,
                }}
                onChange={(_, val) => {
                  formik.setFieldValue('location', val?.value || '');
                }}
                inputProps={{
                  // error:
                  //   formik.touched.location && Boolean(formik.errors.location),
                  helperText: formik.touched.location && formik.errors.location,
                }}
                options={countries}
              />

              {employeeToBeEdited !== null ? (
                <>
                  <FormInput
                    label={'Reporting manager'}
                    id="outlined-basic"
                    variant="outlined"
                    fullWidth
                    disabled
                    name="dob"
                    readOnly
                    value={
                      departmentHod?.data?.fname
                        ? `${departmentHod?.data?.fname} ${departmentHod?.data?.lname}`
                        : departmentHod?.data?.email
                    }
                  />

                  <FormInput
                    label={'Tenure'}
                    // required
                    disabled
                    id="outlined-basic"
                    variant="outlined"
                    fullWidth
                    name="tenure"
                    value={formik.values.tenure}
                  />
                  {/* <FormSelect
                    label={"Assign role"}
                    required
                    id="outlined-basic"
                    id="disable-clearable"
                    disableClearable
                    variant="outlined"
                    fullWidth
                    name="assignRole"
                    sx={{ textTransform: "capitalize" }}
                    value={{
                      label: formik.values.assignRole,
                      value: formik.values.assignRole,
                    }}
                    onChange={roleHandler}
                    inputProps={{
                      error:
                        formik.touched.assignRole &&
                        Boolean(formik.errors.assignRole),
                      helperText:
                        formik.touched.assignRole && formik.errors.assignRole,
                    }}
                    options={roles ? roles?.data : []}
                    getOptionLabel={(option) =>
                      option.label.toLowerCase() != "customrole"
                        ? option.label.toLowerCase()
                        : "Multiple role"
                    }
                    getOptionSelected={(option, value) =>
                      option.value === value.value
                    }
                  /> */}
                  {formik.values.assignRole && (
                    <FormControl sx={{ width: '100%' }}>
                      <h5 className="ftwt__r">Assign Role</h5>
                      <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        name="assignRole"
                        defaultValue={formik.values.assignRole}
                        value={formik.values.assignRole}
                        onChange={roleHandler}
                        inputProps={{
                          error:
                            formik.touched.assignRole &&
                            Boolean(formik.errors.assignRole),
                          helperText:
                            formik.touched.assignRole &&
                            formik.errors.assignRole,
                        }}>
                        {roles?.data.map((item, index) => (
                          <MenuItem
                            sx={{ fontSize: '14px' }}
                            key={index}
                            value={item}>
                            {item.displayName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </>
              ) : null}
            </div>
            {employeeToBeEdited && isCustom && (
              <div>
                <h5 className="ftwt__r">
                  Permissions <span style={{ color: 'var(--error)' }}>*</span>
                </h5>
                <AccessRoles
                  empPermissions={employeeData?.data?.permissionSets}
                  allPermisions={employeeData?.data?.selectedPermissions}
                  setPermissions={setPermissions}
                  permissions={permissions}
                  employeeData={employeeData}
                />
              </div>
            )}
            <div className="employee__modal_footer">
              {/* <div className="icon-container" onClick={handleClose}>
                <img src={DeleteInActiveSvg} height="30px" alt="" />
                <img src={DeleteActiveSvg} height="30px" alt="" />
              </div> */}
              <Button
                type="submit"
                variant="contained"
                onClick={() => {
                  checkPhoneError();
                }}>
                {employeeToBeEdited !== null ? 'Update' : 'Save'}
              </Button>
            </div>
          </Box>
        </form>
      </Modal>
      <div className="employee__page_container">
        <div className="employee__page_header">
          <div>
            <Search
              placehold={'Search...'}
              setSearchVal={(val) => setDebouncedSearchQuery(val)}
            />
          </div>
          <div className="employee__page_actions">
            {/* <Button
              variant="outlined"
              onClick={() => {
                setOpenCreateEmployee(true);
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "var(--pblue)",
                  color: "var(--pwhite)",
                },
              }}
            >
              Add Employee
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setOpenBulkUpload(true);
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "var(--pblue)",
                  color: "var(--pwhite)",
                },
              }}
            >
              Bulk upload
            </Button> */}
            <div className="employee__manage_employee_dd">
              <div
                className={
                  (openManageEmployeeSelect ? 'open' : '') +
                  ' employee__manage_employee_dd_item'
                }>
                <div
                  onClick={() => {
                    setOpenManageEmployeeSelect(!openManageEmployeeSelect);
                  }}
                  className="employee__manage_employee_dd_label">
                  <p value="">Manage employee</p>
                  <div>
                    <img
                      src={rightArrow}
                      style={{ marginBottom: '2px' }}
                      alt=""
                    />
                  </div>
                </div>
                {openManageEmployeeSelect ? (
                  <div className="employee__select_menu">
                    <h1 className="select_divider" />
                    <p
                      onClick={() => {
                        setOpenCreateEmployee(true);
                        setOpenManageEmployeeSelect(false);
                      }}>
                      Add employee
                    </p>
                    <p
                      onClick={() => {
                        setOpenBulkUpload(true);
                        setOpenManageEmployeeSelect(false);
                      }}>
                      Bulk upload
                    </p>
                    <p
                      onClick={() => {
                        setOpenManageEmployeeSelect(false);
                        setOpenAssignRole(true);
                      }}>
                      Assign role
                    </p>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <EmployeeTable
          onEditClick={(id) => {
            setIsCustom(false);
            setEmployeeToBeEdited(id);
          }}
          setSelectedEmployee={setSelectedEmployee}
          selectedEmployee={selectedEmployee}
          refetchData={refetchEmp}
          searchQuery={searchQuery}
        />
      </div>
    </div>
  );
};
