import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Avatar } from "@mui/material";
import { Paper } from "@mui/material";
import Switch from "@mui/material/Switch";
import ProgressBar from "../../../../UI/ProgressBar";
import {
  useGetAdminDeptUserStatisticQuery,
  useGetAdminUserStatisticQuery,
} from "../../../../../redux-toolkit/features/reports/ReportsApiSlice";
import { Error404 } from "../../../../UI/404/Error404";
import { useSelector } from "react-redux";

export const UserStatisticAdminTable = ({
  check,
  getTableData,
  startDate,
  endDate,
}) => {
  const userDepartmentId = useSelector((state) => state.auth.user.departmentId);

  const { data, isError, isLoading } = useGetAdminDeptUserStatisticQuery({
    depId: userDepartmentId,
    startDate,
    endDate,
  });

  React.useEffect(() => {}, [check]);

  React.useEffect(() => {
    if (!data) return;
    getTableData && getTableData(data);
  }, [data]);

  if (isLoading) {
    return <ProgressBar />;
  }

  if (isError) {
    return <Error404 />;
  }
  return (
    <div>
      <TableContainer
        sx={{
          height: "100%",
          maxHeight: "calc( 100vh - 220px)",
          padding: "0 1px ",
          minWidth: "1050px",
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow className="tableUi__head">
              {check.totalUsers && (
                <TableCell
                  sx={{ borderRadius: "20px 0px 0px 0px" }}
                  align="center"
                >
                  Total Users
                </TableCell>
              )}
              {check.activeUsers && (
                <TableCell align="center"> Total Active Users</TableCell>
              )}
              {check.inactiveUsers && (
                <TableCell align="center">Total Inactive Users</TableCell>
              )}
              {check.pointsEarned && (
                <TableCell align="center">Total Team Points Earned</TableCell>
              )}

              {check.pointsRedeemed && (
                <TableCell
                  sx={{ borderRadius: "0px 20px 0px 0px" }}
                  align="center"
                >
                  Total Team Points Redeemed
                </TableCell>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            <>
              <div className="row__spacing"></div>
              <TableRow
                className="tableUi___body"
                sx={{ borderRadius: "10px" }}
              >
                {check.totalUsers && (
                  <TableCell
                    sx={{
                      borderRadius: "10px 0px 0px 10px",
                      // border: "1px solid grey",
                    }}
                    align="center"
                  >
                    <div className="tableUi__rankscir">
                      <div className="tableUi__rank">
                        {" "}
                        {data?.data?.totalUsers}
                      </div>
                    </div>
                  </TableCell>
                )}
                {check.activeUsers && (
                  <TableCell
                    sx={{
                      borderRadius: "10px 0px 0px 10px",
                      // border: "1px solid grey",
                    }}
                    align="center"
                  >
                    {data?.data?.totalActiveUsers}
                  </TableCell>
                )}
                {check.inactiveUsers && (
                  <TableCell align="center">
                    {data?.data?.totalInactiveUsers}
                  </TableCell>
                )}
                {check.pointsEarned && (
                  <TableCell align="center">
                    {data?.data?.totalTeamPointsEarned}
                  </TableCell>
                )}
                {check.pointsRedeemed && (
                  <TableCell
                    sx={{
                      borderRadius: "0px 10px 10px 0px",
                      // border: "1px solid grey",
                    }}
                    align="center"
                  >
                    {data?.data?.totalTeamPointsRedeemed}
                  </TableCell>
                )}
              </TableRow>
              <div className="row__spacing"></div>
            </>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
