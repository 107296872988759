import React, { useEffect, useState } from "react";
import { Toggle } from "../UI/Toggle";
import { Divider } from "@mui/material";
import {
  useGetUserNotificationPreferencesQuery,
  useUpdateUserNotificationPreferencesMutation,
} from "../../redux-toolkit/features/notifications/notificationsApiSlice";

const SettingItem = ({
  title,
  description,
  toggleId,
  isEnabled,
  handleToggle,
}) => {
  return (
    <div className="settings_item">
      <div className="settings_left">
        <h3>{title}</h3>
        <span style={{ fontSize: "14px" }}>{description}</span>
      </div>
      <div className="settings_right">
        <Toggle
          id={toggleId}
          inActiveColor="rgba(175, 175, 175, 1)"
          isOn={isEnabled}
          handleToggle={handleToggle}
        />
      </div>
    </div>
  );
};

const settingsOptions = [
  {
    title: "New Department Book",
    description: "Notify when a new book has been added to the department",
    id: "NEW_DEPARTMENT_BOOK",
  },
  {
    title: "Next Book Unlocked",
    description: "Notify when a new book gets unlocked ",
    id: "NEXT_BOOK_UNLOCKED",
  },
  {
    title: "Badges",
    description: "Notify when ever there is a new badge to unlock",
    id: "BADGES",
  },
  {
    title: "Points Earned",
    description:
      "Notify every time a task is approved and new points are added to the wallet",
    id: "POINTS_EARNED",
  },
  {
    title: "Permissions",
    description: "Notify whenever a permission is changed",
    id: "PERMISSION",
  },
  {
    title: "Task Approval or Rejection",
    description:
      "Notify whenever a task gets approved or rejected by the admin",
    id: "TASK_STATUS",
  },
];

const NotificationSetting = () => {
  const [disabledPreferences, setDisabledPreferences] = useState([]);
  const { data } = useGetUserNotificationPreferencesQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  const [updatePreference] = useUpdateUserNotificationPreferencesMutation();

  useEffect(() => {
    setDisabledPreferences(data?.data?.preferences || []);
  }, [data]);

  return (
    <div className="container__accounts">
      <div className="notifs_settings_container">
        <h2 style={{ width: "100%", fontSize: "20px" }}>
          Notification settings
        </h2>
        <div
          className="settings_container"
          style={{
            paddingBottom: "180px",
          }}
        >
          {settingsOptions.map((opt, i) => {
            return (
              <>
                <div
                  key={opt.id}
                  style={{
                    padding: "16px 0",
                  }}
                >
                  <SettingItem
                    title={opt.title}
                    description={opt.description}
                    isEnabled={!disabledPreferences.includes(opt.id)}
                    toggleId={opt.id}
                    handleToggle={async () => {
                      try {
                        const res = await updatePreference(opt.id);
                        setDisabledPreferences(
                          res?.data?.data?.preferences || disabledPreferences
                        );
                      } catch (error) {
                        console.log(
                          "Error while update the notification preferences: ",
                          error
                        );
                      }
                    }}
                  />
                </div>
                <Divider
                  style={{
                    margin: 0,
                  }}
                ></Divider>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default NotificationSetting;
