import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import "./DeleteModal.css";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  right: "50%",
  maxHeight: "50vh",
  overflowY: "auto",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  borderRadius: 3,
  boxShadow: 24,
  p: "40px 20px",
};

export const DeleteModal = ({
  setDeleteOpen,
  deleteOpen,
  label,
  deleteHandler,
}) => {
  const handleClose = () => setDeleteOpen(false);

  const confirmHandler = async () => {
    deleteHandler();
    handleClose();
  };

  return (
    <div>
      <div>
        <Modal
          open={deleteOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="deletemodal">
              <h3 className="ftwt__r">
                Are you sure you want to Delete{" "}
                <span style={{ color: "#3361cf", fontWeight: "normal" }}>
                  {label}
                </span>
                ?
              </h3>
              <div className="deletemodal__action">
                <Button
                  onClick={confirmHandler}
                  color="success"
                  variant="outlined"
                >
                  Yes
                </Button>

                <Button onClick={handleClose} color="error" variant="outlined">
                  No
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
};
