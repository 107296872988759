import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import { BreadcrumbAdmin } from "../../UI/BreadcrumbAdmin";
import "./Rewards.css";
import RedeemStatusTable from "./components/RedeemStatusTable";
import { CreateNewFolderOutlined } from "@mui/icons-material";
import { CSVLink } from "react-csv";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';


const base = [
  {
    _id: 1,
    link: "/super-admin/rewards",
    label: "Rewards",
  },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const RedeemStatus = () => {
  const [value, setValue] = useState(0);
  const [csvData, setCsvData] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="container">
      <BreadcrumbAdmin
        base={base}
        page={'Redeem status'}
      />
      <Box sx={{ width: '100%', margin: '25px 0' }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            style={{ width: '100%' }}>
            <Tab
              label="Pending"
              sx={{
                textTransform: 'capitalize',
                marginRight: '20px',
                fontWeight: '500',
              }}
              {...a11yProps(0)}
            />

            <Tab
              sx={{ textTransform: 'capitalize', fontWeight: '500' }}
              label="Completed"
              {...a11yProps(1)}
            />
            {value === 1 ? (
              <CSVLink
                style={{
                  marginLeft: 'auto',
                  alignSelf: 'self-end',
                  marginBottom: '5px',
                }}
                filename="completed_orders"
                data={csvData}>
                <Button
                  variant="contained"
                  endIcon={<FileUploadOutlinedIcon sx={{ color: 'white' }} />}>
                  Export
                </Button>
              </CSVLink>
            ) : null}
          </Tabs>
        </Box>
      </Box>
      <TabPanel
        value={value}
        index={0}>
        <RedeemStatusTable status="pending" />
      </TabPanel>
      <TabPanel
        value={value}
        index={1}>
        <RedeemStatusTable
          status="completed"
          onDataChange={(data) => {
            setCsvData(data);
          }}
        />
      </TabPanel>
    </div>
  );
};

export default RedeemStatus;
