import { Outlet, Link, Navigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../redux-toolkit/features/auth/authSlice";
import { useRefreshMutation } from "../../redux-toolkit/features/auth/authApiSlice";
import usePersist from "../../hooks/usePersist";

const PersistLogin = () => {
  const [persist] = usePersist();
  const token = useSelector(selectCurrentToken);
  const effectRan = useRef(false);

  const [trueSuccess, setTrueSuccess] = useState(false);

  const [refresh, { isUninitialized, isLoading, isSuccess, isError, error }] =
    useRefreshMutation();

  useEffect(() => {
    if (
      effectRan.current === true ||
      process.env.REACT_APP_CLIENT_ENV !== 'development'
    ) {
      // React 18 Strict Mode

      const verifyRefreshToken = async () => {
        try {
          //const response =
          await refresh();

          setTrueSuccess(true);
        } catch (err) {
          console.error(err);
        }
      };

      if (!token && persist) verifyRefreshToken();
    }

    return () => (effectRan.current = true);

    // eslint-disable-next-line
  }, []);

  let content;
  content = <Outlet />;
  // if (!persist) {
  //   // persist: no
  //   console.log("no persist");
  //
  // } else

  if (isLoading) {
    //persist: yes, token: no
    content = <p>Loading...</p>;
  } else if (isError) {
    //persist: yes, token: no
    console.log('error');
    content = <Navigate to={'/login'} />;
  } else if (isSuccess && trueSuccess) {
    // persist: yes, token: yes,
    // console.log("success");
    content = <Outlet />;
  } else if (token && isUninitialized) {
    //persist: yes, token: yes
    // console.log("token and uninit");
    // console.log(isUninitialized);
    content = <Outlet />;
  }

  return content;
};
export default PersistLogin;
