import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React from "react";
import startSvg from "../../../assets/books/action_icons/Start.svg";
// import deniedSvg from "../../../assets/books/action_icons/denied.svg";
import doneSvg from "../../../assets/books/action_icons/done.svg";
import sentSvg from "../../../assets/books/action_icons/sent.svg";
import { useGetQuestCourseByIdQuery } from "../../../redux-toolkit/features/courses/coursesApiSlice";
import ProgressBar from "../../UI/ProgressBar";
import { CourseCover } from "./CourseCover";

import StarIcon from "@mui/icons-material/Star";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import { Button, TextField } from "@mui/material";
import { useAlert } from "../../../hooks/useAlert";
import { useSendTaskMutation } from "../../../redux-toolkit/features/books/bookApiSlice";
import { useSelector } from "react-redux";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  right: '50%',
  // height: '600px',
  // maxHeight: '70vh',
  // overflowY: 'auto',
  transform: 'translate(-50%, -50%)',
  width: '50vw',
  bgcolor: 'background.paper',
  borderRadius: 3,
  boxShadow: 24,
  p: '25px 40px',
};

export const LevelTask = ({
  index,
  task,
  bookTheme,
  refetch,
  levelId,
  bookId,
  bookName,
  levelName,
}) => {
  const [open, setOpen] = React.useState(false);
  const [openTask, setOpenTask] = React.useState(false);
  const [thumbnail, setThumbnail] = React.useState();
  const [url, setUrl] = React.useState();
  const [inUrlValid, setInUrlValid] = React.useState(false);
  const [notes, setNotes] = React.useState();
  const [sendTask] = useSendTaskMutation();
  const { displayAlert } = useAlert();
  const [isLarge, setIsLarge] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = async () => {
    setCourseId(null);
    await clearHandler();
    setOpen(false);
  };
  const bookMode = useSelector((state) => state.theme.bookMode);

  const handleOpenTask = () => {
    clearHandler();
    setOpenTask(true);
  };
  const handleOpenClose = () => {
    clearHandler();
    setOpenTask(false);
  };
  const [courseId, setCourseId] = React.useState(null);

  const { data, isSuccess, isError, isLoading } = useGetQuestCourseByIdQuery(
    courseId,
    {
      skip: courseId === null,
      refetchOnMountOrArgChange: true,
    }
  );
  const thumbnailHandler = (event) => {
    if (url) {
      return displayAlert({
        open: true,
        message: `Send either Image or Url`,
        type: 'error',
        timeout: '2000',
      });
    }
    const file = event.target.files[0];

    if (file.size >= 1099276) {
      setIsLarge(true);

      displayAlert({
        open: true,
        message: `Image Size cannot be Larger than 1 MB`,
        type: 'error',
        timeout: '2000',
      });
    } else {
      setIsLarge(false);

      setThumbnail(file);
    }
  };

  const urlHandler = (event) => {
    if (thumbnail) {
      return displayAlert({
        open: true,
        message: `Send either Image or Url`,
        type: 'error',
        timeout: '2000',
      });
    } else {
      setUrl(event.target.value);
    }
  };

  const clearHandler = () => {
    setInUrlValid(false);
    setUrl('');
    setThumbnail();
    setNotes('');
  };

  function isValidUrl(string) {
    try {
      new URL(string);
      return true;
    } catch (err) {
      return false;
    }
  }

  const sendHandler = () => {
    const taskData = new FormData();
    if (!isValidUrl(url) && url) {
      setInUrlValid(true);
      return displayAlert({
        open: true,
        message: `Invalid URL format`,
        type: 'error',
        timeout: '2000',
      });
    }

    taskData.append('file', thumbnail);
    taskData.append('url', url);
    taskData.append('comments', notes);
    if (!thumbnail && !url && !notes) {
      return displayAlert({
        open: true,
        message: `Add either an image, Note or a URL.`,
        type: 'error',
        timeout: '2000',
      });
    } else {
      sendTask({ bookId, taskId: task._id, levelId, taskData })
        .unwrap()
        .then((res) => {
          console.log(res);
          displayAlert({
            open: true,
            message: res?.message || 'Successfuly sent task for approval',
            type: 'success',
            timeout: '2000',
          });
        })
        .catch((err) => {
          displayAlert({
            open: true,
            message: err?.data?.message || `Error while sending the task`,
            type: 'error',
            timeout: '2000',
          });
        })
        .finally(() => {
          refetch();
          handleClose();
        });
    }
  };

  const courseOpenHandler = (_id) => {
    setCourseId(_id);
    handleOpen();
  };

  return (
    <div>
      {!openTask ? (
        <div key={index}>
          <div className="booksLevels__que">
            <h4>Task {index + 1}:</h4>
            <h4>{task.description}</h4>
          </div>
          <div className="booksLevels__ans">
            <div>
              {bookMode === 'dark' ? (
                <StarIcon
                  sx={{
                    color:
                      task.status === 'APPROVED'
                        ? 'var(--sstar)'
                        : 'var(--grey700)',
                    fontSize: '20px',
                  }}
                />
              ) : (
                <StarIcon
                  sx={{
                    color:
                      task.status === 'APPROVED' ? 'var(--sstar)' : 'white',
                    fontSize: '20px',
                  }}
                />
              )}
              <h5> {task.status === 'APPROVED' ? task.points : 0}</h5>
            </div>
            <div>
              <>
                {task.isStarted === false &&
                  task.isFinished === false &&
                  task.course != null && (
                    <Button
                      startIcon={
                        <img
                          height={'15px'}
                          src={startSvg}
                          alt=""
                        />
                      }
                      onClick={() => courseOpenHandler(task.course?._id)}
                      variant="contained">
                      Start.
                    </Button>
                  )}
                {task.isStarted === true &&
                  task.isFinished === false &&
                  task.course != null && (
                    <Button
                      startIcon={
                        <img
                          height={'15px'}
                          src={startSvg}
                          alt=""
                        />
                      }
                      onClick={() => courseOpenHandler(task.course?._id)}
                      variant="contained">
                      Get Started
                    </Button>
                  )}
                {task.isFinished === true && task.course != null && (
                  <Button
                    startIcon={
                      <img
                        width={'15px'}
                        src={doneSvg}
                        alt=""
                      />
                    }
                    variant="contained"
                    color="success"
                    onClick={() => courseOpenHandler(task.course?._id)}>
                    Done
                  </Button>
                )}

                {task.status === 'INCOMPLETE' && task.course === null && (
                  <Button
                    startIcon={
                      <img
                        width={'15px'}
                        src={startSvg}
                        alt=""
                      />
                    }
                    onClick={handleOpenTask}
                    variant="contained">
                    Start
                  </Button>
                )}

                {task.status === 'REJECTED' && task.course === null && (
                  <Button
                    startIcon={
                      <img
                        width={'15px'}
                        src={startSvg}
                        alt=""
                      />
                    }
                    onClick={handleOpenTask}
                    variant="contained">
                    Resend
                  </Button>
                )}
                {/* {task.status === "REJECTED" && (
                  <Button
                    startIcon={<img width={"15px"} src={deniedSvg} alt="" />}
                    variant="contained"
                    color="error"
                  >
                    Denied
                  </Button>
                )} */}
                {task.status === 'APPROVED' && task.course === null && (
                  <Button
                    startIcon={
                      <img
                        width={'15px'}
                        src={doneSvg}
                        alt=""
                      />
                    }
                    variant="contained"
                    color="success">
                    Done
                  </Button>
                )}
                {task.status === 'PENDING' && (
                  <Button
                    startIcon={
                      <img
                        width={'15px'}
                        src={sentSvg}
                        alt=""
                      />
                    }
                    // onClick={handleOpenTask}
                    variant="contained"
                    color="warning"
                    sx={{ backgroundColor: '#EE7233' }}>
                    Sent
                  </Button>
                )}
              </>
              <div>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description">
                  <Box sx={style}>
                    {data && (
                      <div className="create__formhead">
                        <h1></h1>
                        <CloseIcon
                          onClick={handleClose}
                          sx={{
                            fontSize: '18px',
                            cursor: 'pointer',
                            marginBottom: '5px',
                            color: 'var(--grey400)',
                            '&:hover': {
                              color: 'var(--error)',
                            },
                          }}
                        />
                      </div>
                    )}

                    {!isSuccess ? (
                      <ProgressBar />
                    ) : (
                      <CourseCover
                        bookName={bookName}
                        levelName={levelName}
                        bookId={bookId}
                        levelId={levelId}
                        course={data?.data}
                        taskId={task?._id}
                      />
                    )}
                  </Box>
                </Modal>
              </div>
            </div>
          </div>
          <img
            src={bookTheme?.subDivider}
            className="booksLevels_sepratorarrow levelresponsive__img"
            alt="sepratorArrow"
          />
        </div>
      ) : (
        <div key={index}>
          <div
            className="booktask"
            style={{ border: '1px solid rgba(0, 0, 0, 0.2)' }}>
            <div className="booktask__close">
              <CloseIcon
                onClick={handleOpenClose}
                sx={{
                  fontSize: '18px',
                  cursor: 'pointer',
                  color: 'var(--grey600)',
                  '&:hover': {
                    color: 'var(--error)',
                  },
                }}
              />
            </div>
            <div className="booktask__content">
              <div className="booktask__head">
                <h5 className="ftwt__m">Points:</h5>
                <div>
                  {/* <img src={star} alt="" /> */}
                  <h5 className="ftwt__m">{task.points}</h5>
                </div>
              </div>
              <h5
                className="ftwt__r"
                style={{ color: '#747474' }}>
                {task.description}
              </h5>
              <div
                style={{
                  borderColor: isLarge ? 'var(--error)' : '',
                  backgroundColor: url && 'var(--grey100)',
                }}
                className="dropzone-wrapper">
                {thumbnail ? (
                  <div className="dropzone-image">
                    <img
                      height={'100%'}
                      src={URL.createObjectURL(thumbnail)}
                      alt="Thumbnail Preview"
                    />
                    <CloseIcon
                      onClick={() => setThumbnail()}
                      sx={{
                        fontSize: '18px',
                        cursor: 'pointer',
                        color: 'var(--grey400)',
                        '&:hover': {
                          color: 'var(--error)',
                        },
                      }}
                    />
                  </div>
                ) : (
                  <div className="dropzone-desc">
                    <UploadFileOutlinedIcon sx={{ fontSize: '70px' }} />
                    <p
                      style={{
                        fontSize: '14px',
                        // color: isLarge ? 'var(--error)' : '',
                      }}>
                      Choose an image file{' '}
                      <span style={{ fontSize: '11px' }}>or drag it here.</span>
                      <span style={{ fontSize: '11px' }}>
                        Maximum file size 1MB
                      </span>
                    </p>
                  </div>
                )}
                {!thumbnail && (
                  <input
                    disabled={url}
                    name="thumbnail"
                    onChange={thumbnailHandler}
                    type="file"
                    accept="image/*"
                    className="dropzone"
                  />
                )}
              </div>
              {isLarge && (
                <p
                  style={{
                    color: 'var(--error)',
                    fontSize: '12px',
                  }}>
                  Choose Image smaller than 1MB
                </p>
              )}
              <h4 className="ftwt__r">OR</h4>
              <div>
                <h6 className="ftwt__r">Upload from a URL</h6>
                <TextField
                  sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': {
                      top: 0,
                    },
                    marginTop: '5px',
                  }}
                  variant="outlined"
                  placeholder="https://www.example.com"
                  fullWidth
                  value={url}
                  disabled={thumbnail}
                  error={inUrlValid ? true : false}
                  helperText={inUrlValid && 'Detected Invalid Url.'}
                  onChange={urlHandler}
                  // InputProps={{
                  //   endAdornment: (
                  //     <>
                  //       <button
                  //         style={{
                  //           margin: '10px 0px 7px',
                  //           padding: '8px 10px',
                  //           fontSize: '14px',
                  //         }}
                  //         className="contained__lgbtn">
                  //         upload
                  //       </button>
                  //     </>
                  //   ),
                  // }}
                />
              </div>
              <h4 className="ftwt__r">OR</h4>

              <div>
                <h6 className="ftwt__r">Additional notes to the submission.</h6>
                <TextField
                  sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    marginTop: '5px',
                  }}
                  variant="outlined"
                  placeholder="Note"
                  fullWidth
                  multiline
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  rows={2}
                />
              </div>
              <div className="booktask__actions">
                <Button
                  variant="outlined"
                  color="error"
                  onClick={clearHandler}
                  sx={{
                    color: 'var(--grey400)',
                    borderColor: 'var(--grey400)',
                    width: '100px',
                    '&:hover': {
                      color: 'var(--error)',
                    },
                  }}>
                  Reset
                </Button>
                <Button
                  variant="contained"
                  onClick={sendHandler}
                  sx={{
                    width: '100px',
                  }}>
                  Send
                </Button>
              </div>
            </div>
          </div>
          <img
            src={bookTheme?.subDivider}
            className="booksLevels_sepratorarrow levelresponsive__img"
            alt="sepratorArrow"
          />
        </div>
      )}
    </div>
  );
};
