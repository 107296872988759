import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Autocomplete, TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import "./CreateCourse.css";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import { useCreateCourseMutation } from "../../../redux-toolkit/features/courses/coursesApiSlice";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import { useAlert } from "../../../hooks/useAlert";
import DeleteInActiveSvg from '../../../assets/delete_icon.svg';
import DeleteActiveSvg from '../../../assets/Icons/delete_red.svg';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  right: '50%',
  maxHeight: '85vh',
  overflowY: 'auto',
  overflowX: 'hidden',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  borderRadius: 3,
  boxShadow: 24,
  p: '25px 40px',
};

const config = {
  modules: {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
    ],
  },
  formats: [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'indent',
  ],
};

const validationSchema = yup.object({
  name: yup.string('Enter course name').required('course name is required'),
  // points: yup.number("Enter course points").required("points is required"),
  // hours: yup
  //   .number("Enter course Course Duration")
  //   .required("Course Duration is required"),
  description: yup
    .string('Enter description')
    .required('description is required')
    .max(150, 'maximum limit is  150 words'),

  // .min(100, "min 100 word required!")
  // certificate: yup
  //   .string("Enter certificate")
  //   .required("certificate is required"),
});

export const CreateCourse = ({ courseRefetch, courseOpen, setCourseOpen }) => {
  const [createCourse] = useCreateCourseMutation();
  const [thumbnail, setThumbnail] = React.useState();
  const [isLarge, setIsLarge] = React.useState(false);
  const thumbnailHandler = (event) => {
    const file = event.target.files[0];

    if (file.size >= 1099276) {
      setIsLarge(true);

      displayAlert({
        open: true,
        message: `Image Size cannot be Larger than 1 MB`,
        type: 'error',
        timeout: '2000',
      });
    } else {
      setIsLarge(false);

      setThumbnail(file);
    }
  };

  const { displayAlert } = useAlert();
  const handleClose = () => {
    formik.handleReset();
    setThumbnail();

    setCourseOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      // hours: "",
      description: '',
      // department: "638600f5c4c190950234248b",
      // tags: ["6385fbc51fdaaa8fc97958c6"],
      // thumbnail: "63860045c4c1909502342485",
      certificate: '6385fc151fdaaa8fc97958d8',
      // skills: "",
      // certificate: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (!thumbnail) {
        return displayAlert({
          open: true,
          message: `Courses Image is manadatry field`,
          type: 'error',
          timeout: '2000',
        });
      }

      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('description', values.description);
      formData.append('coverImage', thumbnail);

      await createCourse(formData);
      await courseRefetch();
      await displayAlert({
        open: true,
        message: `New Courses ${formik.values.name} Added!`,
        type: 'success',
        timeout: '2000',
      });
      handleClose();
    },
  });

  return (
    <div>
      <div>
        <Modal
          open={courseOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <form onSubmit={formik.handleSubmit}>
            <Box sx={style}>
              <div className="create__form">
                <div className="create__formhead">
                  <h1>Add Course</h1>
                  <CloseIcon
                    onClick={handleClose}
                    sx={{
                      fontSize: '18px',
                      cursor: 'pointer',
                      color: 'var(--grey400)',
                      '&:hover': {
                        color: 'var(--error)',
                      },
                    }}
                  />
                </div>

                <div className="seprator"></div>
                <br />

                <h5 className="ftwt__r">
                  Course Name <span>*</span>
                </h5>

                <TextField
                  required
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />

                {/* <h5 className="ftwt__r">
                  Course Duration (in hours) <span>*</span>
                </h5>
                <TextField
                  required
                  type="number"
                  id="outlined-basic"
                  variant="outlined"
                  name="hours"
                  fullWidth
                  value={formik.values.hours}
                  onChange={formik.handleChange}
                  error={formik.touched.hours && Boolean(formik.errors.hours)}
                  helperText={formik.touched.hours && formik.errors.hours}
                /> */}

                <h5 className="ftwt__r">
                  Description <span>*</span>
                </h5>
                <TextField
                  required
                  id="outlined-basic"
                  variant="outlined"
                  name="description"
                  fullWidth
                  rows={2}
                  multiline
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />

                <h6 className="ftwt__r">
                  Total letter count {formik.values.description.length}
                </h6>

                <h5 className="ftwt__r">
                  Display Image <span>*</span>
                </h5>
                <div
                  style={{ borderColor: isLarge ? 'var(--error)' : '' }}
                  className="dropzone-wrapper">
                  {thumbnail ? (
                    <div className="dropzone-image">
                      <img
                        height={'100%'}
                        src={URL.createObjectURL(thumbnail)}
                        alt="Thumbnail Preview"
                      />
                      <CloseIcon
                        onClick={() => setThumbnail()}
                        sx={{
                          fontSize: '18px',
                          cursor: 'pointer',

                          color: 'var(--grey400)',
                          '&:hover': {
                            color: 'var(--error)',
                          },
                        }}
                      />
                    </div>
                  ) : (
                    <div className="dropzone-desc">
                      <UploadFileOutlinedIcon
                        sx={{
                          fontSize: '70px',
                        }}
                      />
                      <p
                        style={{
                          fontSize: '14px',
                          // color: isLarge ? 'var(--error)' : '',
                        }}>
                        Choose an image file{' '}
                        <span style={{ fontSize: '11px' }}>
                          or drag it here.
                        </span>
                        <br />
                        <span style={{ fontSize: '11px' }}>
                          Maximum file size 1MB
                        </span>
                      </p>
                    </div>
                  )}
                  {!thumbnail && (
                    <input
                      name="thumbnail"
                      onChange={thumbnailHandler}
                      type="file"
                      accept="image/*"
                      // name="img_logo"
                      className="dropzone"
                    />
                  )}
                </div>
                {isLarge && (
                  <p
                    style={{
                      color: 'var(--error)',
                      marginTop: '5px',
                      fontSize: '12px',
                    }}>
                    Choose Image smaller than 1MB
                  </p>
                )}
              </div>
              <br />

              <div className="createcourse__action">
                {/* <button onClick={formik.handleReset} className="outlined__btn">
                  Discard
                </button> */}

                <div
                  onClick={formik.handleReset}
                  className="figure">
                  <img
                    src={DeleteInActiveSvg}
                    className="Sirv image-main"
                    height="22px"
                    alt=""
                  />
                  <img
                    src={DeleteActiveSvg}
                    className="Sirv image-hover"
                    height="22px"
                    alt=""
                  />
                </div>

                <Button
                  type="submit"
                  // onClick={createCourseHandler}
                  // onSubmit={formik.handleSubmit}
                  variant="contained">
                  Create
                </Button>
              </div>
            </Box>
          </form>
        </Modal>
      </div>
    </div>
  );
};

const top100Films = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
  { title: "12 Angry Men", year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: "Pulp Fiction", year: 1994 },
];
