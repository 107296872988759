import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startSessionTimer } from '../../../redux-toolkit/features/theme/themeSlice';

function SessionTimer({ user, token, sessionTime }) {
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(startSessionTimer(sessionTime + 1));
    }, 60000);

    const handleWindowClose = (event) => {
      event.preventDefault(); // Prompt user before closing the window
      // Clear the interval and perform any other necessary cleanup
      clearInterval(interval);
      // Additional cleanup code here if needed
    };
    // Add the event listener for window close
    window.addEventListener('beforeunload', handleWindowClose);

    return () => {
      clearInterval(interval);
      // Remove the event listener when the component unmounts
      window.removeEventListener('beforeunload', handleWindowClose);
    };
  }, []);

  useEffect(() => {
    // if (user != null && token) {
    //   dispatch(startSessionTimer());
    // }

    // Add event listener for window close
    const handleWindowClose = (event) => {
      event.preventDefault();
      // Additional cleanup code here if needed
    };

    window.addEventListener('beforeunload', handleWindowClose);

    return () => {
      window.removeEventListener('beforeunload', handleWindowClose);
    };
  }, [dispatch]);

  const handleLogout = () => {
    // Dispatch logout action and additional logout logic here
  };

  return <></>;
}

export default SessionTimer;
