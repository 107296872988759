import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import "./CertificateModal.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CloseIcon from "@mui/icons-material/Close";

import {
  useUpdatePublishMutation,
  useUpdateCertificateMutation,
} from "../../../../../redux-toolkit/features/courses/coursesApiSlice";
import { useAlert } from "../../../../../hooks/useAlert";
import { useNavigate } from "react-router-dom";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  right: '50%',
  maxHeight: '50vh',
  overflowY: 'auto',
  transform: 'translate(-50%, -50%)',
  width: 470,
  bgcolor: 'background.paper',
  borderRadius: 3,
  boxShadow: 24,
  p: '20px 20px 30px',
};
export const CertificateModal = ({
  courseId,
  setCertificateOpen,
  certificateOpen,
  hasCertificate,
}) => {
  const handleClose = () => setCertificateOpen(false);
  const [check, setCheck] = React.useState(hasCertificate);
  const [updateCertificate] = useUpdateCertificateMutation();
  const [updatePublish] = useUpdatePublishMutation();
  const { displayAlert } = useAlert();
  const navigate = useNavigate();

  React.useEffect(() => {
    setCheck(hasCertificate);
  }, []);

  const handleChange = (event) => {
    setCheck(event.target.value);
  };
  const saveHandler = async () => {
    const pub = { isPublish: true };
    const cer = { hasCertificate: check };
    await updateCertificate({ courseId, cer });
    await updatePublish({ courseId, pub });
    handleClose();
    await displayAlert({
      open: true,
      message: `Course has been published`,
      type: "success",
      timeout: "2000",
    });
    navigate("/admin/my-workspace");
  };

  return (
    <div>
      <div>
        <Modal
          open={certificateOpen}
          onClose={handleClose}
          onChange={handleChange}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="certificatemodal__cross">
              <CloseIcon
                onClick={handleClose}
                sx={{
                  fontSize: "18px",
                  cursor: "pointer",
                  color: "var(--grey400)",
                  "&:hover": {
                    color: "var(--error)",
                  },
                }}
              />
            </div>
            <div className="certificatemodal">
              <h3 className="ftwt__r">
                Provide completion certification for this course?
              </h3>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                value={check}
                name="position"
              >
                <FormControlLabel
                  value={true}
                  control={<Radio color="success" />}
                  label="Yes"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
              <div className="certificatemodal__action">
                <Button
                  onClick={saveHandler}
                  sx={{ width: "150px" }}
                  variant="contained"
                >
                  Save &amp; Publish
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
};
