import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useAddCartMutation,
  useRemoveCartProductMutation,
} from "../../../redux-toolkit/features/rewards/rewardsApiSlice";
import ProgressBar from "../../UI/ProgressBar";
import "../../UI/Tables/TableUi.css";
import { ReactComponent as DeleteIcon } from './../../../assets/delete_icon.svg';
import { ReactComponent as DeleteIconH } from './../../../assets/Icons/delete_red.svg';

import { useAlert } from '../../../hooks/useAlert';
import { Box, CircularProgress } from '@mui/material';

const CartTable = () => {
  const rewards = useSelector((state) => state.rewards.cart);
  const dispatch = useDispatch();
  const [removeCartProduct] = useRemoveCartProductMutation();
  const [addCart] = useAddCartMutation();
  const totalPoints = useSelector((state) => state.rewards.totalPoints);
  const { displayAlert } = useAlert();
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {}, [rewards]);

  const handleremoveProduct = (productId) => {
    dispatch(removeCartProduct(productId));
  };
  const handleAddProduct = async (productId, qty, type) => {
    const newQuantity = { quantity: qty, type: type };
    setIsLoading(true);

    await addCart({ productId, newQuantity })
      .then((data) => {
        // Display a success alert

        if (data.error && data.error.status === 400) {
          displayAlert({
            open: true,
            message: `${data.error.data.message}`,
            type: 'error',
            timeout: 2000,
          });
        } else {
          displayAlert({
            open: true,
            message: `Product quantity updated Successfully`,
            type: 'success',
            timeout: 2000,
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        // Display an error alert
        setIsLoading(false);
        // displayAlert({
        //   open: true,
        //   message: 'Something Went Wrong',
        //   type: 'error',
        //   timeout: 1500,
        // });
      });
  };

  const handleIncrement = (productId, qty) => {
    handleAddProduct(productId, qty + 1, 'add');
  };
  const handleDecrement = (productId, qty) => {
    if (qty > 1) {
      handleAddProduct(productId, qty - 1, 'remove').then((data) => {
        // Display a success alert

        if (data.error && data.error.status === 400) {
          displayAlert({
            open: true,
            message: `${data.error.data.message}`,
            type: 'error',
            timeout: 2000,
          });
        } else {
          displayAlert({
            open: true,
            message: `Product quantity updated Successfully`,
            type: 'success',
            timeout: 2000,
          });
        }
        setIsLoading(false);
      });
    } else {
      removeCartProduct(productId).then((data) => {
        // Display a success alert
        console.log(data);
        displayAlert({
          open: true,
          message: `${data.data.message}`,
          type: 'success',
          timeout: 2000,
        });
      });
    }
  };

  return (
    <div style={{ width: '100%' }}>
      {true ? (
        <TableContainer
          sx={{
            // height: 'calc( 100vh - 230px)',
            padding: '0 1px',
            minWidth: '65vw',
          }}>
          <Table
            stickyHeader
            aria-label="sticky table">
            <TableHead>
              <TableRow
                sx={{ borderRadius: '15px 15px 0px 0px' }}
                className="tableUi__head">
                <TableCell
                  sx={{ borderRadius: '15px 0px 0px 0px' }}
                  className="cart_product_table_cell"
                  align="left">
                  Product
                </TableCell>

                <TableCell align="left"> Category</TableCell>
                <TableCell align="left">Quantity</TableCell>
                <TableCell align="left">Points</TableCell>

                <TableCell
                  sx={{ borderRadius: '0px 15px 0px 0px' }}
                  align="left"></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {rewards &&
                rewards.map((row, index) => (
                  <React.Fragment key={row?.product._id}>
                    <div className="row__spacing"></div>
                    <div style={{ height: '15px' }}></div>
                    {row && (
                      <TableRow
                        className="tableUi___body"
                        key={row?.product.name}
                        sx={{
                          borderRadius: '10px',
                          backgroundColor: 'white',
                        }}>
                        <TableCell
                          sx={{
                            padding: '0px !important',

                            borderRadius: '10px 0px 0px 10px',
                            // border: "1px solid grey",
                          }}
                          align="left">
                          <div
                            style={{
                              display: 'flex',
                              gap: '20px',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                            }}>
                            <img
                              style={{
                                width: '150px',
                                height: '120px',
                                objectFit: 'cover',
                                borderRadius: '10px, 10px 0px 0px',
                              }}
                              src={row?.product?.image}
                              alt=""
                            />
                            <p style={{ marginLeft: '0px' }}>
                              {row?.product?.name}
                            </p>
                          </div>
                        </TableCell>

                        <TableCell align="left">
                          {row?.product?.category &&
                            row?.product?.category?.name}
                        </TableCell>
                        <TableCell align="left">
                          <div
                            style={{
                              position: 'relative',
                            }}>
                            <div
                              style={{
                                display: 'flex',
                                alignItem: 'center',
                                marginTop: '-10px',
                                visibility: isLoading && 'hidden',
                                // border: '1px solid var(--pblue)',
                                borderRadius: '4px',
                                width: '30px',
                              }}>
                              <RemoveOutlinedIcon
                                onClick={() =>
                                  handleDecrement(
                                    row?.product?._id,
                                    row?.quantity
                                  )
                                }
                                sx={{
                                  fontSize: '20px',
                                  color: 'white',
                                  cursor: 'pointer',
                                  margin: 'auto',
                                  borderTopLeftRadius: '2px',
                                  borderBottomLeftRadius: '2px',
                                  backgroundColor: 'var(--pblue)',
                                  border: '1px solid var(--pblue)',
                                  padding: '8px',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              />

                              <input
                                type="text"
                                style={{
                                  width: '40px',
                                  // height: '50px',
                                  // border: 'none',
                                  borderLeft: '0px solid var(--grey300)',
                                  borderRight: '0px solid var(--grey300)',
                                  borderBottom: '1px solid var(--grey300)',
                                  borderTop: '1px solid var(--grey300)',
                                  textAlign: 'center',
                                  padding: '0px !important',
                                  backgroundColor: 'white', // Set background color to white
                                  borderRadius: '0', // Set border radius to 0
                                }}
                                value={row?.quantity}
                                defaultValue={row?.quantity}
                              />
                              <AddOutlinedIcon
                                onClick={() =>
                                  handleIncrement(
                                    row?.product?._id,
                                    row?.quantity
                                  )
                                }
                                sx={{
                                  fontSize: '20px',
                                  color: 'white',
                                  cursor: 'pointer',
                                  margin: 'auto',
                                  borderTopRightRadius: '2px',
                                  borderBottomRightRadius: '2px',
                                  backgroundColor: 'var(--pblue)',
                                  border: '1px solid var(--pblue)',
                                  padding: '8px',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              />
                            </div>
                            {/* ) : ( */}
                            <Box
                              sx={{
                                width: '100%',
                                position: 'absolute',
                                top: '50%',
                                visibility: !isLoading && 'hidden',
                                display: 'flex',
                                justifyContent: 'center',
                                height: '3px !important',
                                alignItems: 'center',
                              }}>
                              <CircularProgress />
                            </Box>
                            {/* )} */}
                          </div>
                        </TableCell>
                        <TableCell align="left">
                          <div
                            style={{
                              display: 'flex',
                              gap: '2px',
                              alignItem: 'center',
                              justifyContent: 'center',
                            }}>
                            <StarBorderOutlinedIcon
                              sx={{ fontSize: '20px', color: 'var(--sstar)' }}
                            />
                            <p>
                              {' '}
                              {row?.product.points *
                                row?.quantity}&nbsp;Points{' '}
                            </p>
                          </div>
                        </TableCell>

                        <TableCell
                          sx={{
                            borderRadius: '0px 10px 10px 0px',
                            // border: "1px solid grey",
                            marginRight: '20px',
                          }}
                          align="left">
                          {/* <img
                            style={{ cursor: 'pointer', marginRight: '5px' }}
                            onClick={() =>
                              handleremoveProduct(row?.product?._id)
                            }
                            src={deleteIcon}
                            // height={'30px'}
                            alt=""
                          /> */}
                          <div
                            onClick={() =>
                              handleremoveProduct(row?.product._id)
                            }
                            className="nav__iconsbg">
                            <div className="nav__Icons1">
                              <DeleteIconH style={{ width: 20, height: 20 }} />

                              <div className="nav__IconsH1">
                                <DeleteIcon style={{ width: 20, height: 20 }} />
                              </div>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className="employee__loader">
          <ProgressBar />
        </div>
      )}
    </div>
  );
};

export default CartTable;
