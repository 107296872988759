import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedDept: "",
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDepartment: (state, action) => {
      state.selectedDept = action.payload;
    },
  },
});

export const { setDepartment } = dashboardSlice.actions;

export default dashboardSlice.reducer;
