import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import * as React from "react";
import CredentialPoints from "./CredentialPoints/CredentialPoints";
import "./Credentials.css";
import { CredentialTier } from "./CredentialTier/CredentialTier";

const tiers = [
  {
    title: "Tier 1",
    steps: [
      {
        badge: {
          title: "Early Pioneer",
          image:
            "https://wajeshubham-portfolio.s3.ap-south-1.amazonaws.com/Early+Pioneer.png",
        },
        tasks: "15 tasks",
      },
      {
        badge: {
          title: "Leading Trailblazer",
          image:
            "https://wajeshubham-portfolio.s3.ap-south-1.amazonaws.com/Leading+Trailblazer.png",
        },
        tasks: "25 tasks",
      },
      {
        badge: {
          title: "Consistent Wanderer",
          image:
            "https://wajeshubham-portfolio.s3.ap-south-1.amazonaws.com/Consistent+Wanderer.png",
        },
        tasks: "35 tasks",
      },
      {
        badge: {
          image:
            "https://wajeshubham-portfolio.s3.ap-south-1.amazonaws.com/Quest+Rookie.png",
        },
        tasks: "Quest Rookie",
      },
    ],
  },
  {
    title: "Tier 2",
    steps: [
      {
        badge: {
          title: "Spirited Seeker",
          image:
            "https://wajeshubham-portfolio.s3.ap-south-1.amazonaws.com/Brilliant+Pathfinder.png",
        },
        tasks: "50 tasks",
      },
      {
        badge: {
          title: "Brilliant Pathfinder",
          image:
            "https://wajeshubham-portfolio.s3.ap-south-1.amazonaws.com/Brilliant+Pathfinder.png",
        },
        tasks: "65 tasks",
      },
      {
        badge: {
          title: "Prominent Forerunner",
          image:
            "https://wajeshubham-portfolio.s3.ap-south-1.amazonaws.com/Prominent+Forerunner.png",
        },
        tasks: "80 tasks",
      },
      {
        badge: {
          image:
            "https://wajeshubham-portfolio.s3.ap-south-1.amazonaws.com/Quest+Champion.png",
        },
        tasks: "Quest Champion",
      },
    ],
  },
  {
    title: "Tier 3",
    steps: [
      {
        badge: {
          title: "Gutsy Adventurer",
          image:
            "https://wajeshubham-portfolio.s3.ap-south-1.amazonaws.com/Gutsy+adventurer.png",
        },
        tasks: "100 tasks",
      },
      {
        badge: {
          title: "Worthy Voyager",
          image:
            "https://wajeshubham-portfolio.s3.ap-south-1.amazonaws.com/Worthy+Voyager.png",
        },
        tasks: "120 tasks",
      },
      {
        badge: {
          title: "Certified Explorer",
          image:
            "https://wajeshubham-portfolio.s3.ap-south-1.amazonaws.com/Certified+Explorer.png",
        },
        tasks: "140 tasks",
      },

      {
        badge: {
          image:
            "https://wajeshubham-portfolio.s3.ap-south-1.amazonaws.com/Quest+Conquerer.png",
        },
        tasks: "Quest Conquerer",
      },
    ],
  },
];

const points = [
  {
    title: "Bronze badge",
    image:
      "https://quest-static-bucket.s3.ap-south-1.amazonaws.com/point_badges/bronze_badge.png",
    description: "To get a Bronze badge you have to earn 250 points",
  },
  {
    title: "Silver badge",
    image:
      "https://quest-static-bucket.s3.ap-south-1.amazonaws.com/point_badges/silver_badge.png",
    description: "To get a Silver badge you have to earn 500 points",
  },
  {
    title: "Gold badge",
    image:
      "https://quest-static-bucket.s3.ap-south-1.amazonaws.com/point_badges/golden_badge.png",
    description: "To get a Gold badge you have to earn 1000 points",
  },
  {
    title: "Platinum badge",
    image:
      "https://quest-static-bucket.s3.ap-south-1.amazonaws.com/point_badges/platinum_badge.png",
    description: "To get a Platinum badge you have to earn 1500 points",
  },
  {
    title: "Diamond badge",
    image:
      "https://quest-static-bucket.s3.ap-south-1.amazonaws.com/point_badges/diamond_badge.png",
    description: "To get a Diamond badge you have to earn 2000 points",
  },
];
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const Credentials = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="container">
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label="Points"
              sx={{
                textTransform: "capitalize",
                marginRight: "20px",
                fontWeight: "500",
              }}
              {...a11yProps(0)}
            />
            <Tab
              sx={{ textTransform: "capitalize", fontWeight: "500" }}
              label="Tasks"
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <CredentialPoints points={points} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className="credentials__CredentialTier">
            {tiers.map((tier, i) => {
              return <CredentialTier key={i} tier={tier} />;
            })}
          </div>
        </TabPanel>
      </Box>
    </div>
  );
};
