import * as React from "react";
import "./Access.css";
import { Button } from "@mui/material";
import { AccessHead } from "./Access head/AccessHead";
import { AccessBottom } from "./Access Bottom/AccessBottom";

export const Access = () => {
  return (
    <div className="container">
      <div className="access">
        <AccessHead />
        <AccessBottom />
      </div>
    </div>
  );
};
