export const debounce = (func, waitFor) => {
  let timeout = null;

  const debounced = (...args) => {
    if (timeout !== null) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(() => func(...args), waitFor);
  };

  return debounced;
};

export const toHoursAndMinutes = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return { hours, minutes };
};

export const getFormattedTimeInHoursAndMinuteByMinutes = (totalMinutes) => {
  let label =
    (toHoursAndMinutes(totalMinutes)?.hours
      ? `${toHoursAndMinutes(totalMinutes)?.hours} hour${
          toHoursAndMinutes(totalMinutes)?.hours > 1 ? "s" : ""
        } `
      : "") +
    (toHoursAndMinutes(totalMinutes)?.minutes
      ? `${toHoursAndMinutes(totalMinutes)?.minutes} minute${
          toHoursAndMinutes(totalMinutes)?.minutes > 1 ? "s" : ""
        }`
      : "");
  return label || "0 hours";
};
