import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import { default as React, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import cartIcon from "../../../assets/rewards/cart.svg";
import { useAlert } from '../../../hooks/useAlert';
import {
  useAddCartMutation,
  useGetAllProductsEmployeeQuery,
  useGetCategoriesQuery,
  useGetUserCartQuery,
  useRemoveCartProductMutation,
} from '../../../redux-toolkit/features/rewards/rewardsApiSlice';
import { removeProduct } from '../../../redux-toolkit/features/rewards/rewardsSlice';
import './Shop.css';
import ShopCard from './ShopCard';
import SearchSvg from '../../../assets/Icons/Rewards_Search.png';

const Shop = ({ walletPoints }) => {
  const [search, setSearch] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  const [categoryId, setCategoryId] = React.useState('all');
  const dispatch = useDispatch();
  const rewards = useSelector((state) => state.rewards.cart);
  const { data: products, refetch } = useGetAllProductsEmployeeQuery(
    {
      query: search,
      category: categoryId === 'all' ? 'all' : categoryId._id,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { data: category } = useGetCategoriesQuery({
    refetchOnMountOrArgChange: true,
  });
  const [addCart] = useAddCartMutation();
  const [removeCartProduct] = useRemoveCartProductMutation();
  const { data: cart, refetch: refetchCart } = useGetUserCartQuery({
    refetchOnMountOrArgChange: true,
  });
  const { displayAlert } = useAlert();
  let totalQty = rewards.reduce((acc, curElm) => {
    return acc + curElm?.quantity;
  }, 0);

  useEffect(() => {
    refetchCart();
  }, []);

  useEffect(() => {}, [rewards, totalQty]);

  const handleAddProduct = async (productId, qty, type) => {
    const newQuantity = { quantity: qty, type: type };
    setIsLoading(true);
    await addCart({ productId, newQuantity })
      .then((data) => {
        // Display a success alert
        console.log(data);
        refetch();
        if (!qty && type === 'add') {
          displayAlert({
            open: true,
            message: 'Successfully added Product ',
            type: 'success',
            timeout: 1500,
          });
        } else if (data.error && data.error.status === 400) {
          displayAlert({
            open: true,
            message: `${data.error.data.message}`,
            type: 'error',
            timeout: 2000,
          });
        } else {
          displayAlert({
            open: true,
            message: `Product quantity updated Successfully`,
            type: 'success',
            timeout: 2000,
          });
        }

        refetchCart();
        setIsLoading(false);
      })
      .catch((error) => {
        // Display an error alert
        setIsLoading(false);

        displayAlert({
          open: true,
          message: error.message || 'Something Went Wrong',
          type: 'error',
          timeout: 1500,
        });
      });
  };
  const handleremoveProduct = (product) => {
    dispatch(removeProduct(product));
    refetchCart();
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div className="shop">
      <div className="shop__head">
        <div className="shop__dropdown">
          <div className="shop__dropdownChild">
            <h5 onClick={() => setCategoryId('all')}> All Products </h5>

            {category &&
              category?.data.categories.map((item) => (
                <h5
                  onClick={() => setCategoryId(item)}
                  key={item._id}>
                  {item.name}
                </h5>
              ))}
          </div>

          {categoryId === 'all' ? (
            <h4> All Products </h4>
          ) : (
            <h4>{categoryId.name}</h4>
          )}
          <KeyboardArrowDownOutlinedIcon sx={{ fontSize: '20px' }} />
        </div>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: 300,
            backgroundColor: 'white',
            borderRadius: '5px',
            border: '1px solid var(--grey300)',
          }}>
          <IconButton
            sx={{ padding: '1px 10px' }}
            aria-label="menu">
            <img
              src={SearchSvg}
              height={15}
              alt=""
            />
            {/* <SearchSvg sx={{ fontSize: '20px' }} /> */}
          </IconButton>
          <InputBase
            onChange={handleSearch}
            sx={{ flex: 1, fontSize: '16px', mr: 1 }}
            placeholder="Search..."
            inputProps={{ 'aria-label': 'search google maps' }}
          />
        </Box>
        <Link to={'/quest/cart'}>
          <div style={{ position: 'relative' }}>
            <Button
              variant="contained"
              startIcon={
                <img
                  src={cartIcon}
                  alt="cart_icon"
                />
              }>
              Cart
            </Button>
            {rewards.length > 0 && <div className="cart__qty"> {totalQty}</div>}
          </div>
        </Link>
      </div>
      <div className="shop__cards">
        {products &&
          products?.data?.products.map((item, index) => (
            <div key={index}>
              <ShopCard
                isLoading={isLoading}
                refetchCart={refetchCart}
                walletPoints={walletPoints}
                removeCartProduct={removeCartProduct}
                item={item}
                rewards={rewards}
                handleremoveProduct={handleremoveProduct}
                handleAddProduct={handleAddProduct}
              />
            </div>
          ))}
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '50px 0 -30px 0',
        }}>
        <Link to={'/quest/cart'}>
          <Button
            sx={{
              color: 'gray',
              marginBottom: '50px',
              borderColor: 'gray',
              '&:hover': { color: 'var(--pblue)' },
            }}
            variant="outlined">
            Proceed to checkout
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default Shop;
