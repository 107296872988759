import React from "react";
import { ReportCard } from "../../Admin/Reports/ReportCard";
import "../../Admin/Reports/Reports.css";
import customReports from "../../../assets/Icons/SuperAdmin _ Reports/Custom Report.svg";
import OrgBooksActivity from "../../../assets/Icons/SuperAdmin _ Reports/Organization Book Activity.svg";
import OrgUserStatistics from "../../../assets/Icons/SuperAdmin _ Reports/Organization User Statistics.svg";
import UserActivityReports from "../../../assets/Icons/SuperAdmin _ Reports/user activity report.svg";

const reportData = [
  {
    _id: 1,
    icon: UserActivityReports,
    label: 'Department User Summary',
    link: 'user-summary',
    description:
      'Total number of employees under each department and their activity.',
  },

  {
    _id: 3,
    icon: OrgBooksActivity,
    label: ' Department Book Summary',
    link: 'book-summary',
    description:
      'Elaborated details of each book created under any department.',
  },
  {
    _id: 4,
    icon: OrgUserStatistics,
    label: 'Organizational User Statistics',
    link: 'user-statistic',
    description: 'Statistics of users under each department.',
  },

  // {
  //   _id: 12,
  //   icon: customReports,
  //   label: "Custom Report",
  //   link: "custom-reports",
  //   description: "Create your own report.",
  // },
];

export const SaReports = () => {
  return (
    <div className="container">
      <div className="reports">
        {reportData.map((elm) => (
          <ReportCard base={"/super-admin/reports"} data={elm} />
        ))}
      </div>
    </div>
  );
};
