import { apiSlice } from "../../api/apiSlice";

export const pointsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPoints: builder.query({
      query: () => "/api/user/points/stats",
      method: "GET",
    }),
  }),
});

export const { useGetPointsQuery } = pointsApiSlice;
