import { Avatar } from "@mui/material";
import React from "react";
import { BreadcrumbAdmin } from "../../../UI/BreadcrumbAdmin";
import "./TaskDetailed.css";
import DefaultMaleProfilePic from "../../../../assets/Icons/profile/Profile icon _ male.png";
import { TaskAccordian } from "./TaskAccordian";
import { useGetPendingTaskByIdQuery } from "../../../../redux-toolkit/features/books/bookApiSlice";
import { useParams } from "react-router-dom";
import ProgressBar from "../../../UI/ProgressBar";
import { Error404 } from "../../../UI/404/Error404";
import { NoRecord } from "../../../UI/No record/NoRecord";

const data = [
  {
    _id: "B1",
  },
  {
    _id: "B2",
  },
  {
    _id: "B3",
  },
  {
    _id: "B4",
  },
  {
    _id: "B5",
  },
  {
    _id: "B6",
  },
  {
    _id: "B7",
  },
];

export const TaskDetailed = () => {
  const { _id } = useParams();
  const {
    data: allTasks,
    isLoading,
    isFetching,
    isError,
  } = useGetPendingTaskByIdQuery(_id, {
    refetchOnMountOrArgChange: true,
  });

  const base = [
    {
      _id: 1,
      link: "/admin/task-verification",
      label: "Task Verification",
    },
  ];

  if (isLoading || isFetching) {
    return <ProgressBar />;
  }

  if (isError) {
    return <Error404 />;
  }
  console.log(allTasks);

  return (
    <>
      {Object.keys(allTasks.data).length != 0 ? (
        <div className="container">
          <BreadcrumbAdmin base={base} page={"All Tasks"} />

          <div className="taskdetHead">
            <div className="task__head">
              <div className="task__headprofile">
                <Avatar
                  alt="Remy Sharp"
                  sx={{ width: "65px", height: "65px" }}
                  src={
                    allTasks?.data.user.avatar
                      ? allTasks?.data.user.avatar
                      : DefaultMaleProfilePic
                  }
                />

                <div className="task__headnotf">
                  {allTasks?.data?.pendingTasks}
                </div>
              </div>
              <div>
                <div>
                  <h2>
                    {allTasks?.data.user.fname} {allTasks?.data.user.lname}
                  </h2>
                </div>
                <div>
                  <h4 className="ftwt__r"> {allTasks?.data.user.department}</h4>
                </div>
              </div>
            </div>
            <div className="taskdetHead__right">
              <div className="taskDet__books">
                <div className="taskDet__bookshead">
                  <h5 className="ftwt__r">Progress</h5>
                  <h6 className="ftwt__r">
                    {allTasks?.data.submittedTasks}/{allTasks?.data.totalTasks}
                    &nbsp; Tasks
                  </h6>
                </div>
                <div className="taskDet__bookscontent">
                  {data.map((elm, index) => (
                    <div
                      key={index}
                      className={
                        index < allTasks?.data.lastBook
                          ? "taskDet__bookscard"
                          : "taskDet__bookscardIA"
                      }
                    >
                      <caption>Book {index + 1}</caption>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="taskdetHead__cards">
            {allTasks &&
              allTasks?.data.tasks.map((curElm, index) => (
                <TaskAccordian taskData={curElm} />
              ))}
          </div>
        </div>
      ) : (
        <div className="container">
          <BreadcrumbAdmin base={base} />

          <NoRecord />
        </div>
      )}
    </>
  );
};
