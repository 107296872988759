import React, { useState } from "react";
import "./AssignMultiple.css";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Checkbox } from "@mui/material";
import { useGetAllUsersDeptQuery } from "../../../../../redux-toolkit/features/department/departmentApiSlice";
import ProgressBar from "../../../../UI/ProgressBar";
import { Error404 } from "../../../../UI/404/Error404";
import { useAssignMultipleRolesMutation } from "../../../../../redux-toolkit/features/employee/employeeApiSlice";
import { useAlert } from "../../../../../hooks/useAlert";
export const AssignMultiple = ({
  selectedEmployee,
  setSelectedEmployee,
  setOpenAssignRole,
}) => {
  const [active, setActive] = useState("6359025cf0f19b14f64673d9");
  const [check, setCheck] = useState(false);
  const { data, isLoading, isError } = useGetAllUsersDeptQuery();
  const [assignMultipleRoles] = useAssignMultipleRolesMutation();
  const { displayAlert } = useAlert();

  const removeEmployeeHandler = (_id) => {
    const filtered = selectedEmployee.filter((item) => item != _id);
    setSelectedEmployee(filtered);
  };

  const saveHandler = async () => {
    const finalData = {
      userIds: selectedEmployee,
      permissionSetId: active,
    };
    await assignMultipleRoles(finalData)
      .unwrap()
      .then(() => {
        displayAlert({
          open: true,
          message: 'Successfully Updated Roles',
          type: 'success',
          timeout: '2000',
        });
        setSelectedEmployee([]);
      })
      .catch((err) => {
        console.log(err);
        displayAlert({
          open: true,
          message: `${err}`,
          type: 'error',
          timeout: '2000',
        });
      });
    setOpenAssignRole(false);
  };

  if (isLoading) {
    return <ProgressBar />;
  }

  if (isError) {
    return <Error404 />;
  }

  return (
    <div className="assignmultiple">
      <div className="assignmultiple__users">
        {selectedEmployee.length > 0 ? (
          data &&
          data?.data.users
            .filter((item) => selectedEmployee?.includes(item._id))
            .map((elm, index) => (
              <div key={index} className="assignmultiple__userData">
                <div>
                  <h5>{elm.fname + elm.lname}</h5>
                  <h6 className="ftwt__r">{elm.email}</h6>
                </div>
                <CloseIcon
                  onClick={() => removeEmployeeHandler(elm._id)}
                  sx={{
                    fontSize: "18px",
                    cursor: "pointer",
                    color: "var(--grey400)",
                    "&:hover": {
                      color: "var(--error)",
                    },
                  }}
                />
              </div>
            ))
        ) : (
          <h4 style={{ textAlign: "center" }}>
            Select atleast one user to assign role
          </h4>
        )}
      </div>
      <div>
        <div
          onClick={() => setActive("6359025cf0f19b14f64673d9")}
          className={
            active === "6359025cf0f19b14f64673d9"
              ? "assignmultiple__roleActive"
              : "assignmultiple__role"
          }
        >
          <h6>Admin</h6>
        </div>
        <div
          onClick={() => setActive("63593a15dcfcbad0acd22697")}
          className={
            active === "63593a15dcfcbad0acd22697"
              ? "assignmultiple__roleActive"
              : "assignmultiple__role"
          }
        >
          <h6>Super Admin</h6>
        </div>
      </div>
      <div className="assignmultiple__check">
        <Checkbox
          checked={check}
          onChange={() => setCheck(!check)}
          sx={{ "& .MuiSvgIcon-root": { fontSize: "20px" } }}
        />
        <div>
          <h6 className="ftwt__m">Confirm the role changes of users.</h6>
          <h6 className="ftwt__r assignmultiple__small">
            Changing role enables the user to access permissions assigned under
            that category.
          </h6>
        </div>
      </div>

      <div className="assignmultiple__actions">
        <Button variant="outlined">Cancel</Button>
        <Button
          disabled={!check || selectedEmployee.length === 0}
          variant="contained"
          onClick={saveHandler}
        >
          Save
        </Button>
      </div>
    </div>
  );
};
