import React from 'react';
import './Feedback.css';
import { ReactComponent as FeedbackSvg } from '../../../assets/Icons/profile/new feedback_white.svg';

const Feedback = ({ handleFeedbackOpen, hasGivenFeedback, sessionTime }) => {
  const isAnimate = sessionTime > 15 && sessionTime < 21;
  return (
    <>
      {/* {hasGivenFeedback && ( */}
      <div
        onClick={handleFeedbackOpen}
        className={`feedback   ${isAnimate && 'feedback__anim'}`}>
        <FeedbackSvg style={{ height: '25px', width: '25px' }} />
      </div>
      {/* )} */}
    </>
  );
};

export default Feedback;
