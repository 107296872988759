import * as React from "react";
import "./Workspace.css";
import { ReactComponent as EditSvg } from '../../../assets/Icons/edit (1).svg';
import { ReactComponent as DeleteSvg } from "../../../assets/Icons/Delete _ Default.svg";
import { useOutsideClick } from "../../../hooks/useOutsideClick";

export default function DepDrawer({
  deletebtnHandler,
  index,
  setDrawer,
  editbtnHandler,
}) {
  const ref = React.useRef(null);

  const closePopup = () => {
    setDrawer && setDrawer(false);
  };

  useOutsideClick(ref, closePopup);

  return (
    <>
      <div
        ref={ref}
        className="wsDrawerActive">
        <p onClick={editbtnHandler}>
          <EditSvg style={{ width: '15px' }} />
          Edit
        </p>
        <p onClick={deletebtnHandler}>
          <DeleteSvg style={{ width: '15px' }} /> Delete
        </p>
      </div>
    </>
  );
}
