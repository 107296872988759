import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
export const PrivateRoutes = ({
  component: Component,
  permissions,
  role,
  ...rest
}) => {
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);
  let hasPermission = permissions.includes(user?.role);

  return hasPermission ? (
    <Outlet />
  ) : (
    <Navigate
      to="/restricted"
      state={{ from: location }}
      replace
    />
  );
};
