import React, { useState } from 'react';
import PointsCounter from './components/PointsCounter';
import './RewardsEmp.css';
// import ProgressBar from '../../../UI/ProgressBar';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Shop from './Shop/Shop';
import { RedeemStatus } from './Redeem status/RedeemStatus';
import { useSelector } from 'react-redux';
import { useGetPointsStatusQuery } from '../../redux-toolkit/features/rewards/rewardsApiSlice';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ paddingTop: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const RewardsEmp = () => {
  const [value, setValue] = useState(0);
  const user = useSelector((state) => state.auth.user);
  const { data: points } = useGetPointsStatusQuery({});

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
   return (
     <div className="container__mact">
       <div className="bg__rewards">
         <div className="mact">
           <h1 className="rewards__head">Qoin Wallet</h1>
           <PointsCounter points={points?.data.walletPoints} />
           <h3 className="rewards__descp">
             Total Points: {points?.data.earnedPoints}
           </h3>

           <Box sx={{ width: '100%', marginTop: '10px' }}>
             <Box
               sx={{
                 borderBottom: 1,
                 borderColor: 'divider',
                 display: 'flex',
                 justifyContent: 'center',
               }}>
               <Tabs
                 value={value}
                 onChange={handleChange}
                 aria-label="basic tabs example">
                 <Tab
                   label="Shop"
                   sx={{
                     textTransform: 'capitalize',
                     marginRight: '20px',
                     fontWeight: '500',
                   }}
                   {...a11yProps(0)}
                 />

                 <Tab
                   sx={{ textTransform: 'capitalize', fontWeight: '500' }}
                   label="Redeem Status"
                   {...a11yProps(1)}
                 />
               </Tabs>
             </Box>

             <TabPanel
               value={value}
               index={0}>
               <Shop walletPoints={points?.data.walletPoints} />
             </TabPanel>

             <TabPanel
               value={value}
               index={1}>
               <div className="redeemStatus">
                 <RedeemStatus />
               </div>
             </TabPanel>
           </Box>
         </div>
       </div>
     </div>
   );
};

export default RewardsEmp;
