import React, { useEffect, useState } from "react";
import "./Sidebar.css";
import ForumChannelSvg from "../../../../assets/Icons/sidebar/Forum channels.svg";
import EmployeeDSvg from '../../../../assets/Side Bar/Super Admin/dark Mode/Employee.svg';
// import EmployeeDHSvg from '../../../../assets/Side Bar/Super Admin/dark Mode/em';

import SidebarListItem from './SidebarListItem';
import { Divider, Grid, IconButton, Switch } from '@mui/material';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import TaskVerification from "../../../../assets/Icons/sidebar/Task Verification _ normal.svg";
import Workspace from '../../../../assets/Icons/sidebar/My Workspace _ normal stste.svg';

import { ReactComponent as DarkModeActive } from '../../../../assets/Icons/theme/Dark mode _ active state.svg';
import { ReactComponent as DarkModeNormal } from '../../../../assets/Icons/theme/Dark mode _ normal state.svg';
import { ReactComponent as LightModeActive } from '../../../../assets/Icons/theme/Light mode _ active state.svg';
import { ReactComponent as LightModeNormal } from '../../../../assets/Icons/theme/Light mode _ normal state.svg';
import ForumListItem from './ForumListItem';
import { useDispatch, useSelector } from 'react-redux';
import whiteLogo from '../../../../assets/White logo.svg';
import {
  selectedTheme,
  themeToggler,
  setActiveItem,
} from '../../../../redux-toolkit/features/theme/themeSlice';
import { Transition } from 'react-transition-group';
import { useGetInitialAppliedThemeQuery } from '../../../../redux-toolkit/features/theme/themeApiSlice';

const forumItemList = [
  {
    id: 1,
    name: 'Employee',
    icon: ForumChannelSvg,
    iconOnHover: ForumChannelSvg,
    iconD: ForumChannelSvg,
    iconOnHoverD: ForumChannelSvg,
    altText: 'Employee',
    link: '/super-admin/manage-organization',
  },
  {
    id: 2,
    name: 'Department',
    icon: ForumChannelSvg,
    iconOnHover: ForumChannelSvg,
    altText: 'Design',
    link: '/super-admin/manage-organization',
  },
  {
    id: 3,
    name: 'Access',
    icon: ForumChannelSvg,
    iconOnHover: ForumChannelSvg,
    altText: 'Technical',
    link: '/super-admin/manage-organization',
  },
  {
    id: 4,
    name: 'Credentials',
    icon: ForumChannelSvg,
    iconOnHover: ForumChannelSvg,
    altText: 'Credentials',
    link: '/super-admin/manage-organization',
  },
];
const duration = 500;
const sidebarStyle = {
  transition: `width ${duration}ms ease`,
};
const sidebarTransitionStyles = {
  entering: { width: '52px' },
  entered: { width: '270px' },
  exiting: { width: '270px' },
  exited: { width: '52px' },
};

const modeSwitcherStyle = {
  transition: `opacity ${duration}ms`,
  flexWrap: 'nowrap',
};

const opacitySwitchTransitionStyles = {
  entering: { opacity: 0, width: 0, height: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 1 },
  exited: { opacity: 0, width: 0, height: 0 },
};
const opacitySwitchButtonTransitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 0, width: 0, height: 0 },
  exiting: { opacity: 0, width: 0, height: 0 },
  exited: { opacity: 1 },
};

const sidebarLinksWidthTransitionStyles = {
  entering: { width: '50%' },
  entered: { width: '85%' },
  exiting: { width: '85%' },
  exited: { width: '42%' },
};

const opacityArrowTransitionStyles = {
  entering: { transform: 'rotate(0)' },
  entered: { transform: 'rotate(180deg)' },
  exiting: { transform: 'rotate(180deg)' },
  exited: { transform: 'rotate(0)' },
};

export const Sidebar = ({ open, handleSidebar, sideBarAdmin }) => {
  // const [activeItem, setActiveItem] = useState(sideBarAdmin[0].id);
  const [forumList, setForumList] = useState(false);
  const theme = useSelector(selectedTheme);
  const { data, refetch } = useGetInitialAppliedThemeQuery({
    refetchOnMountOrArgChange: true,
  });
  const { activeItem } = useSelector((state) => state.theme);
  const [activeOrg, setActiveOrg] = useState(false);
  const [activeQuest, setActiveQuest] = useState(false);
  const dispatch = useDispatch();
  function onClick(id) {
    dispatch(setActiveItem(id));
  }

  const handleSwitchChange = (e) => {
    dispatch(themeToggler());
  };

  const handleForumDropDown = () => {
    setForumList(!forumList);
  };

  return (
    <div className="sidebar__layout">
      <Transition
        in={open}
        timeout={150}>
        {(state) => (
          <>
            <div className="sidebar__switch">
              <IconButton
                onClick={handleSidebar}
                aria-label="sidebar-switch"
                size="small">
                <ArrowForwardIosIcon
                  sx={{
                    color: 'white',
                    strokeWidth: 1,
                    stroke: 'white',
                    transition: `transform ${duration}ms  `,
                  }}
                  style={{ ...opacityArrowTransitionStyles[state] }}
                  fontSize="inherit"
                />
              </IconButton>
            </div>
            <div
              className="sidebar"
              style={{
                ...sidebarStyle,
                ...sidebarTransitionStyles[state],
              }}>
              <div>
                <div
                  className="sidebar__logo"
                  style={{
                    ...opacitySwitchTransitionStyles[state],
                  }}>
                  {/* {theme ? <img src={logo} alt="" /> : <img src={whiteLogo} alt="" />} */}
                </div>

                <div className="sidebar__content">
                  <div
                    className="sidebar__links"
                    style={{
                      ...sidebarLinksWidthTransitionStyles[state],
                      ...sidebarStyle,
                    }}>
                    <ul>
                      {sideBarAdmin.map((listItem) => {
                        return (
                          <SidebarListItem
                            activeOrg={activeOrg}
                            setActiveOrg={setActiveOrg}
                            activeQuest={activeQuest}
                            setActiveQuest={setActiveQuest}
                            theme={data?.data.mode}
                            key={listItem.id}
                            active={activeItem === listItem.id}
                            onClick={onClick}
                            access={listItem.access}
                            handleForumDropDown={handleForumDropDown}
                            open={open}
                            {...listItem}
                          />
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Transition>
    </div>
  );
};
