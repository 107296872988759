import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allUsers: [],
};

export const departmentSlice = createSlice({
  name: "department",
  initialState,
  reducers: {
    setAllUsers: (state, action) => {
      state.allUsers = action.payload;
    },
  },
});

export const { setAllUsers } = departmentSlice.actions;

export default departmentSlice.reducer;
