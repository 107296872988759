import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Avatar } from "@mui/material";
import { Paper } from "@mui/material";
import Switch from "@mui/material/Switch";
import ProgressBar from "../../../../UI/ProgressBar";
const rows = [
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: true,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: false,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: true,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: true,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: true,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: true,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: true,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: true,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: true,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: true,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: true,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: false,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: true,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: false,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: true,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: true,
  },
];

export const CustomStatisticTable = ({ check }) => {
  React.useEffect(() => {}, [check]);
  return (
    <div>
      <TableContainer
        sx={{
          height: '100%',
          maxHeight: 'calc( 100vh - 220px)',
          padding: '0 1px',
          width: '2667px',
        }}>
        <Table
          stickyHeader
          aria-label="sticky table">
          <TableHead>
            <TableRow
              sx={{ borderRadius: '20px 20px 0px 0px' }}
              className="tableUi__head">
              {check.empName && (
                <TableCell
                  sx={{ borderRadius: '20px 0px 0px 0px' }}
                  align="center">
                  Employee Name{' '}
                </TableCell>
              )}
              {check.email && (
                <TableCell align="center">Employee Email</TableCell>
              )}
              {check.empId && <TableCell align="center">Employee Id</TableCell>}
              {check.empDep && (
                <TableCell align="center">Employee Department</TableCell>
              )}
              {check.lLogin && <TableCell align="center">Last login</TableCell>}
              {check.bookComp && (
                <TableCell align="center">Book Completed</TableCell>
              )}
              {check.bookInProg && (
                <TableCell align="center">Book Inprogress</TableCell>
              )}
              {check.pointsEarned && (
                <TableCell align="center">Points Earned</TableCell>
              )}
              {check.status && <TableCell align="center">Status</TableCell>}
              {check.bookId && <TableCell align="center">Book Id</TableCell>}
              {check.bookDept && (
                <TableCell align="center">Book Department</TableCell>
              )}
              {check.bookCreator && (
                <TableCell align="center">Book Creator</TableCell>
              )}
              {check.depName && (
                <TableCell align="center">Department name</TableCell>
              )}
              {check.Id && <TableCell align="center">Department Id</TableCell>}
              {check.hod && (
                <TableCell align="center">Department HOD</TableCell>
              )}
              {check.noOfCompletions && (
                <TableCell align="center">No of Completion</TableCell>
              )}

              {check.noofInprogress && (
                <TableCell align="center">No of Inprogress</TableCell>
              )}
              {check.pointsAssigned && (
                <TableCell
                  sx={{ borderRadius: '0px 20px 0px 0px' }}
                  align="center">
                  Points Assigned
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <>
                <div className="row__spacing"></div>
                <TableRow
                  className="tableUi___body"
                  key={row?.name}
                  // sx={{
                  //   // "&:last-child td, &:last-child th": {
                  //   //  //     border: "1px solid grey",
                  //   // },
                  //   borderRadius: "20px",
                  // }}
                  sx={{ borderRadius: '15px' }}>
                  {check.empName && (
                    <TableCell
                      sx={{
                        borderRadius: '15px 0px 0px 15px',
                        //     border: "1px solid grey",
                      }}
                      align="center">
                      {row?.fname}
                    </TableCell>
                  )}
                  {check.email && (
                    <TableCell align="center">{row?.fname}</TableCell>
                  )}
                  {check.empId && (
                    <TableCell align="center">{row?.lname}</TableCell>
                  )}
                  {check.empDep && <TableCell align="center">7849</TableCell>}
                  {check.lLogin && <TableCell align="center">7812</TableCell>}
                  {check.bookComp && <TableCell align="center">7812</TableCell>}
                  {check.bookInProg && <TableCell align="center">38</TableCell>}
                  {check.pointsEarned && (
                    <TableCell align="center">38</TableCell>
                  )}
                  {check.status && <TableCell align="center">38</TableCell>}
                  {check.bookId && <TableCell align="center">38</TableCell>}
                  {check.bookDept && <TableCell align="center">38</TableCell>}
                  {check.bookCreator && (
                    <TableCell align="center">38</TableCell>
                  )}
                  {check.depName && <TableCell align="center">38</TableCell>}

                  {check.Id && <TableCell align="center">38</TableCell>}
                  {check.hod && <TableCell align="center">38</TableCell>}
                  {check.noOfCompletions && (
                    <TableCell align="center">38</TableCell>
                  )}
                  {check.noofInprogress && (
                    <TableCell align="center">38</TableCell>
                  )}

                  {check.pointsEarned && (
                    <TableCell
                      sx={{
                        borderRadius: '0px 15px 15px 0px',
                        //     border: "1px solid grey",
                      }}
                      align="center">
                      6000
                    </TableCell>
                  )}
                </TableRow>
                <div className="row__spacing"></div>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
