import { Button } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useSelector, useDispatch } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { useResetPasswordMutation } from "../../redux-toolkit/features/auth/authApiSlice";
import { Link, useParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import React from "react";
import { useAlert } from "../../hooks/useAlert";

const validationSchema = yup.object({
  password: yup
    .string()
    .min(6, "Password length is less than 6 characters ")
    .required("This field is required"),
  confirmPassword: yup.string().when("password", {
    is: (val) => (val && val.length > 0 ? true : false),
    then: yup
      .string()
      .oneOf([yup.ref("password")], "Both passwords should match"),
  }),
});

export const Respassword = (props) => {
  const [resetPassword, { isLoading, isSuccess, isError }] =
    useResetPasswordMutation();
  const user = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const { id, token } = useParams();
  const [passwordType, setPasswordType] = React.useState("password");
  const [cPasswordType, setCPasswordType] = React.useState("password");
  const [notification, setNotification] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const { displayAlert } = useAlert();

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const toggleConfirmPassword = () => {
    if (cPasswordType === "password") {
      setCPasswordType("text");
      return;
    }
    setCPasswordType("password");
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const data = values;
    },
  });

  const resetHandler = async () => {
    if (!formik.errors.confirmPassword && !formik.errors.password) {
      if (formik.values.password === formik.values.confirmPassword) {
        let password = { password: formik.values.password };
        await resetPassword({ password, id, token })
          .unwrap()
          .then(() => {
            displayAlert({
              open: true,
              message: 'Password successfully reset.',
              type: 'success',
            });
          })
          .catch((err) => {
            console.log(err);
            displayAlert({
              open: true,
              message: 'Server error.',
              type: 'error',
            });
          });
      } else {
        displayAlert({
          open: true,
          message: "Incorrect Credentials",
          type: "error",
        });
      }
    }
  };

  return (
    <>
      {/* <Notify notification={notification} setNotification={setNotification} /> */}
      <form className="login__form" onSubmit={formik.handleSubmit}>
        <div className="password__input__wrapper">
          <input
            type={passwordType}
            placeholder="New Password"
            name="password"
            className={
              formik.touched.password && formik.errors.password
                ? "login__input login__invalid"
                : "login__input"
            }
            value={formik.values.password}
            onChange={formik.handleChange}
          />
          <i className="password__visibility__icon" onClick={togglePassword}>
            {passwordType === "password" ? (
              <VisibilityIcon />
            ) : (
              <VisibilityOffIcon />
            )}
          </i>{" "}
        </div>
        {formik.touched.password && formik.errors.password && (
          <div className="login__error">
            <ErrorOutlineIcon fontSize="small" color="error" />

            <span>{formik.errors.password}</span>
          </div>
        )}
        <div className="password__input__wrapper">
          <input
            type={cPasswordType}
            placeholder="Confirm Password"
            name="confirmPassword"
            className={
              formik.touched.confirmPassword && formik.errors.confirmPassword
                ? "login__input login__invalid"
                : "login__input"
            }
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
          />
          <i
            className="password__visibility__icon"
            onClick={toggleConfirmPassword}
          >
            {cPasswordType === "password" ? (
              <VisibilityIcon />
            ) : (
              <VisibilityOffIcon />
            )}
          </i>
        </div>
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className="login__error">
            <ErrorOutlineIcon fontSize="small" color="error" />

            <span>{formik.errors.confirmPassword}</span>
          </div>
        )}
        <Button
          fullWidth
          type="submit"
          sx={{
            marginTop: "40px",
            backgroundColor: "var(--sblue)",
            padding: "15x 10px",
          }}
          onClick={resetHandler}
          variant="contained"
          // onClick={() => dispatch(login())}
        >
          Submit
        </Button>
        <Link style={{ textDecoration: "none" }} to={"/login"}>
          <p className="login__forgot">Back To Login</p>
        </Link>
      </form>
    </>
  );
};
