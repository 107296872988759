import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Forum.css";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useSelector } from "react-redux";

const ForumListItem = ({
  id,
  active,
  onClick,
  name,

  theme,
  icon,
  iconOnHover,
  iconD,
  iconOnHoverD,
  altText,
  link,
  open,
  access,
  setActiveQuest,
}) => {
  const navigate = useNavigate();
  const [iconSrc, setIconSrc] = useState(icon);
  const user = useSelector((state) => state.auth.user);
  const [isHovering, setIsHovering] = useState(false);

  const handleHoverEnter = () => {
    setIsHovering(true);
  };

  const handleHoverLeave = () => {
    setIsHovering(false);
  };

  React.useEffect(() => {}, [active]);

  let hasAccess = true;
  if (access.length > 0) {
    hasAccess =
      user?.allowedAccess.filter((value) => access.includes(value)).length > 0;
  }

  const handleMouseEnter = (iconPath, isH) => {
    if (isH) {
      handleHoverEnter();
    } else {
      handleHoverLeave();
    }
    return () => {
      setIconSrc(iconPath);
    };
  };

  const handleRedirect = () => {
    setIconSrc(iconOnHover);
    onSelfClick();
    navigate(link);
    // setActiveQuest(false);
  };

  function onSelfClick() {
    onClick(id);
  }


  return (
    <>
      {hasAccess && (
        <li
          onMouseEnter={() => handleMouseEnter(iconOnHover, true)}
          onMouseLeave={() => handleMouseEnter(icon, false)}
          onClick={handleRedirect}
          className={`forum__link ${
            active ? 'forum__active sidebar__child' : 'sidebar__child inactive'
          }`}>
          <span className="forum__icon">
            {theme === 'light' ? (
              <img
                src={active || isHovering ? iconOnHoverD : iconD}
                alt={altText}
              />
            ) : (
              <img
                src={active || isHovering ? iconOnHover : icon}
                alt={altText}
              />
            )}
          </span>
          <span style={{ color: theme === 'light' ? 'white' : '' }}>
            {name}
          </span>
        </li>
      )}
    </>
  );
};

export default ForumListItem;
