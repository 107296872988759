import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "./Departments.css";
import { ReactComponent as EditSvg } from '../../../../assets/Icons/edit (1).svg';
import { ReactComponent as DeleteSvg } from "../../../../assets/Icons/Delete _ Default.svg";

export default function DepDrawer({
  setDeleteOpen,
  editDepHandler,
  index,
  setDepId,
  setDrawer,
}) {
  const deletebtnHandler = () => {
    setDepId(index);
    setDrawer(0);
    setDeleteOpen(true);
  };
  const editbtnHandler = () => {
    setDepId(index);
    editDepHandler();
  };

  return (
    <>
      <div className="depDrawerActive">
        <p onClick={editbtnHandler}>
          <EditSvg style={{ width: "15px" }} />
          Edit
        </p>
        <p onClick={deletebtnHandler}>
          <DeleteSvg style={{ width: "15px" }} /> Delete
        </p>
      </div>
    </>
  );
}
