import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Autocomplete, TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import "./CreateCourse.css";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import { useCreateCourseMutation } from "../../../redux-toolkit/features/courses/coursesApiSlice";
import dayjs, { Dayjs } from "dayjs";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  right: '50%',
  maxHeight: '80vh',
  overflowY: 'auto',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  borderRadius: 3,
  boxShadow: 24,
  p: '25px 40px',
};

const validationSchema = yup.object({
  name: yup.string("Enter course name").required("course name is required"),
  description: yup
    .string("Enter your description")
    .min(10, "Description should be of minimum 8 characters length")
    .required("Description is required"),
  organizer: yup
    .string("Enter organizer name")
    .required("organizer name is required"),
  link: yup
    .string("Enter link to Register")
    .required("link to Register is required"),
  date: yup.date("Enter the date").required("date is required"),
});

export const CreateEvent = ({ eventOpen, setEventOpen }) => {
  const [createCourse] = useCreateCourseMutation();
  const [value, setValue] = React.useState();

  const handleEventClose = () => setEventOpen(false);
  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      organizer: "",
      link: "",
      notify: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const data = JSON.stringify(values, null, 2);
      // console.log(data);
      //   createCourse(data);
    },
  });

  return (
    <div>
      <div>
        <Modal
          open={eventOpen}
          onClose={handleEventClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <form onSubmit={formik.handleSubmit}>
            <Box sx={style}>
              <div className="create__form">
                <div className="create__formhead">
                  <h1>Event Details</h1>
                  <CloseIcon
                    onClick={handleEventClose}
                    sx={{
                      fontSize: "18px",
                      cursor: "pointer",
                      color: "var(--grey400)",
                      "&:hover": {
                        color: "var(--error)",
                      },
                    }}
                  />
                </div>

                <div className="seprator"></div>

                <h3 className="ftwt__r">
                  Event Name <span>*</span>
                </h3>

                <TextField
                  required
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
                <h3 className="ftwt__r">
                  Description <span>*</span>
                </h3>
                <TextField
                  required
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
                <h3 className="ftwt__r">
                  Organizer <span>*</span>
                </h3>
                <TextField
                  required
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  name="organizer"
                  value={formik.values.organizer}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.organizer && Boolean(formik.errors.organizer)
                  }
                  helperText={
                    formik.touched.organizer && formik.errors.organizer
                  }
                />

                <h3 className="ftwt__r">
                  Link to Register <span>*</span>
                </h3>
                <TextField
                  required
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  name="link"
                  value={formik.values.link}
                  onChange={formik.handleChange}
                  error={formik.touched.link && Boolean(formik.errors.link)}
                  helperText={formik.touched.link && formik.errors.link}
                />
                <h3 className="ftwt__r">
                  Date And Time<span>*</span>
                </h3>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  {/* <Stack spacing={0}> */}
                  <DateTimePicker
                    renderInput={(params) => <TextField {...params} />}
                    value={value}
                    onChange={(newValue) => {
                      setValue(newValue);
                    }}
                  />
                  {/* </Stack> */}
                </LocalizationProvider>
                <h3 className="ftwt__r">
                  Notify<span>*</span>
                </h3>
                <TextField
                  required
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  name="notify"
                  value={formik.values.notify}
                  onChange={formik.handleChange}
                  error={formik.touched.notify && Boolean(formik.errors.notify)}
                  helperText={formik.touched.notify && formik.errors.notify}
                />
              </div>
              <div className="createcourse__action">
                <button onClick={formik.handleReset} className="outlined__btn">
                  Discard
                </button>
                <Button type="submit" variant="contained">
                  Post
                </Button>
              </div>
            </Box>
          </form>
        </Modal>
      </div>
    </div>
  );
};
