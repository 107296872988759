import React from 'react';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { Button } from '@mui/material';
import './Cart.css';
import { Link } from 'react-router-dom';

const Thankyou = () => {
  return (
    <div className="thanks__layout">
      <div className="thanks">
        <div className="thanks__correct">
          <CheckOutlinedIcon
            sx={{ fontSize: '60px', color: 'var(--sgreen)' }}
          />
        </div>
        <h1>Your Request to redeem has been successfully shared</h1>
        <Link to={'/quest/rewards'}>
          {' '}
          <Button variant="outlined">Go Back</Button>
        </Link>
      </div>
    </div>
  );
};

export default Thankyou;
