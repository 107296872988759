import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Login } from './Components/Login/Login';
import PersistLogin from './Components/Login/PersistLogin';
import { RequireAuth } from './Components/Login/RequireAuth';
import ProtectedRoute from './Components/Protected/ProtectedRoute';
import { LEVELS } from './config/levels';
import { selectCurrentToken } from './redux-toolkit/features/auth/authSlice';
import { Account } from './Components/Account/Account';
import { AdminDashboard } from './Components/Admin/Dashboard/AdminDashboard';
import { EditBooks } from './Components/Admin/My Workspace/Edit books/EditBooks';
import { EditCourses } from './Components/Admin/My Workspace/Edit courses/EditCourses';
import { Workspace } from './Components/Admin/My Workspace/Workspace';
import { Reports } from './Components/Admin/Reports/Reports';
import { DepartmentBooksStatistic } from './Components/Admin/Reports/Reports Detailed/Department Books Statistic/DepartmentBooksStatistic';
import { UserActivity } from './Components/Admin/Reports/Reports Detailed/User Activity/UserActivity';
import { UserStatisticAdmin } from './Components/Admin/Reports/Reports Detailed/User Statistic/UserStatisticAdmin';
import { TaskDetailed } from './Components/Admin/Task Verification/Task Detailed/TaskDetailed';
import { TaskVerification } from './Components/Admin/Task Verification/TaskVerification';
import { Books } from './Components/Books/Books';
import { Home } from './Components/Books/Home';
import { Levels } from './Components/Books/Levels/Levels';
import EmployeeLayout from './Components/Layout/Book/EmployeLayout';
import MainBookLayout from './Components/Layout/Book/MainBookLayout';
import MainAdminLayout from './Components/Layout/Lms/Sidebar/MainAdminLayout';
import MyActivity from './Components/MyActivity/MyActivity';
import { Coursedetail } from './Components/Mycourses/Course/Coursedetail';
import { NotAllowed } from './Components/Not allowed/NotAllowed';
import { PrivateRoutes } from './Components/Routes HOC/PrivateRoutes';
import { RestrictedRoutes } from './Components/Routes HOC/RestrictedRoutes';
import { Access } from './Components/Super Admin/Manage Organization/Access/Access';
import { Credentials } from './Components/Super Admin/Manage Organization/Credentials/Credentials';
import { DepBooks } from './Components/Super Admin/Manage Organization/Department/Department books/DepBooks';
import { Departments } from './Components/Super Admin/Manage Organization/Department/Departments';
import { Employee } from './Components/Super Admin/Manage Organization/Employee/Employee';
import { BooksMq } from './Components/Super Admin/Manage Quest/Books/BooksMq';
import { HodTaskVerification } from './Components/Super Admin/Manage Quest/Hod Task Verification/HodTaskVerification';
import { TaskDetailedHod } from './Components/Super Admin/Manage Quest/Hod Task Verification/Task Detailed/TaskDetailedHod';
import { ManageQuest } from './Components/Super Admin/Manage Quest/ManageQuest';
import { Themes } from './Components/Super Admin/Manage Quest/Themes/Themes';
import { SaDashboard } from './Components/Super Admin/Super Admin Dashboard/SaDashboard';
import { BookSummary } from './Components/Super Admin/Super Admin Reports/Reports detailed Sa/Book Summary/BookSummary';
import { CustomReport } from './Components/Super Admin/Super Admin Reports/Reports detailed Sa/Custom Reports/CustomReport';
import { UserStatistic } from './Components/Super Admin/Super Admin Reports/Reports detailed Sa/User Statistics/UserStatistic';
import { UserSummary } from './Components/Super Admin/Super Admin Reports/Reports detailed Sa/User Summary/UserSummary';
import { SaReports } from './Components/Super Admin/Super Admin Reports/SaReports';
import { useGetInitialAppliedThemeQuery } from './redux-toolkit/features/theme/themeApiSlice';
import AllNotifications from './Components/Notifications/AllNotifications';
import NotificationSetting from './Components/Notifications/NotificationSetting';
import Rewards from './Components/Super Admin/Rewards/Rewards';
import RedeemStatus from './Components/Super Admin/Rewards/RedeemStatus';
import RewardsEmp from './Components/Rewards/RewardsEmp';
import Cart from './Components/Rewards/Cart/Cart';
import { TourModal } from './Components/UI/Tour/TourModal';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { useLocation } from 'react-router-dom';
import Thankyou from './Components/Rewards/Cart/Thankyou';

import axios from 'axios';
import {
  useConfirmFeedbackMutation,
  useGetCompletedAlphaScreensQuery,
  useMarkAlphaScreenCompleteMutation,
} from './redux-toolkit/features/auth/authApiSlice';
import {
  setStep,
  setTourOpen,
  setTourActive,
  setFeedbackOpen,
} from './redux-toolkit/features/theme/themeSlice';
import Feedback from './Components/UI/Feedback/Feedback';
import FeedbackFormReact from '@schbangq/feedback-form-react';
import { useAlert } from './hooks/useAlert';
import SessionTimer from './Components/Util/SessionTimer/SessionTimer';

const homePage = [
  {
    target: '.my-first-step',
    content: 'This is my awesome feature!',
  },
  {
    target: '.step21',
    title: 'Explore Different Books!',
    content: 'Your books are categorised under Organization and Department.',
  },
  {
    target: '.step22',
    title: 'The Quest',
    content:
      'Get ready to start your Quest Journey! Each Book has levels and tasks to explore. ',
  },
  {
    target: '#step23',
    title: 'Rewards',
    content: 'Redeem your points here.',
  },
  {
    target: '#step24',
    title: 'My Activity',
    content: 'View all your progress & achievements.',
  },
  {
    target: '.step25',
    title: 'Notifications ',
    content:
      'Here you can see all triggers generated by your activity on the platform. Overall points, badges, and Rewards collected.',
  },
];

const adminTour = [
  {
    target: '#step0',
    title: 'kickstart',
    content:
      'The dashboard provides an at-a-glance view to the important metrics, insights of the activities within an organization. Each time you sign-in, Analytics will surface data from all the departments.',
  },
  {
    target: '#step1',
    title: 'Dashboard',
    content:
      'If you have the admin permissions, here you can see an overview of information about your users and their engagement on the platform. ',
  },
  {
    target: '#step3',
    title: 'My Workspace',
    content:
      'Get ready to create an engagement platform. Create your books and courses here. You can gamify by adding levels and tasks to each book.',
  },

  {
    target: '#step2',
    title: 'Task Verification',
    content:
      "The admin responsible for overseeing the department's tasks is informed about the completed task and initiates the review process here.",
  },
  {
    target: '#step4',
    title: 'Reports',
    content:
      'From this section, you can review all detailed information on the user’s performance',
  },
  // {
  //   target: '#step9',
  //   title: 'Notifications ',
  //   content:
  //     'Here you can see all the triggers generated by user activities. Requests to approve any tasks or details about the team points.',
  // },
];

const superAdminTour = [
  {
    target: '#step0',
    title: 'kickstart',
    content:
      'The dashboard provides an at-a-glance view to the important metrics, insights of the activities within an organization. Each time you sign-in, Analytics will surface data from all the departments.',
  },
  {
    target: '#step1',
    title: 'Dashboard',
    content:
      'The dashboard provides an at-a-glance view to the important metrics, insights of the activities within an organization. Each time you sign-in, Analytics will surface data from all the departments.',
  },
  {
    target: '#step3',
    title: 'Manage Quest',
    content:
      'Manage the books with edit and create functionality. Any activity by users under these books will be verified under Task verification. To centralise the platform, explore Themes.',
  },

  {
    target: '#step2',
    title: 'Manage Organization',
    content:
      'Review and edit your users, departments, their permissions and more.',
  },
  {
    target: '#step4',
    title: 'Reports',
    content:
      'Gain a deeper understanding of your data available via dashboard (users, books, rewards). Click Reports and export the required details.',
  },
  {
    target: '#step5',
    title: 'Rewards',
    content: 'Create categories here and add products under them.',
  },
];

function App() {
  const token = useSelector(selectCurrentToken);
  const user = useSelector((state) => state.auth.user);
  const theme = useSelector((state) => state.theme);
  const { confirmFeedback } = useConfirmFeedbackMutation();
  const location = useLocation();
  const dispatch = useDispatch();
  const { displayAlert } = useAlert();

  const [markAlphaScreenComplete] = useMarkAlphaScreenCompleteMutation();
  const handleFeedbackOpen = () => {
    dispatch(setFeedbackOpen(true));
  };

  const feedbackOpenClose = () => {
    dispatch(setFeedbackOpen(false));
  };

  const handleTourStart = () => {
    dispatch(setTourOpen(false));
    dispatch(setTourActive(true));
  };
  const handleTourClose = () => {
    dispatch(setTourOpen(false));
  };

  const handleTourEnd = () => {
    dispatch(setTourActive(false));
    markScreenComplete();
  };
  const { data, refetch } = useGetInitialAppliedThemeQuery({
    refetchOnMountOrArgChange: true,
  });

  const { data: alphaScreens, refetch: alphaRefetch } =
    useGetCompletedAlphaScreensQuery(null, {
      refetchOnMountOrArgChange: true,
    });

  useEffect(() => {
    refetch();
    let isRefreshing = sessionStorage.getItem('refreshing') !== null; // if it is not null means user is refreshing the page
    if (isRefreshing) {
    } else {
      // if isRefreshing is false, means user has opened the app in new tab
      if (!user?.rememberFlag) {
        // if remember flag is false logout the user
        localStorage.clear();
        //  redirect to login
        window.location.href = '/login';
      }
    }

    sessionStorage.setItem('refreshing', 'yes');
  }, []);

  const handleLocalStorageAScreenState = async (screen) => {
    let existingScreens =
      JSON.parse(localStorage.getItem('alpha_screens')) || [];
    if (!existingScreens.includes(screen)) {
      let newArray = [...existingScreens, screen];
      localStorage.setItem('alpha_screens', JSON.stringify(newArray));
      await markAlphaScreenComplete({ screen });
    }
  };

  const showScreen = (screen) => {
    let existingScreens =
      JSON.parse(localStorage.getItem('alpha_screens')) || [];
    if (user && token && !existingScreens.includes(screen)) return true;
    return false;
  };

  const shouldShowSteps = () => {
    switch (location.pathname) {
      case '/quest':
        if (showScreen('HOME')) {
          dispatch(setStep(homePage));
          dispatch(setTourOpen(true));
        }
        break;
      case '/admin':
        if (showScreen('ADMIN')) {
          dispatch(setStep(adminTour));
          dispatch(setTourActive(true));
        }
        break;
      case '/super-admin':
        if (showScreen('SUPERADMIN')) {
          dispatch(setStep(superAdminTour));
          dispatch(setTourActive(true));
        }
        break;
      default:
        break;
    }
  };
  const markScreenComplete = async (completedScreens) => {
    if (theme?.step[1]?.title === 'Switch View') {
      return handleLocalStorageAScreenState('PROFILE_DD');
    }

    switch (location.pathname) {
      case '/quest':
        await handleLocalStorageAScreenState('HOME');
        break;
      case '/admin':
        await handleLocalStorageAScreenState('ADMIN');
        break;
      case '/super-admin':
        await handleLocalStorageAScreenState('SUPERADMIN');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!alphaScreens || !alphaScreens?.data) return;
    let timer = setTimeout(() => {
      shouldShowSteps();
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [location.pathname, alphaScreens, user, token]);

  const handleFeedbackNotification = (data) => {
    if (data?.result) {
      displayAlert({
        open: true,
        message: 'Successfully submitted feddback',
        type: 'success',
        timeout: '2000',
      });
      confirmFeedback();
    } else {
      displayAlert({
        open: true,
        message: 'Something went wrong! Try after sometime',
        type: 'error',
        timeout: '2000',
      });
    }
  };


  return (
    <div className={data?.data?.mode === 'light' ? '' : 'dark'}>
      <TourModal
        onSkipClick={handleTourEnd}
        handleTourClose={handleTourClose}
        tourOpen={theme.tourOpen}
        mode={data?.data?.mode}
        handleTourStart={handleTourStart}
      />
      {token &&
        user != null &&
        theme?.sessionTime < 25 &&
        !user?.hasGivenFeedback && (
          <SessionTimer
            user={user}
            token={token}
            sessionTime={theme?.sessionTime}
          />
        )}

      {token && user != null && (
        <Feedback
          hasGivenFeedback={user?.hasGivenFeedback}
          sessionTime={theme?.sessionTime}
          handleFeedbackOpen={handleFeedbackOpen}
        />
      )}

      <FeedbackFormReact
        userName={user?.fname + ' ' + user?.lname}
        sheetUrl={
          'https://script.google.com/macros/s/AKfycbwsXyJM9ycuF4autlt5Rv5YwpgbrPoBPX58DPbBiEtaICT4rGMQ5uBR1DuHlvASWA8j-w/exec'
        }
        page={location.pathname}
        userEmail={user?.email}
        userRole={user?.role}
        isOpen={theme.feedbackOpen}
        onClose={feedbackOpenClose}
        onSuccess={(data) => handleFeedbackNotification(data)}
        onError={console.log}
      />

      <Routes>
        {/* PUBLIC ROUTES */}
        <Route
          path="/login"
          element={
            <Login
              onLoginSuccess={() => {
                alphaRefetch();
              }}
            />
          }
        />
        <Route
          path="/auth"
          element={<RequireAuth />}
        />
        <Route
          path="/forgot-password"
          element={<Login />}
        />
        <Route
          path="/send-mail"
          element={<Login />}
        />

        <Route
          path="*"
          element={
            token && user != null ? (
              <Navigate to={'quest'} />
            ) : (
              <Navigate to={'/login'} />
            )
          }
        />

        {/* END OF PUBLIC ROUTES */}
        {/* PRIVATE ROUTES WITH AUTH */}
        <Route element={<PersistLogin />}>
          <Route
            element={
              <ProtectedRoute allowedLevels={[...Object.values(LEVELS)]} />
            }>
            {/* <Route path="lms" element={<MainLmsLayout />}> */}
            {/* <Route path="dashboard" element={<Main />} />
              <Route path="courses" element={<Courses />} />
              <Route path="calendar" element={<Calendar />} />
              <Route path="library" element={<Library />} /> */}
            {/* <Route path="library/:_id" element={<LibDepartment />} />
              <Route path="forums" element={<Forums />} />
              <Route path="leaderboard" element={<Leaderboard />} /> */}
            {/* </Route> */}
            <Route
              path="restricted"
              element={<NotAllowed />}
            />

            {/* quest routes   */}
            <Route
              path="quest"
              element={<MainBookLayout />}>
              <Route
                path="books/:bookId"
                element={<Books />}
              />
              <Route
                path="books/:bookId/levels/:levelId"
                element={<Levels />}
              />
              <Route
                path=":bookName/:bookId/:levelName/:levelId/task/:taskId/course/:courseId"
                element={<Coursedetail />}
              />
            </Route>

            {/* Employee routes   */}
            <Route
              path="quest"
              element={<EmployeeLayout />}>
              <Route
                path=""
                element={
                  <Home
                    wheelBgColor={data?.data.wheelBgColor}
                    wheelColors={data?.data.wheelColors}
                    themeCardImage={data?.data.themeCardImage}
                    user={user}
                  />
                }
              />
              <Route
                path="rewards"
                element={<RewardsEmp />}
              />
              <Route
                path="cart"
                element={<Cart />}
              />
              <Route
                path="thank-you"
                element={<Thankyou />}
              />

              <Route
                path="account"
                element={<Account />}
              />
              <Route
                path="my-activity"
                element={<MyActivity />}
              />
              <Route
                path="notifications"
                element={<AllNotifications />}
              />
              <Route
                path="notifications/settings"
                element={<NotificationSetting />}
              />
            </Route>
          </Route>

          {/* Admin routes */}

          <Route
            element={
              <PrivateRoutes
                permissions={['ADMIN', 'CUSTOM_ROLE']}
                allowedLevels={true}
              />
            }>
            <Route
              path="admin"
              element={<MainAdminLayout isAdmin />}>
              {/* ADMIN Dashboard  */}
              <Route
                path=""
                element={<AdminDashboard />}
              />

              {/* Task verifications */}
              <Route element={<RestrictedRoutes access={['ADMIN_TASK_VER']} />}>
                <Route
                  path="task-verification"
                  element={<TaskVerification />}
                />
                <Route
                  path="task-verification/:_id"
                  element={<TaskDetailed />}
                />
              </Route>

              {/* My workspace || Admin dashboard will only be available if course or books access is given */}
              <Route
                element={
                  <RestrictedRoutes access={['ADMIN_COURSE', 'ADMIN_BOOKS']} />
                }>
                <Route
                  path="my-workspace"
                  element={<Workspace />}
                />

                <Route
                  path="my-workspace/edit-books/:_id"
                  element={<EditBooks isAdmin />}
                />
                <Route
                  path="my-workspace/edit-courses/:_id"
                  element={<EditCourses />}
                />
              </Route>
              <Route element={<RestrictedRoutes access={['ADMIN_REPORTS']} />}>
                <Route
                  path="reports"
                  element={<Reports />}
                />
                <Route
                  path="reports/user-activity"
                  element={<UserActivity />}
                />
                <Route
                  path="reports/user-statistic"
                  element={<UserStatisticAdmin />}
                />
                <Route
                  path="reports/book-statistic"
                  element={<DepartmentBooksStatistic />}
                />
              </Route>
            </Route>
          </Route>
          <Route
            element={
              <PrivateRoutes
                permissions={['SUPERADMIN', 'CUSTOM_ROLE']}
                allowedLevels={true}
              />
            }>
            {/* Super Admin routes */}

            <Route
              path="super-admin"
              element={<MainAdminLayout isSuperAdmin />}>
              <Route
                path=""
                element={<SaDashboard />}
              />
              <Route
                path="manage-quest"
                element={<ManageQuest />}
              />
              <Route
                path="manage-organization/credentials"
                element={<Credentials />}
              />
              <Route
                path="manage-organization/access"
                element={<Access />}
              />
              <Route
                element={
                  <RestrictedRoutes access={['SUPERADMIN_EMPLOYEES']} />
                }>
                <Route
                  path="manage-organization/employee"
                  element={<Employee />}
                />
              </Route>
               
              <Route
                element={
                  <RestrictedRoutes access={['SUPERADMIN_DEPARTMENT']} />
                }>
                <Route
                  path="manage-organization/departments"
                  element={<Departments />}
                />
                <Route
                  path="manage-organization/departments/books/:departmentId"
                  element={<DepBooks />}
                />
              </Route>
              {/* SUPERADMIN_THEMES */}
              <Route
                element={<RestrictedRoutes access={['SUPERADMIN_THEMES']} />}>
                <Route
                  path="manage-organization/themes"
                  element={<Themes refetchCurrentTheme={refetch} />}
                />
              </Route>
              {/* SUPERADMIN_Task_Verification */}
              <Route
                element={<RestrictedRoutes access={['SUPERADMIN_TASK']} />}>
                <Route
                  path="manage-organization/task-verification"
                  element={<HodTaskVerification />}
                />
                <Route
                  path="manage-organization/task-verification/:_id"
                  element={<TaskDetailedHod />}
                />
              </Route>
              {/* Dashboard and  */}
              <Route
                element={<RestrictedRoutes access={['SUPERADMIN_BOOKS']} />}>
                <Route
                  path="my-workspace"
                  element={<Workspace />}
                />
                <Route
                  path="my-workspace/edit-books/:_id"
                  element={<EditBooks isAdmin />}
                />
                <Route
                  path="manage-organization/organizational-quest"
                  element={<BooksMq />}
                />
                <Route
                  path="manage-organization/organizational-quest/edit-books/:_id"
                  element={<EditBooks />}
                />
              </Route>
              <Route
                element={<RestrictedRoutes access={['SUPERADMIN_REPORTS']} />}>
                <Route
                  path="reports"
                  element={<SaReports />}
                />
                <Route
                  path="reports/custom-reports"
                  element={<CustomReport />}
                />
                <Route
                  path="reports/user-summary"
                  element={<UserSummary />}
                />
                <Route
                  path="reports/user-statistic"
                  element={<UserStatistic />}
                />
                <Route
                  path="reports/book-summary"
                  element={<BookSummary />}
                />
              </Route>
              <Route
                path="rewards"
                element={<Rewards />}></Route>
              <Route
                path="rewards/redeem-status"
                element={<RedeemStatus />}></Route>
            </Route>
          </Route>
        </Route>

        {/* END OF PRIVATE ROUTES WITH AUTH */}
      </Routes>

      <Joyride
        styles={{
          options: {
            overlayColor: 'rgba(0, 0, 0, 0.5)',
            primaryColor: 'var(--pblue)',
            padding: '30px',
            zIndex: '1900',
          },
        }}
        steps={theme.step}
        continuous={true}
        // showProgress={true}
        showSkipButton={true}
        run={theme.tourActive}
        callback={(data) => {
          if (data.type === 'tour:end') {
            handleTourEnd();
          }
        }}
        showBackButton={false}
        locale={{
          last: 'End', // This sets the label for the last button to "End"
        }}
      />
    </div>
  );
}

export default App;
