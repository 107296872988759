import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useAlert } from "../../../../hooks/useAlert";
import {
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
} from "../../../../redux-toolkit/features/rewards/rewardsApiSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  right: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  borderRadius: 3,
  boxShadow: 24,
  p: " 40px",
};

const AddCategory = ({ open, handleClose, editData }) => {
  const [name, setName] = useState("");
  const [categoryType, setCategoryType] = useState("merchandise");
  const { displayAlert } = useAlert();
  const [createCategory] = useCreateCategoryMutation();
  const [updateCategory] = useUpdateCategoryMutation();

  const handleSubmit = async () => {
    if (!name || !categoryType) {
      displayAlert({
        open: true,
        message: `All fields required!`,
        type: "error",
        timeout: "2500",
      });
      return;
    }
    try {
      if (editData?._id) {
        await updateCategory({
          data: { name, type: categoryType },
          _id: editData._id,
        });
      } else {
        await createCategory({
          name,
          type: categoryType,
        });
      }
      displayAlert({
        open: true,
        message: `Changes successfully`,
        type: "success",
        timeout: "2500",
      });
      handleModelClose();
    } catch (error) {
      displayAlert({
        open: true,
        message: `Something went wrong`,
        type: "error",
        timeout: "2500",
      });
    }
  };

  const handleModelClose = () => {
    setName("");
    setCategoryType("merchandise");
    handleClose();
  };

  useEffect(() => {
    if (editData?.name) {
      setName(editData.name);
    }
  }, [editData]);

  return (
    <Modal
      open={open}
      onClose={handleModelClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="create__formhead">
          <h1>{editData?._id ? "Update" : "Add"} Category</h1>
          <CloseIcon
            onClick={handleModelClose}
            sx={{
              fontSize: "18px",
              cursor: "pointer",
              color: "var(--grey400)",
              "&:hover": {
                color: "var(--error)",
              },
            }}
          />
        </div>
        <hr className="rewards_divider" />
        <div className="rewards_cat_form">
          <div style={{ marginTop: "20px" }}>
            <h5 className="ftwt__r">
              Category Name<span className="required_star">*</span>
            </h5>

            <TextField
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
                marginTop: "5px",
              }}
              required
              id="outlined-basic"
              variant="outlined"
              fullWidth
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="department__actions">
            <Button
              sx={{ width: "100px" }}
              variant="contained"
              onClick={handleSubmit}
            >
              {editData?._id ? "Update" : " Create"}
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default AddCategory;
