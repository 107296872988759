import React from "react";
import { Books } from "../../Books/Books";
import { Navbar } from "../../Navbar/Navbar";
import { Outlet } from "react-router-dom";
import { useGetInitialAppliedThemeQuery } from "../../../redux-toolkit/features/theme/themeApiSlice";
import ProgressBar from "../../UI/ProgressBar";
import { useGetBookByIdQuery } from "../../../redux-toolkit/features/books/bookApiSlice";
import { useParams } from "react-router-dom";
import "./MainBookLayout.css";

const MainBookLayout = () => {
  const { bookId } = useParams();
  const { data, isLoading, isError } = useGetInitialAppliedThemeQuery({
    refetchOnMountOrArgChange: true,
  });
  const { data: bookData } = useGetBookByIdQuery(bookId, {
    skip: !bookId,
  });

  React.useEffect(() => {}, [data]);

  if (isLoading) {
    return (
      <div style={{ backgroundColor: "white", height: "100vh" }}>
        <ProgressBar />;
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundImage: `url(${bookData?.data?.theme?.bgImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100vw 100vh",
      }}
      className="bookLayout__rel"
    >
      {bookData?.data?.theme?.rightIcon != null && (
        <div
          className={
            bookData?.data?.name === "Wilderness Expedition"
              ? "bookLayout__rightJungle"
              : "bookLayout__right"
          }
        >
          <img src={bookData?.data?.theme?.rightIcon} alt="rightIcon" />
        </div>
      )}
      {bookData?.data?.theme?.rightIcon != null && (
        <div
          className={
            bookData?.data?.name === "Wilderness Expedition"
              ? "bookLayout__leftJungle"
              : "bookLayout__left"
          }
        >
          <img src={bookData?.data?.theme?.leftIcon} alt="leftIcon" />
        </div>
      )}
      <Navbar bookMode={bookData?.data?.theme?.mode} isBook />
      <Outlet />
    </div>
  );
};

export default MainBookLayout;
