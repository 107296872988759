import { ChevronRight, MoreHoriz } from "@mui/icons-material";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import { ReactComponent as DeleteIcon } from "../../../../assets/Icons/Delete _ Default.svg";
import { ReactComponent as EditIcon } from "../../../../assets/Icons/EditIcon.svg";
import { useDeleteCategoryMutation } from "../../../../redux-toolkit/features/rewards/rewardsApiSlice";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import AddCategory from "./AddCategory";

const CategoryDeleteAlert = ({ open, setOpen, handleDelete }) => {
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      style={{ bottom: "unset", top: "5rem" }}
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          The product associated with the category will also be deleted.{" "}
          <strong style={{ color: "#3361cf" }}>
            Are you sure you want to delete this category?
          </strong>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        style={{
          padding: "20px 24px",
        }}
      >
        <Button
          variant="contained"
          color="error"
          onClick={() => handleDelete()}
        >
          Delete
        </Button>
        <Button variant="outlined" autoFocus onClick={() => setOpen(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const RewardsCategoryBtn = ({
  isActive,
  children,
  onClick,
  category,
  onDelete,
  onEdit,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [editCat, setEditCat] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [deleteCategory] = useDeleteCategoryMutation();
  return (
    <>
      <CategoryDeleteAlert
        open={openModal}
        setOpen={setOpenModal}
        handleDelete={async () => {
          await deleteCategory(category?._id);
          onDelete(category?._id);
        }}
      />

      <AddCategory
        open={!!editCat}
        handleClose={() => {
          setEditCat(null);
          onEdit();
        }}
        editData={editCat}
      />
      <Menu
        id="admincard-option-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          sx={{
            "&:hover": {
              backgroundColor: "#3361CF22",
            },
          }}
          onClick={(e) => {
            e.stopPropagation();
            setEditCat(category);
          }}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>
            <h5 className="ftwt__r">Edit</h5>
          </ListItemText>
        </MenuItem>
        <MenuItem
          sx={{
            "&:hover": {
              backgroundColor: "#3361CF22",
            },
          }}
          onClick={async (e) => {
            e.stopPropagation();
            setOpenModal(true);
          }}
        >
          <ListItemIcon>
            <DeleteIcon style={{ width: 18, height: 18 }} />
          </ListItemIcon>
          <ListItemText>
            <h5 className="ftwt__r">Delete</h5>
          </ListItemText>
        </MenuItem>
      </Menu>

      <button
        onClick={onClick}
        className={(isActive ? "active " : "") + "rewards_cat_btn"}
      >
        <p>{children}</p>
        <div>
          <IconButton
            onClick={async (e) => {
              e.stopPropagation();
              setAnchorEl(e.currentTarget);
            }}
          >
            <MoreHoriz />
          </IconButton>
          <IconButton
            sx={{
              "&:hover": {
                color: "#3361CF",
              },
            }}
            onClick={onClick}
          >
            <ChevronRight />
          </IconButton>
        </div>
      </button>
    </>
  );
};

export default RewardsCategoryBtn;
