import { apiSlice } from "../../api/apiSlice";

export const departmentApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllAccess: builder.query({
      query: () => ({
        url: "/api/rbac/custom",
        method: "GET",
        params: { group: "resource" },
      }),
    }),
    getPermissionSets: builder.query({
      query: (userId) => ({
        url: `/api/rbac/permission-set/${userId}`,
        method: "GET",
      }),
    }),
    addPermissionSets: builder.mutation({
      query: (data) => ({
        url: `/api/rbac/permission`,
        method: "POST",
        body: data,
      }),
    }),
    getAdministratorsRole: builder.query({
      query: (data) => ({
        url: `/api/user/get/admin-superadmins?role_type=${data?.role}&sort_by=${
          data?.sortBy || "fname"
        }&sort_type=${data?.sortType || "desc"}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetAllAccessQuery,
  useGetPermissionSetsQuery,
  useAddPermissionSetsMutation,
  useGetAdministratorsRoleQuery,
} = departmentApiSlice;
