import React, { useEffect, useRef } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./AddTopic.css";
// import ImageResize from "quill-image-resize-module-react";
import { Button, TextField } from "@mui/material";
import { useState } from "react";
// import DeleteInActiveSvg from '../../../../assets/delete_icon.svg';
// import DeleteActiveSvg from '../../../../assets/Icons/delete_red.svg';
import DeleteActiveSvg from "../../../../assets/Icons/delete_red.svg";
import DeleteInActiveSvg from "../../../../assets/delete_icon.svg";
import CloseIcon from '@mui/icons-material/Close';

import { useAlert } from '../../../../hooks/useAlert';
import {
  useCreateTopicMutation,
  useDeleteFileMutation,
  useDeleteTopicMutation,
  useUpdateTopicMutation,
  useUploadFileMutation,
  useUploadRTEImageMutation,
} from '../../../../redux-toolkit/features/courses/coursesApiSlice';
import { DeleteModal } from '../../../UI/Delete Modal/DeleteModal';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

const AlignStyle = Quill.import('attributors/style/align');
Quill.register(AlignStyle, true);

const AlignClass = Quill.import('attributors/class/align');
Quill.register(AlignClass, true);

const Align = Quill.import('formats/align');
Quill.register(Align, true);

const config = {
  modules: {
    toolbar: [
      // [{ imageResize: { displaySize: false } }],
      [{ header: [1, 2, 3, 4, 5, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      [{ align: [] }],
      ['link', 'video', 'image'],
      ['clean'],
    ],
  },

  formats: [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'align',
    'indent',

    'image',
    'video',
    'link',
  ],

  theme: 'snow',
};

const AddVideoLinkAlert = ({
  open,
  handleClose,
  handleSubmit,
  url,
  setUrl,
}) => {
  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={() => {
        handleClose();
      }}
      PaperProps={{
        style: {
          width: '100%',
        },
      }}>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Enter a video url
        </DialogContentText>
        <TextField
          sx={{
            '& legend': { display: 'none' },
            '& fieldset': { top: 0 },
            marginTop: '5px',
          }}
          type={'url'}
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          id="task-points-add-field"
          name="points"
          fullWidth
          variant="outlined"
        />
      </DialogContent>
      <DialogActions
        style={{
          padding: '20px 24px',
        }}>
        <Button
          variant="contained"
          onClick={() => handleSubmit()}>
          Done
        </Button>
        <Button
          variant="outlined"
          autoFocus
          onClick={() => handleClose()}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const AddTopic = ({
  isAdd,
  course,
  refetch,
  modules,
  moduleNo,
  setShowTopic,
  topics,
  topicNo,
}) => {
  const quill = useRef();
  const [name, setName] = useState(topics?.name);
  const [timeToComplete, setTimeToComplete] = useState(topics?.timeToComplete);
  const [content, setContent] = useState(topics?.content);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteTopic] = useDeleteTopicMutation();
  const { displayAlert, clearAlert } = useAlert();
  const [createTopic] = useCreateTopicMutation();
  const [updateTopic] = useUpdateTopicMutation();
  const [uploadRTEImage] = useUploadRTEImageMutation();
  const [thumbnail, setThumbnail] = React.useState(topics?.resources || []);
  const [isLarge, setIsLarge] = React.useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [uploadFile] = useUploadFileMutation();
  const [deleteFile] = useDeleteFileMutation();

  const handleOpen = () => setDeleteOpen(true);
  // useEffect(() => {
  //   if (!isAdd) {
  //     setThumbnail(topics?.resources);
  //   }
  // }, [topics]);
  useEffect(() => {}, [thumbnail]);

  const saveTopicHandler = async () => {
    const topic = {
      name: name,
      content: content,
      resources: thumbnail,
      timeToComplete: timeToComplete,
    };

    const finalTask = {
      topic,
      courseId: course?._id,
      moduleId: modules?._id,
    };

    if (name != '' && content != '' && timeToComplete != '') {
      await createTopic(finalTask);
      await refetch();
      setName('');
      setTimeToComplete('');
      setContent('');
      setShowTopic(false);
      displayAlert({
        open: true,
        message: 'Added New Topic Successfully',
        type: 'success',
        timeout: '2000',
      });
    }
  };

  const deleteTopicHandler = async () => {
    const topicId = topics?._id;
    await deleteTopic(topicId);
    await refetch();
    displayAlert({
      open: true,
      message: 'Successfully Deleted Topic',
      type: 'error',
      timeout: '2000',
    });
  };

  const updateTopicHandler = async () => {
    const topic = {
      name: name,
      content: content,
      resources: thumbnail,
      timeToComplete: timeToComplete,
    };
    const topicId = topics?._id;
    const finalData = { topic, topicId };
    await updateTopic(finalData);
    await refetch();
    displayAlert({
      open: true,
      message: 'Successfully Updated Topic',
      type: 'success',
      timeout: '2000',
    });
  };

  const timeToCompleteHandler = (e) => {
    if (e.target.value > 0) {
      setTimeToComplete(e.target.value);
    }
  };

  function getVideoUrl(url) {
    let match =
      url.match(
        /^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/
      ) ||
      url.match(
        /^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/
      ) ||
      url.match(/^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/);

    if (match && match[2].length === 11) {
      return 'https' + '://www.youtube.com/embed/' + match[2] + '?showinfo=0';
    }

    if ((match = url.match(/^(?:(https?):\/\/)?(?:www\.)?vimeo\.com\/(\d+)/))) {
      // eslint-disable-line no-cond-assign
      return (
        (match[1] || 'https') + '://player.vimeo.com/video/' + match[2] + '/'
      );
    }

    return null;
  }

  function videoHandler() {
    let url = prompt('Enter youtube url');
    url = getVideoUrl(url);
    let range = quill.current?.editor?.getSelection();
    if (url != null) {
      quill.current?.editor?.insertEmbed(range, 'video', url);
    } else {
      displayAlert({
        open: true,
        message: 'Invalid video link',
        type: 'error',
        timeout: '2000',
      });
    }
  }

  const imageHandler = async () => {
    let inp = document.createElement('input');
    inp.visibility = 'hidden';
    document.body.appendChild(inp);
    inp.type = 'file';
    inp.addEventListener('change', async (e) => {
      let file = e.target.files[0];
      if (file) {
        if (file.size > 1024 * 1000) {
          displayAlert({
            open: true,
            message: 'Image must be less than 4MB  in size',
            type: 'error',
            timeout: '2000',
          });
        } else {
          displayAlert({
            open: true,
            message: 'Uploading image...',
            type: 'success',
            timeout: '20000',
          });
          await uploadRTEImage(file)
            .unwrap()
            .then((res) => {
              let url = res.data?.location; // upload to s3
              let range = quill.current?.editor?.getSelection();
              if (url != null) {
                quill.current?.editor?.insertEmbed(range, 'image', url);
              }
            })
            .finally(() => {
              clearAlert();
            });
        }
      }
    });
    inp.click();
  };

  useEffect(() => {
    quill.current?.editor
      ?.getModule('toolbar')
      ?.addHandler('video', videoHandler);
    quill.current?.editor
      ?.getModule('toolbar')
      ?.addHandler('image', imageHandler);
  }, [quill]);

  const thumbnailHandler = async (event) => {
    const file = event.target.files[0];

    if (file.size >= 4099276) {
      setIsLarge(true);

      displayAlert({
        open: true,
        message: `Image Size cannot be Larger than 1 MB`,
        type: 'error',
        timeout: '2000',
      });
    } else {
      setIsLarge(false);
      uploadFile(file)
        .unwrap()
        .then(async (data) => {
          let fileFormat = await {
            url: data?.data?.location,
            filename: file.name,
            filetype: file.type,
          };
          setThumbnail([...thumbnail, fileFormat]);
        })
        .catch((err) =>
          displayAlert({
            open: true,
            message: `${err}`,
            type: 'error',
            timeout: '2000',
          })
        );
    }
  };
  const deleteResourseHandler = async (id) => {
    console.log(thumbnail[id].url);
    const file = { url: thumbnail[id]?.url };
    await deleteFile(file);
    const updatedArray = thumbnail.filter((item, index) => index != id);
    setThumbnail(updatedArray);
  };

  return (
    <>
      {/* <AddVideoLinkAlert
        open={openVideoModal}
        handleClose={() => {
          setOpenVideoModal(false);
          setVideoUrl("");
        }}
        url={videoUrl}
        setUrl={setVideoUrl}
        handleSubmit={videoHandler}
      /> */}
      {isAdd ? (
        <div className="addTopics">
          <h6>
            Module {moduleNo} &gt; Topic {modules?.submodules?.length + 1}
          </h6>
          <div className="addTopics__form">
            <div>
              <h4 className="ftwt__b">Topic Name</h4>
              <TextField
                sx={{
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                  marginTop: '5px',
                }}
                id="outlined-basic"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                variant="outlined"
              />
            </div>
            <div>
              <h4 className="ftwt__b">Content</h4>
              <ReactQuill
                ref={quill}
                rows={10}
                style={{
                  margin: '5px 0px',
                  height: '500px',
                }}
                value={content}
                onChange={(value) => setContent(value)}
                {...config}
              />
            </div>
            <div>
              <h4 className="ftwt__b topic__points">Add Resources (PDF)</h4>
              <div
                style={{ borderColor: isLarge ? 'var(--error)' : '' }}
                className="dropzone-wrapper">
                <div className="dropzone-desc">
                  <UploadFileOutlinedIcon
                    sx={{
                      fontSize: '70px',
                    }}
                  />
                  <p
                    style={{
                      fontSize: '14px',
                      // color: isLarge ? 'var(--error)' : '',
                    }}>
                    Choose an PDF file{' '}
                    <span style={{ fontSize: '11px' }}>or drag it here.</span>
                    <br />
                    <span style={{ fontSize: '11px' }}>
                      Maximum file size 4MB
                    </span>
                  </p>
                </div>

                {/* {thumbnail && ( */}
                <input
                  name="thumbnail"
                  onChange={thumbnailHandler}
                  type="file"
                  accept="application/pdf" // name="img_logo"
                  className="dropzone"
                />
                {/* )} */}
              </div>
              {isLarge && (
                <p
                  style={{
                    color: 'var(--error)',
                    marginTop: '5px',
                    fontSize: '12px',
                  }}>
                  Choose PDF smaller than 4MB
                </p>
              )}
              <br />
              {thumbnail &&
                thumbnail.length > 0 &&
                thumbnail.map((item, index) => (
                  <div
                    style={{
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'center',
                    }}>
                    <h5 style={{ marginTop: '5px', color: 'var(--pblue)' }}>
                      {item.filename}
                    </h5>
                    <CloseIcon
                      onClick={() => deleteResourseHandler(index)}
                      sx={{
                        marginTop: '5px',
                        fontSize: '16px',
                        cursor: 'pointer',
                        color: 'var(--grey400)',
                        '&:hover': {
                          color: 'var(--error)',
                        },
                      }}
                    />
                  </div>
                ))}
            </div>
            <div>
              <h4 className="ftwt__b topic__points">
                Time to Complete (In minutes)
              </h4>
              <TextField
                sx={{
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                  marginTop: '5px',
                }}
                id="outlined-basic"
                type="number"
                name="timeToComplete"
                value={timeToComplete}
                onChange={timeToCompleteHandler}
                fullWidth
                variant="outlined"
              />
            </div>
            {isAdd && (
              <div className="topic__actions">
                <Button
                  color="error"
                  onClick={() => setShowTopic(false)}
                  variant="outlined">
                  Close
                </Button>
                <Button
                  onClick={saveTopicHandler}
                  variant="outlined">
                  Add Topic
                </Button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="addTopics">
          <DeleteModal
            label={topics?.name}
            deleteHandler={deleteTopicHandler}
            setDeleteOpen={setDeleteOpen}
            deleteOpen={deleteOpen}
          />
          <h6>
            Module {moduleNo} &gt; Topic {topicNo + 1}
          </h6>
          <div className="addTopics__form">
            <div>
              <h4 className="ftwt__b">Topic Name</h4>
              <TextField
                sx={{
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                  marginTop: '5px',
                }}
                id="outlined-basic"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                variant="outlined"
              />
            </div>
            <div>
              <h4 className="ftwt__b">Content</h4>
              <ReactQuill
                ref={quill}
                rows={10}
                style={{
                  margin: '5px 0px',
                  height: '500px',
                }}
                value={content}
                onChange={(value) => setContent(value)}
                {...config}
              />
            </div>
            <div>
              <h4 className="ftwt__b topic__points">Add Resources (PDF)</h4>
              <div
                style={{ borderColor: isLarge ? 'var(--error)' : '' }}
                className="dropzone-wrapper">
                <div className="dropzone-desc">
                  <UploadFileOutlinedIcon
                    sx={{
                      fontSize: '70px',
                    }}
                  />
                  <p
                    style={{
                      fontSize: '14px',
                      // color: isLarge ? 'var(--error)' : '',
                    }}>
                    Choose an PDF file{' '}
                    <span style={{ fontSize: '11px' }}>or drag it here.</span>
                    <br />
                    <span style={{ fontSize: '11px' }}>
                      Maximum file size 4MB
                    </span>
                  </p>
                </div>

                {/* {thumbnail && ( */}
                <input
                  name="thumbnail"
                  onChange={thumbnailHandler}
                  type="file"
                  accept="application/pdf" // name="img_logo"
                  className="dropzone"
                />
                {/* )} */}
              </div>
              {isLarge && (
                <p
                  style={{
                    color: 'var(--error)',
                    marginTop: '5px',
                    fontSize: '12px',
                  }}>
                  Choose PDF smaller than 4MB
                </p>
              )}
              <br />
              {thumbnail &&
                thumbnail.length > 0 &&
                thumbnail.map((item, index) => (
                  <div
                    style={{
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'center',
                    }}>
                    <h5 style={{ marginTop: '5px', color: 'var(--pblue)' }}>
                      {item.filename}
                    </h5>
                    <CloseIcon
                      onClick={() => deleteResourseHandler(index)}
                      sx={{
                        marginTop: '5px',
                        fontSize: '16px',
                        cursor: 'pointer',
                        color: 'var(--grey400)',
                        '&:hover': {
                          color: 'var(--error)',
                        },
                      }}
                    />
                  </div>
                ))}
            </div>
            <div>
              <h4 className="ftwt__b topic__points">
                Time to Complete (In minutes)
              </h4>
              <TextField
                sx={{
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                  marginTop: '5px',
                }}
                id="outlined-basic"
                type="number"
                name="timeToComplete"
                value={timeToComplete}
                onChange={(e) => setTimeToComplete(e.target.value)}
                fullWidth
                variant="outlined"
              />
            </div>

            <div className="topic__actions">
              <div
                className="figure"
                onClick={handleOpen}>
                <img
                  src={DeleteInActiveSvg}
                  onClick={handleOpen}
                  className="Sirv image-main"
                  height="24px"
                  alt=""
                />
                <img
                  src={DeleteActiveSvg}
                  onClick={handleOpen}
                  className="Sirv image-hover"
                  height="24px"
                  alt=""
                />
              </div>
              <div className="figure">
                <Button
                  variant="contained"
                  onClick={updateTopicHandler}>
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
