import { useSelector } from "react-redux";
import { selectCurrentToken } from "../redux-toolkit/features/auth/authSlice";
import jwtDecode from "jwt-decode";

const useAuth = () => {
  const token = useSelector(selectCurrentToken);
  let isAdmin = false;
  let isSuperAdmin = false;
  let isCustomRole = false;
  let level = "";
  let permissions = null;
  let allPermissions = [];

  if (token) {
    const decoded = jwtDecode(token);
    const { _id, permissions } = decoded.userData;
     if (permissions) {
       level = 'EMPLOYEE';
       if (permissions.ADMIN) {
         permissions.ADMIN.map((item) => allPermissions.push(item));
         isAdmin = true;
         level = 'ADMIN';
       }
       if (permissions.SUPERADMIN) {
         permissions.SUPERADMIN.map((item) => allPermissions.push(item));
         isSuperAdmin = true;
         level = 'SUPERADMIN';
       }
       if (permissions.CUSTOMROLE) {
         permissions.CUSTOMROLE.map((item) => allPermissions.push(item));
         isCustomRole = true;
         level = 'CUSTOMROLE';
       }
     }

    return {
      id: _id,
      level,
      isAdmin,
      isSuperAdmin,
      allPermissions,
      permissions,
    };
  }
  return {
    id: null,
    level: "",
    isAdmin,
    isSuperAdmin,
    allPermissions,
    permissions,
  };
};
export default useAuth;
