import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  homeTab: true,
  mode: 'light',
  sidebar: true,
  workspace: 0,
  step: {},
  tourOpen: false,
  tourActive: false,
  divider:
    'https://quest-static-bucket.s3.ap-south-1.amazonaws.com/themes/viking/viking_separator.svg',
  bg: '../../assets/books/bgs/christmas _ new year theme background.png',
  bookMode: 'light',
  activeItem: 1,
  selectBooks: [],
  feedbackOpen: false,
  sessionTime: 0,
};

const theme = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setActiveItem: (state, action) => {
      state.activeItem = action.payload;
    },
    setSelectBooks: (state, action) => {
      state.selectBooks = action.payload;
    },
    setStep: (state, action) => {
      state.step = action.payload;
    },
    setTourOpen: (state, action) => {
      state.tourOpen = action.payload;
    },
    setTourActive: (state, action) => {
      state.tourActive = action.payload;
    },
    setFeedbackOpen: (state, action) => {
      state.feedbackOpen = action.payload;
    },
    themeToggler: (state, action) => {
      state.mode = !state.mode;
    },
    startSessionTimer: (state, action) => {
      state.sessionTime = action.payload;
    },
    sidebarToggler: (state, action) => {
      state.sidebar = action.payload;
    },
    workspaceToggler: (state, action) => {
      state.workspace = action.payload;
    },
    bgSwitcher: (state, action) => {
      state.bg = action.payload.bgImage;
      state.mode = action.payload.mode;
      state.divider = action.payload.dividerImage;
    },
    bookModeToggler: (state, action) => {
      state.bookMode = action.payload;
    },
    homeTabToggler: (state, action) => {
      state.homeTab = action.payload;
    },
    logoutTheme: (state, action) => {
      return initialState;
    },
  },
});

export const {
  themeToggler,
  bookModeToggler,
  logoutTheme,
  homeTabToggler,
  sidebarToggler,
  workspaceToggler,
  bgSwitcher,
  setActiveItem,
  setFeedbackOpen,
  setTourActive,
  setTourOpen,
  setStep,
  startSessionTimer,
  setSelectBooks,
} = theme.actions;

export default theme.reducer;

export const selectedTheme = (state) => state.theme.mode;
