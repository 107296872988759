import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import "./TourModal.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  right: "50%",
  maxHeight: "50vh",
  transform: "translate(-50%, -50%)",
  width: 450,
  borderRadius: "10px",
  border: "none",
  boxShadow: 24,
};

export const TourModal = ({
  handleTourClose,
  tourOpen,
  handleTourStart,
  mode,
  onSkipClick,
}) => {
  const handleClose = () => {
    handleTourClose();
    onSkipClick();
  };

  const confirmHandler = async () => {
    handleClose();
  };

  const overlayStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  };

  return (
    <div>
      <div>
        <Modal
          open={tourOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          BackdropProps={{ style: overlayStyle }}
          aria-describedby="modal-modal-description">
          <Box sx={style}>
            <div
              style={{
                backgroundColor: mode != 'light' ? 'white' : 'white',
              }}
              className="tourModal">
              <h1
                style={{
                  color: mode != 'light' ? 'var(--pblue)' : 'var(--pblue)',
                }}
                className="ftwt__b">
                Welcome to the Quest!{' '}
              </h1>
              <h3
                style={{
                  color: mode === 'light' ? 'var(--grey400)' : 'var(--grey400)',
                }}
                className="ftwt__r">
                Explore the key features for a great experience. Let's get
                started!
              </h3>
              <div className="tourModal__actions">
                <h4
                  onClick={handleClose}
                  style={{
                    color:
                      mode === 'light' ? 'var(--grey400)' : 'var(--grey400)',
                  }}>
                  Skip
                </h4>

                <div>
                  {/* <h4
                    style={{
                      color: mode === 'light' ? 'var(--pblue)' : 'white',
                    }}
                    className="ftwt__b">
                    Back
                  </h4> */}
                  <Button
                    style={{
                      backgroundColor:
                        mode != 'light' ? 'var(--pblue)' : 'var(--pblue)',
                      color: mode != 'light' ? 'white' : 'white',
                    }}
                    onClick={handleTourStart}
                    variant="contained">
                    Next
                  </Button>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
};
