import React from "react";
import "./CredentialTier.css";
import { Avatar, AvatarGroup } from "@mui/material";

export const CredentialTier = ({ tier }) => {
  return (
    <div className="bs__creds_parent_container">
      <div className="bs__creds_tier_left">
        <small className="bs__creds_tier_title">{tier?.title}</small>
        <div className="bs__creds_tier_left_border"></div>
      </div>
      <div className="bs__creds_tier_right">
        {tier?.steps?.map((step, i) => {
          let isEndBadge = i === 3;
          let isStartBadge = i === 0;
          return (
            <div key={i} className="bs__creds_tier_step">
              <div
                className="bs__creds_tier_badge"
                style={{
                  justifyContent: isEndBadge ? "center" : "",
                  paddingLeft: isEndBadge ? "0" : "",
                }}
              >
                {!isEndBadge ? (
                  <div className="bs__creds_tier_badge_border"></div>
                ) : null}
                <div
                  style={{
                    width: isEndBadge ? "70px" : "50px",
                    zIndex: 2,
                  }}
                >
                  <img
                    style={{
                      width: isEndBadge ? "70px" : "50px",
                      zIndex: 2,
                    }}
                    src={step?.badge?.image}
                    alt="badge"
                  />
                </div>
                {!isEndBadge ? (
                  <small className="bs__creds_tier_badge_text">
                    {step.badge.title}
                  </small>
                ) : null}
              </div>
              <div
                className="bs__creds_tier_tasks_count"
                style={{
                  borderTopLeftRadius: isStartBadge ? "15px" : "",
                  borderBottomLeftRadius: isStartBadge ? "15px" : "",
                  borderTopRightRadius: isEndBadge ? "15px" : "",
                  borderBottomRightRadius: isEndBadge ? "15px" : "",
                }}
              >
                <div className="bs__creds_tier_task_count_zebra">
                  {new Array(50).fill(0).map(() => (
                    <div></div>
                  ))}
                </div>
                <p style={{ color: "#fff" }}>{step.tasks}</p>
              </div>
              <div className="bs__creds_avatars">
                <AvatarGroup max={4}>
                  {step?.avatars?.length ? (
                    step?.avatars?.map((avatar) => {
                      return (
                        <Avatar
                          style={{
                            width: "20px",
                            height: "20px",
                            border: "none",
                          }}
                          alt="avatar"
                          src={avatar}
                        />
                      );
                    })
                  ) : (
                    <>
                      <Avatar
                        style={{
                          width: "20px",
                          height: "20px",
                          border: "none",
                        }}
                        alt="avatar"
                      />
                      <Avatar
                        style={{
                          width: "20px",
                          height: "20px",
                          border: "none",
                        }}
                        alt="avatar"
                      />
                      <Avatar
                        style={{
                          width: "20px",
                          height: "20px",
                          border: "none",
                        }}
                        alt="avatar"
                      />
                    </>
                  )}
                </AvatarGroup>
                <span className="bs__creds_task_badge_user_text">
                  {step?.label}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
