import React from "react";
import "./CreateCard.css";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

export const CreateCard = ({ label }) => {
  return (
    <div className="createcard">
      <div className="createcard__add">
        <AddRoundedIcon sx={{ fontSize: "70px", color: "var(--grey400)" }} />
      </div>
      <div className="createcard__content">
        <div>
          <div className="createcard__contenthead">
            <h4 className="frwt__b">{label}</h4>
          </div>
          <div className="createcard__contentseprator"></div>
        </div>
      </div>
    </div>
  );
};
