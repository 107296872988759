import React, { useState } from "react";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import { Button } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useSelector } from "react-redux";
import bowIcon from "./../../../assets/rewards/cartbow.png";
import { CartModal } from "./CartModal";

import {
  useClearCartProductMutation,
  useGetPointsStatusQuery,
  usePurchaseMutation,
} from '../../../redux-toolkit/features/rewards/rewardsApiSlice';
import { useNavigate } from 'react-router-dom';
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const Checkout = () => {
  const [cartOpen, setCartOpen] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [gift, setGift] = useState(false);

  const rewards = useSelector((state) => state.rewards.cart);
  const totalPoints = useSelector((state) => state.rewards.totalPoints);
  const [purchase] = usePurchaseMutation();
  const [clearCartProduct] = useClearCartProductMutation();
  const { data: points } = useGetPointsStatusQuery();
  const navigate = useNavigate();

  let totalQty = rewards?.reduce((acc, curElm) => {
    return acc + curElm?.quantity;
  }, 0);

  const handleOpen = () => {
    setCartOpen(true);
  };
  const handleConfirmPurchase = (user) => {
    let address = {
      isInOfficeDelivery: true,
    };
    if (gift) {
      address = {
        isInOfficeDelivery: true,
        giftFor: user?._id,
      };
    }

    purchase(address)
      .then(() => {
        clearCartProduct();
        navigate('/quest/thank-you');
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="checkout">
      <CartModal
        gift={gift}
        balance={points?.data.walletPoints - totalPoints}
        handleConfirmPurchase={handleConfirmPurchase}
        setToggle={setToggle}
        isToggle={toggle}
        setCartOpen={setCartOpen}
        cartOpen={cartOpen}
      />
      <h1>Order Summary</h1>
      <h5>{totalQty} Items </h5>

      <div className="checkout__seprator"></div>
      <div className="checkout__cal">
        <h3 style={{ fontSize: '20px' }}>Total</h3>
        <div
          style={{
            display: 'flex',
            gap: '2px',
            alignItem: 'center',
            justifyContent: 'center',
          }}>
          <StarBorderOutlinedIcon
            sx={{ fontSize: '20px', color: 'var(--sstar)' }}
          />
          <p
            style={{
              fontSize: '16px',
            }}>
            {' '}
            {totalPoints} Points{' '}
          </p>
        </div>
      </div>
      <div className="checkout__seprator"></div>
      <Button
        onClick={handleOpen}
        disabled={totalQty === 0 ? true : false}
        sx={{
          margin: '40px 0',
          padding: '12px 10px !important',
          fontSize: '16px !important',
        }}
        variant="contained"
        fullWidth>
        Checkout
      </Button>

      <div className="checkout__gift">
        <div className="checkout__bow">
          <img
            src={bowIcon}
            alt=""
          />
        </div>
        <div>
          <Checkbox
            {...label}
            // size="small"
            value={gift}
            onChange={() => setGift((prev) => !prev)}
            sx={{
              '& .MuiSvgIcon-root': { fontSize: '27px', border: 'none' },
              '&:hover': {
                backgroundColor: 'white', // Background color when hovered
                borderColor: 'var(--pblue)', // Border color when hovered
              },
              backgroundColor: 'white', // Default background color
              borderColor: 'var(--pblue)', // Default border color
              borderRadius: '1px', // Set border radius to 0
              border: '0.5px solid !important', // Set border
            }}
          />
          Gift a Friend
        </div>
        <h6>
          Do you want to redeem points for your friend? Simply{' '}
          <span>select the above option</span> during checkout and send it
          directly to your friend.
        </h6>
      </div>
    </div>
  );
};

export default Checkout;
